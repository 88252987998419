import {
  ACTIVITYTICKER_FETCH,
  ACTIVITYTICKER_FETCH_FAIL,
  ACTIVITYTICKER_SUCCESS,
  ActivityTickerAction,
  TickerInfo
} from './types'

export interface State {
  tickerInfo?: TickerInfo[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  tickerInfo: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: ActivityTickerAction): State => {
  switch (action.type) {
    case ACTIVITYTICKER_SUCCESS:
      let items: TickerInfo[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.tickerInfo) {
        return {
          ...state,
          tickerInfo: items
        }
      }

      return {
        ...state,
        tickerInfo: [...state.tickerInfo, ...items]
      }

    case ACTIVITYTICKER_FETCH:
      return {
        ...state,
        pending: true,
        tickerInfo: undefined,
        error: false
      }
    case ACTIVITYTICKER_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }
    default:
      return state
  }
}
