import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  getSystemParamError,
  getSystemParams
} from '../../../../store/admin/sysparam/selectors'
import { SystemParam } from '../../../../store/admin/sysparam/types'
import { useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import { columnDefinitions } from './columnDefs'

interface Props {
  onDoubleClick: (row: any) => void
}

const getRowId: GetRowIdFunc<SystemParam> = ({ data }) => {
  return `${data.id}`
}
const SystemParametersGrid: FC<Props> = (props: Props) => {
  const theme = useAppSelector(getCurrentTheme)

  const onDoubleClick = props.onDoubleClick
  const systemParameters = useAppSelector(getSystemParams)
  const systemParametersError = useAppSelector(getSystemParamError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'name', sort: 'desc' } as const]
        columnApi.applyColumnState({ state: sortModel })
      }
    },
    []
  )

  if (systemParametersError) {
    return <div>{systemParametersError}</div>
  }

  return (
    <div className={cx(gridStyles.gridDimensions, theme)}>
      <AgGridReact
        rowData={systemParameters}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No system parameters found."
        overlayLoadingTemplate="Loading System Parameters…"
        alwaysShowVerticalScroll={true}
        maintainColumnOrder={true}
        defaultColDef={defaultColumnDefinitions}
        onGridReady={onGridReady}
        suppressDragLeaveHidesColumns={true}
        onRowDoubleClicked={onDoubleClick}
        getRowId={getRowId}
      />
    </div>
  )
}

export default SystemParametersGrid
