import React, { useCallback } from 'react'
import Checkbox from '../../../components/Checkbox'

interface Props {
  onChange: (orderId: string) => void
  isChecked: boolean
  orderId: string
  enabled?: boolean
}

const SelectAggressOrderToCancelCheckbox = ({
  onChange,
  isChecked,
  orderId,
  enabled
}: Props) => {
  const onValueChange = useCallback(() => {
    onChange(orderId)
  }, [orderId])
  return (
    <Checkbox
      locator={`select-${orderId}-listTrading-order-cancel-checkbox`}
      checked={isChecked}
      onChange={onValueChange}
      disabled={!enabled}
    />
  )
}

export default SelectAggressOrderToCancelCheckbox
