import {
  EXTERNALORDERSTATS_CLEAR,
  EXTERNALORDERSTATS_GET,
  EXTERNALORDERSTATS_GET_FAIL,
  EXTERNALORDERSTATS_SUCCESS,
  ExternalOrderStatsAction,
  ExternalOrderStatsTable
} from './types'

export interface State {
  externalOrderStats?: ExternalOrderStatsTable
  pending: boolean
  error: boolean
}

export const initialState: State = {
  externalOrderStats: undefined,
  pending: false,
  error: false
}

export default (
  state = initialState,
  action: ExternalOrderStatsAction
): State => {
  switch (action.type) {
    case EXTERNALORDERSTATS_GET:
      return {
        ...state,
        pending: true,
        externalOrderStats: undefined,
        error: false
      }
    case EXTERNALORDERSTATS_GET_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }
    case EXTERNALORDERSTATS_CLEAR:
      return {
        ...state,
        pending: true,
        error: false,
        externalOrderStats: undefined
      }

    case EXTERNALORDERSTATS_SUCCESS:
      let item: any = { rows: [] }

      if ('payload' in action) {
        item = action.payload ?? { rows: [] }
      }
      return {
        ...state,
        pending: false,
        error: false,
        externalOrderStats: { rows: item.data.rows }
      }
    default:
      return state
  }
}
