import {
  ClearExternalOrderStatsAction,
  EXTERNALORDERSTATS_CLEAR,
  EXTERNALORDERSTATS_GET,
  EXTERNALORDERSTATS_GET_FAIL,
  EXTERNALORDERSTATS_INITIALIZE_SUCCESS,
  EXTERNALORDERSTATS_SUCCESS,
  ExternalOrderStatsTable,
  GetExternalOrderStatsAction,
  GetExternalOrderStatsFailAction,
  GetExternalOrderStatsSuccessAction,
  InitializeExternalOrderStatsSuccessAction
} from './types'

export const externalOrderStatsGet = (
  queryUserId: number,
  queryCustId: number,
  startDate: Date,
  endDate: Date
): GetExternalOrderStatsAction => ({
  type: EXTERNALORDERSTATS_GET,
  payload: { queryUserId, queryCustId, startDate, endDate }
})

export const externalOrderStatsClear = (): ClearExternalOrderStatsAction => ({
  type: EXTERNALORDERSTATS_CLEAR,
  payload: {}
})

export const externalOrderStatsGetSuccess = (
  data: ExternalOrderStatsTable
): GetExternalOrderStatsSuccessAction => ({
  type: EXTERNALORDERSTATS_SUCCESS,
  payload: { data }
})

export const externalOrderStatsInitializeSuccess = (
  data: ExternalOrderStatsTable
): InitializeExternalOrderStatsSuccessAction => ({
  type: EXTERNALORDERSTATS_INITIALIZE_SUCCESS,
  payload: { data }
})

export const externalOrderStatsGetFail = (
  error: any
): GetExternalOrderStatsFailAction => ({
  type: EXTERNALORDERSTATS_GET_FAIL,
  payload: error,
  error: true
})
