import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GenericBondsSearch from '../../components/GenericBondsSearch/GenericBondsSearch'
import { formatMaturityDateAsText } from '../../helpers/formatting'
import { useFetchBondsSearchResults } from '../../helpers/hooks/useFetchBondsSearchResults'
import {
  setIssuerFilter,
  setQueryFilter,
  setSecuritiesFilter
} from '../../store/securities/actions'
import {
  getIssuerFilter,
  getQueryFilter,
  getSecuritiesFilter,
  getSecurityStaticDataById
} from '../../store/securities/selectors'
import { addSecuritiesToWatchlist } from '../../store/watchList/actions'

interface BondsListHeaderSearchProps {
  gridIndex: number
}
const BondsListHeaderSearch = ({ gridIndex }: BondsListHeaderSearchProps) => {
  // we separate this out into two variables so calls to set the search term that are just about formatting
  // don't rerun the search
  const [displayedSearch, setDisplayedSearch] = useState('')
  const [searchString, setSearchString] = useState('')

  const dispatch = useDispatch()

  const {
    watchlist,
    watchListResults,
    notInWatchListResults,
    showLiveChecked
  } = useFetchBondsSearchResults(gridIndex, searchString)

  const queryFilter = useSelector(getQueryFilter)(gridIndex)

  /*
      When user clicks "Show all" on an issuer, set the filter to match that issuer.
      When a user clicks on a specific security, change the search to a nicely formatted
      representation of that security
      1) get the values needed to do that:                                               */
  const issuerFilter = useSelector(getIssuerFilter)(gridIndex)
  const securitiesFilter = useSelector(getSecuritiesFilter)(gridIndex)
  const getSecurity = useSelector(getSecurityStaticDataById)
  const sec =
    securitiesFilter && securitiesFilter[0] && getSecurity(securitiesFilter[0])

  //  2) perform the update
  useEffect(() => {
    // we already hit clear and redux hasn't caught up
    if (!displayedSearch) return
    if (issuerFilter) {
      setDisplayedSearch(`${issuerFilter} `)
    } else if (sec) {
      setDisplayedSearch(
        sec
          ? `${sec.issuerSymbol} ${sec.coupon} ${formatMaturityDateAsText(
              sec.maturityDate,
              false
            )}`
          : ''
      )
    } else {
      setDisplayedSearch(queryFilter ?? '')
    }
  }, [issuerFilter, queryFilter, sec, watchlist]) // done reformatting

  const handleSearchChanged = useCallback((search: string) => {
    setDisplayedSearch(search)
    setSearchString(search)
  }, [])

  const onClear = useCallback(() => {
    dispatch(setIssuerFilter(gridIndex, undefined))
    dispatch(setSecuritiesFilter(gridIndex, undefined))
    dispatch(setQueryFilter(gridIndex, undefined))
    setDisplayedSearch('')
    setSearchString('')
  }, [dispatch, gridIndex])

  const onSearchCommit = useCallback(
    (search: string | undefined) => {
      dispatch(setQueryFilter(gridIndex, search))
    },
    [dispatch, gridIndex]
  )

  const onSecurityClicked = useCallback(
    (securityId: number, isInWatchlist: boolean) => {
      const security = getSecurity(securityId)
      if (isInWatchlist) {
        dispatch(setSecuritiesFilter(gridIndex, [securityId]))
      } else {
        if (security && watchlist?.id) {
          dispatch(
            addSecuritiesToWatchlist(
              gridIndex,
              watchlist.id,
              [],
              [security.cusip.length ? security.cusip : security.isin]
            )
          )
        }
      }
    },
    [dispatch, watchlist]
  )

  const onIssuerAddAllClicked = useCallback(
    (issuer: string) => {
      dispatch(
        addSecuritiesToWatchlist(
          gridIndex,
          watchlist!.id!,
          /* notInWatchListResults
            .find((r) => r.issuer === issuer)!
            .securities.map((s) => s.id)*/
          [],
          [issuer]
        )
      )
    },
    [gridIndex, dispatch, watchlist, notInWatchListResults]
  )

  const onIssuerShowAllClicked = useCallback(
    (issuer: string) => {
      dispatch(setIssuerFilter(gridIndex, issuer))
    },
    [gridIndex, dispatch]
  )

  // ---------------------------------------------------------------------------------------------------------------- //
  return (
    <GenericBondsSearch
      search={searchString}
      formattedSearch={displayedSearch}
      onSearchChanged={handleSearchChanged}
      watchListResults={watchListResults}
      notInWatchListResults={notInWatchListResults}
      showLiveChecked={showLiveChecked}
      onClear={onClear}
      onSearchCommit={onSearchCommit}
      onSecurityClicked={onSecurityClicked}
      onIssuerAddAllClicked={onIssuerAddAllClicked}
      onIssuerShowAllClicked={onIssuerShowAllClicked}
    />
  )
}

export default BondsListHeaderSearch
