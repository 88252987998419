import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDatalist from '../../helpers/hooks/useDatalist'
import { userFetch } from '../../store/users/actions'
import { getUsers } from '../../store/users/selectors'
import { User } from '../../store/users/types'
import SearchInput from './SearchInput'

type Props = {
  onUserSelect: (userId: User['id']) => void
  onClear: () => void
}

const userToUserNameAndCust = (user: User) =>
  `${user.userName} (${user.custName})`

const UserSearch = ({ onUserSelect, onClear }: Props) => {
  const dispatch = useDispatch()
  const allUsers = useSelector(getUsers ?? [])
  const [searchTerm, setSearchTerm] = useState('')

  const filteredUsers = useMemo(() => {
    const userNames = (allUsers || []).map(userToUserNameAndCust)
    return Array.from(
      new Set(
        userNames.filter((name) =>
          name.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
      )
    )
  }, [searchTerm, allUsers])

  const { datalist, listId, onInputKeydown } = useDatalist({
    datalistItems: filteredUsers,
    datalistId: searchTerm.length ? 'user-list' : ''
  })

  const handleChange = useCallback((e: ChangeEvent) => {
    setSearchTerm((e.target as HTMLInputElement).value)
  }, [])
  const handleClear = useCallback(() => {
    setSearchTerm('')
    onClear()
  }, [])

  useEffect(() => {
    if (!allUsers?.length) dispatch(userFetch())
  }, [])

  useEffect(() => {
    const selectedUser = allUsers?.find(
      (user) => `${user.userName} (${user.custName})` === searchTerm
    )
    if (selectedUser) {
      onUserSelect(selectedUser.id)
    }
  }, [searchTerm])

  return (
    <>
      <SearchInput
        list={listId}
        data-testid="user-search-input"
        onKeyDownCapture={onInputKeydown}
        onClear={handleClear}
        onChange={handleChange}
        value={searchTerm}
        placeholder="Search by User name"
      />
      {datalist}
    </>
  )
}

export default UserSearch
