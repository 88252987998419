import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getCustomers = createSelector(
  [getState],
  (state) => state.customers
)

export const getCustomerById = createSelector(
  [getState],
  (state) => (id: number) => state.customers?.find((c) => c.id === id)
)

export const getCustomerIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getCustomerError = createSelector(
  [getState],
  (state) => state.error
)
