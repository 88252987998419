import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMarketClosed } from '../../store/market/selectors'
import { SecurityOrderData } from '../../store/securities/reducer'
import { QuoteReliability } from '../../store/securities/types'
import { getHasTradingRights } from '../../store/webSettings/selectors'
import { openAggressorWindow } from '../../store/windows/actions'
import qrStyles from '../../components/QuoteReliability/QuoteReliability.module.scss'
import styles from './bondListStyle.module.scss'

export interface Props {
  security: SecurityOrderData
  type: 'hit' | 'lift'
}

const HitLiftButton: FC<Props> = ({ security, type }) => {
  const dispatch = useDispatch()
  const marketClosed = useSelector(getMarketClosed)
  const hasTradingRights = useSelector(getHasTradingRights)
  const onClick = useCallback(
    (event) => {
      event.preventDefault()
      const order = type === 'hit' ? security.bestBid : security.bestOffer
      dispatch(openAggressorWindow(order!.id, security.id))
    },
    [security, type]
  )

  if (
    (type === 'hit' &&
      (!security.bestBid ||
        !security.bestBid.canAggress ||
        security.isRestricted)) ||
    (type === 'lift' &&
      (!security.bestOffer ||
        !security.bestOffer.canAggress ||
        security.isRestricted))
  ) {
    // not returning null to avoid ~React Component '' not created within 1000ms~ error.
    return <> </>
  }
  let qr = QuoteReliability.All
  if (type === 'hit' && security.bestBid) {
    qr = security.bestBid.qr
  } else if (type === 'lift' && security.bestOffer) {
    qr = security.bestOffer.qr
  }

  return !marketClosed && hasTradingRights ? (
    <div
      className={cx(
        styles.hitLiftButton,
        qrStyles[QuoteReliability[qr]],
        qrStyles[type]
      )}
      onClick={onClick}
      data-testid={
        type === 'hit'
          ? security.id + '-hitbutton'
          : security.id + '-liftbutton'
      }
    >
      {type === 'hit' ? 'Hit' : 'Lift'}
    </div>
  ) : null
}

export default HitLiftButton
