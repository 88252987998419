import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import { benchmarkDataFetchFail, getBenchmarkDataSuccess } from './actions'
import {
  BENCHMARKDATA_FETCH,
  BENCHMARKDATA_FETCH_CANCEL,
  BenchmarkInfo
} from './types'

const fetchBenchmarkDataEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(BENCHMARKDATA_FETCH),
    switchMap(() => {
      const getBenchmarkData$ =
        getHub().stream<BenchmarkInfo>('GetBenchmarkData')
      return getBenchmarkData$.pipe(
        map((info: BenchmarkInfo) => {
          return getBenchmarkDataSuccess(info)
        }),
        takeUntil(action$.pipe(ofType(BENCHMARKDATA_FETCH_CANCEL))),
        catchError((err) => of(benchmarkDataFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchBenchmarkDataEpic)
