import {
  DeleteRestrictedTradesFailureAction,
  DeleteRestrictedTradesSuccessAction,
  RESTRICTED_TRADES_ADD,
  RESTRICTED_TRADES_DELETE,
  RESTRICTED_TRADES_DELETE_FAILURE,
  RESTRICTED_TRADES_DELETE_SUCCESS,
  RESTRICTED_TRADES_FETCH,
  RESTRICTED_TRADES_FETCH_CANCEL,
  RESTRICTED_TRADES_FETCH_FAIL,
  RESTRICTED_TRADES_SAVE,
  RESTRICTED_TRADES_SAVE_FAILURE,
  RESTRICTED_TRADES_SAVE_SUCCESS,
  RestrictedTrades,
  RestrictedTradesAddRestrictedTradesAction,
  RestrictedTradesDeleteAction,
  RestrictedTradesFetchAction,
  RestrictedTradesFetchCancelAction,
  RestrictedTradesFetchFailAction,
  RestrictedTradesSaveAction,
  SaveRestrictedTradesFailureAction,
  SaveRestrictedTradesSuccessAction
} from './types'

export const restrictedTradesFetch = (): RestrictedTradesFetchAction => ({
  type: RESTRICTED_TRADES_FETCH
})

export const getRestrictedTradesSuccess = (
  restrictedTrades: RestrictedTrades[]
): RestrictedTradesAddRestrictedTradesAction => ({
  type: RESTRICTED_TRADES_ADD,
  payload: restrictedTrades
})

export const restrictedTradesFetchFail = (
  error: any
): RestrictedTradesFetchFailAction => ({
  type: RESTRICTED_TRADES_FETCH_FAIL,
  payload: error,
  error: true
})

export const saveRestrictedTrades = (
  param: RestrictedTrades
): RestrictedTradesSaveAction => ({
  type: RESTRICTED_TRADES_SAVE,
  payload: param
})

export const saveRestrictedTradesSuccess = (
  restrictedTradesList: RestrictedTrades,
  transactionId?: number
): SaveRestrictedTradesSuccessAction => ({
  type: RESTRICTED_TRADES_SAVE_SUCCESS,
  payload: { restrictedTradesList, transactionId }
})

export const saveRestrictedTradesFailure = (
  error: any
): SaveRestrictedTradesFailureAction => ({
  type: RESTRICTED_TRADES_SAVE_FAILURE,
  payload: error,
  error: true
})

export const deleteRestrictedTrades = (
  param: RestrictedTrades[]
): RestrictedTradesDeleteAction => ({
  type: RESTRICTED_TRADES_DELETE,
  payload: param
})

export const deleteRestrictedTradesSuccess = (
  restrictedTradesList: RestrictedTrades,
  transactionId?: number
): DeleteRestrictedTradesSuccessAction => ({
  type: RESTRICTED_TRADES_DELETE_SUCCESS,
  payload: { restrictedTradesList, transactionId }
})

export const deleteRestrictedTradesFailure = (
  error: any
): DeleteRestrictedTradesFailureAction => ({
  type: RESTRICTED_TRADES_DELETE_FAILURE,
  payload: error,
  error: true
})

export const unsubscribeFromRestrictedTrades =
  (): RestrictedTradesFetchCancelAction => ({
    type: RESTRICTED_TRADES_FETCH_CANCEL
  })
