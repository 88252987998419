import {
  Trade,
  TRADEBLOTTER_ADD_TRADES,
  TRADEBLOTTER_FETCH,
  TRADEBLOTTER_FETCH_CANCEL,
  TRADEBLOTTER_FETCH_FAIL,
  TradeBlotterAddTradesAction,
  TradeBlotterFetchAction,
  TradeBlotterFetchCancelAction,
  TradeBlotterFetchFailAction
} from './types'

export const tradeBlotterFetch = (): TradeBlotterFetchAction => ({
  type: TRADEBLOTTER_FETCH
})

export const tradeBlotterFetchCancel = (): TradeBlotterFetchCancelAction => ({
  type: TRADEBLOTTER_FETCH_CANCEL
})

export const tradeBlotterFetchFail = (
  error: any
): TradeBlotterFetchFailAction => ({
  type: TRADEBLOTTER_FETCH_FAIL,
  payload: error,
  error: true
})

export const tradeBlotterAddTrades = (
  items: Trade[]
): TradeBlotterAddTradesAction => ({
  type: TRADEBLOTTER_ADD_TRADES,
  payload: items
})
