import dayjs from 'dayjs'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveRestrictedTrades } from '../../../store/admin/restrictedTrades/actions'
import { getRestrictedTrade } from '../../../store/admin/restrictedTrades/selectors'
import ComponentFooter from '../../ComponentFooter/ComponentFooter'
import Modal from '../../GenericModal/Modal'
import styles from '../../GenericModal/modal.module.scss'

interface Props {
  closeFunc: () => void
  isPermanent: boolean
}
const RestrictedTradesModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const dispatch = useDispatch()
  const param = useSelector(getRestrictedTrade)(0)
  const [criteria, setCriteria] = useState<string>('')
  const [rationale, setRationale] = useState<string>('')

  const time = new Date().setHours(17, 0)
  const [restrictUntil, setRestrictUntil] = useState(
    dayjs(time).format('YYYY-MM-DDTHH:mm')
  )

  const criteriaChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCriteria(evt.target.value)
  }

  const rationaleChanged = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRationale(evt.target.value)
  }

  const handleValidateParam = () => {
    if (criteria) {
      const paramToSave = param
      paramToSave.criteria = criteria
      paramToSave.rationale = rationale
      paramToSave.isDeleted = false
      paramToSave.restrictUntil = props.isPermanent
        ? new Date(0)
        : dayjs(restrictUntil).toDate()
      paramToSave.dateTimeRestricted = new Date()
      dispatch(saveRestrictedTrades(paramToSave))
      closeFunc()
    }
  }

  return (
    <Modal>
      <div className={styles.formWrapper}>
        <label>Criteria:</label>
        <input
          type="text"
          className={styles.criteria}
          value={criteria}
          onChange={criteriaChanged}
        />
        {!props.isPermanent && (
          <>
            <label>Restrict Until:</label>
            <input
              className={styles.dateTimePicker}
              type="datetime-local"
              value={restrictUntil}
              onChange={(e) => setRestrictUntil(e.target.value)}
            />
          </>
        )}
        <label>Rationale:</label>
        <textarea value={rationale} onChange={rationaleChanged} rows={10} />
        <ComponentFooter
          onCancelClick={closeFunc}
          onApplyClick={handleValidateParam}
        />
      </div>
    </Modal>
  )
}
export default RestrictedTradesModal
