import { SearchSecuritiesActions } from './actions'
import { SecuritySearchResult } from './types'

export interface Search {
  query: string
  pending: boolean
  error: boolean
  results: SecuritySearchResult[]
  resultsIgnoringWatchlist: SecuritySearchResult[]
}

export interface State {
  searches: { [gridIndex: number]: Search }
}

export const defaultSearch: Search = {
  query: '',
  pending: false,
  error: false,
  results: [],
  resultsIgnoringWatchlist: []
}

export const initialState: State = { searches: {} }

export default (
  state = initialState,
  action: SearchSecuritiesActions
): State => {
  switch (action.type) {
    case 'searchSecurities.searchSecurities':
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.payload.gridIndex]: {
            query: action.payload.query,
            pending: true,
            error: false,
            /* clear old results when first character is typed */
            results:
              (action.payload.query?.length > 1 &&
                state.searches[action.payload.gridIndex]?.results) ||
              [],
            resultsIgnoringWatchlist:
              (action.payload.query?.length > 1 &&
                state.searches[action.payload.gridIndex]
                  ?.resultsIgnoringWatchlist) ||
              []
          }
        }
      }
    case 'searchSecurities.searchSecuritiesSuccess':
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.payload.gridIndex]: {
            ...state.searches[action.payload.gridIndex],
            pending: false,
            results: action.payload.ignoreWatchlist
              ? state.searches[action.payload.gridIndex]?.results || []
              : action.payload.securities,
            resultsIgnoringWatchlist: action.payload.ignoreWatchlist
              ? action.payload.securities
              : state.searches[action.payload.gridIndex]
                  ?.resultsIgnoringWatchlist || []
          }
        }
      }
    case 'searchSecurities.searchSecuritiesFailure':
      return {
        ...state,
        searches: {
          ...state.searches,
          [action.payload.gridIndex]: {
            ...state.searches[action.payload.gridIndex],
            pending: false,
            error: true,
            results: [],
            resultsIgnoringWatchlist: []
          }
        }
      }
    default:
      return state
  }
}
