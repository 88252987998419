import { useSelector } from 'react-redux'
import {
  getEditableTiersForCust,
  getEditableTiersForUser,
  getLiveTiersForCust,
  getLiveTiersForUser
} from '../../../store/admin/tiers/selectors.ts'
import { Customer } from '../../../store/customers/types.ts'
import { User } from '../../../store/users/types.ts'

const useTiersList = (editable: boolean, customer?: Customer, user?: User) => {
  const editableCutTiers = useSelector(getEditableTiersForCust)(customer?.id)
  const liveCustTiers = useSelector(getLiveTiersForCust)(customer?.id)
  const tierListCust = editable ? editableCutTiers : liveCustTiers
  const editableUserTiers = useSelector(getEditableTiersForUser)(user?.id)
  const liveUserTiers = useSelector(getLiveTiersForUser)(user?.id)
  const tierListUser = editable ? editableUserTiers : liveUserTiers

  return customer ? tierListCust! : tierListUser!
}

export default useTiersList
