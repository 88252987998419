import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  benchmarkDataFetch,
  unsubscribeFromBenchmark
} from '../../store/benchmarkData/actions'
import BenchmarkData from './BenchmarkData'
import { getBenchmarkData } from '../../store/benchmarkData/selectors'

const BenchmarkContainer: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(benchmarkDataFetch())
    return () => {
      dispatch(unsubscribeFromBenchmark())
    }
  }, [])
  const benchmarkData = useSelector(getBenchmarkData)
  if (!benchmarkData) return null

  return <BenchmarkData benchmarkData={benchmarkData} />
}

export default BenchmarkContainer
