import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatAggressorLLPrice } from '../../../helpers/formatting'
import { getTotalSecondsBeforeExpiration } from '../../../helpers/orders'
import { Styles } from '../../../helpers/types'
import { Order, OrderStatus, OrderType } from '../../../store/order/types'
import { showTsySetting } from '../../../store/webSettings/selectors'
import { SecurityModalInfo } from '../../../store/windows/types'
import { getServerTimeDelayMs } from '../../../store/ws/selectors'
import GenericDropdownMenu from '../../GenericDropdownMenu/GenericDropdownMenu'
import TimerLeftComponent from '../../Timer/Timer'

interface Props {
  order: Order
  type: OrderType
  securityModalInfo: SecurityModalInfo
  tradeAmt: string | undefined
  isLastLook: boolean
  // FIXME: define style classname types
  styles: Styles
  orderStatusComp: OrderStatus | undefined
  shouldAlertPriceChange?: boolean
  shouldAlertSizeChange?: boolean
  onChangeTradeAmt?: (e: React.ChangeEvent<HTMLInputElement>) => void
  spotCrossSelection: string
  setSpotCrossSelection: (st: string) => void
  handleCloseModal: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleRejectButtonClick?: () => void
  handleConfirmButtonClick?: (tradeAmt?: number) => () => void
  selectedAccount?: string
  setSelectedAccount?: (st: string) => void
  currency: string
  buySellAmount: number
  isTreasury: boolean
}

const AggressorModalContent: FC<Props> = (props) => {
  const {
    order,
    type,
    securityModalInfo,
    tradeAmt,
    isLastLook,
    styles,
    onChangeTradeAmt,
    spotCrossSelection,
    setSpotCrossSelection,
    handleCloseModal,
    handleConfirmButtonClick,
    handleRejectButtonClick,
    orderStatusComp,
    shouldAlertPriceChange,
    shouldAlertSizeChange,
    selectedAccount,
    setSelectedAccount,
    currency,
    buySellAmount,
    isTreasury
  } = props

  const showTsy = useSelector(showTsySetting)

  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)

  const [prc, setPrc] = useState(0)
  const [allInPrice, setAllInPrice] = useState(0)
  const [ytw, setYtw] = useState(0)
  const [bmPrice, setBmPrice] = useState<number | undefined>(0)
  const [hedgeNotional, setHedgeNotional] = useState<number | undefined>(0)
  const [spread, setSpread] = useState(0)

  useEffect(() => {
    if (
      (orderStatusComp === 'creationPending' && !buySellAmount) ||
      orderStatusComp === 'cancelled'
    ) {
      setPrc(securityModalInfo.price)
      setAllInPrice(securityModalInfo.allInPrice)
      setYtw(securityModalInfo.yieldToWorst)
      setBmPrice(securityModalInfo.benchmarkPrice)
      setHedgeNotional(securityModalInfo.hedgeNotional)
      setSpread(securityModalInfo.spread)
    }
  }, [
    orderStatusComp,
    securityModalInfo.price,
    securityModalInfo.allInPrice,
    securityModalInfo.yieldToWorst,
    securityModalInfo.benchmarkPrice,
    securityModalInfo.hedgeNotional
  ])

  const isActive = orderStatusComp === 'waitingForConfirmation'

  const rightSideInfoClasses = cx(
    styles.rightInfos,
    orderStatusComp !== 'accepted' && !isActive && isLastLook && styles.timedOut
  )

  const minSizeForTsyHedge = securityModalInfo.minSizeForTsyHedge
    ? securityModalInfo.minSizeForTsyHedge
    : '500'

  const disableButton =
    orderStatusComp === 'accepted' ||
    orderStatusComp === 'rejected' ||
    orderStatusComp === 'waitingForConfirmation'

  const disableCrossButton =
    disableButton || !bmPrice || Number(tradeAmt) < Number(minSizeForTsyHedge)

  const accountOptions = Object.values(securityModalInfo.accountIdToName).sort()
  const getRightSideContent = () => {
    if (isLastLook) {
      const timerColors =
        isActive || orderStatusComp === 'accepted'
          ? 'var(--activeBackgroundColor)'
          : 'var(--primaryTextColor)'

      const { totalSeconds } = getTotalSecondsBeforeExpiration(
        order.aggressorOrder?.expiration,
        order.aggressorOrder?.submitTime,
        serverTimeDelayMs
      )

      return (
        <>
          <div className={styles.timerWrapper}>
            <TimerLeftComponent
              valueColor={timerColors}
              timerInit={totalSeconds}
              isPlaying={isActive}
              valueSize="big"
              totalSecondsBeforeExpiration={{
                expiration: order.aggressorOrder?.expiration,
                submitTime: order.aggressorOrder?.submitTime,
                serverTimeDelayMs
              }}
            />
          </div>
          <div className={styles.timerActionWrapper}>
            {(orderStatusComp === 'accepted' ||
              orderStatusComp === 'rejected' ||
              orderStatusComp === 'cancelled') && (
              <button
                className={
                  orderStatusComp === 'accepted'
                    ? styles.timerActionCloseButtonAccepted
                    : styles.timerActionCloseButton
                }
                data-testid="lastlook-close"
                onClick={handleCloseModal}
              >
                Close
              </button>
            )}
            {orderStatusComp === 'waitingForConfirmation' && (
              <>
                <button
                  data-testid="lastlook-reject"
                  className={styles.timerActionRejectButton}
                  onClick={handleRejectButtonClick}
                >
                  Reject
                </button>
                <button
                  data-testid="lastlook-confirm"
                  className={styles.timerActionMainButton}
                  onClick={
                    handleConfirmButtonClick &&
                    handleConfirmButtonClick(Number(tradeAmt))
                  }
                >
                  CONFIRM
                </button>
              </>
            )}
          </div>
        </>
      )
    } else {
      return (
        <div className={styles.allInPrice}>
          <p>All-in Price</p>
          <p
            data-testid="aggressor-all-in-price"
            className={cx(
              styles.value,
              shouldAlertPriceChange && styles.warning
            )}
          >
            {formatAggressorLLPrice(allInPrice, currency, isTreasury)}
          </p>
          {showTsy && (
            <div className={styles.aggressorPriceHedge}>
              Price/hedge options:
              <button
                type="button"
                className={cx(
                  styles.aggressorPriceHedgeOption,
                  spotCrossSelection === 'S' && styles.selectedButton
                )}
                onClick={() => setSpotCrossSelection('S')}
                disabled={disableButton}
                title={disableButton ? 'Not Available' : ''}
              >
                Auto-SPOT (Tsy)
              </button>
              <button
                type="button"
                className={cx(
                  styles.aggressorPriceHedgeOption,
                  spotCrossSelection === 'H' && styles.selectedButton
                )}
                onClick={() => setSpotCrossSelection('H')}
                disabled={disableCrossButton}
                title={disableCrossButton ? 'Not Available' : ''}
              >
                Auto-HEDGE (Tsy)
              </button>
            </div>
          )}
        </div>
      )
    }
  }
  return (
    <div
      className={cx(
        styles.content,
        setSelectedAccount ? styles.aggressorContent : styles.lastLookContent
      )}
    >
      <div className={styles.leftInfos}>
        <div className={styles.row}>
          <div className={styles.inputContent}>
            <label>Trade Amt</label>
            {isLastLook ? (
              <p data-testid="lastlook-amt" className={styles.value}>
                {tradeAmt}
              </p>
            ) : (
              <>
                <input
                  value={tradeAmt}
                  data-testid="aggressor-trade-amt"
                  onChange={onChangeTradeAmt}
                  disabled={
                    orderStatusComp !== 'creationPending' &&
                    orderStatusComp !== 'cancelled' &&
                    orderStatusComp !== 'error'
                  }
                  type="number"
                  autoFocus
                  className={
                    type === 'buy'
                      ? styles.buySecondColor
                      : styles.sellSecondColor
                  }
                />
                <span className={styles.minQty}>
                  Minimum Qty:{' '}
                  <span data-testid="aggressor-minimumquantity">
                    {securityModalInfo.minimumSize}{' '}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className={styles.inputContent}>
            <p>Spread</p>
            <p
              className={cx(
                styles.value,
                shouldAlertPriceChange && styles.warning
              )}
            >
              {spread}
            </p>
          </div>
        </div>
        {selectedAccount && setSelectedAccount && (
          <div className={styles.accountLine}>
            Account:
            <GenericDropdownMenu
              selectedItem={selectedAccount}
              setSelectedItem={setSelectedAccount}
              options={accountOptions}
              selectId={''}
              openRight={true}
              className={''}
            />
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.inputContent}>
            <p>Available Amt</p>
            <p
              data-testid="aggressor-amt"
              className={cx(
                styles.value,
                shouldAlertSizeChange && styles.warning
              )}
            >
              {securityModalInfo.maximumSize}
            </p>
          </div>
          <div className={styles.inputContent}>
            <p>Price</p>
            <p
              data-testid="aggressor-price"
              className={cx(
                styles.value,
                shouldAlertPriceChange && styles.warning
              )}
            >
              {formatAggressorLLPrice(prc, currency, isTreasury)}
            </p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.inputContent}>
            <p>*Bench Price</p>
            <p data-testid="aggressor-bench-price" className={styles.value}>
              {bmPrice}
            </p>
          </div>
          <div className={styles.inputContent}>
            <p>YTW</p>
            <p
              data-testid="aggressor-YTW"
              className={cx(
                styles.value,
                shouldAlertPriceChange && styles.warning
              )}
            >
              {ytw}
            </p>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.inputContent}>
            <p>Hedge Notional</p>
            <p data-testid="aggressor-hedge-notional" className={styles.value}>
              {hedgeNotional}
            </p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.disclaimer}>
            <p>*Treasury execution/hedge may occur at a different price</p>
          </div>
        </div>
      </div>
      <div className={rightSideInfoClasses}>{getRightSideContent()}</div>
    </div>
  )
}

export default AggressorModalContent
