import { days, fullMonth } from '../../helpers/formatting'
import { OrderType } from '../../store/order/types'
import { StagedOrder } from '../../store/stagedOrders/types'

export const formatDate = (date: Date) =>
  `${days[date.getDay()]},  ${fullMonth[date.getMonth()]} ${date.getDate()}`

export const formatResubmitDate = (date: Date) =>
  date ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : ''

export interface LastDay {
  isSelected: boolean
  dateFormat: string
  id: number
  stagedOrders: StagedOrder[]
}

export interface MyOrderList {
  securityId: number
  orderType: OrderType
  price: number
  size: number
  submitTime: Date
}
