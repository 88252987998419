/**
 * This context is for providing a true/false value to the children of the component
 * that can be in a popout or not so those children can change their behavior/styling based on
 * if they're in a popout without needing so much prop drilling.
 *
 * If there is no provider above them in the tree, they will receive a false value.
 */
import {createContext, useContext} from "react";

export const IsInPopupContext = createContext(false)

export function useIsInPopup() {
  const isInPopup = useContext(IsInPopupContext)
  return {isInPopup}
}
