import { Action } from 'redux'
import { ActivityFilterId } from './types'

export interface SearchActivityAction extends Action {
  type: 'activity.search'
  payload: string
}

export const searchActivity = (payload: string): SearchActivityAction => ({
  type: 'activity.search',
  payload
})

export interface FilterActivityAction extends Action {
  type: 'activity.filter'
  payload: string
}

export const filterActivity = (
  payload: ActivityFilterId
): FilterActivityAction => ({
  type: 'activity.filter',
  payload
})

export interface ToggleActivityPanelAction extends Action {
  type: 'activity.toggle-visibility'
}

export const toggleActivityPanel = (): ToggleActivityPanelAction => ({
  type: 'activity.toggle-visibility'
})

export type ActivityAction =
  | SearchActivityAction
  | FilterActivityAction
  | ToggleActivityPanelAction
