import { addOrUpdateSystemParam } from './helpers'
import {
  SystemParam,
  SYSTEMPARAM_ADD,
  SYSTEMPARAM_FETCH,
  SYSTEMPARAM_FETCH_FAIL,
  SystemParamAction
} from './types'

export interface State {
  systemParams?: SystemParam[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  systemParams: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: SystemParamAction): State => {
  switch (action.type) {
    case SYSTEMPARAM_FETCH:
      return {
        ...state,
        pending: true,
        systemParams: undefined,
        error: false
      }
    case SYSTEMPARAM_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case SYSTEMPARAM_ADD:
      let items: SystemParam[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.systemParams) {
        return {
          ...state,
          systemParams: items
        }
      }

      return {
        ...state,
        systemParams: addOrUpdateSystemParam(state.systemParams, items)
      }
    default:
      return state
  }
}
