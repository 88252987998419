import { UserDashboardAction } from './actions'
import { UserDashboardEntry } from './types'

export interface State {
  userDashboard: UserDashboardEntry[]
}

const initialState: State = {
  userDashboard: []
}

const reducer = (state = initialState, action: UserDashboardAction) => {
  switch (action.type) {
    case 'userDashboard.updateUserDashboard':
      return {
        ...state,
        userDashboard: action.payload.userDashboard
      }
    default:
      return state
  }
}

export default reducer
