import { PassiveOrder } from './types'

export const getNewPassiveOrders = (
  stateOrders: PassiveOrder[],
  payloadOrders: PassiveOrder[]
): PassiveOrder[] => {
  const ret: PassiveOrder[] = payloadOrders
  stateOrders.forEach((po) => {
    if (!ret.some((p) => p.id === po.id)) {
      ret.push(po)
    }
  })
  return ret
}
