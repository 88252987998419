import React, { useCallback, useMemo } from 'react'
import { Order } from '../../../store/order/types'
import Checkbox from '../../Checkbox'
import { OrderProps } from '../helpers.ts'

interface Props {
  checked: boolean
  enabled: boolean
  testId: string
  onToggle: () => void
}

const SelectOrder = ({ enabled, testId, onToggle, checked }: Props) => {
  return (
    <td>
      <Checkbox
        locator={testId}
        disabled={!enabled}
        onClick={onToggle}
        checked={checked}
      />
    </td>
  )
}

type Config = {
  onOrderToggle: (orderId: string) => void
  checkedFn: (orderId: string) => boolean
  disableFn: (order: Order) => boolean
}

const configureSelectOrder =
  ({ onOrderToggle, checkedFn, disableFn }: Config) =>
  ({ order }: OrderProps) => {
    const enabled = useMemo(() => !disableFn(order), [order, disableFn])
    const onToggle = useCallback(() => {
      onOrderToggle(order.id)
    }, [order])
    const testId = `select-order-${order.id}-lt`
    const checked = checkedFn(order.id)
    return (
      <SelectOrder
        enabled={enabled}
        onToggle={onToggle}
        testId={testId}
        checked={checked}
      />
    )
  }

export default configureSelectOrder
