import React, { ChangeEvent, useCallback } from 'react'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { useManageGridCellFocus } from './useManageGridCellFocus'
import { WorkingOrderFieldEditorProps } from './WorkingOrderFieldRenderer'

const SpreadEditor = ({ data, watchlistId }: WorkingOrderFieldEditorProps) => {
  const { order, updateFields } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin
  )

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    const isSpread = value.length > 0
    const price = Number(value)
    updateFields({ price, isSpread })
  }, [])

  const inputRef = useManageGridCellFocus()

  if (!data) return <></>

  const initialValue = !order.isSpread ? undefined : order.price
  const fieldId = `listTrading-edit${data.id}-spread`
  return (
    <input
      ref={inputRef}
      id={fieldId}
      data-testid={fieldId}
      className="ag-input-field-input ag-text-field-input"
      type="number"
      defaultValue={initialValue || ''}
      onChange={onChange}
    />
  )
}

export default SpreadEditor
