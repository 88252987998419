import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getActiveLastLookWindow,
  getLastLookWindows
} from '../../store/windows/selectors'
import { SecurityModalInfo } from '../../store/windows/types'
import Popout from '../Popouts/Popout'
import LastLookModal from '../../components/Modal/LastLookModal/LastLookModal'
import { useActiveWindow } from '../../components/Modal/helpers'
import { foregroundLastLookWindowAtIndex } from '../../store/windows/actions'

const LastLookOpener: FC = () => {
  const lastLookWindows = useSelector(getLastLookWindows)
  const activeModalIndex = useSelector(getActiveLastLookWindow)
  const { handleMinimize } = useActiveWindow(
    lastLookWindows.length,
    activeModalIndex,
    foregroundLastLookWindowAtIndex
  )

  return lastLookWindows.length > 0 ? (
    <div>
      {lastLookWindows.map((windowInfo: SecurityModalInfo, i: number) => (
        <Popout
          key={windowInfo.orderId}
          title={`LastLook/${windowInfo.orderId}`}
          windowInfo={windowInfo}
        >
          <LastLookModal
            key={windowInfo.orderId}
            windowInfo={windowInfo}
            isActiveWindow={true}
            handleMinimize={handleMinimize}
            index={i}
          />
        </Popout>
      ))}
    </div>
  ) : null
}

export default LastLookOpener
