import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Action } from 'redux'

export const useActiveWindow = (
  windowsLength: number,
  activeModalIndex: number | undefined,
  setActiveModalIndexAction: (newIndex: number | undefined) => Action
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setActiveModalIndexAction(0))
  }, [windowsLength])

  const handleMinimize = useCallback(
    (index: number) => {
      dispatch(
        setActiveModalIndexAction(activeModalIndex === index ? -1 : index)
      )
    },
    [activeModalIndex]
  )

  return { handleMinimize }
}
