import { faSpinner } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CurrencyValues } from '../../store/filter/types'
import { QuoteReliabilityDisplayNames } from '../../store/securities/types'
import {
  fetchUserEditorData,
  saveUserEditorData
} from '../../store/userEditorData/actions'
import { getUserEditorDataById } from '../../store/userEditorData/selectors'
import {
  NoBrokerSet,
  SubAccountInfo,
  UserEditorData
} from '../../store/userEditorData/types'
import Checkbox from '../Checkbox'
import ComponentFooter from '../ComponentFooter/ComponentFooter'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import { modalHeaderClose } from '../ComponentHeader/helpers'
import GenericDropdownMenu from '../GenericDropdownMenu/GenericDropdownMenu'
import GenericDropdownMenuMulti from '../GenericDropdownMenu/GenericDropdownMenuMulti'
import Modal from '../GenericModal/Modal'
import SubAccountEditor from './SubAccountEditor'
import styles from './UserEditor.module.scss'

interface Props {
  closeFunc: () => void
  userId: number
  wrapModal: boolean
}

// TODO: WIRE UP EMPTY INPUTS

const UserEditorModal: FC<Props> = ({ closeFunc, userId, wrapModal }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserEditorData(userId))
  }, [])

  const user = useSelector(getUserEditorDataById)(userId)
  const handleValidatePreferences = () => {
    const custName = user.possibleCustomerRecords.find(
      (name) => name.customerName === account
    )
    const userClass = user.possibleUserClassifications.find(
      (name) => name.userClassName === userClassification
    )
    if (!custName) {
      setError('Invalid Account')
      return
    }

    if (!userClass) {
      setError('Invalid User classification')
      return
    }

    const broker1AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1A) ??
      NoBrokerSet
    const broker2AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2A) ??
      NoBrokerSet
    const broker3AUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3A) ??
      NoBrokerSet
    const broker1BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1B) ??
      NoBrokerSet
    const broker2BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2B) ??
      NoBrokerSet
    const broker3BUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3B) ??
      NoBrokerSet
    const broker1CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker1C) ??
      NoBrokerSet
    const broker2CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker2C) ??
      NoBrokerSet
    const broker3CUserInfo =
      user.possibleBrokers.find((broker) => broker.userName === broker3C) ??
      NoBrokerSet

    const salesPerson1UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson1
      ) ?? NoBrokerSet
    const salesPerson2UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson2
      ) ?? NoBrokerSet
    const salesPerson3UserInfo =
      user.possibleBrokers.find(
        (salesperson) => salesperson.userName === salesPerson3
      ) ?? NoBrokerSet

    enabledSubAccounts.forEach((sa) => {
      if (defaultSubAcct?.custId === sa.custId) {
        sa.isDefault = true
      } else {
        sa.isDefault = false
      }
    })

    const usr: UserEditorData = {
      id: userId,
      userName,
      possibleUserClassifications: [],
      possibleCustomerRecords: [],
      possibleBrokers: [],
      cust: custName,
      userClassification: userClass,
      emailAddress,
      isAdmin,
      isBackOffice,
      isBroker,
      doNotAddToAD,
      isWebUser,
      ableToSeeTradersTheyCover,
      ableToSeeAllTradersAtFirm,
      logonName: '',
      loginPassword: '',
      automaticallyEnabledNewAccounts,
      subAccounts: enabledSubAccounts,
      broker1A: broker1AUserInfo,
      broker1APct,
      broker2A: broker2AUserInfo,
      broker2APct,
      broker3A: broker3AUserInfo,
      broker3APct,
      broker1B: broker1BUserInfo,
      broker1BPct,
      broker2B: broker2BUserInfo,
      broker2BPct,
      broker3B: broker3BUserInfo,
      broker3BPct,
      broker1C: broker1CUserInfo,
      broker1CPct,
      broker2C: broker2CUserInfo,
      broker2CPct,
      broker3C: broker3CUserInfo,
      broker3CPct,
      salesPersonWhoCovers1: salesPerson1UserInfo,
      salesPersonWhoCovers2: salesPerson2UserInfo,
      salesPersonWhoCovers3: salesPerson3UserInfo,
      currencies: selectedCurrencies,
      validMacs: '',
      bloombergUUID: '',
      qr: selectedQR,
      canRunReports,
      highlightWhenNotLogged,
      markAsDeleted,
      lastClickThroughVersion: '',
      defaultFIXAccountAlias: '',
      maxSingleOrderLimit
    }

    dispatch(saveUserEditorData(usr))
    closeFunc()
  }

  const [error, setError] = useState<string>('')
  const [doNotAddToAD, setDoNotAddToAD] = useState<boolean>(false)
  const [isWebUser, setIsWebUser] = useState<boolean>(false)
  const [ableToSeeTradersTheyCover, setAbleToSeeTradersTheyCover] =
    useState<boolean>(false)
  const [ableToSeeAllTradersAtFirm, setAbleToSeeAllTradersAtFirm] =
    useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  const [account, setAccount] = useState<string>('')
  const [userClassification, setUserClassification] = useState<string>('')

  const [broker1A, setBroker1A] = useState<string>(' ')
  const [broker1APct, setBroker1APct] = useState<string>('')
  const [broker1B, setBroker1B] = useState<string>('&nbsp;')
  const [broker1BPct, setBroker1BPct] = useState<string>('')
  const [broker1C, setBroker1C] = useState<string>('&nbsp;')
  const [broker1CPct, setBroker1CPct] = useState<string>('')

  const [broker2A, setBroker2A] = useState<string>('&nbsp;')
  const [broker2APct, setBroker2APct] = useState<string>('')
  const [broker2B, setBroker2B] = useState<string>('&nbsp;')
  const [broker2BPct, setBroker2BPct] = useState<string>('')
  const [broker2C, setBroker2C] = useState<string>('&nbsp;')
  const [broker2CPct, setBroker2CPct] = useState<string>('')

  const [broker3A, setBroker3A] = useState<string>('&nbsp;')
  const [broker3APct, setBroker3APct] = useState<string>('')
  const [broker3B, setBroker3B] = useState<string>('&nbsp;')
  const [broker3BPct, setBroker3BPct] = useState<string>('')
  const [broker3C, setBroker3C] = useState<string>('&nbsp;')
  const [broker3CPct, setBroker3CPct] = useState<string>('')

  const [salesPerson1, setSalesPerson1] = useState<string>('&nbsp;')
  const [salesPerson2, setSalesPerson2] = useState<string>('&nbsp;')
  const [salesPerson3, setSalesPerson3] = useState<string>('&nbsp;')

  const [subAccounts, setSubAccounts] = useState<SubAccountInfo[]>([])
  const [enabledSubAccounts, setEnabledSubAccounts] = useState<
    SubAccountInfo[]
  >([])

  const [emailAddress, setEmailAddress] = useState<string>('')

  const [defaultSubAcct, setDefaultSubAcct] = useState<SubAccountInfo>()

  const setDefaultSubAccount = (sa: SubAccountInfo) => {
    if (defaultSubAcct === undefined || defaultSubAcct.custId !== sa.custId) {
      setDefaultSubAcct(sa)
    }
  }

  const setOrUnsetSubAccount = (sa: SubAccountInfo) => {
    const subAcctFromSelected = enabledSubAccounts.findIndex(
      (sub) => sub.custId === sa.custId
    )
    if (subAcctFromSelected > -1) {
      const tempArr = enabledSubAccounts
      tempArr.splice(subAcctFromSelected, 1)
      setEnabledSubAccounts([...tempArr])
    } else {
      const tempArr = enabledSubAccounts
      tempArr.push(sa)
      setEnabledSubAccounts([...tempArr])
    }
  }

  const [automaticallyEnabledNewAccounts, setAutomaticallyEnabledNewAccounts] =
    useState<boolean>(false)

  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([])

  const doNotAddToADChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotAddToAD(evt.target.checked)
  }

  const isWebUserChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsWebUser(evt.target.checked)
  }

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isBackOffice, setIsBackOffice] = useState<boolean>(false)
  const [isBroker, setIsBroker] = useState<boolean>(false)

  const isAdminChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(evt.target.checked)
  }

  const isBackOfficeChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsBackOffice(evt.target.checked)
  }

  const isBrokerChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsBroker(evt.target.checked)
  }

  const [canRunReports, setCanRunReports] = useState<boolean>(false)
  const [submitsExternalSTPTradesFromAPI, setSubmitsExternalSTPTradesFromAPI] =
    useState<boolean>(false)
  const [highlightWhenNotLogged, setHighlightWhenNotLogged] =
    useState<boolean>(false)
  const [markAsDeleted, setMarkAsDeleted] = useState<boolean>(false)

  const [maxSingleOrderLimit, setMaxSingleOrderLimit] = useState<string>('')

  const canRunReportsChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCanRunReports(evt.target.checked)
  }

  const [selectedQR, setSelectedQR] = useState<string>('')
  const handleQRChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedQR(evt.target.value)
  }

  const submitsExternalSTPTradesFromAPIChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubmitsExternalSTPTradesFromAPI(evt.target.checked)
  }

  const highlightWhenNotLoggedChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHighlightWhenNotLogged(evt.target.checked)
  }

  const markAsDeletedChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setMarkAsDeleted(evt.target.checked)
  }

  const ableToSeeTradersTheyCoverChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAbleToSeeTradersTheyCover(evt.target.checked)
  }

  const ableToSeeAllTradersAtFirmChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAbleToSeeAllTradersAtFirm(evt.target.checked)
  }

  useEffect(() => {
    if (user) {
      setAccount(
        user.cust
          ? user.cust.customerName
          : user.possibleCustomerRecords[0].customerName
      )
      setUserClassification(
        user.userClassification
          ? user.userClassification.userClassName
          : user.possibleUserClassifications[0].userClassName
      )
      setUserName(user.userName)
      setBroker1A(user.broker1A?.userName)
      setBroker1APct(user.broker1APct)
      setBroker1B(user.broker1B?.userName)
      setBroker1BPct(user.broker1BPct)
      setBroker1C(user.broker1C?.userName)
      setBroker1CPct(user.broker1CPct)
      setBroker2A(user.broker2A?.userName)
      setBroker2APct(user.broker2APct)
      setBroker2B(user.broker2B?.userName)
      setBroker2BPct(user.broker2BPct)
      setBroker2C(user.broker2C?.userName)
      setBroker2CPct(user.broker2CPct)
      setBroker3A(user.broker3A?.userName)
      setBroker3APct(user.broker3APct)
      setBroker3B(user.broker3B?.userName)
      setBroker3BPct(user.broker3BPct)
      setBroker3C(user.broker3C?.userName)
      setBroker3CPct(user.broker3CPct)

      setSalesPerson1(user.salesPersonWhoCovers1?.userName)
      setSalesPerson2(user.salesPersonWhoCovers2?.userName)
      setSalesPerson3(user.salesPersonWhoCovers3?.userName)

      const defaultSubAccount = user.subAccounts.find((sa) => sa.isDefault)
      setDefaultSubAcct(defaultSubAccount)

      const enabled = user.subAccounts.filter((sa) => sa.enabled)
      setEnabledSubAccounts(enabled)
      setEmailAddress(user.emailAddress)

      setSubAccounts(user.subAccounts)
      setIsAdmin(user.isAdmin)
      setIsBackOffice(user.isBackOffice)
      setIsBroker(user.isBroker)
      setSelectedCurrencies(user.currencies)

      setSelectedQR(user.qr)
      setMaxSingleOrderLimit(user.maxSingleOrderLimit)
    }
  }, [user])

  const qrOptions = Object.entries(QuoteReliabilityDisplayNames).map(
    ([value, label]) => ({
      value,
      label
    })
  )

  const editorForm = () => {
    if (!user) {
      return (
        <div className="loading-error-container">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </div>
      )
    }

    return (
      <>
        <ComponentHeader
          title={`User editor for ${user?.userName}`}
          headerButtons={modalHeaderClose(closeFunc)}
        />
        <form className={styles.userEditorForm}>
          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>
              General Account Information
            </legend>

            <div className={styles.accountContainer}>
              <div className={styles.accountRow}>
                <label className={styles.accountLabel}>User Name:</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(event) => setUserName(event.currentTarget.value)}
                ></input>
                <label className={styles.accountLabel}>
                  User Classification:
                </label>
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setUserClassification}
                    options={user?.possibleUserClassifications.map(
                      (uc) => uc.userClassName
                    )}
                    selectedItem={userClassification}
                    selectId={'UserClassifications'}
                    openRight={false}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                  />
                </div>
              </div>

              <div className={styles.accountRow}>
                <label className={styles.accountLabel}>Logon Name:</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(event) => setUserName(event.currentTarget.value)}
                ></input>
                <label className={styles.accountLabel}>Account:</label>
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setAccount}
                    options={user?.possibleCustomerRecords.map(
                      (c) => c.customerName
                    )}
                    selectedItem={account}
                    selectId={'AlertWatchlists'}
                    openRight={false}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                  />
                </div>
              </div>

              <div className={styles.accountRow}>
                <label className={styles.accountLabel}>Logon Password:</label>
                <input type="text" />
                <label className={styles.accountLabel}>Email Address:</label>
                <div className={styles.accountDropdown}>
                  <input
                    type="text"
                    value={emailAddress}
                    onChange={(event) =>
                      setEmailAddress(event.currentTarget.value)
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className={styles.checkboxContainer}>
              <Checkbox
                locator={''}
                checked={doNotAddToAD}
                onChange={doNotAddToADChanged}
              >
                Do not add to AD
              </Checkbox>

              <Checkbox
                locator={''}
                checked={isWebUser}
                onChange={isWebUserChanged}
              >
                Web User
              </Checkbox>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                locator={''}
                checked={ableToSeeTradersTheyCover}
                onChange={ableToSeeTradersTheyCoverChanged}
              >
                Able to see traders they cover
              </Checkbox>
              <Checkbox
                locator={''}
                checked={ableToSeeAllTradersAtFirm}
                onChange={ableToSeeAllTradersAtFirmChanged}
              >
                Able to see all traders at firm
              </Checkbox>
            </div>

            <div className={styles.checkboxAccountContainer}>
              <label>User Privileges</label>
              <div className={styles.checkboxContainer}>
                <Checkbox
                  locator={''}
                  checked={isAdmin}
                  onChange={isAdminChanged}
                >
                  Admin
                </Checkbox>
                <Checkbox
                  locator={''}
                  checked={isBackOffice}
                  onChange={isBackOfficeChanged}
                >
                  Back Office
                </Checkbox>
                <Checkbox
                  locator={''}
                  checked={isBroker}
                  onChange={isBrokerChanged}
                >
                  Broker
                </Checkbox>
              </div>
            </div>
          </fieldset>

          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>Sub Accounts</legend>
            <div className={styles.subAccountContainer}>
              <SubAccountEditor
                subAccounts={subAccounts}
                enabledSubAccounts={enabledSubAccounts}
                setSubAccounts={setOrUnsetSubAccount}
                defaultAccount={defaultSubAcct}
                setDefaultAccount={setDefaultSubAccount}
                automaticallyEnabledNewAccounts={
                  automaticallyEnabledNewAccounts
                }
                setAutomaticallyEnabledNewAccounts={
                  setAutomaticallyEnabledNewAccounts
                }
              />
            </div>
          </fieldset>

          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>Broker Coverage</legend>
            <div className={styles.accountRow}>
              Default Broker(s) who Cover:
            </div>
            <div className={styles.accountContainer}>
              <div className={styles.accountRow}>
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker1A}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker1A}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker1APct}
                  onChange={(event) =>
                    setBroker1APct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker1B}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker1B}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker1BPct}
                  onChange={(event) =>
                    setBroker1BPct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker1C}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker1C}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker1CPct}
                  onChange={(event) =>
                    setBroker1CPct(event.currentTarget.value)
                  }
                />
                %
              </div>
            </div>

            <div className={styles.accountRow}>
              #2 Default Broker(s) who Cover:
            </div>
            <div className={styles.accountContainer}>
              <div className={styles.accountRow}>
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker2A}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker2A}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker2APct}
                  onChange={(event) =>
                    setBroker2APct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker2B}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker2B}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker2BPct}
                  onChange={(event) =>
                    setBroker2BPct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker2C}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker2C}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker2CPct}
                  onChange={(event) =>
                    setBroker2CPct(event.currentTarget.value)
                  }
                />
                %
              </div>
            </div>

            <div className={styles.accountRow}>
              #3 Default Broker(s) who Cover:
            </div>
            <div className={styles.accountContainer}>
              <div className={styles.accountRow}>
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker3A}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker3A}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker3APct}
                  onChange={(event) =>
                    setBroker3APct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker3B}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker3B}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker3BPct}
                  onChange={(event) =>
                    setBroker3BPct(event.currentTarget.value)
                  }
                />
                %
                <div className={styles.accountDropdown}>
                  <GenericDropdownMenu
                    setSelectedItem={setBroker3C}
                    options={user?.possibleBrokers.map((u) => u.userName)}
                    selectedItem={broker3C}
                    selectId={'AlertWatchlists'}
                    openRight={true}
                    className={cx(styles.userClassDropdown, styles.dropdown)}
                    placeholder="Select Broker"
                  />
                </div>
                <input
                  type="number"
                  className={styles.brokerPercent}
                  value={broker3CPct}
                  onChange={(event) =>
                    setBroker3CPct(event.currentTarget.value)
                  }
                />
                %
              </div>
            </div>
          </fieldset>

          <div className={styles.smallFieldsetGroup}>
            <fieldset className={cx(styles.fieldset, styles.smallFieldset)}>
              <legend className={styles.legend}>Salesperson Coverage</legend>
              <label>Salesperson(s) Who Cover:</label>
              <GenericDropdownMenu
                setSelectedItem={setSalesPerson1}
                options={user?.possibleBrokers.map((u) => u.userName)}
                selectedItem={salesPerson1}
                selectId={'AlertWatchlists'}
                openRight={true}
                className={cx(styles.userClassDropdown, styles.dropdown)}
              />
              <GenericDropdownMenu
                setSelectedItem={setSalesPerson2}
                options={user?.possibleBrokers.map((u) => u.userName)}
                selectedItem={salesPerson2}
                selectId={'AlertWatchlists'}
                openRight={true}
                className={cx(styles.userClassDropdown, styles.dropdown)}
              />
              <GenericDropdownMenu
                setSelectedItem={setSalesPerson3}
                options={user?.possibleBrokers.map((u) => u.userName)}
                selectedItem={salesPerson3}
                selectId={'AlertWatchlists'}
                openRight={true}
                className={cx(styles.userClassDropdown, styles.dropdown)}
              />
            </fieldset>

            <fieldset className={cx(styles.fieldset, styles.smallFieldset)}>
              <legend className={styles.legend}>Enabled Currencies</legend>
              <GenericDropdownMenuMulti
                placeholder="Currency"
                selectedItems={selectedCurrencies}
                options={CurrencyValues}
                setSelectedItems={setSelectedCurrencies}
                selectId={''}
                openRight={true}
                className={
                  selectedCurrencies.length !== CurrencyValues.length
                    ? styles.notDefault
                    : ''
                }
              />
            </fieldset>

            <fieldset className={cx(styles.fieldset, styles.smallFieldset)}>
              <legend className={styles.legend}>Last Connected From Mac</legend>
              <label>Valic MACs:</label>
              <textarea rows={4}></textarea>
            </fieldset>
          </div>

          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>Miscellanceous</legend>
            <div className={styles.qrContainer}>
              <label>QR (Fill Rate) Color:</label>
              <select
                data-testid="qr-select"
                value={selectedQR}
                onChange={handleQRChange}
              >
                {qrOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className={cx(styles.qrBox, styles[selectedQR])} />
            </div>

            <div className={styles.checkboxContainer}>
              <Checkbox
                locator={''}
                checked={canRunReports}
                onChange={canRunReportsChanged}
              >
                Can Run Reports
              </Checkbox>
              <Checkbox
                locator={''}
                checked={submitsExternalSTPTradesFromAPI}
                onChange={submitsExternalSTPTradesFromAPIChanged}
              >
                Submits External STP Trades from API
              </Checkbox>
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                locator={''}
                checked={highlightWhenNotLogged}
                onChange={highlightWhenNotLoggedChanged}
              >
                Highlight when not logged
              </Checkbox>
              <Checkbox
                locator={''}
                checked={markAsDeleted}
                onChange={markAsDeletedChanged}
              >
                Mark as Deleted
              </Checkbox>
            </div>
            <div className={styles.inputContainer}>
              <label>Last click through version:</label>
              <input type="text" />
              <label>Default FIX Account Alias:</label>
              <input type="text" />
              <label>Max Single Order Limit:</label>
              <input
                type="text"
                value={maxSingleOrderLimit}
                onChange={(event) =>
                  setMaxSingleOrderLimit(event.currentTarget.value)
                }
              />
            </div>
          </fieldset>

          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>Bloomberg Info</legend>
            <div className={styles.inputContainer}>
              <label>Bloomberg User Name:</label>
              <input type="text" />
              <label>FIX Logon Sender ID:</label>
              <input type="text" />
              <label>Bloomberg UUID:</label>
              <input type="text" />
            </div>
          </fieldset>
        </form>
        <ComponentFooter
          onCancelClick={closeFunc}
          onApplyClick={handleValidatePreferences}
          errorText={error}
        />
      </>
    )
  }

  return wrapModal ? <>{editorForm()}</> : <Modal>{editorForm()}</Modal>
}

export default UserEditorModal
