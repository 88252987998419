import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import {
  CreateOrUpdateAlertFilterAction,
  createOrUpdateAlertFilterSuccess,
  DeleteAlertFilterAction,
  deleteAlertFilterSuccess,
  fetchAlertFiltersSuccess,
  MuteAlertFilterAction,
  muteAlertFilterSuccess
} from './actions'
import { AlertFilter } from './types'

const fetchAlertFiltersEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('alertFilter.fetchAlertFilters'),
    mergeMap(() => {
      return getHub()
        .invoke<AlertFilter[]>('GetAlertFilters')
        .pipe(
          map((alertFilters) => fetchAlertFiltersSuccess(alertFilters))
          // catchError(err => of(fetchAlertFiltersFailure(err), logError(err)))
        )
    })
  )

const createOrUpdateAlertFilterEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('alertFilter.createOrUpdate'),
    mergeMap((action: CreateOrUpdateAlertFilterAction) => {
      return getHub()
        .invoke('CreateOrUpdateAlertFilter', action.payload.alertFilter)
        .pipe(
          map((response: any) => createOrUpdateAlertFilterSuccess(response))
          // catchError(err => of(fetchAlertFiltersFailure(err), logError(err)))
        )
    })
  )

const deleteAlertFilterEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('alertFilter.deleteAlertFilter'),
    mergeMap((action: DeleteAlertFilterAction) => {
      return getHub()
        .invoke('DeleteAlertFilter', action.payload.id)
        .pipe(
          map((response: any) => deleteAlertFilterSuccess(response))
          // catchError(err => of(fetchAlertFiltersFailure(err), logError(err)))
        )
    })
  )

const muteAlertFilterEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('alertFilter.muteAlertFilter'),
    mergeMap((action: MuteAlertFilterAction) => {
      return getHub()
        .invoke('MuteAlertFilter', action.payload.id, action.payload.mute)
        .pipe(
          map((response: any) => muteAlertFilterSuccess(response)),
          catchError((err) => of(logError(err)))
        )
    })
  )

export default combineEpics(
  fetchAlertFiltersEpic,
  createOrUpdateAlertFilterEpic,
  deleteAlertFilterEpic,
  muteAlertFilterEpic
)
