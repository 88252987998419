import {
  getOrderTypeForResponse,
  getOrderTypeFromResponse
} from '../order/helpers'
import {
  CancelOrderAlert,
  CounteredOrToppedType,
  CounteredToppedAlert,
  NewOrderAlert,
  TradingNowAlert
} from './types'

const getCounteredToppedAlertTypeForResponse = (type: CounteredOrToppedType) =>
  type === 'countered' ? 0 : 1

export const tradingNowAlertFromResponse = (
  response: any
): TradingNowAlert => ({
  ...response,
  type: getOrderTypeFromResponse(response.type),
  spread: response.spread || undefined
})

export const newOrderAlertFromResponse = (response: any): NewOrderAlert => ({
  ...response,
  type: getOrderTypeFromResponse(response.type),
  spread: response.spread || undefined
})

export const cancelOrderAlertFromResponse = (
  response: any
): CancelOrderAlert => ({
  ...response,
  type: getOrderTypeFromResponse(response.type),
  spread: response.spread || undefined
})

export const tradingNowAlertToResponse = (alert: TradingNowAlert) => ({
  ...alert,
  type: getOrderTypeForResponse(alert.type),
  spread: alert.spread || null
})

export const counteredToppedAlertFromResponse = (
  response: any
): CounteredToppedAlert => ({
  ...response,
  alertType: response.alertType === 0 ? 'countered' : 'topped',
  orderType: getOrderTypeFromResponse(response.orderType),
  spread: response.spread || undefined
})

export const counteredToppedAlertToResponse = (
  alert: CounteredToppedAlert
) => ({
  ...alert,
  spread: alert.spread || null,
  alertType: getCounteredToppedAlertTypeForResponse(alert.alertType),
  orderType: getOrderTypeForResponse(alert.orderType)
})
