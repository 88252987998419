import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getQueueStats = createSelector(
  [getState],
  (state) => state.queueStats
)

export const getHighQueueStats = createSelector([getState], (state) =>
  state.queueStats?.filter((q) =>
    q.isMDQueue ? q.queueSize > 500000 : q.queueSize > 50000
  )
)

export const getQueueStatsIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getQueueStatsError = createSelector(
  [getState],
  (state) => state.error
)
