import {
  ManagementReport,
  MANAGEMENTREPORT_ADD,
  MANAGEMENTREPORT_FETCH,
  MANAGEMENTREPORT_FETCH_FAIL,
  ManagementReportAddAction,
  ManagementReportFetchAction,
  ManagementReportFetchFailAction
} from './types'

export const managementReportFetch = (
  date: Date
): ManagementReportFetchAction => ({
  type: MANAGEMENTREPORT_FETCH,
  payload: date
})

export const addManagementReport = (
  managementReport: ManagementReport
): ManagementReportAddAction => ({
  type: MANAGEMENTREPORT_ADD,
  payload: managementReport
})

export const managementReportFetchFail = (
  error: any
): ManagementReportFetchFailAction => ({
  type: MANAGEMENTREPORT_FETCH_FAIL,
  payload: error,
  error: true
})
