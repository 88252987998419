import { createSelector } from 'reselect'
import {
  getSearchResults,
  getSearchResultsIgnoringWatchlist
} from '../../store/searchSecurities/selectors'
import { SecuritySearchResult } from '../../store/searchSecurities/types'

export interface IssuerSecurities {
  issuer: string
  securities: SecuritySearchResult[]
}

const groupSecuritiesByIssuer = (securities: SecuritySearchResult[]) =>
  Object.entries(
    securities.reduce<Record<string, SecuritySearchResult[]>>(
      (acc, security) => ({
        ...acc,
        [security.issuerSymbol]: [
          ...(acc[security.issuerSymbol] || []),
          security
        ]
      }),
      {}
    )
  ).map<IssuerSecurities>(([issuer, issuerSecurities]) => ({
    issuer,
    securities: issuerSecurities
  }))

export const getSearchResultsByIssuer = createSelector(
  [getSearchResults],
  securities => (gridIndex: number) =>
    groupSecuritiesByIssuer(securities(gridIndex))
)

export const getSearchResultsIgnoringWatchlistByIssuer = createSelector(
  [getSearchResultsIgnoringWatchlist],
  securities => (gridIndex: number) =>
    groupSecuritiesByIssuer(securities(gridIndex))
)
