export interface Query {
  name: string
  query: string
  parameters: QueryParameter[]
}

export interface QueryParameter {
  name: string
  type: QueryParameterType
}

export enum QueryParameterType {
  String = 1,
  Date,
  DateTime,
  Float32,
  UInt,
  Int,
  Boolean,
  Security
}

export interface QueryParameterValue {
  name: string
  value: string
}

export interface ChartBreak {
  from: number
  to: number
}

export interface QueryResult {
  queryName: string
  data: DataResult[]
  rawData: string
  chartType: PlotType
  breaks: ChartBreak[]
  queryTitle: string
}

export interface DataResult {
  source: string
  result: ChartResult[]
  plotType: PlotType
}

export interface ChartResult {
  x: number // unix time
}

export interface LineResult extends ChartResult {
  y: number
  additionalData: any
}

export interface CandlestickResult extends ChartResult {
  open: number
  close: number
  high: number
  low: number
}

export interface QueryResultForAction {
  queryName: string
  data: DataResultForAction[]
  rawData: string
  chartType: PlotType
}

export interface DataResultForAction {
  source: string
  result: ChartResultForAction[]
  plotType: PlotType
}

export interface ChartResultForAction {
  x: number
}

export interface LineResultForAction extends ChartResultForAction {
  y: number
  additionalData: any
}

export interface CandlestickResultForAction extends ChartResultForAction {
  open: number
  close: number
  high: number
  low: number
}

export enum PlotType {
  Line = 1,
  Scatter,
  Candlestick
}
