import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getExternalOrderStats = createSelector(
  [getState],
  (state) => state.externalOrderStats
)

export const getExternalOrderStatsError = createSelector(
  [getState],
  (state) => state.error
)

export const getExternalOrderStatsPending = createSelector(
  [getState],
  (state) => state.pending
)
