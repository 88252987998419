import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'

import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'

import { tradeBlotterAddTrades, tradeBlotterFetchFail } from './actions'

import {
  Trade,
  TRADEBLOTTER_FETCH,
  TRADEBLOTTER_FETCH_CANCEL,
  TradeBlotterFetchAction
} from './types'

const fetchActivityTradeBlotterEpic: Epic<Action> = (action$, state$) => {
  return action$.pipe(
    ofType(TRADEBLOTTER_FETCH),
    delay(2000),
    switchMap((action: TradeBlotterFetchAction) => {
      const getActivityTradeBlotter$ = getHub().stream<Trade[]>('GetTrades')
      return getActivityTradeBlotter$.pipe(
        map(trade => {
          return tradeBlotterAddTrades(trade)
        }),
        takeUntil(action$.pipe(ofType(TRADEBLOTTER_FETCH_CANCEL))),
        catchError(err => of(tradeBlotterFetchFail(err), logError(err)))
      )
    })
  )
}

export default combineEpics(fetchActivityTradeBlotterEpic)
