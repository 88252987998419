import { RestrictedTrades } from './types'

export const addOrUpdateRestrictedTrades = (
  stateRestrictedTrades: RestrictedTrades[],
  restrictedTradesToAdd: RestrictedTrades[]
) => {
  const unchangedRestrictedTrades = stateRestrictedTrades.filter(
    (t) => !restrictedTradesToAdd.find(({ id }) => t.id === id)
  )
  const addRestrictedTrades = restrictedTradesToAdd.filter((t) => !t.isDeleted)
  return [...unchangedRestrictedTrades, ...addRestrictedTrades]
}
