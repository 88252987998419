import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getManagementReports = createSelector(
  [getState],
  (state) => state.managementReports
)

export const getManagementReportIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getManagementReportError = createSelector(
  [getState],
  (state) => state.error
)

export const getManagementReportsForDate = createSelector(
  [getState],
  (state) => (date: Date) => {
    const dateStr = dayjs(date).format('MM/DD/yyyy')
    if (state.managementReports.hasOwnProperty(dateStr)) {
      return state.managementReports[dateStr]
    } else {
      return undefined
    }
  }
)
