import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import {
  catchError,
  delay,
  map,
  mergeMap,
  mergeMapTo,
  switchMap,
  takeUntil
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import {
  addOrUpdatePassiveOrders,
  CancelPassiveOrderAction,
  cancelPassiveOrderFailure,
  clearPassiveOrders,
  CreateOrderFromPassiveOrderAction,
  createOrderFromPassiveOrderSuccess,
  fetchPassiveOrders,
  FetchPassiveOrdersAction,
  fetchPassiveOrdersFail,
  handleCreateOrderFromPassiveOrderError,
  unsubscribePassiveOrders,
  UnsubscribePassiveOrdersAction
} from './actions'

import { PassiveOrder } from './types'

export let nextMessageId = 0

const fetchPassiveOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('passiveOrders.fetchPassiveOrders'),
    delay(1000),
    switchMap((action: FetchPassiveOrdersAction) => {
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      const getPassiveOrders$ = getHub().stream<PassiveOrder[]>(
        'GetPassiveOrders',
        selUser,
        state$.value.passiveOrders.viewAllOrders
      )
      return getPassiveOrders$.pipe(
        map((orders) => {
          return addOrUpdatePassiveOrders(orders)
        }),
        takeUntil(
          action$.ofType<UnsubscribePassiveOrdersAction>(
            'passiveOrders.unsubscribePassiveOrders'
          )
        ),
        catchError((err) => of(fetchPassiveOrdersFail(err), logError(err)))
      )
    })
  )

const setSelectedUserEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('users.setSelectedUser'),
    map(() => {
      return clearPassiveOrders()
    })
  )

const clearPassiveOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('passiveOrders.clearPassiveOrders'),
    map(() => {
      return unsubscribePassiveOrders()
    })
  )

const unsubscribePassiveOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('passiveOrders.unsubscribePassiveOrders'),
    map(() => {
      return fetchPassiveOrders()
    })
  )

const cancelPassiveOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('passiveOrders.cancelPassiveOrder'),
    mergeMap((action: CancelPassiveOrderAction) => {
      const orderId = action.payload
      return getHub()
        .invoke('CancelOrderFromPassiveOrders', orderId)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) =>
            of(cancelPassiveOrderFailure(orderId, err), logError(err))
          )
        )
    })
  )

const resubmitOrderFromPassiveOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('passiveOrders.createOrderFromPassiveOrder'),
    mergeMap((action: CreateOrderFromPassiveOrderAction) => {
      const orderId = action.payload.orderId
      const messageId = nextMessageId++
      return getHub()
        .invoke('CreateOrderFromPassiveOrder', orderId, messageId)
        .pipe(
          map(() => createOrderFromPassiveOrderSuccess()),
          catchError((err) =>
            of(
              handleCreateOrderFromPassiveOrderError(err.errorMsg, orderId),
              logError(err)
            )
          )
        )
    })
  )

export default combineEpics(
  fetchPassiveOrdersEpic,
  setSelectedUserEpic,
  clearPassiveOrdersEpic,
  unsubscribePassiveOrdersEpic,
  cancelPassiveOrderEpic,
  resubmitOrderFromPassiveOrderEpic
)
