import React, { FC } from 'react'
import { useOpenFin } from '../../app/openFinContext'
import { abbreviateVolume } from '../../helpers/formatting'
import { BenchmarkInfo, BenchmarkList } from '../../store/benchmarkData/types'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import { finHeaderButtons } from '../ComponentHeader/helpers'
import styles from './benchmarkData.module.scss'

interface Props {
  benchmarkData: BenchmarkInfo
}

const BenchmarkData: FC<Props> = ({ benchmarkData }) => {
  const { fin } = useOpenFin()

  return (
    <div className={fin ? styles.finSizing : styles.webSizing}>
      {fin && (
        <ComponentHeader
          title="Benchmarks"
          headerButtons={finHeaderButtons('Benchmarks')}
        />
      )}
      <div className={styles.benchmarkContainer}>
        <div className={styles.benchmarkTitle}>
          Live on <br /> BondsPro:
        </div>
        <div className={styles.benchmarkData}>
          {benchmarkData.totalOrders.toLocaleString()}{' '}
          <div className={styles.benchmarkLabel}>TOTAL ORDERS</div>
        </div>
        <div className={styles.benchmarkData}>
          {abbreviateVolume(benchmarkData.notional * 1000)}{' '}
          <div className={styles.benchmarkLabel}>NOTIONAL</div>
        </div>
        <div className={styles.benchmarkTitle}>
          Treasury <br /> Benchmarks:
        </div>
        {benchmarkData.benchmarkData.map((item: BenchmarkList) => (
          <div key={item.id} className={styles.table}>
            <div className={styles.benchmarkYearLabel}>
              {item.benchmarkName}
            </div>
            <div>
              {item.bestBid}/{item.bestOffer}
            </div>
            <div>{item.yield ? item.yield.toFixed(2) : 'N/A'}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BenchmarkData
