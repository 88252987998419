import { setUser } from '@sentry/react'
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable'
import {
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { marketClosed, marketCloseTimerTick } from './actions'
import { EMPTY, interval, of } from 'rxjs'
import { closeTimerCount } from './reducer'
import { getMarketClosed } from './selectors'
import { getIsAdmin } from '../webSettings/selectors'

const marketClosedEpic: Epic = (action$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    switchMap(() => {
      return getHub().stream<boolean>('MarketClosed').pipe(map(marketClosed))
    })
  )

const signOut = (state$: StateObservable<any>) => {
  if (state$.value.auth?.oktaAuth && getMarketClosed(state$.value)) {
    state$.value.auth.oktaAuth.signOut({
      postLogoutRedirectUri: null
    })
    return of(setUser(null))
  }
  return EMPTY
}

const marketHasClosedEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('market.marketClosed'),
    distinctUntilChanged(),
    switchMap((action) => {
      if (!getIsAdmin(state$.value)) {
        if (document.visibilityState === 'hidden') {
          return signOut(state$)
        }
        if (action.payload.closed) {
          return interval(1000).pipe(
            map(marketCloseTimerTick),
            take(closeTimerCount),
            finalize(() => {
              return signOut(state$)
            })
          )
        }
      }
      return EMPTY
    })
  )

export default combineEpics(marketClosedEpic, marketHasClosedEpic)
