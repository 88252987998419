import React from 'react'
import { formatCurrency } from '../../../helpers/formatting'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { WorkingOrderFieldRendererProps } from './WorkingOrderFieldRenderer'

const PriceRenderer = ({
  data,
  watchlistId
}: WorkingOrderFieldRendererProps) => {
  const { order } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin
  )
  if (!order || !data || order.isSpread) return <></>

  if (order.isSpread || !order.price) return <></>
  return <div>{formatCurrency(order.price, data.currency)}</div>
}

export default PriceRenderer
