import { OktaAuthOAuthInterface } from '@okta/okta-auth-js'
import { AuthAction } from './actions'

export interface State {
  authToken?: string
  userName?: string
  userId?: string
  authTokenPending: boolean
  authTokenError: boolean
  oktaAuth?: OktaAuthOAuthInterface
}

export const initialState: State = {
  authToken: undefined,
  userName: undefined,
  userId: undefined,
  authTokenPending: false,
  authTokenError: false
}

const reducer = (state = initialState, action: AuthAction): State => {
  switch (action.type) {
    case 'auth.fetch-auth-token':
      return {
        ...state,
        authTokenPending: true,
        authTokenError: false
      }
    case 'auth.fetch-auth-token-success':
      return {
        ...state,
        authTokenPending: false,
        authToken: action.payload.authToken,
        userName: action.payload.userName,
        userId: action.payload.userId
      }
    case 'auth.fetch-auth-token-failure':
      return {
        ...state,
        authTokenPending: false,
        authTokenError: true
      }
    case 'auth.set-okta-auth':
      return {
        ...state,
        oktaAuth: action.payload
      }
    default:
      return state
  }
}

export default reducer
