import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, { FC, useCallback, useRef, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import { deleteRestrictedTrades } from '../../../../store/admin/restrictedTrades/actions'
import {
  getRestrictedTrades,
  getRestrictedTradesError
} from '../../../../store/admin/restrictedTrades/selectors'
import { RestrictedTrades } from '../../../../store/admin/restrictedTrades/types'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import RestrictedTradesModal from '../RestrictedTradesModal'
import { columnDefinitions } from './columnDefs'

interface Props {
  isPermanent: boolean
}

const getRowId: GetRowIdFunc<RestrictedTrades> = ({ data }) => {
  return `${data.id}`
}
const RestrictedTradesGrid: FC<Props> = (props: Props) => {
  const theme = useAppSelector(getCurrentTheme)
  const gridRef = useRef(null)
  const dispatch = useAppDispatch()
  const [displayModal, toggleModal] = useState(false)

  const restrictedTrades = useAppSelector(getRestrictedTrades)
  const filteredTrades = restrictedTrades?.filter((trade) => {
    const minDate = dayjs(new Date(0)).format('M/DD/YY, h:mm a')
    const formattedRestrictUntilDate = dayjs(trade.restrictUntil).format(
      'M/DD/YY, h:mm a'
    )

    return props.isPermanent
      ? formattedRestrictUntilDate === minDate
      : formattedRestrictUntilDate !== minDate
  })

  const restrictedTradesError = useAppSelector(getRestrictedTradesError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
      columnApi.setColumnVisible('restrictUntil', !props.isPermanent)
    },
    []
  )

  const handleShowModal = useCallback(() => {
    toggleModal(!displayModal)
  }, [displayModal])

  const deleteSelected = useCallback(() => {
    if (gridRef) {
      // @ts-ignore: Object is possibly 'undefined'
      const selectedData = gridRef.current.api.getSelectedRows()
      dispatch(deleteRestrictedTrades(selectedData))
    }
  }, [])
  if (restrictedTradesError) {
    return <div>{restrictedTradesError}</div>
  }

  return (
    <>
      <div className={gridStyles.optionsContainer}>
        <button onClick={handleShowModal}>New</button>
        <button onClick={deleteSelected}>Delete Selected</button>
      </div>
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <AgGridReact
          ref={gridRef}
          rowData={filteredTrades}
          columnDefs={columnDefinitions}
          overlayNoRowsTemplate="No restricted trading found."
          overlayLoadingTemplate="Loading Restricted Trading…"
          alwaysShowVerticalScroll={true}
          maintainColumnOrder={true}
          defaultColDef={defaultColumnDefinitions}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns={true}
          getRowId={getRowId}
        />
      </div>
      {displayModal && (
        <RestrictedTradesModal
          closeFunc={handleShowModal}
          isPermanent={props.isPermanent}
        />
      )}
    </>
  )
}

export default RestrictedTradesGrid
