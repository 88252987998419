import { Action } from 'redux'
import { WorkingWatchlistUpload } from '../../components/Upload/EnhancedUpload/EnhancedUploadDropdownMenu'
import { EnhancedOrderUploadRow } from '../../components/Upload/EnhancedUpload/types'
import {
  DropdownState,
  ErrorInfo,
  OrderRowInfo,
  OrdersWithSecurityIds,
  WatchlistInfo
} from './types'
import { Book } from '../books/types'
import { Filter } from '../filter/types'
import { StagedOrder } from '../stagedOrders/types'
import { WatchlistDetails } from '../watchList/types'

export interface UploadOrdersFailure extends Action {
  type: 'upload.uploadOrdersFailure'
  payload: ErrorInfo[]
  error: boolean
}

export const uploadOrdersFailure = (
  errors: ErrorInfo[]
): UploadOrdersFailure => ({
  type: 'upload.uploadOrdersFailure',
  payload: errors,
  error: true
})

export interface UploadOrdersServerError extends Action {
  type: 'upload.uploadOrdersServerError'
  payload: string
}

export const uploadOrdersServerError = (
  message: string
): UploadOrdersServerError => {
  return {
    type: 'upload.uploadOrdersServerError',
    payload: message
  }
}

export interface ResetInvalidIdentifiers {
  type: 'upload.resetInvalidIdentifiers'
}

export const resetInvalidIdentifiers = (): ResetInvalidIdentifiers => ({
  type: 'upload.resetInvalidIdentifiers'
})

export interface ResetUploadOrdersServerError {
  type: 'upload.resetUploadOrdersServerError'
}

export const resetUploadOrdersServerError =
  (): ResetUploadOrdersServerError => ({
    type: 'upload.resetUploadOrdersServerError'
  })

export interface CreateNewWatchlistWithSecurityIdsAction {
  type: 'upload.createNewWatchlistWithSecurityIds'
  payload: {
    gridIndex: number
    name: string
    permission: 0 | 1
    book: Book
    orders: OrderRowInfo[]
    securityIds: number[][]
    identifiers: string[]
    filter: Filter
    myFirmChecked: boolean
    useSizeChecked: boolean
    size: number
    useAdvancedFilter: boolean
  }
}

export const createNewWatchlistWithSecurityIds = (
  gridIndex: number,
  name: string,
  permission: 0 | 1,
  book: Book,
  orders: OrderRowInfo[],
  securityIds: number[][],
  identifiers: string[],
  filter: Filter,
  myFirmChecked: boolean,
  useSizeChecked: boolean,
  size: number,
  useAdvancedFilter: boolean
): CreateNewWatchlistWithSecurityIdsAction => ({
  type: 'upload.createNewWatchlistWithSecurityIds',
  payload: {
    gridIndex,
    name,
    permission,
    book,
    orders,
    securityIds,
    identifiers,
    filter,
    myFirmChecked,
    useSizeChecked,
    size,
    useAdvancedFilter
  }
})

export interface UploadOrdersToNewWatchlistAction {
  type: 'upload.uploadOrdersToNewWatchlist'
  payload: {
    gridIndex: number
    securityIds: OrdersWithSecurityIds
    bookId: number
  }
}

export const uploadOrdersToNewWatchlist = (
  gridIndex: number,
  securityIds: OrdersWithSecurityIds,
  bookId: number
): UploadOrdersToNewWatchlistAction => ({
  type: 'upload.uploadOrdersToNewWatchlist',
  payload: { gridIndex, securityIds, bookId }
})

// ------------------ uploading enhanced orders ------------------ //

export const createWatchlistWithEnhancedUploadRows = (
  watchlist: WorkingWatchlistUpload,
  gridIndex: number,
  book?: Book
) =>
  ({
    type: `upload.createWatchlistWithEnhancedUploadRows`,
    payload: { watchlist, gridIndex, book }
  } as const)
export type CreateWatchlistWithEnhancedUploadRowsAction = ReturnType<
  typeof createWatchlistWithEnhancedUploadRows
>

export const createWatchlistFromWatchlistInfo = (
  watchlist: WatchlistInfo,
  identifiers: string[]
) =>
  ({
    type: `upload.createWatchlistFromWatchlistInfo`,
    payload: { watchlist, identifiers }
  } as const)
export type CreateWatchlistFromWatchlistInfoAction = ReturnType<
  typeof createWatchlistFromWatchlistInfo
>

export const uploadEnhancedOrderRows = (
  gridIndex: number,
  watchlist: WatchlistDetails,
  rows: EnhancedOrderUploadRow[],
  bookId: number
) =>
  ({
    type: `upload.uploadEnhancedOrderRows`,
    payload: { gridIndex, watchlist, rows, bookId }
  } as const)
export type UploadEnhancedOrderRowsAction = ReturnType<
  typeof uploadEnhancedOrderRows
>

// ------------------ end uploading enhanced orders ------------------ //

export interface UploadOrdersFromPreviousDate extends Action {
  type: 'upload.uploadOrdersFromPreviousDate'
  payload: StagedOrder[]
}

export const uploadOrdersFromPreviousDate = (
  stagedOrders: StagedOrder[]
): UploadOrdersFromPreviousDate => ({
  type: 'upload.uploadOrdersFromPreviousDate',
  payload: stagedOrders
})

export interface SetNewWatchlistTransactionIdAction extends Action {
  type: 'upload.setNewWatchlistTransactionId'
  payload: { gridIndex: number; transactionId: number }
}

export const setNewWatchlistTransactionId = (
  gridIndex: number,
  transactionId: number
): SetNewWatchlistTransactionIdAction => ({
  type: 'upload.setNewWatchlistTransactionId',
  payload: { gridIndex, transactionId }
})

export interface ResetNewWatchlistTransactionId extends Action {
  type: 'upload.resetNewWatchlistTransactionId'
  payload: { gridIndex: number }
}

export const resetNewWatchlistTransactionId = (
  gridIndex: number
): ResetNewWatchlistTransactionId => ({
  type: 'upload.resetNewWatchlistTransactionId',
  payload: { gridIndex }
})

export interface ToggleDropdownState {
  type: 'upload.toggleDropdownState'
  payload: { gridIndex: number; state: DropdownState }
}

export const toggleDropdownState = (
  gridIndex: number,
  state: DropdownState
): ToggleDropdownState => ({
  type: 'upload.toggleDropdownState',
  payload: { gridIndex, state }
})

export type UploadOrdersActions =
  | UploadOrdersFailure
  | UploadOrdersServerError
  | ResetInvalidIdentifiers
  | ResetUploadOrdersServerError
  | UploadOrdersFromPreviousDate
  | SetNewWatchlistTransactionIdAction
  | ResetNewWatchlistTransactionId
  | CreateNewWatchlistWithSecurityIdsAction
  | UploadOrdersToNewWatchlistAction
  | ToggleDropdownState
  | CreateWatchlistWithEnhancedUploadRowsAction
  | UploadEnhancedOrderRowsAction
