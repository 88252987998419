import React, { FC } from 'react'
import { SecurityStaticData } from '../../store/securities/reducer'
import { SecurityModalInfo } from '../../store/windows/types'
import ExpandableComponent from '../ExpandableComponent/ExpandableComponent'
import ExpandableSectionTitle from '../ExpandableComponent/ExpandableSectionTitle'
import ModalSecurityInformation from './ModalSecurityInformation/ModalSecurityInformation'
import styles from './ModalSecurityInformationWrapper.module.scss'

interface Props {
  securityModalInfo: SecurityModalInfo
  security: SecurityStaticData
}

const ModalSecurityInformationWrapper: FC<Props> = (props) => {
  const { securityModalInfo, security } = props

  const title = (
    isExpanded: boolean,
    setExpanded: (expanded: boolean) => void
  ) => {
    return (
      <ExpandableSectionTitle
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        className={styles.securityInfoTitle}
      >
        Security and Transaction Information
      </ExpandableSectionTitle>
    )
  }

  return (
    <ExpandableComponent title={title} isInitiallyExpanded={false}>
      <ModalSecurityInformation
        securityModalInfo={securityModalInfo}
        security={security}
      />
    </ExpandableComponent>
  )
}

export default ModalSecurityInformationWrapper
