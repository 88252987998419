import React from 'react'
import HitLiftButton from '../HitLiftButton'
import { OrderType } from '../../../store/order/types'
import { CellProps, useSecurityOrderData } from './helpers'

const HitLiftCell =
  (orderType: OrderType) =>
  ({ data }: CellProps) => {
    const security = useSecurityOrderData(data)
    if (!security) return <> </>

    return (
      <HitLiftButton
        security={security}
        type={orderType === 'buy' ? 'hit' : 'lift'}
      />
    )
  }
export default HitLiftCell
