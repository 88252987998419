import {
  Venue,
  VENUE_ADD,
  VENUE_FETCH,
  VENUE_FETCH_FAIL,
  VenueAddAction,
  VenueFetchAction,
  VenueFetchFailAction
} from './types'

export const venueFetch = (): VenueFetchAction => ({
  type: VENUE_FETCH
})

export const addVenues = (queueStats: Venue[]): VenueAddAction => ({
  type: VENUE_ADD,
  payload: queueStats
})

export const venueFetchFail = (error: any): VenueFetchFailAction => ({
  type: VENUE_FETCH_FAIL,
  payload: error,
  error: true
})
