import { Action } from 'redux'
import { UserPermissions, WebSettings } from './types'

export interface SetWebSettingsAction extends Action {
  type: 'webSettings.setWebSettings'
  payload: {
    settings: WebSettings
  }
}

export const setWebSettings = (
  settings: WebSettings
): SetWebSettingsAction => ({
  type: 'webSettings.setWebSettings',
  payload: { settings }
})

export interface FetchUserPermissionsAction extends Action {
  type: 'webSettings.fetchUserPermissions'
}

export const fetchUserPermissions = (): FetchUserPermissionsAction => ({
  type: 'webSettings.fetchUserPermissions'
})

export interface FetchUserPermissionsSuccessAction extends Action {
  type: 'webSettings.fetchUserPermissionsSuccess'
  payload: UserPermissions
}

export const fetchUserPermissionsSuccess = (
  userPermissions: UserPermissions
): FetchUserPermissionsSuccessAction => ({
  type: 'webSettings.fetchUserPermissionsSuccess',
  payload: userPermissions
})

export type WebSettingsAction =
  | SetWebSettingsAction
  | FetchUserPermissionsAction
  | FetchUserPermissionsSuccessAction
