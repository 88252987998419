import { Filter } from '../filter/types'
export interface AlertFilter {
  filter: Filter
  watchlists: number[]
  name: string
  alertWhenBidTopped: boolean
  alertWhenOfferCut: boolean
  alertWhenNewBid: boolean
  alertWhenNewOffer: boolean
  alertWhenTradingNow: boolean
  alertWhenBidCountered: boolean
  alertWhenOfferCountered: boolean
  alertSize: number | undefined
  alertWhenSizeGreaterOrLessThan: GreaterOrLessThan
  all: boolean
  muted: boolean
  usePriceSizeCriteria: boolean
  priceSizeAndOr: AndOr
  alertPrice: number | undefined
  alertWhenPriceGreaterOrLessThan: GreaterOrLessThan
  alertPriceType: PriceType
  includesMineWatchlist: boolean
  id: number
}

export enum AndOr {
  And = 0,
  Or
}

export const AndOrStrings = ['And', 'Or']

export enum GreaterOrLessThan {
  GreaterThan = 0,
  LessThan
}

export enum BidAskDiffType {
  Price = 0,
  Spread
}

export const GreaterOrLessThanStrings = ['≥', '≤']

export const BidAskDiffTypeStrings = ['Price', 'Spread']

export enum PriceType {
  Price = 0,
  Spread,
  Yield
}
