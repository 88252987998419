import { Action } from 'redux'
import { Book } from './types'

export interface FetchBooksAction extends Action {
  type: 'books.fetchBooks'
}

export const fetchBooks = (): FetchBooksAction => ({
  type: 'books.fetchBooks'
})

export interface FetchBooksFailure extends Action {
  type: 'books.fetchBooksFailure'
  payload: any
  error: true
}

export const fetchBooksFailure = (error: any) => ({
  type: 'books.fetchBooksFailure',
  payload: error,
  error: true
})

export interface AddOrUpdateBooks extends Action {
  type: 'books.addOrUpdateBooks'
  payload: Book[]
}

export const addOrUpdateBooks = (books: Book[]) => ({
  type: 'books.addOrUpdateBooks',
  payload: books
})

export type BookAction = FetchBooksAction | FetchBooksFailure | AddOrUpdateBooks
