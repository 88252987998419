import { QueueStats } from './types'

export const addOrUpdateQueueStats = (
  stateQueueStats: QueueStats[],
  queueStatsToAdd: QueueStats[]
) => {
  const unchangedQueueStats = stateQueueStats.filter(
    (t) => !queueStatsToAdd.find(({ id }) => t.id === id)
  )
  return [...unchangedQueueStats, ...queueStatsToAdd]
}
