import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'
import {
  SubscribeToUserDashboard,
  UnsubscribeFromUserDashboard,
  updateUserDashboard
} from './actions'

const subscribeToUserDashboardEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToUserDashboard>('userDashboard.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetUserDashboard')
        .pipe(
          map(updateUserDashboard),
          takeUntil(
            action$.ofType<UnsubscribeFromUserDashboard>(
              'userDashboard.unsubscribe'
            )
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

export default combineEpics(subscribeToUserDashboardEpic)
