import { WebSettingsAction } from './actions'
import { UserPermissions, WebSettings } from './types'

export interface State {
  settings: WebSettings
  userPermissions: UserPermissions
}

const initialState: State = {
  settings: {
    isLoading: true,
    icebergThreshold: 500,
    maxGrids: 5,
    showLT: false,
    showTsy: false,
    showTOB: false
  },
  userPermissions: {
    isAdmin: false,
    hasTradingRights: false,
    canEditTiers: false,
    canSeeSystemParamEditor: false,
    canMarkRestricted: false,
    canForceCancel: false,
    canEnterPassiveOrders: false
  }
}

export default (state = initialState, action: WebSettingsAction): State => {
  switch (action.type) {
    case 'webSettings.setWebSettings':
      return {
        ...state,
        settings: { ...action.payload.settings }
      }
    case 'webSettings.fetchUserPermissions':
      return { ...state }
    case 'webSettings.fetchUserPermissionsSuccess':
      return {
        ...state,
        userPermissions: action.payload
      }
    default:
      return state
  }
}
