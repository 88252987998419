import React, { ComponentType, useCallback } from 'react'
import { useOpenFin } from '../app/openFinContext.tsx'

type ClipboardComponent = {
  copyToClipboard: (clipboardText: string) => void
}

export const WithClipboard = <Props extends ClipboardComponent>(
  Component: ComponentType<Props>
) => {
  return (props: Omit<Props, 'copyToClipboard'>) => {
    const { fin } = useOpenFin()
    const copyToClipboard = useCallback(
      (text: string) => {
        if (fin) {
          fin.Clipboard.write({
            data: {
              text
            }
          })
        } else {
          navigator.clipboard.writeText(text)
        }
      },
      [fin]
    )
    // @ts-ignore
    return <Component copyToClipboard={copyToClipboard} {...props} />
  }
}
