import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'

import React, { FC, MouseEvent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import stop_sign from '../../assets/images/stop_sign.svg'

import {
  cancelCheckedOrders,
  submitCheckedOrders
} from '../../store/checkedOrders/actions'
import {
  getNumberOfCancellableOrders,
  getNumberOfCheckedOrders,
  getNumberOfCheckedStagedOrderIsNotLive
} from '../../store/checkedOrders/selectors'
import { clearCheckedStagedOrders } from '../../store/stagedOrders/actions'
import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup'
import styles from './MyOrdersButtons.module.scss'

const MyOrdersControlButtons: FC = () => {
  const dispatch = useDispatch()
  const numberOfCancellableOrders = useSelector(getNumberOfCancellableOrders)
  const numberOfSubmittableOrders = useSelector(
    getNumberOfCheckedStagedOrderIsNotLive
  )
  const numberOfClearableOrders = useSelector(getNumberOfCheckedOrders)

  const handleCancel = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(cancelCheckedOrders())
  }, [])

  const handleclear = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(clearCheckedStagedOrders())
  }, [])

  const handleSubmit = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(submitCheckedOrders())
  }, [])
  return (
    <div className={styles.buttons}>
      <ButtonGroup>
        <Button
          onClick={handleclear}
          data-testid="myorders-clearbutton"
          disabled={numberOfClearableOrders === 0}
        >
          Clear ({numberOfClearableOrders})
        </Button>
        <Button
          onClick={handleCancel}
          data-testid="myorders-stopbutton"
          disabled={numberOfCancellableOrders === 0}
        >
          <img
            src={stop_sign}
            className={styles.stopIcon}
            alt="stop sign icon"
          />
          Stop ({numberOfCancellableOrders})
        </Button>
        <Button
          onClick={handleSubmit}
          data-testid="myorders-submitbutton"
          disabled={numberOfSubmittableOrders === 0}
        >
          <FontAwesomeIcon icon={faPlay} />
          Submit ({numberOfSubmittableOrders})
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default MyOrdersControlButtons
