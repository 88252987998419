import {
  faAnglesDown,
  faAnglesRight
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'

interface Props {
  isExpanded: boolean
}

const ExpandableComponentIcon: FC<Props> = ({ isExpanded }) => {
  return <FontAwesomeIcon icon={!isExpanded ? faAnglesRight : faAnglesDown} />
}

export default ExpandableComponentIcon
