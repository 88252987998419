import { Action } from 'redux'

const NAMESPACE = `users.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const USER_FETCH = `${NAMESPACE}fetch`
export interface UserFetchAction extends Action {
  type: typeof USER_FETCH
}

export const USER_FETCH_FAIL = `${NAMESPACE}fetch`
export interface UserFetchFailAction extends Action {
  type: typeof USER_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const USER_ADD = `${NAMESPACE}addUsers`
export interface UserAddUserAction extends Action {
  type: typeof USER_ADD
  payload: User[]
}

export const USER_SET_SELECTED_USER = `${NAMESPACE}setSelectedUser`
export interface UserSetSelectedUserAction extends Action {
  type: typeof USER_SET_SELECTED_USER
  payload: number
}

export type UsersAction =
  | UserFetchAction
  | UserAddUserAction
  | UserFetchFailAction
  | UserSetSelectedUserAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface User {
  id: number
  userName: string
  custId: number
  color: string
  custName: string
}

export const EmptyUser = {
  id: 0,
  userName: '',
  custId: 0,
  color: '',
  custName: ''
}
