import { Trade } from './types'

export const addOrUpdateTrade = (
  stateTrades: Trade[],
  tradesToAdd: Trade[]
) => {
  const unchangedTrades = stateTrades.filter(
    (t) => !tradesToAdd.find(({ id }) => t.id === id)
  )
  return [...unchangedTrades, ...tradesToAdd]
}
