import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'
import {
  alertType,
  counteredToppedAlertId,
  tradingNowAlertId
} from './types'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getTradingNowAlerts = createSelector(
  [getState],
  (state) => state.tradingNowAlerts
)

export const getCounteredToppedAlerts = createSelector(
  [getState],
  (state) => state.counteredToppedAlerts
)

export const getNewOrderAlerts = createSelector(
  [getState],
  (state) => state.newOrderAlerts
)

export const getTradingNowAlertsAsArray = createSelector(
  [getState],
  (state) => {
    const alerts = []
    for (const a in state.tradingNowAlerts) {
      if (state.tradingNowAlerts.hasOwnProperty(a)) {
        alerts.push(state.tradingNowAlerts[a])
      }
    }
    return alerts
  }
)

export const getCounteredToppedAlertsAsArray = createSelector(
  [getState],
  (state) => {
    const alerts = []
    for (const a in state.counteredToppedAlerts) {
      if (state.counteredToppedAlerts.hasOwnProperty(a)) {
        alerts.push(state.counteredToppedAlerts[a])
      }
    }
    return alerts
  }
)

export const getNewOrderAlertsAsArray = createSelector([getState], (state) => {
  const alerts = []
  for (const a in state.newOrderAlerts) {
    if (state.newOrderAlerts.hasOwnProperty(a)) {
      alerts.push(state.newOrderAlerts[a])
    }
  }
  return alerts
})

export const getCancelOrderAlerts = createSelector(
  [getState],
  (state) => state.cancelOrderAlerts
)

export const getCancelOrderAlertsAsArray = createSelector(
  [getState],
  (state) => {
    const alerts = []
    for (const a in state.cancelOrderAlerts) {
      if (state.cancelOrderAlerts.hasOwnProperty(a)) {
        alerts.push(state.cancelOrderAlerts[a])
      }
    }
    return alerts
  }
)

export const getAlert = createSelector(
  [getTradingNowAlerts, getCounteredToppedAlerts, getNewOrderAlerts],
  (tradingNowAlerts, counteredToppedAlerts, newOrderAlerts) => (
    alertId: tradingNowAlertId | counteredToppedAlertId  
  ) => {
    if (
      typeof alertId === 'number' &&
      tradingNowAlerts.hasOwnProperty(alertId)
    ) {
      return tradingNowAlerts[alertId]
    } else if (counteredToppedAlerts.hasOwnProperty(alertId)) {
      return counteredToppedAlerts[alertId]
    } else if (newOrderAlerts.hasOwnProperty(alertId)) {
      return newOrderAlerts[alertId]
    }
    return undefined
    /* [...tradingNowAlerts, ...counteredToppedAlerts, ...newOrderAlerts].find(
      alert => alert.id === alertId
    )*/
  }
)

export const getMutedAlertSecurityId = createSelector(
  [getState],
  (state) => (type: alertType, securityId: number) =>
    state.mutedAlerts[type].find((id) => id === securityId)
)

export const getMutedTradingNowAlertsSecurityIds = createSelector(
  [getState],
  (state) => state.mutedAlerts.tradingNow
)

export const getMutedCounteredAlertsSecurityIds = createSelector(
  [getState],
  (state) => state.mutedAlerts.countered
)

export const getMutedToppedAlertsSecurityIds = createSelector(
  [getState],
  (state) => state.mutedAlerts.topped
)

export const getMutedNewOrderAlertsSecurityIds = createSelector(
  [getState],
  (state) => state.mutedAlerts.newOrder
)

export const getMutedCancelOrderAlertsSecurityIds = createSelector(
  [getState],
  (state) => state.mutedAlerts.cancelOrder
)
