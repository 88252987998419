import cx from 'classnames'
import React, { FC, ReactNode, useCallback } from 'react'
import ButtonWithFeedback from '../ButtonWithFeedback/ButtonWithFeedback'
import styles from './ComponentFooter.module.scss'

interface FooterButtonsProps {
  onCancelClick: () => void
  onApplyClick: () => void
  cancelId?: string
  applyId?: string
  submitText?: string | ReactNode
  cancelText?: string | ReactNode
  errorText?: string
  disableSubmit?: boolean
  disableCancel?: boolean
}

const ComponentFooter: FC<FooterButtonsProps> = ({
  onCancelClick,
  onApplyClick,
  cancelId,
  applyId,
  errorText,
  submitText = 'Save',
  cancelText = 'Cancel',
  disableSubmit = false,
  disableCancel = false
}) => {
  const showSavingTime = 500
  const showSaving = useCallback(() => {
    const timer = setTimeout(onApplyClick, showSavingTime)
    return () => clearTimeout(timer)
  }, [onApplyClick])

  return (
    <>
      {errorText && (
        <div className={styles.errorTextWrapper}>
          <label className={styles.errorText}>{errorText}</label>
        </div>
      )}
      <div className={styles.footerButtons}>
        <button
          id={cancelId}
          data-testid={cancelId}
          className={styles.cancel}
          onClick={onCancelClick}
          disabled={disableCancel}
        >
          {cancelText}
        </button>
        <ButtonWithFeedback
          id={applyId}
          data-testid={applyId}
          className={cx(`${styles.save} confirm`)}
          onClick={showSaving}
          contentUpdate="SUBMITTING"
          timerToReturnToFirstState={showSavingTime}
          disabled={disableSubmit}
        >
          {submitText}
        </ButtonWithFeedback>
      </div>
    </>
  )
}

export default ComponentFooter
