import { AlertFilterAction } from './actions'
import { AlertFilter } from './types'

export interface State {
  filters: AlertFilter[]
}

export const initialState: State = {
  filters: []
}

export default (state = initialState, action: AlertFilterAction): State => {
  switch (action.type) {
    case 'alertFilter.fetchAlertFiltersSuccess':
      return {
        ...state,
        filters: action.payload.alertFilters
      }
    case 'alertFilter.muteAlertFilterSuccess':
      const newFilters = [] as AlertFilter[]
      state.filters.forEach((f) => {
        if (f.id === action.payload.filter.id) {
          newFilters.push(action.payload.filter)
        } else {
          newFilters.push(f)
        }
      })
      return {
        ...state,
        filters: newFilters
      }
    case 'alertFilter.deleteAlertFilterSuccess':
      const newFiltersArr = [] as AlertFilter[]
      state.filters.forEach((f) => {
        if (f.id !== action.payload.id) {
          newFiltersArr.push(f)
        }
      })
      return {
        ...state,
        filters: newFiltersArr
      }
    default:
      return state
  }
}
