import { Action } from 'redux'

const NAMESPACE = `activityticker.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const ACTIVITYTICKER_FETCH = `${NAMESPACE}fetch`
export interface ActivityTickerFetchAction extends Action {
  type: typeof ACTIVITYTICKER_FETCH
}

/**
 * Fetch cancel
 */
export const ACTIVITYTICKER_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface ActivityTickerFetchCancelAction extends Action {
  type: typeof ACTIVITYTICKER_FETCH_CANCEL
}

export const ACTIVITYTICKER_FETCH_FAIL = `${NAMESPACE}fetch`
export interface ActivityTickerFetchFailAction extends Action {
  type: typeof ACTIVITYTICKER_FETCH_FAIL
  payload: any
  error: true
}

export const ACTIVITYTICKER_SUCCESS = `${NAMESPACE}success`
export interface ActivityTickerSuccessAction extends Action {
  type: typeof ACTIVITYTICKER_SUCCESS
  payload: TickerInfo[]
}

export type ActivityTickerAction =
  | ActivityTickerFetchAction
  | ActivityTickerFetchFailAction
  | ActivityTickerFetchCancelAction
  | ActivityTickerSuccessAction

export interface TickerInfo {
  orderTime: string
  security: string
  description: string
  trader: string
  operator: string
  exception: string
}

export const EmptyParam = {
  orderTime: '',
  security: '',
  description: '',
  trader: '',
  operator: '',
  exception: ''
}
