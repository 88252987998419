import {
  SaveSystemParamFailureAction,
  SaveSystemParamSuccessAction,
  SystemParam,
  SYSTEMPARAM_ADD,
  SYSTEMPARAM_FETCH,
  SYSTEMPARAM_FETCH_FAIL,
  SYSTEMPARAM_SAVE,
  SYSTEMPARAM_SAVE_FAILURE,
  SYSTEMPARAM_SAVE_SUCCESS,
  SystemParamAddSystemParamAction,
  SystemParamFetchAction,
  SystemParamFetchFailAction,
  SystemParamSaveAction
} from './types'

export const systemParamFetch = (): SystemParamFetchAction => ({
  type: SYSTEMPARAM_FETCH
})

export const addSystemParam = (
  systemParams: SystemParam[]
): SystemParamAddSystemParamAction => ({
  type: SYSTEMPARAM_ADD,
  payload: systemParams
})

export const systemParamFetchFail = (
  error: any
): SystemParamFetchFailAction => ({
  type: SYSTEMPARAM_FETCH_FAIL,
  payload: error,
  error: true
})

export const saveSystemParam = (param: SystemParam): SystemParamSaveAction => ({
  type: SYSTEMPARAM_SAVE,
  payload: param
})

export const saveSystemParamSuccess = (
  systemParamList: SystemParam,
  transactionId?: number
): SaveSystemParamSuccessAction => ({
  type: SYSTEMPARAM_SAVE_SUCCESS,
  payload: { systemParamList, transactionId }
})

export const saveSystemParamFailure = (
  error: any
): SaveSystemParamFailureAction => ({
  type: SYSTEMPARAM_SAVE_FAILURE,
  payload: error,
  error: true
})
