import React, { useCallback } from 'react'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { BuySellSwitch } from './ToggleSwitch'
import { WorkingOrderFieldRendererProps } from './WorkingOrderFieldRenderer'

const BuySellToggleRenderer = ({
  data,
  watchlistId
}: WorkingOrderFieldRendererProps) => {
  const { order, updateField } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin,
    true
  )
  const onSwitchChange = useCallback((value) => {
    updateField('isBid', value)
  }, [])
  if (!data) return <></>
  if (typeof order?.isBid !== 'boolean') {
    // temp third state
    return (
      <div
        style={{
          minWidth: '100%',
          minHeight: '100%',
          background: 'transparent'
        }}
        onClick={() => onSwitchChange(true)}
      ></div>
    )
  }
  return (
    <BuySellSwitch
      securityId={data.id}
      isBid={!!order.isBid}
      onChange={onSwitchChange}
    />
  )
}

export default BuySellToggleRenderer
