import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ManagementReport } from './types'
dayjs.extend(utc)

export const addOrUpdateManagementReport = (
  stateManagementReports: Record<string, ManagementReport>,
  managementReportToAdd: ManagementReport
) => {
  const newManagementReports: Record<string, ManagementReport> = {
    ...stateManagementReports
  }
  newManagementReports[
    dayjs(managementReportToAdd.reportDate).utc().format('MM/DD/yyyy')
  ] = managementReportToAdd
  return newManagementReports
}
