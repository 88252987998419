import { Action } from 'redux'
import { Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import { addOrUpdateBooks, fetchBooksFailure } from './actions'
import { Book } from './types'

const fetchBooksEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('books.fetchBooks'),
    mergeMap(() => {
      return getHub()
        .invoke<Book[]>('GetTradeBooks')
        .pipe(map((books) => addOrUpdateBooks(books)))
    }),
    catchError((err) => of(fetchBooksFailure(err), logError(err)))
  )

export default fetchBooksEpic
