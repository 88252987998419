import React, { ChangeEvent, useCallback } from 'react'
import { toggleSwitch } from '../helpers'

interface Props {
  securityId: number
  isBid: boolean
  onChange?: (newVal: boolean) => void
}

export const BuySellSwitch = ({ securityId, isBid, onChange }: Props) => {
  const switchId = `list-trading-order-${securityId}-isBid-toggle`

  const onSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return
      const value = e.currentTarget.checked
      onChange(value)
    },
    [onChange]
  )

  return (
    <div className={toggleSwitch}>
      <input
        type="checkbox"
        name={`${switchId}`}
        id={`${switchId}`}
        data-testid={`${switchId}`}
        checked={isBid}
        onChange={onSwitchToggle}
      />
      <div className="state p-on p-default">
        <label htmlFor={`${switchId}`}>Buy</label>
      </div>
      <div className="state p-off p-default">
        <label htmlFor={`${switchId}`}>Sell</label>
      </div>
    </div>
  )
}
