import { ActivityAction } from './actions'
import { ActivityFilterId } from './types'

export interface State {
  visible: boolean
  search: string
  filter: ActivityFilterId
}

export const initialState: State = {
  visible: false,
  search: '',
  filter: 'all'
}

export default (state = initialState, action: ActivityAction): State => {
  switch (action.type) {
    case 'activity.search':
      return {
        ...state,
        search: action.payload
      }
    case 'activity.filter':
      return {
        ...state,
        filter: action.payload as ActivityFilterId
      }
    case 'activity.toggle-visibility':
      return {
        ...state,
        visible: !state.visible
      }
    default:
      return state
  }
}
