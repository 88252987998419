import { Field, RuleGroupType } from 'react-querybuilder'
import { Action } from 'redux'
import { AndOr } from '../../alertFilter/types'

const NAMESPACE = `tiers.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const TIER_FETCH = `${NAMESPACE}fetch`
export interface TierFetchAction extends Action {
  type: typeof TIER_FETCH
  payload: boolean
}

export const TIER_FETCH_FAIL = `${NAMESPACE}fetch`
export interface TierFetchFailAction extends Action {
  type: typeof TIER_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const TIER_ADD = `${NAMESPACE}addTiers`
export interface TierAddTierAction extends Action {
  type: typeof TIER_ADD
  payload: TierList[]
}

export const TIER_SAVE = `${NAMESPACE}saveTier`
export interface TierSaveAction extends Action {
  type: typeof TIER_SAVE
  payload: TierList
}

export const TIER_REVERT = `${NAMESPACE}revertTier`
export interface TierRevertAction extends Action {
  type: typeof TIER_REVERT
  payload: number
}

export const TIER_SAVE_SUCCESS = `${NAMESPACE}saveTierSuccess`
export interface SaveTierSuccessAction extends Action {
  type: typeof TIER_SAVE_SUCCESS
  payload: any
}

export const TIER_REVERT_SUCCESS = `${NAMESPACE}revertTierSuccess`
export interface RevertTierSuccessAction extends Action {
  type: typeof TIER_REVERT_SUCCESS
  payload: any
}

export const TIER_SAVE_FAILURE = `${NAMESPACE}saveTierFailure`
export interface SaveTierFailureAction extends Action {
  type: typeof TIER_SAVE_FAILURE
  payload: any
  error: boolean
}

export type TierAction =
  | TierFetchAction
  | TierAddTierAction
  | TierFetchFailAction
  | TierSaveAction
  | SaveTierSuccessAction
  | SaveTierFailureAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface Tier {
  userId: number
  customers: number[]
  enabledAndOr: AndOr
  restrictedCustomers: number[]
  restrictedAndOr: AndOr
  types: RuleGroupType | undefined
  restrictedTypes: RuleGroupType | undefined
  isDefault: boolean
  isDisabled: boolean
  tierName: string
  addTier?: boolean
}

export interface TierList {
  id: number
  tiers: Tier[]
  custId: number
  userId: number
  isTemp: boolean
  updateForTierList: number
  isDeleted: boolean
}

export enum CPartyType {
  Buyside = 0,
  Sellside
}

export const EmptyTier = {
  userId: 0,
  customers: [],
  restrictedCustomers: [],
  types: undefined,
  restrictedTypes: undefined,
  /* types: [],
  restrictedTypes: [],*/
  isDefault: false,
  isDisabled: false,
  enabledAndOr: AndOr.Or,
  restrictedAndOr: AndOr.Or,
  tierName: '',
  addTier: false
}

export const tierIsEmpty = (tier: Tier) => {
  return (
    tier.userId === 0 &&
    tier.customers.length === 0 &&
    tier.restrictedCustomers.length === 0 &&
    // tslint:disable-next-line
    (tier.types === null || tier.types?.rules.length === 0) &&
    // tslint:disable-next-line
    (tier.restrictedTypes === null ||
      tier.restrictedTypes?.rules.length === 0) &&
    !tier.isDefault
  )
}

export const fields: Field[] = [
  {
    name: 'buySide',
    label: 'BuySide',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' }
    ],
    valueEditorType: 'radio',
    values: [
      { name: 'true', label: 'true' },
      { name: 'false', label: 'false' }
    ]
  },
  {
    name: 'sellSide',
    label: 'SellSide',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' }
    ],
    valueEditorType: 'radio',
    values: [
      { name: 'true', label: 'true' },
      { name: 'false', label: 'false' }
    ]
  },
  {
    name: 'autoAlgoFeed',
    label: 'Algo/Automated Feed',
    operators: [
      { name: '=', label: '=' },
      { name: '!=', label: '!=' }
    ],
    valueEditorType: 'radio',
    values: [
      { name: 'true', label: 'true' },
      { name: 'false', label: 'false' }
    ]
  }
]
