import { Action } from 'redux'

const NAMESPACE = `aggressAttempts.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const AGGRESSATTEMPT_FETCH = `${NAMESPACE}fetch`
export interface AggressAttemptFetchAction extends Action {
  type: typeof AGGRESSATTEMPT_FETCH
}

export const AGGRESSATTEMPT_FETCH_FAIL = `${NAMESPACE}fetch`
export interface AggressAttemptFetchFailAction extends Action {
  type: typeof AGGRESSATTEMPT_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const AGGRESSATTEMPT_ADD = `${NAMESPACE}addAggressAttempt`
export interface AggressAttemptAddAggressAttemptAction extends Action {
  type: typeof AGGRESSATTEMPT_ADD
  payload: AggressAttempt[]
}

export type AggressAttemptAction =
  | AggressAttemptFetchAction
  | AggressAttemptAddAggressAttemptAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface AggressAttempt {
  id: number
  TakerOrderId: number
  MakerOrderId: number | undefined
  status: string
  DidNotMatchReason: string
  TakerOperator: string
  MakerOperator: string
  TakerVenue: string
  MakerVenue: string
  TakerTicketId: number | undefined
  MakerTicketId: number | undefined
  TakerInterface: string
  MakerInterface: string
  TakerOrderParams: string
  MakerOrderParams: string
  TakerOrderStatusUpdates: string
  MakerOrderStatusUpdates: string
  OrderTime: string
  OrderDate: string
  MakerExternalOrderId: string
  MakerVenueNumber: number | undefined
  TakerComponentLocation: string
  SecurityBoardLabel: string
  ISIN: string
  OrderType: string
  Price: string
  Spread: string
  CompletedAmt: number
  InitialAmt: number
  RemainingAmt: number
  SecondsLive: string
  SecurityId: number
  CancelTime: Date
  IsLive: boolean
  WasCanceled: string
  WasRejected: string
  Trader: string
  Customer: string
  Specific: string
  IsEligibleResubmit: string
  TradeRecordId: number | undefined
  ActivityTicker: string
  FixVenueCount: number
  BasisPoints: string
  Brokerage: string
}
