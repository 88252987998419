import { Action } from 'redux'
import {
  alertType,
  CancelOrderAlert,
  CounteredToppedAlert,
  MutedAlert,
  NewOrderAlert,
  TradingNowAlert
} from './types'

export interface SubscribeToAlertsAction extends Action {
  type: 'alerts.subscribe'
}

export const subscribeToAlerts = (): SubscribeToAlertsAction => ({
  type: 'alerts.subscribe'
})

export interface AddTradingNowAlerts extends Action {
  type: 'alerts.addTradingNowAlerts'
  payload: { tradingNowAlerts: TradingNowAlert[] }
}

export const addTradingNowAlerts = (
  tradingNowAlerts: TradingNowAlert[]
): AddTradingNowAlerts => ({
  type: 'alerts.addTradingNowAlerts',
  payload: { tradingNowAlerts }
})

export interface AddNewOrderAlerts extends Action {
  type: 'alerts.addNewOrderAlerts'
  payload: { newOrderAlerts: NewOrderAlert[] }
}

export const addNewOrderAlerts = (
  newOrderAlerts: NewOrderAlert[]
): AddNewOrderAlerts => ({
  type: 'alerts.addNewOrderAlerts',
  payload: { newOrderAlerts }
})

export interface AddCancelOrderAlerts extends Action {
  type: 'alerts.addCancelOrderAlerts'
  payload: { cancelOrderAlerts: CancelOrderAlert[] }
}

export const addCancelOrderAlerts = (
  cancelOrderAlerts: CancelOrderAlert[]
): AddCancelOrderAlerts => ({
  type: 'alerts.addCancelOrderAlerts',
  payload: { cancelOrderAlerts }
})

export interface AddCounteredToppedAlerts extends Action {
  type: 'alerts.addCounteredToppedAlerts'
  payload: { counteredToppedAlerts: CounteredToppedAlert[] }
}

export const addCounteredToppedAlerts = (
  counteredToppedAlerts: CounteredToppedAlert[]
): AddCounteredToppedAlerts => ({
  type: 'alerts.addCounteredToppedAlerts',
  payload: { counteredToppedAlerts }
})

export interface UnsubscribeFromAlertsAction extends Action {
  type: 'alerts.unsubscribe'
}

export const unsubscribeFromAlerts = (): UnsubscribeFromAlertsAction => ({
  type: 'alerts.unsubscribe'
})

export interface MuteAlertAction extends Action {
  type: 'alerts.muteAlert'
  payload: MutedAlert
}

export const muteAlert = (alert: MutedAlert): MuteAlertAction => ({
  type: 'alerts.muteAlert',
  payload: alert
})

export interface UnmuteAlertAction extends Action {
  type: 'alerts.unmuteAlert'
  payload: {
    type: alertType
    securityId: number
  }
}

export const unmuteAlert = (
  type: alertType,
  securityId: number
): UnmuteAlertAction => ({
  type: 'alerts.unmuteAlert',
  payload: {
    type,
    securityId
  }
})

export type AlertsAction =
  | SubscribeToAlertsAction
  | AddTradingNowAlerts
  | AddCounteredToppedAlerts
  | AddNewOrderAlerts
  | UnsubscribeFromAlertsAction
  | MuteAlertAction
  | UnmuteAlertAction
  | AddCancelOrderAlerts
