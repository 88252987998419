import React, { memo } from 'react'
import { useLoadSecurity } from '../../BondList/cells/helpers'
import SecurityDetailsModal from './SecurityDetailsModal'

interface Props {
  securityId: number
  isOpen: boolean
  toggleIsOpen: () => void
}

const SecurityDetailsModalLazy: React.FC<Props> = ({
  securityId,
  isOpen = false,
  toggleIsOpen
}) => {
  const security = useLoadSecurity(securityId)

  if (!security) return <> </>

  return (
    <SecurityDetailsModal
      security={security}
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
    />
  )
}

export default memo(SecurityDetailsModalLazy)
