import {
  ClearHistoricalDataAction,
  GetHistoricalDataAction,
  GetHistoricalDataFailAction,
  GetHistoricalDataSuccessAction,
  HISTORICALDATA_CLEAR,
  HISTORICALDATA_GET,
  HISTORICALDATA_GET_FAIL,
  HISTORICALDATA_INITIALIZE_SUCCESS,
  HISTORICALDATA_SUCCESS,
  HistoricalDataTable,
  InitializeHistoricalDataSuccessAction
} from './types'

export const historicalDataGet = (
  queryUserId: number,
  queryCustId: number,
  startDate: Date,
  endDate: Date
): GetHistoricalDataAction => ({
  type: HISTORICALDATA_GET,
  payload: { queryUserId, queryCustId, startDate, endDate }
})

export const historicalDataClear = (): ClearHistoricalDataAction => ({
  type: HISTORICALDATA_CLEAR,
  payload: {}
})

export const historicalDataGetSuccess = (
  data: HistoricalDataTable
): GetHistoricalDataSuccessAction => ({
  type: HISTORICALDATA_SUCCESS,
  payload: { data }
})

export const historicalDataGetFail = (
  error: any
): GetHistoricalDataFailAction => ({
  type: HISTORICALDATA_GET_FAIL,
  payload: error,
  error: true
})

export const historicalDataInitializeSuccess = (
  data: HistoricalDataTable
): InitializeHistoricalDataSuccessAction => ({
  type: HISTORICALDATA_INITIALIZE_SUCCESS,
  payload: { data }
})
