import { Action } from 'redux'
import { AlertFilter } from './types'

export interface CreateOrUpdateAlertFilterAction extends Action {
  type: 'alertFilter.createOrUpdate'
  payload: { alertFilter: AlertFilter }
}

export const createOrUpdateAlertFilter = (
  alertFilter: AlertFilter
): CreateOrUpdateAlertFilterAction => ({
  type: 'alertFilter.createOrUpdate',
  payload: { alertFilter }
})

export interface CreateOrUpdateAlertFilterSuccessAction extends Action {
  type: 'alertFilter.createOrUpdateSuccess'
  payload: { response: number }
}

export const createOrUpdateAlertFilterSuccess = (
  response: number
): CreateOrUpdateAlertFilterSuccessAction => ({
  type: 'alertFilter.createOrUpdateSuccess',
  payload: { response }
})

export interface MuteAlertFilterAction extends Action {
  type: 'alertFilter.muteAlertFilter'
  payload: { id: number; mute: boolean }
}

export const muteAlertFilter = (
  id: number,
  mute: boolean
): MuteAlertFilterAction => ({
  type: 'alertFilter.muteAlertFilter',
  payload: { id, mute }
})

export interface MuteAlertFilterSuccessAction extends Action {
  type: 'alertFilter.muteAlertFilterSuccess'
  payload: { filter: AlertFilter }
}

export const muteAlertFilterSuccess = (
  filter: AlertFilter
): MuteAlertFilterSuccessAction => ({
  type: 'alertFilter.muteAlertFilterSuccess',
  payload: { filter }
})

export interface AlertFilterFailure extends Action {
  type: 'alertFilter.failure'
}

export const alertFilterFailure = (): AlertFilterFailure => ({
  type: 'alertFilter.failure'
})

export interface DeleteAlertFilterAction extends Action {
  type: 'alertFilter.deleteAlertFilter'
  payload: { id: number }
}

export const deleteAlertFilter = (id: number): DeleteAlertFilterAction => ({
  type: 'alertFilter.deleteAlertFilter',
  payload: { id }
})

export interface DeleteAlertFilterSuccessAction extends Action {
  type: 'alertFilter.deleteAlertFilterSuccess'
  payload: { id: number }
}

export const deleteAlertFilterSuccess = (
  id: number
): DeleteAlertFilterSuccessAction => ({
  type: 'alertFilter.deleteAlertFilterSuccess',
  payload: { id }
})

export interface FetchAlertFilters extends Action {
  type: 'alertFilter.fetchAlertFilters'
}

export const fetchAlertFilters = (): FetchAlertFilters => ({
  type: 'alertFilter.fetchAlertFilters'
})

export interface FetchAlertFiltersSuccess extends Action {
  type: 'alertFilter.fetchAlertFiltersSuccess'
  payload: { alertFilters: AlertFilter[] }
}

export const fetchAlertFiltersSuccess = (
  alertFilters: AlertFilter[]
): FetchAlertFiltersSuccess => ({
  type: 'alertFilter.fetchAlertFiltersSuccess',
  payload: { alertFilters }
})

export type AlertFilterAction =
  | FetchAlertFilters
  | FetchAlertFiltersSuccess
  | DeleteAlertFilterAction
  | DeleteAlertFilterSuccessAction
  | MuteAlertFilterAction
  | MuteAlertFilterSuccessAction
  | AlertFilterFailure
