import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'

import {
  addUserEditorData,
  fetchUserEditorDataFailure,
  saveUserEditorDataFailure,
  saveUserEditorDataSuccess
} from './actions'
import {
  UserEditorData,
  USEREDITORDATA_FETCH,
  USEREDITORDATA_SAVE,
  UserEditorDataSaveAction
} from './types'

const fetchUserEditorDataEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(USEREDITORDATA_FETCH),
    mergeMap((action: UserEditorDataSaveAction) => {
      return getHub()
        .invoke<UserEditorData[]>('GetUserEditorData', action.payload)
        .pipe(
          map((errors: any) => addUserEditorData(errors)),
          catchError((err) =>
            of(fetchUserEditorDataFailure(err), logError(err))
          )
        )
    })
  )

const saveUserEditorDataEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(USEREDITORDATA_SAVE),
    mergeMap((action: UserEditorDataSaveAction) => {
      return getHub()
        .invoke<UserEditorData[]>('SaveUserEditorData', action.payload)
        .pipe(
          map((errors: any) => saveUserEditorDataSuccess(errors)),
          catchError((err) => of(saveUserEditorDataFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(saveUserEditorDataEpic, fetchUserEditorDataEpic)
