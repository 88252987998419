import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import styles from './modalNotification.module.scss'

export interface Props {
  colorStyle: string
  messageNotification: string
}

const ModalNotification: FC<Props> = (props) => {
  const { colorStyle, messageNotification } = props
  return (
    <div
      className={`${styles.main} ${styles[colorStyle]}`}
      data-testid="agressor-error"
    >
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <div className={styles.content}>{messageNotification}</div>
    </div>
  )
}

export default ModalNotification
