import cx from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'

import { ColDef, GetRowIdFunc } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import { TierList, tierIsEmpty } from '../../../../store/admin/tiers/types'
import { getCustomers } from '../../../../store/customers/selectors'
import { Customer } from '../../../../store/customers/types'
import { useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import { getUsers } from '../../../../store/users/selectors'
import { User } from '../../../../store/users/types'
import { getCanEditTiers } from '../../../../store/webSettings/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import EditDeleteTiers from './EditDeleteTiers'

interface Props {
  setSelectedRow: (id: number) => void
  selectedTier?: TierList | undefined
  onDoubleClick: (row: any) => void
  tiers: TierList[] | undefined
  isEditable?: boolean
  customer: Customer | undefined
  user: User | undefined
}

interface RowData {
  id: number
  customer: string
  user: string
  tiers: number
}

const getRowId: GetRowIdFunc<RowData> = ({ data }) => {
  return `${data.id}`
}

const TiersGrid: FC<Props> = (props: Props) => {
  const theme = useAppSelector(getCurrentTheme)

  const setSelectedRow = props.setSelectedRow
  const onDoubleClick = props.onDoubleClick
  const tiers = props.tiers
  const customers = useAppSelector(getCustomers)
  const users = useAppSelector(getUsers)
  const canEditTiers = useAppSelector(getCanEditTiers)
  const [tiersRowData, setTiersRowData] = useState<RowData[]>([])
  const isEditable = props.isEditable

  const columns: ColDef[] = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'Tier Id',
        flex: 1
      },
      {
        field: 'customer',
        headerName: 'Customer',
        wrapText: true,
        flex: 2
      },
      {
        field: 'user',
        headerName: 'User',
        flex: 2
      },
      {
        field: 'tiers',
        headerName: 'Tiers',
        flex: 1
      },
      {
        colId: 'edit',
        field: 'edit',
        headerName: 'Edit',
        cellRenderer: EditDeleteTiers,
        filter: false,
        flex: 1
      },
      {
        colId: 'delete',
        field: 'delete',
        headerName: 'Delete',
        cellRenderer: EditDeleteTiers,
        hide: !isEditable || !canEditTiers,
        filter: false,
        flex: 1
      }
    ]
  }, [])

  useEffect(() => {
    const rowData = []
    if (tiers) {
      for (const r of tiers) {
        rowData.push({
          id: r.id,
          customer: customers?.find((c) => r.custId === c.id)?.shortName ?? '',
          user: users?.find((u) => r.userId === u.id)?.userName ?? '',
          tiers: r.tiers.filter((t) => !tierIsEmpty(t)).length
        })
      }
    }
    setTiersRowData(rowData)
  }, [tiers, customers])

  const setRow = (row: any) => {
    setSelectedRow(row.api.getSelectedNodes()[0].data.id)
  }

  return (
    <div className={cx(gridStyles.gridDimensions, theme, 'tiers')}>
      <AgGridReact
        defaultColDef={defaultColumnDefinitions}
        rowData={tiersRowData}
        columnDefs={columns}
        overlayNoRowsTemplate="No tiers found."
        overlayLoadingTemplate="Loading tiers"
        alwaysShowVerticalScroll={true}
        suppressDragLeaveHidesColumns={true}
        rowClass="tiers-grid-row"
        rowSelection={'single'}
        onRowDoubleClicked={onDoubleClick}
        onSelectionChanged={setRow}
        getRowId={getRowId}
      />
    </div>
  )
}

export default TiersGrid
