import { Action } from 'redux'
import { State } from './reducer'
import { StickyColumnGrid, WindowSettingsDto } from './types.ts'

export interface LoadSettingsAction extends Action {
  type: 'settings.loadSettings'
  payload: {
    settings: any
  }
}

export const loadSettings = (settings: any): LoadSettingsAction => ({
  type: 'settings.loadSettings',
  payload: { settings }
})

export const loadWindowSettings = (settings: WindowSettingsDto) =>
  ({
    type: 'settings.loadWindowSettings',
    payload: { settings }
  } as const)

type LoadWindowSettingsAction = ReturnType<typeof loadWindowSettings>

export interface HandleSettingsAction extends Action {
  type: 'settings.handleSettings'
  payload: {
    stateValuesToSave: string[]
    actionTypesToTriggerSaving: string[]
  }
}

export const handleSettings = (
  actionTypesToTriggerSaving: string[],
  stateValuesToSave: string[]
): HandleSettingsAction => ({
  type: 'settings.handleSettings',
  payload: { actionTypesToTriggerSaving, stateValuesToSave }
})

export interface HandleWindowSettingsAction extends Action {
  type: 'settings.handleWindowSettings'
  payload: {
    stateValuesToSave: string[]
    actionTypesToTriggerSaving: string[]
  }
}

export const handleWindowSettings = (
  actionTypesToTriggerSaving: string[],
  stateValuesToSave: string[]
): HandleWindowSettingsAction => ({
  type: 'settings.handleWindowSettings',
  payload: { actionTypesToTriggerSaving, stateValuesToSave }
})

export interface AggressAttemptsColumnModelChangedAction extends Action {
  type: 'settings.aggressAttemptsColumnModelChanged'
  payload: {
    aggressAttemptsColDefs: string[]
  }
}

export const aggressAttemptsColumnModelChanged = (
  aggressAttemptsColDefs: string[]
): AggressAttemptsColumnModelChangedAction => ({
  type: 'settings.aggressAttemptsColumnModelChanged',
  payload: { aggressAttemptsColDefs }
})

export interface ExternalOrderColumnModelChangedAction extends Action {
  type: 'settings.externalOrderColumnModelChanged'
  payload: {
    externalOrderColDefs: string[]
  }
}

export const externalOrderColumnModelChanged = (
  externalOrderColDefs: string[]
): ExternalOrderColumnModelChangedAction => ({
  type: 'settings.externalOrderColumnModelChanged',
  payload: { externalOrderColDefs }
})

export interface ExternalLiquidityColumnModelChangedAction extends Action {
  type: 'settings.externalLiquidityColumnModelChanged'
  payload: {
    externalLiquidityColDefs: string[]
  }
}

export const externalLiquidityColumnModelChanged = (
  externalLiquidityColDefs: string[]
): ExternalLiquidityColumnModelChangedAction => ({
  type: 'settings.externalLiquidityColumnModelChanged',
  payload: { externalLiquidityColDefs }
})

export interface HistoricalDataColumnModelChangedAction extends Action {
  type: 'settings.historicalDataColumnModelChanged'
  payload: {
    historicalDataColDefs: string[]
  }
}

export const historicalDataColumnModelChanged = (
  historicalDataColDefs: string[]
): HistoricalDataColumnModelChangedAction => ({
  type: 'settings.historicalDataColumnModelChanged',
  payload: { historicalDataColDefs }
})

export interface ClearSettingsAction extends Action {
  type: 'settings.clearSettings'
}

type MaybeStickyColumnGrid =
  | (StickyColumnGrid | null)
  | (StickyColumnGrid | undefined)
type GridKeys = KeysWithValsOfType<State, MaybeStickyColumnGrid>

export const updateColumnsOrder = (gridName: GridKeys, columnDefs: string[]) =>
  ({
    type: 'settings.updateColumnsOrder',
    payload: {
      gridName,
      columnDefs
    }
  } as const)

export type UpdateColumnsOrderAction = ReturnType<typeof updateColumnsOrder>

export const clearSettings = (): ClearSettingsAction => ({
  type: 'settings.clearSettings'
})

export type SettingsAction =
  | HandleSettingsAction
  | HandleWindowSettingsAction
  | LoadSettingsAction
  | LoadWindowSettingsAction
  | AggressAttemptsColumnModelChangedAction
  | ExternalOrderColumnModelChangedAction
  | ExternalLiquidityColumnModelChangedAction
  | HistoricalDataColumnModelChangedAction
  | UpdateColumnsOrderAction
