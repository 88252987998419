export interface Config {
  /** Config for back-end connection. */
  api: {
    /** URL of SignalR hub. */
    url: string
    /** Delay (ms) before trying to reconnect when connection is lost */
    reconnectDelay: number
    /** Timeout (ms) for token refresh before it expires */
    refreshTokenTimeout: number
    /** Timeout (ms) for websocket before page reload */
    serverTimeoutInMilliseconds: number
  }
  /** Config for Okta SSO. */
  okta: {
    /** Okta issuer. */
    issuer: string
    /** Okta redirect URI after login. */
    redirectUri: string
    /** Okta client ID. */
    clientId: string
    /** Deactivate Okta? (For automated tests) */
    deactivate: boolean
    tokenManager: object
    /** User Id (if Okta is deactivated) */
    userId?: number
  }
  env: string
  sentry: Record<string, object>
}

type DefaultConfig = { default: Config }

const getUserId = () => Number(localStorage.getItem('userId'))

const nodeEnv = import.meta.env.MODE

/*
  When you're running locally, import the local.ts file to provide config.
  We do a dynamic import so that this file doesn't get compiled into
  our source code.

  Use an .env.[mode] file to provide overrides for the VITE_XXX variables.

  This capability is mainly so in future we can create a script in
  package.json that will trigger a mode where we'll turn off okta
  for renewed cypress testing.

  If you are NOT running locally, the config will be placed on
  window by the build process.
 */

async function loadConfig(): Promise<Config> {
  if (['test', 'localdev'].includes(nodeEnv)) {
    const allConfig = (await import(
      '../config/local'
    )) as unknown as DefaultConfig
    const baseConfig = allConfig.default
    return {
      api: {
        ...baseConfig.api,
        url: import.meta.env.VITE_API_URL || baseConfig.api.url
      },
      okta: {
        ...baseConfig.okta,
        tokenManager: { storage: 'sessionStorage' },
        issuer: import.meta.env.VITE_OKTA_ISSUER || baseConfig.okta.issuer,
        redirectUri:
          import.meta.env.VITE_OKTA_REDIRECT_URI || baseConfig.okta.redirectUri,
        clientId:
          import.meta.env.VITE_OKTA_CLIENT_ID || baseConfig.okta.clientId,
        deactivate: import.meta.env.VITE_DEACTIVATE_OKTA
          ? import.meta.env.VITE_DEACTIVATE_OKTA === '1'
          : baseConfig.okta.deactivate,
        userId: getUserId()
      },
      env: baseConfig.env,
      sentry: baseConfig.sentry || {}
    }
  } else {
    const baseConfig = (window as any).config
    return {
      ...baseConfig,
      okta: {
        ...baseConfig.okta,
        tokenManager: { storage: 'sessionStorage' },
        userId: getUserId()
      },
      env: baseConfig.env,
      sentry: baseConfig.sentry || {}
    }
  }
}

const config = await loadConfig()

export const actionsToListenToForLocalStorage = [
  'securities.setWatchListId',
  'securities.setIssuerFilter',
  'securities.setSecuritiesFilter',
  'securities.setIsMine',
  'securities.setShowLive',
  'securities.setMyFirm',
  'securities.saveAdvancedFilter',
  'securities.setUseAdvancedFilter',
  'securities.setQuoteReliability',
  'securities.setSizeFilter',
  'securities.createGrid',
  'securities.removeGrid',
  'grid.updateColumnsOrder',
  'grid.updateDisplayedColumns',
  'depthofmarket.popout',
  'depthofmarket.closePopout',
  'depthofmarket.closeAllPopouts',
  'depthofmarket.updatePopouts',
  'order.setMyOrdersOpen',
  'alerts.muteAlert',
  'alerts.unmuteAlert',
  'settings.aggressAttemptsColumnModelChanged',
  'settings.externalOrderColumnModelChanged',
  'settings.externalLiquidityColumnModelChanged',
  'settings.historicalDataColumnModelChanged',
  'settings.updateColumnsOrder'
]

export const stateValueForLocalStorage = [
  'securities.gridDefinitions',
  'grid.columnsOrder',
  'grid.displayedColumns',
  'depthofmarket.popoutSecurityIds',
  'order.myOrdersIsOpen',
  'alerts.tradingNowAlerts',
  'alerts.counteredToppedAlerts',
  'alerts.mutedAlerts',
  'activity.visible'
]

export default config
