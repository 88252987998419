import { TierList } from './types'

export const addOrUpdateTier = (
  stateTiers: TierList[],
  tiersToAdd: TierList[]
) => {
  const unchangedTiers = stateTiers.filter(
    (t) => !tiersToAdd.find(({ id }) => t.id === id)
  )
  const addTiers = tiersToAdd.filter((t) => !t.isDeleted)
  return [...unchangedTiers, ...addTiers]
}
