import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  faArchive,
  faBan,
  faBookOpen,
  faCalendar,
  faChartBar,
  faChartLine,
  faDatabase,
  faFileSpreadsheet,
  faFlask,
  faLayerGroup,
  faMessagesDollar,
  faSignalAlt3,
  faUsers
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOpenFin } from '../../../app/openFinContext'
import {
  getCanMarkRestricted,
  getCanSeeSystemParamEditor
} from '../../../store/webSettings/selectors'
import styles from './menus.module.scss'

export interface Props {
  closeMenu: () => void
  handleNavigation: (item: string) => void
}

const AdminMenu: FC<Props> = ({ closeMenu, handleNavigation }) => {
  const { fin } = useOpenFin()
  const canMarkRestricted = useSelector(getCanMarkRestricted)
  const canSeeSystemParameterEditor = useSelector(getCanSeeSystemParamEditor)

  return (
    <div className={fin && styles.menuContainer}>
      <div className={styles.menuSection}>
        Admin
        {fin && (
          <span onClick={closeMenu}>
            <FontAwesomeIcon icon={faTimes} title="Close Menu" />
          </span>
        )}
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('TradeBlotter')}
        >
          <FontAwesomeIcon icon={faArchive} />
          <span>Trade Blotter</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('ActivityTicker')}
        >
          <FontAwesomeIcon icon={faChartLine} />
          <span>Activity Ticker</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('AggressAttempts')}
        >
          <FontAwesomeIcon icon={faMessagesDollar} />
          <span>Aggress Attempts</span>
        </div>
        {canSeeSystemParameterEditor && (
          <div
            className={styles.dropdownButton}
            onClick={() => handleNavigation('SystemParameters')}
          >
            <FontAwesomeIcon icon={faBookOpen} /> System Parameters
          </div>
        )}

        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('Tiers')}
        >
          <FontAwesomeIcon icon={faLayerGroup} /> Tiers
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('QueueStats')}
        >
          <FontAwesomeIcon icon={faSignalAlt3} /> Queue Stats
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('QuerySelector')}
        >
          <FontAwesomeIcon icon={faDatabase} /> Order/Trade History
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('UserDashboard')}
        >
          <FontAwesomeIcon icon={faUsers} />
          <span>Active User Dashboard</span>
        </div>
        {canMarkRestricted && (
          <>
            <div
              className={styles.dropdownButton}
              onClick={() => handleNavigation('RestrictedTrading')}
            >
              <FontAwesomeIcon icon={faBan} />
              <span>Restrict Trading</span>
            </div>
            <div
              className={styles.dropdownButton}
              onClick={() => handleNavigation('RestrictedTradingPermanent')}
            >
              <FontAwesomeIcon icon={faBan} />
              <span>Restrict Permanent</span>
            </div>
          </>
        )}
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('GUIUserData')}
        >
          <FontAwesomeIcon icon={faCalendar} />
          <span>GUI User Data</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('APIUserData')}
        >
          <FontAwesomeIcon icon={faCalendar} />
          <span>API User Data</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('ExternalLiquidityStats')}
        >
          <FontAwesomeIcon icon={faFlask} />
          <span>External Liquidity</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('WatchlistManager')}
        >
          <FontAwesomeIcon icon={faChartBar} /> Watchlist Manager
        </div>
      </div>
      <div className={styles.optionsContainer}>
        <div className={styles.menuSection}>Ops</div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('BBMISIN')}
        >
          <FontAwesomeIcon icon={faFileSpreadsheet} /> B-BMISIN
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => handleNavigation('ManagementReports')}
        >
          <FontAwesomeIcon icon={faChartBar} /> Management Reports
        </div>
      </div>
    </div>
  )
}

export default AdminMenu
