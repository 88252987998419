import { Action } from 'redux'

export interface MarketClosedAction extends Action {
  type: 'market.marketClosed'
  payload: {
    closed: boolean
  }
}

export const marketClosed = (closed: boolean): MarketClosedAction => ({
  type: 'market.marketClosed',
  payload: { closed }
})

export const marketCloseTimerTick = () =>
  ({
    type: 'market.closeTimerTick'
  } as const)

type MarketClosetTimerTickAction = ReturnType<typeof marketCloseTimerTick>

export type MarketAction = MarketClosedAction | MarketClosetTimerTickAction
