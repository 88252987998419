import { Action } from 'redux'

const NAMESPACE = `managementReports.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const MANAGEMENTREPORT_FETCH = `${NAMESPACE}fetch`
export interface ManagementReportFetchAction extends Action {
  type: typeof MANAGEMENTREPORT_FETCH
  payload: any
}

export const MANAGEMENTREPORT_FETCH_FAIL = `${NAMESPACE}fetchFail`
export interface ManagementReportFetchFailAction extends Action {
  type: typeof MANAGEMENTREPORT_FETCH_FAIL
  payload: any
  error: true
}

export const MANAGEMENTREPORT_ADD = `${NAMESPACE}add`
export interface ManagementReportAddAction extends Action {
  type: typeof MANAGEMENTREPORT_ADD
  payload: any
}

export type ManagementReportAction =
  | ManagementReportAddAction
  | ManagementReportFetchAction
  | ManagementReportFetchFailAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface UserStats {
  userName: string
  brokerUserName: string
  customerName: string
  totalMinutes: number
  totalTrades: number
  annualCustomerVolume: number
  dataForDate: Record<string, MinutesAndTradesForDay>
}

export interface MinutesAndTradesForDay {
  minutes: number
  trades: number
}

export interface UserDataReport {
  reportDate: Date
  userStats: UserStats[]
}

export interface ManagementReport {
  reportDate: Date
  webMakerOrders: number
  webTradeTickets: number
  apiTradeTickets: number
  citrixTickets: number
  allTradeTickets: number
  uniqueWebUsers: number
  totalCitrixUsers: number
  bondsComCitrixUsers: number
  customerCitrixUsers: number
  topWebTraders: WebTradeData[]
  topWebMarketMakers: WebMarketMakerData[]
  allUsers: string[]
}

export const EmptyManagementReport = {
  reportDate: new Date(),
  webMakerOrders: 0,
  webTradeTickets: 0,
  apiTradeTickets: 0,
  citrixTickets: 0,
  allTradeTickets: 0,
  uniqueWebUsers: 0,
  totalCitrixUsers: 0,
  bondsComCitrixUsers: 0,
  customerCitrixUsers: 0,
  topWebTraders: [],
  topWebMarketMakers: [],
  allUsers: []
}

export interface WebTradeData {
  customerName: string
  userName: string
  tradeCount: number
}

export interface WebMarketMakerData {
  customerName: string
  userName: string
  orderCount: number
}
