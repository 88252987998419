import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const isPending = createSelector([getState], (state) => state.pending)

export const hasError = createSelector([getState], (state) => state.error)

export const getBooks = createSelector([getState], (state) => state.books)

export const getDefaultBookId = createSelector([getState], (state) =>
  state.books ? state.books[0].id : 0
)

export const getBook = createSelector([getState], (state) => (id: number) => {
  let retval = state.books![0]
  for (const book of state.books!) {
    if (book.id === id) {
      retval = book
      break
    }
  }
  return retval
})
