import React from 'react'
import { OrderType } from '../../../store/order/types'
import { CellProps, useSecurityOrderData } from './helpers'

const BestYTWCell = (orderType: OrderType) => ({ data }: CellProps) => {
  const security = useSecurityOrderData(data)
  if (!security) return <> </>

  const order = orderType === 'buy' ? security.bestBid : security.bestOffer
  const ytw = order?.ytw
  const testId = data.id + (orderType === 'buy' ? '-bestbidytw' : '-bestofrytw')
  return (
    <span data-testid={testId} data-testid-value={data.id + '-' + ytw}>
      {ytw === 0
        ? '-'
        : ytw?.toLocaleString(undefined, { maximumFractionDigits: 3 }) || '-'}
    </span>
  )
}

export default BestYTWCell
