import { Action } from 'redux'

const NAMESPACE = `venue.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const VENUE_FETCH = `${NAMESPACE}fetch`
export interface VenueFetchAction extends Action {
  type: typeof VENUE_FETCH
}

export const VENUE_FETCH_FAIL = `${NAMESPACE}fetchFail`
export interface VenueFetchFailAction extends Action {
  type: typeof VENUE_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const VENUE_ADD = `${NAMESPACE}addVenue`
export interface VenueAddAction extends Action {
  type: typeof VENUE_ADD
  payload: Venue[]
}

export type VenueAction =
  | VenueFetchAction
  | VenueAddAction
  | VenueFetchFailAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface Venue {
  id: number
  name: string
}
