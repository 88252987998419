import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  takeUntil
} from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  deleteRestrictedTradesFailure,
  deleteRestrictedTradesSuccess,
  getRestrictedTradesSuccess,
  restrictedTradesFetchFail,
  saveRestrictedTradesFailure,
  saveRestrictedTradesSuccess
} from './actions'
import {
  RESTRICTED_TRADES_DELETE,
  RESTRICTED_TRADES_FETCH,
  RESTRICTED_TRADES_FETCH_CANCEL,
  RESTRICTED_TRADES_SAVE,
  RestrictedTrades,
  RestrictedTradesDeleteAction,
  RestrictedTradesSaveAction
} from './types'

const fetchRestrictedTradesEpic: Epic<Action> = (action$, state$) => {
  return action$.pipe(
    ofType(RESTRICTED_TRADES_FETCH),
    delay(2000),
    switchMap((action: RestrictedTradesSaveAction) => {
      const getRestricted$ = getHub().stream<RestrictedTrades[]>(
        'GetRestrictedTrades'
      )
      return getRestricted$.pipe(
        map((restricted: RestrictedTrades[]) => {
          return getRestrictedTradesSuccess(restricted)
        }),
        takeUntil(action$.pipe(ofType(RESTRICTED_TRADES_FETCH_CANCEL))),
        catchError((err) => of(restrictedTradesFetchFail(err), logError(err)))
      )
    })
  )
}

const saveRestrictedTradesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(RESTRICTED_TRADES_SAVE),
    mergeMap((action: RestrictedTradesSaveAction) => {
      return getHub()
        .invoke<RestrictedTrades[]>('SaveRestrictedTrades', action.payload)
        .pipe(
          map((transactionId: any) =>
            saveRestrictedTradesSuccess(action.payload, transactionId)
          ),
          catchError((err) =>
            of(saveRestrictedTradesFailure(err), logError(err))
          )
        )
    })
  )

const deleteRestrictedTradesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(RESTRICTED_TRADES_DELETE),
    mergeMap((action: RestrictedTradesDeleteAction) => {
      return getHub()
        .invoke<RestrictedTrades[]>('DeleteRestrictedTrades', action.payload)
        .pipe(
          map((transactionId: any) =>
            deleteRestrictedTradesSuccess(transactionId)
          ),
          catchError((err) =>
            of(deleteRestrictedTradesFailure(err), logError(err))
          )
        )
    })
  )

export default combineEpics(
  fetchRestrictedTradesEpic,
  saveRestrictedTradesEpic,
  deleteRestrictedTradesEpic
)
