import { CheckedOrder } from './types'

export const checkedOrdersEqual = (co1: CheckedOrder, co2: CheckedOrder) =>
  co1.securityId === co2.securityId && co1.orderType === co2.orderType

export const includesCheckedOrder = (
  checkedOrders: CheckedOrder[],
  checkedOrder: CheckedOrder
) => checkedOrders.some(co => checkedOrdersEqual(co, checkedOrder))

export const addCheckedOrder = (
  checkedOrders: CheckedOrder[],
  checkedOrder: CheckedOrder
) =>
  includesCheckedOrder(checkedOrders, checkedOrder)
    ? checkedOrders
    : [...checkedOrders, checkedOrder]

export const removeCheckedOrder = (
  checkedOrders: CheckedOrder[],
  checkedOrder: CheckedOrder
) => {
  return checkedOrders.filter(co => !checkedOrdersEqual(co, checkedOrder))
}

export const removeCheckedOrders = (
  checkedOrders: CheckedOrder[],
  checkedOrdersToRemove: CheckedOrder[]
) => {
  const arr = [] as CheckedOrder[]
  for (const co of checkedOrders) {
    let remove = false
    for (const co2 of checkedOrdersToRemove) {
      if (checkedOrdersEqual(co, co2)) {
        remove = true
        break
      }
    }
    if (!remove) {
      arr.push(co)
    }
  }
  return arr
}

export const toggleCheckedOrder = (
  checkedOrders: CheckedOrder[],
  checkedOrder: CheckedOrder
) =>
  includesCheckedOrder(checkedOrders, checkedOrder)
    ? removeCheckedOrder(checkedOrders, checkedOrder)
    : [...checkedOrders, checkedOrder]

export const safeAdd = (
  firstOperand: number,
  secondOperand: number,
  factor = 1e12
) => (firstOperand * factor + secondOperand * factor) / factor
