import {
  ADD_USEREDITORDATA,
  AddUserEditorDataAction,
  UserEditorData,
  USEREDITORDATA_FETCH,
  USEREDITORDATA_FETCH_FAILURE,
  USEREDITORDATA_SAVE,
  USEREDITORDATA_SAVE_FAILURE,
  USEREDITORDATA_SAVE_SUCCESS,
  UserEditorDataAction,
  UserEditorDataFetchAction,
  UserEditorDataFetchFailureAction,
  UserEditorDataSaveFailureAction,
  UserEditorDataSaveSuccessAction
} from './types'

export const saveUserEditorData = (
  userEditorData: UserEditorData
): UserEditorDataAction => ({
  type: USEREDITORDATA_SAVE,
  payload: userEditorData
})

export const saveUserEditorDataSuccess = (
  errors: any
): UserEditorDataSaveSuccessAction => ({
  type: USEREDITORDATA_SAVE_SUCCESS,
  payload: errors
})

export const saveUserEditorDataFailure = (
  error: any
): UserEditorDataSaveFailureAction => ({
  type: USEREDITORDATA_SAVE_FAILURE,
  payload: error,
  error: true
})

export const fetchUserEditorData = (
  userId: number
): UserEditorDataFetchAction => ({
  type: USEREDITORDATA_FETCH,
  payload: userId
})

export const addUserEditorData = (error: any): AddUserEditorDataAction => ({
  type: ADD_USEREDITORDATA,
  payload: error
})

export const fetchUserEditorDataFailure = (
  userId: number
): UserEditorDataFetchFailureAction => ({
  type: USEREDITORDATA_FETCH_FAILURE,
  payload: userId,
  error: true
})
