import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import config from '../../config'
import {
  removeAggressorWindow,
  removeLastLookFromWindows
} from '../../store/windows/actions'
import {
  AggressorWindowInfo,
  SecurityModalInfo
} from '../../store/windows/types'
import { copyAppStyles } from '../Openfin/helpers'

interface Props {
  title: string
  windowInfo: AggressorWindowInfo | SecurityModalInfo
  children: any
}

const popupElementId = `PopupRoot`

export const PopoutHost = () => {
  return <div id={popupElementId}></div>
}

const Popout: FC<Props> = ({ title, windowInfo, children }) => {
  const lastHeightRef = useRef<number | undefined>()
  const { ref, height } = useResizeObserver<HTMLDivElement>()
  const dispatch = useDispatch()
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )
  const maxHeight = 800
  const val = useRef()
  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const unload = () => {
    if (val.current) {
      const w = val.current as any
      w.close()
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', unload)
    renderWindow()
    return () => {
      unload()
    }
  }, [])

  useEffect(() => {
    if (
      externalWindow &&
      height &&
      height < maxHeight &&
      height !== lastHeightRef.current
    ) {
      const offsetHeight =
        externalWindow.document.getElementById('modalWrapper')?.offsetHeight
      const heightToUse = offsetHeight ?? height

      // web version of window has a larger titlebar
      const titlebarHeight = offsetHeight ? 75 : 38
      externalWindow.resizeTo(415, heightToUse + titlebarHeight)
      externalWindow.focus()
      lastHeightRef.current = height
    }
  }, [height])

  const renderWindow = () => {
    const formattedTitle = title.split('/')[0]
    const left = localStorage.getItem(`${formattedTitle}Left`)
    const top = localStorage.getItem(`${formattedTitle}Top`)
    const extWindow = window.open(
      '',
      title.toString(),
      `resizable=yes,width=415,height=458,left=${left ?? '300'},top=${
        top ?? '200'
      }`
    )
    if (extWindow) {
      const container = extWindow.document.createElement('div')
      extWindow.document.body.appendChild(container)
      setContainerElement(container)

      copyAppStyles(extWindow)

      extWindow.document.title = `${formattedTitle} ${config.env ?? ''}`

      // Make sure web lastlook/aggressor gets removed if its closed from the titlebar
      extWindow.addEventListener('beforeunload', () => {
        localStorage.setItem(
          `${formattedTitle}Left`,
          extWindow.screenX.toString()
        )
        localStorage.setItem(
          `${formattedTitle}Top`,
          extWindow.screenY.toString()
        )
        if ('orderId' in windowInfo) {
          dispatch(removeLastLookFromWindows(windowInfo))
        } else dispatch(removeAggressorWindow(windowInfo))
      })
    }
    setExternalWindow(extWindow)
  }

  return (
    containerElement &&
    externalWindow &&
    ReactDOM.createPortal(<div ref={ref}>{children}</div>, containerElement)
  )
}

export default Popout
