import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GridApi
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  getHistoricalData,
  getHistoricalDataError,
  getHistoricalDataPending
} from '../../../../store/admin/historicalUserData/selectors'
import { HistoricalDataTableRow } from '../../../../store/admin/historicalUserData/types'
import { historicalDataColumnModelChanged } from '../../../../store/settings/actions'
import { getHistoricalDataColumnOrder } from '../../../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import { applyColumnsOrder } from '../../helpers'
import { columnDefinitions } from './columnDefs'

const getRowId: GetRowIdFunc<
  HistoricalDataTableRow & { id: string | number }
> = ({ data }) => {
  return `${data.id}`
}

const HistoricalDataGrid: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const pending = useAppSelector(getHistoricalDataPending)
  const error = useAppSelector(getHistoricalDataError)
  const historicalData = useAppSelector(getHistoricalData)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)
  const [historicalDataRowData, setHistoricalDataRowData] = useState<
    HistoricalDataTableRow[]
  >([])
  const columnsOrder = useAppSelector(getHistoricalDataColumnOrder)
  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder, columnDefinitions))
  }, [columnsOrder?.join(',')])

  useEffect(() => {
    if (historicalData) {
      setHistoricalDataRowData(historicalData.rows)
    } else {
      setHistoricalDataRowData([])
    }
  }, [historicalData])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    if (gridApi) {
      if (pending) {
        gridApi.api.showLoadingOverlay()
      } else if (error) {
        gridApi.api.showNoRowsOverlay()
      } else {
        if (historicalDataRowData.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [pending, error, historicalDataRowData, gridApi])

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(historicalDataColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  return (
    <div className={cx(gridStyles.gridDimensions, theme)}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={historicalDataRowData}
        defaultColDef={defaultColumnDefinitions}
        overlayNoRowsTemplate="No user data."
        overlayLoadingTemplate="Loading GUI user data. This may take up to several minutes depending on server load."
        suppressDragLeaveHidesColumns={true}
        onColumnMoved={handleColumnChange}
        onDisplayedColumnsChanged={handleColumnChange}
        onFirstDataRendered={onDataInitialized}
        alwaysShowVerticalScroll={true}
        onGridReady={onGridReady}
        enableBrowserTooltips={true}
        rowSelection={'single'}
        groupIncludeTotalFooter={true}
        getRowId={getRowId}
      />
    </div>
  )
}

export default HistoricalDataGrid
