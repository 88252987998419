import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import useDatalist from '../../helpers/hooks/useDatalist'
import { useFetchBondsSearchResults } from '../../helpers/hooks/useFetchBondsSearchResults'
import { SecuritySearchResult } from '../../store/searchSecurities/types'
import SearchInput from './SearchInput'

type Props = {
  onSecuritySelect: (security: SecuritySearchResult) => void
  onClear: () => void
}

const CusipIsinSearch = ({ onSecuritySelect, onClear }: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const { watchListResults, notInWatchListResults } =
    useFetchBondsSearchResults(0, searchTerm, true)

  const isins = useMemo(() => {
    const flatSearchResults = [
      ...watchListResults,
      ...notInWatchListResults
    ].flatMap((issuerResult) => issuerResult.securities)
    const noDupes = new Set(flatSearchResults.map((security) => security.isin))
    return Array.from(noDupes).sort()
  }, [watchListResults, notInWatchListResults])

  const searchTermHasDigits = useMemo(() => {
    if (!searchTerm) return false
    const digits = /\d+/
    return digits.test(searchTerm)
  }, [searchTerm])

  const { datalist, listId, onInputKeydown } = useDatalist({
    datalistItems: isins,
    datalistId: searchTerm.length && searchTermHasDigits ? 'user-list' : ''
  })

  const handleChange = useCallback((e: ChangeEvent) => {
    setSearchTerm((e.target as HTMLInputElement).value)
  }, [])
  const handleClear = useCallback(() => {
    setSearchTerm('')
    onClear()
  }, [])

  useEffect(() => {
    const flatSearchResults = [
      ...watchListResults,
      ...notInWatchListResults
    ].flatMap((issuerResult) => issuerResult.securities)
    const security = flatSearchResults.find(
      (result) => result.isin === searchTerm
    )
    if (security) {
      onSecuritySelect(security)
    }
  }, [searchTerm, watchListResults, notInWatchListResults])

  return (
    <>
      <SearchInput
        list={listId}
        data-testid="isin-search-input"
        onKeyDownCapture={onInputKeydown}
        onClear={handleClear}
        onChange={handleChange}
        value={searchTerm}
        placeholder="Search by ISIN"
      />
      {datalist}
    </>
  )
}

export default React.memo(CusipIsinSearch)
