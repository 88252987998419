import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { fetchWatchlistDetails } from '../../store/watchList/actions'
import { getWatchlistDetails } from '../../store/watchList/selectors'
import { WatchList } from '../../store/watchList/types'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import HeaderCloseButton from '../ComponentHeader/HeaderCloseButton'
import Modal from '../GenericModal/Modal'

import styles from './styles.module.css'

interface Props {
  watchlist: WatchList
  closeConfirm: () => void
}

const TradeListConfirmModal = ({ watchlist, closeConfirm }: Props) => {
  const dispatch = useAppDispatch()
  const watchlistDetails = useAppSelector(getWatchlistDetails)(
    watchlist.id ?? -1
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onClose = useCallback(() => {
    closeConfirm()
  }, [closeConfirm])
  useEffect(() => {
    if (watchlist.id && !watchlistDetails) {
      dispatch(fetchWatchlistDetails(watchlist.id, 0))
    } else if (watchlistDetails.securityIds.length <= 200) {
      const path = `ListTrading/${watchlist.id}`
      if ('/' + path !== pathname) {
        navigate(path)
      }
    }
  }, [watchlistDetails, watchlist.id])
  if (!watchlist.id) {
    // this shouldn't happen, but theoretically could
    return (
      <Modal>
        <ComponentHeader
          title={`Watchlist ${watchlist.name} has no id.`}
          headerButtons={<HeaderCloseButton onClick={onClose} />}
        />
        This watchlist may not have been saved.
      </Modal>
    )
  }
  if (watchlistDetails && watchlistDetails.securityIds.length > 200) {
    return (
      <Modal>
        <ComponentHeader
          title={`Watchlist "${watchlist.name}" too large`}
          headerButtons={<HeaderCloseButton onClick={onClose} />}
        />
        <div className={styles.tradeConfirm}>
          Please select a watchlist with less than 200 securities.
        </div>
      </Modal>
    )
  }
  return <></>
}

export default TradeListConfirmModal
