import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { writeLogItems } from '../../store/log/actions'

const Logger = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    setInterval(() => {
      dispatch(writeLogItems())
    }, 60000)
  }, [])
  return <></>
}

export default Logger
