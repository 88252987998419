import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MouseEvent } from 'react'

interface Props {
  onClick: (e: MouseEvent<SVGElement>) => void
}

const HeaderCloseButton = ({ onClick }: Props) => {
  return <FontAwesomeIcon icon={faTimes} onClick={onClick} />
}

export default HeaderCloseButton
