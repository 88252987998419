import { Order } from '../order/types'
import { UserPreferences } from '../userPreferences/type'
import { ListTradingSecurity, WorkingListTradingOrder } from './types'

export const updateArray = (
  arr: ListTradingSecurity[],
  updates: ListTradingSecurity[]
): ListTradingSecurity[] => {
  // Create a copy of the original array to avoid modifying it directly
  const updatedArray = [...arr]

  updates.forEach((update) => {
    const index = updatedArray.findIndex(
      (originalSecurity) => originalSecurity.id === update.id
    )

    if (index !== -1) {
      // If the Security with the same id is already present, replace it
      updatedArray[index] = update
    } else {
      // If the Security with the id is not present, append it to the array
      updatedArray.push(update)
    }
  })

  return updatedArray
}

export const securityToOrder = (
  security: ListTradingSecurity,
  securityId: number,
  isTemp: boolean
): WorkingListTradingOrder => ({
  securityId,
  cusipOrIsin: security.isin,
  isBid: security.isBid,
  isSpread: security.isSpread,
  price: security.isSpread ? security.targetSpread : security.targetPrice,
  size: security.remainingInterest,
  selectedOrders: [],
  ordersToCancel: [],
  isTemp
})

const validationFields = ['cusipOrIsin', 'size', 'isSpread'] as const
export const hasRequiredFields = <O extends WorkingListTradingOrder>(
  order: O
) =>
  !validationFields.some(
    (field) => order[field] === undefined || order[field] === null
  )
export const getMaxExceeded = (size: number, maxSize: number) =>
  `The amount of ${size} exceeds your Maximum amount of ${maxSize}`
export const validateAggressOrder = (
  order: Order,
  settings: UserPreferences,
  bypassSizeCheck: boolean
) => {
  const maxSize = settings.maximumTradeSize || 0
  if (
    !bypassSizeCheck &&
    settings.maximumTradeSizeIsHardLimit &&
    order.size > maxSize
  ) {
    return getMaxExceeded(order.size, maxSize)
  }
}
