import { AggressAttempt } from './types'

export const addOrUpdateAggressAttempt = (
  stateAggressAttempts: AggressAttempt[],
  aggressAttemptsToAdd: AggressAttempt[]
) => {
  const unchangedAggressAttempts = stateAggressAttempts.filter(
    (t) => !aggressAttemptsToAdd.find(({ id }) => t.id === id)
  )
  return [...unchangedAggressAttempts, ...aggressAttemptsToAdd]
}
