import React, { FC } from 'react'
import styles from './ComponentHeader.module.scss'

export interface Props {
  title: string
  headerButtons: React.ReactNode // custom header functionality
  children?: React.ReactNode
}

const ComponentHeader: FC<Props> = ({ title, headerButtons, children }) => {
  return (
    <header className={styles.header}>
      <label className={styles.title}>{children ?? title}</label>
      <div className={styles.iconContainer}>{headerButtons}</div>
    </header>
  )
}

export default ComponentHeader
