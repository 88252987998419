import { ReactNode } from 'react'
import { Action } from 'redux'

const NAMESPACE = `activityTradeBlotter.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const TRADEBLOTTER_FETCH = `${NAMESPACE}fetch`
export interface TradeBlotterFetchAction extends Action {
  type: typeof TRADEBLOTTER_FETCH
}

/**
 * Fetch cancel
 */
export const TRADEBLOTTER_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface TradeBlotterFetchCancelAction extends Action {
  type: typeof TRADEBLOTTER_FETCH_CANCEL
}

/**
 * Fetch fail
 */
export const TRADEBLOTTER_FETCH_FAIL = `${NAMESPACE}fetchFail`
export interface TradeBlotterFetchFailAction extends Action {
  type: typeof TRADEBLOTTER_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Trades
 */
export const TRADEBLOTTER_ADD_TRADES = `${NAMESPACE}addTrades`
export interface TradeBlotterAddTradesAction extends Action {
  type: typeof TRADEBLOTTER_ADD_TRADES
  payload: Trade[]
}

export type TradeBlotterActions =
  | TradeBlotterFetchAction
  | TradeBlotterAddTradesAction
  | TradeBlotterFetchCancelAction
  | TradeBlotterFetchFailAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export interface Trade {
  orderId: number
  confirmText: any
  id: number
  title: string
  description: ReactNode
  date: Date
}

export interface Activity {
  matId: number
  eventTime: Date
  SecurityId: number
  Security: string
  Description: string
}
