export interface ApiCallState {
  pending: boolean
  error: boolean
  status?: string
}

export const callInitiated = {
  pending: true,
  error: false
}

export const callFailed = {
  pending: false,
  error: true
}

export const callSucceeded = {
  pending: false,
  error: false
}

export type Writable<T extends Record<string, any>> = {
  -readonly [P in keyof T]: T[P]
}
