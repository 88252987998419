import { BidAskDiffType, GreaterOrLessThan } from '../alertFilter/types'
export interface Filter {
  sectors: Sector[]
  maturities: string[]
  securityTypes: SecurityType[]
  couponTypes: CouponType[]
  currencies: Currency[]
  ratings: Rating[]
  securitiesAndTickers: string[]
  price: number | undefined
  priceGreaterLessThan: GreaterOrLessThan
  yield: number | undefined
  yieldGreaterLessThan: GreaterOrLessThan
  bidAskDiff: number | undefined
  bidAskDiffGreaterLessThan: GreaterOrLessThan
  bidAskDiffType: BidAskDiffType
  series: Series
  countries: string[]
}

export enum Series {
  All,
  REGS,
  _144a
}

export const AllSeries = ['All Series', '144a', 'REGS']

export enum Sector {
  BasicMaterials = 387,
  Communications = 386,
  ConsumerCyclical = 388,
  ConsumerNonCyclical = 390,
  Energy = 391,
  Diversified = 392,
  EGB = 394,
  Financial = 384,
  Government = 395,
  Industrial = 385,
  Technology = 393,
  TestingDemoSecurities = 379,
  UST = 365,
  Utilities = 389
}

export type Maturities =
  | '1yr'
  | '2yr'
  | '3yr'
  | '4yr'
  | '5yr'
  | '6yr'
  | '7yr'
  | '8yr'
  | '9yr'
  | '10yr'
  | '>10yr'
export const MaturitiesValues = [
  '1yr',
  '2yr',
  '3yr',
  '4yr',
  '5yr',
  '6yr',
  '7yr',
  '8yr',
  '9yr',
  '10yr',
  '>10yr'
]
export enum SecurityType {
  Corporate = 0,
  US_Government,
  European_Government,
  Other
}

export const AllSecurityTypes = [
  SecurityType.Corporate,
  SecurityType.US_Government,
  SecurityType.European_Government,
  SecurityType.Other
]

export const SecurityTypeValues = [
  'Corporate',
  'US Government',
  'European Government',
  'Other'
]

export enum CouponType {
  Floating_Rate = 0,
  Non_Floating_Rate
}

export const AllCouponTypes = [
  CouponType.Floating_Rate,
  CouponType.Non_Floating_Rate
]

export const CouponTypeValues = ['Floating Rate', 'Non Floating Rate']

export type Currency =
  | 'USD'
  | 'EUR'
  | 'GBP'
  | 'CAD'
  | 'AUD'
  | 'SGD'
  | 'SEK'
  | 'NOK'
  | 'CHF'
  | 'ZAR'
  | 'HKD'
  | 'CNH'
  | 'NZD'
  | 'TRY'
  | 'MXN'
  | 'DKK'
  | 'CZK'
  | 'JPY'
  | 'ARS'
  | 'BRL'
  | 'COP'
export const CurrencyValues = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
  'AUD',
  'SGD',
  'SEK',
  'NOK',
  'CHF',
  'ZAR',
  'HKD',
  'CNH',
  'NZD',
  'TRY',
  'MXN',
  'DKK',
  'CZK',
  'JPY',
  'ARS',
  'BRL',
  'COP'
]

export const Sectors = {
  387: 'Basic Materials',
  386: 'Communications',
  388: 'Consumer Cyclical',
  390: 'Consumer Non-Cyclical',
  391: 'Energy',
  392: 'Diversified',
  394: 'EGB',
  384: 'Financial',
  395: 'Government',
  385: 'Industrial',
  393: 'Technology',
  379: 'Testing/Demo Securities',
  365: 'UST',
  389: 'Utilities'
}

export const AllSectors = [
  Sector.BasicMaterials,
  Sector.Communications,
  Sector.ConsumerCyclical,
  Sector.ConsumerNonCyclical,
  Sector.Energy,
  Sector.Diversified,
  Sector.EGB,
  Sector.Financial,
  Sector.Government,
  Sector.Industrial,
  Sector.Technology,
  Sector.TestingDemoSecurities,
  Sector.UST,
  Sector.Utilities
]

export enum Rating {
  AAA = 0,
  AAPlus,
  AA,
  AAMinus,
  APlus,
  A,
  AMinus,
  BBBPlus,
  BBB,
  BBBMinus,
  BBPlus,
  BB,
  BBMinus,
  BPlus,
  B,
  BMinus,
  CCCPlus,
  CCC,
  CCCMinus,
  CC,
  C,
  CI,
  R,
  SD,
  Diversified,
  NR
}

export const AllRatings = [
  Rating.AAA,
  Rating.AAPlus,
  Rating.AA,
  Rating.AAMinus,
  Rating.APlus,
  Rating.A,
  Rating.AMinus,
  Rating.BBBPlus,
  Rating.BBB,
  Rating.BBBMinus,
  Rating.BBPlus,
  Rating.BB,
  Rating.BBMinus,
  Rating.BPlus,
  Rating.B,
  Rating.BMinus,
  Rating.CCCPlus,
  Rating.CCC,
  Rating.CCCMinus,
  Rating.CC,
  Rating.C,
  Rating.CI,
  Rating.R,
  Rating.SD,
  Rating.Diversified,
  Rating.NR
]

export const IGRatingValues = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-'
]

export const HYRatingValues = [
  'BB+',
  'BB',
  'BB-',
  'B+',
  'B',
  'B-',
  'CCC+',
  'CCC',
  'CCC-',
  'CC',
  'C',
  // Anything not IG is considered HY
  'CI',
  'R',
  'SD',
  'Diversified',
  'NR'
]

export const Countries: Record<string, string> = {
  US: 'United States of America (US)',
  AR: 'Argentina (AR)',
  BR: 'Brazil (BR)',
  CA: 'Canada (CA)',
  CN: 'China (CN)',
  FR: 'France (FR)',
  DE: 'Germany (DE)',
  IN: 'India (IN)',
  MX: 'Mexico (MX)',
  GB: 'United Kingdom (GB)',
  AF: 'Afghanistan (AF)',
  AL: 'Albania (AL)',
  DZ: 'Algeria (DZ)',
  AS: 'American Samoa (AS)',
  AD: 'Andorra (AD)',
  AO: 'Angola (AO)',
  AI: 'Anguilla (AI)',
  AQ: 'Antarctica (AQ)',
  AG: 'Antigua and Barbuda (AG)',
  AM: 'Armenia (AM)',
  AW: 'Aruba (AW)',
  AU: 'Australia (AU)',
  AT: 'Austria (AT)',
  AZ: 'Azerbaijan (AZ)',
  BS: 'Bahamas (BS)',
  BH: 'Bahrain (BH)',
  BD: 'Bangladesh (BD)',
  BB: 'Barbados (BB)',
  BY: 'Belarus (BY)',
  BE: 'Belgium (BE)',
  BZ: 'Belize (BZ)',
  BJ: 'Benin (BJ)',
  BM: 'Bermuda (BM)',
  BT: 'Bhutan (BT)',
  BO: 'Bolivia (BO)',
  BQ: 'Bonaire, Sint Eustatius and Saba (BQ)',
  BA: 'Bosnia and Herzegovina (BA)',
  BW: 'Botswana (BW)',
  BV: 'Bouvet Island (BV)',
  IO: 'British Indian Ocean Territory (IO)',
  BN: 'Brunei Darussalam (BN)',
  BG: 'Bulgaria (BG)',
  BF: 'Burkina Faso (BF)',
  BI: 'Burundi (BI)',
  CV: 'Cabo Verde (CV)',
  KH: 'Cambodia (KH)',
  CM: 'Cameroon (CM)',
  KY: 'Cayman Islands (KY)',
  CF: 'Central African Republic (CF)',
  TD: 'Chad (TD)',
  CL: 'Chile (CL)',
  CX: 'Christmas Island (CX)',
  CC: 'Cocos Islands (CC)',
  CO: 'Colombia (CO)',
  KM: 'Comoros (KM)',
  CD: 'Democratic Republic of Congo (CD)',
  CG: 'Congo (CG)',
  CK: 'Cook Islands (CK)',
  CR: 'Costa Rica (CR)',
  HR: 'Croatia (HR)',
  CU: 'Cuba (CU)',
  CW: 'Curaçao (CW)',
  CY: 'Cyprus (CY)',
  CZ: 'Czechia (CZ)',
  CI: "Côte d'Ivoire (CI)",
  DK: 'Denmark (DK)',
  DJ: 'Djibouti (DJ)',
  DM: 'Dominica (DM)',
  DO: 'Dominican Republic (the) (DO)',
  EC: 'Ecuador (EC)',
  EG: 'Egypt (EG)',
  SV: 'El Salvador (SV)',
  GQ: 'Equatorial Guinea (GQ)',
  ER: 'Eritrea (ER)',
  EE: 'Estonia (EE)',
  SZ: 'Eswatini (SZ)',
  ET: 'Ethiopia (ET)',
  FK: 'Falkland Islands (FK)',
  FO: 'Faroe Islands (FO)',
  FJ: 'Fiji (FJ)',
  FI: 'Finland (FI)',
  GF: 'French Guiana (GF)',
  PF: 'French Polynesia (PF)',
  TF: 'French Southern Territories (TF)',
  GA: 'Gabon (GA)',
  GM: 'Gambia (GM)',
  GE: 'Georgia (GE)',
  GH: 'Ghana (GH)',
  GI: 'Gibraltar (GI)',
  GR: 'Greece (GR)',
  GL: 'Greenland (GL)',
  GD: 'Grenada (GD)',
  GP: 'Guadeloupe (GP)',
  GU: 'Guam (GU)',
  GT: 'Guatemala (GT)',
  GG: 'Guernsey (GG)',
  GN: 'Guinea (GN)',
  GW: 'Guinea-Bissau (GW)',
  GY: 'Guyana (GY)',
  HT: 'Haiti (HT)',
  HM: 'Heard Island and McDonald Islands (HM)',
  VA: 'Vatican (VA)',
  HN: 'Honduras (HN)',
  HK: 'Hong Kong (HK)',
  HU: 'Hungary (HU)',
  IS: 'Iceland (IS)',
  ID: 'Indonesia (ID)',
  IR: 'Iran (IR)',
  IQ: 'Iraq (IQ)',
  IE: 'Ireland (IE)',
  IM: 'Isle of Man (IM)',
  IL: 'Israel (IL)',
  IT: 'Italy (IT)',
  JM: 'Jamaica (JM)',
  JP: 'Japan (JP)',
  JE: 'Jersey (JE)',
  JO: 'Jordan (JO)',
  KZ: 'Kazakhstan (KZ)',
  KE: 'Kenya (KE)',
  KI: 'Kiribati (KI)',
  KP: 'North Korea (KP)',
  KR: 'South Korea (KR)',
  KW: 'Kuwait (KW)',
  KG: 'Kyrgyzstan (KG)',
  LA: 'Laos (LA)',
  LV: 'Latvia (LV)',
  LB: 'Lebanon (LB)',
  LS: 'Lesotho (LS)',
  LR: 'Liberia (LR)',
  LY: 'Libya (LY)',
  LI: 'Liechtenstein (LI)',
  LT: 'Lithuania (LT)',
  LU: 'Luxembourg (LU)',
  MO: 'Macao (MO)',
  MG: 'Madagascar (MG)',
  MW: 'Malawi (MW)',
  MY: 'Malaysia (MY)',
  MV: 'Maldives (MV)',
  ML: 'Mali (ML)',
  MT: 'Malta (MT)',
  MH: 'Marshall Islands (MH)',
  MQ: 'Martinique (MQ)',
  MR: 'Mauritania (MR)',
  MU: 'Mauritius (MU)',
  YT: 'Mayotte (YT)',
  FM: 'Micronesia (FM)',
  MD: 'Moldova (MD)',
  MC: 'Monaco (MC)',
  MN: 'Mongolia (MN)',
  ME: 'Montenegro (ME)',
  MS: 'Montserrat (MS)',
  MA: 'Morocco (MA)',
  MZ: 'Mozambique (MZ)',
  MM: 'Myanmar (MM)',
  NA: 'Namibia (NA)',
  NR: 'Nauru (NR)',
  NP: 'Nepal (NP)',
  NL: 'Netherlands (NL)',
  NC: 'New Caledonia (NC)',
  NZ: 'New Zealand (NZ)',
  NI: 'Nicaragua (NI)',
  NE: 'Niger (NE)',
  NG: 'Nigeria (NG)',
  NU: 'Niue (NU)',
  NF: 'Norfolk Island (NF)',
  MP: 'Northern Mariana Islands (MP)',
  NO: 'Norway (NO)',
  OM: 'Oman (OM)',
  PK: 'Pakistan (PK)',
  PW: 'Palau (PW)',
  PS: 'Palestine (PS)',
  PA: 'Panama (PA)',
  PG: 'Papua New Guinea (PG)',
  PY: 'Paraguay (PY)',
  PE: 'Peru (PE)',
  PH: 'Philippines (PH)',
  PN: 'Pitcairn (PN)',
  PL: 'Poland (PL)',
  PT: 'Portugal (PT)',
  PR: 'Puerto Rico (PR)',
  QA: 'Qatar (QA)',
  MK: 'North Macedonia (MK)',
  RO: 'Romania (RO)',
  RU: 'Russia (RU)',
  RW: 'Rwanda (RW)',
  RE: 'Réunion (RE)',
  BL: 'Saint Barthélemy (BL)',
  SH: 'Saint Helena (SH)',
  KN: 'Saint Kitts and Nevis (KN)',
  LC: 'Saint Lucia (LC)',
  MF: 'Saint Martin (MF)',
  PM: 'Saint Pierre and Miquelon (PM)',
  VC: 'Saint Vincent and the Grenadines (VC)',
  WS: 'Samoa (WS)',
  SM: 'San Marino (SM)',
  ST: 'Sao Tome and Principe (ST)',
  SA: 'Saudi Arabia (SA)',
  SN: 'Senegal (SN)',
  RS: 'Serbia (RS)',
  SC: 'Seychelles (SC)',
  SL: 'Sierra Leone (SL)',
  SG: 'Singapore (SG)',
  SX: 'Sint Maarten (SX)',
  SK: 'Slovakia (SK)',
  SI: 'Slovenia (SI)',
  SB: 'Solomon Islands (SB)',
  SO: 'Somalia (SO)',
  ZA: 'South Africa (ZA)',
  GS: 'South Georgia and the South Sandwich Islands (GS)',
  SS: 'South Sudan (SS)',
  ES: 'Spain (ES)',
  LK: 'Sri Lanka (LK)',
  SD: 'Sudan (SD)',
  SR: 'Suriname (SR)',
  SJ: 'Svalbard and Jan Mayen (SJ)',
  SE: 'Sweden (SE)',
  CH: 'Switzerland (CH)',
  SY: 'Syrian Arab Republic (SY)',
  TW: 'Taiwan (TW)',
  TJ: 'Tajikistan (TJ)',
  TZ: 'Tanzania (TZ)',
  TH: 'Thailand (TH)',
  TL: 'Timor-Leste (TL)',
  TG: 'Togo (TG)',
  TK: 'Tokelau (TK)',
  TO: 'Tonga (TO)',
  TT: 'Trinidad and Tobago (TT)',
  TN: 'Tunisia (TN)',
  TR: 'Turkey (TR)',
  TM: 'Turkmenistan (TM)',
  TC: 'Turks and Caicos (TC)',
  TV: 'Tuvalu (TV)',
  UG: 'Uganda (UG)',
  UA: 'Ukraine (UA)',
  AE: 'United Arab Emirates (AE)',
  UM: 'United States Minor Outlying Islands (UM)',
  UY: 'Uruguay (UY)',
  UZ: 'Uzbekistan (UZ)',
  VU: 'Vanuatu (VU)',
  VE: 'Venezuela (VE)',
  VN: 'Vietnam (VN)',
  VG: 'British Virgin Islands (VG)',
  VI: 'US Virgin Islands (VI)',
  WF: 'Wallis and Futuna (WF)',
  EH: 'Western Sahara (EH)',
  YE: 'Yemen (YE)',
  ZM: 'Zambia (ZM)',
  ZW: 'Zimbabwe (ZW)',
  AX: 'Åland Islands (AX)'
}

export const AllCountries = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CD',
  'CG',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'MK',
  'RO',
  'RU',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'AX'
]

export const AllRatingValues = IGRatingValues.concat(HYRatingValues)

export const defaultFilter = {
  sectors: AllSectors,
  currencies: CurrencyValues as Currency[],
  maturities: MaturitiesValues,
  ratings: AllRatings,
  countries: AllCountries,
  couponTypes: AllCouponTypes,
  securityTypes: AllSecurityTypes,
  securitiesAndTickers: [],
  price: undefined,
  priceGreaterLessThan: GreaterOrLessThan.GreaterThan,
  yield: undefined,
  yieldGreaterLessThan: GreaterOrLessThan.GreaterThan,
  bidAskDiff: undefined,
  bidAskDiffGreaterLessThan: GreaterOrLessThan.GreaterThan,
  bidAskDiffType: BidAskDiffType.Price,
  series: Series.All
}
