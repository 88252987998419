import dayjs from 'dayjs'
import React, { memo } from 'react'

import ComponentHeader from '../../../components/ComponentHeader/ComponentHeader'
import Modal from '../../../components/GenericModal/Modal'
import { SecurityStaticData } from '../../../store/securities/reducer'
import styles from './securityDetailsModal.module.scss'
import { modalHeaderClose } from '../../../components/ComponentHeader/helpers'

interface Props {
  security: SecurityStaticData
  isOpen: boolean
  toggleIsOpen: () => void
}

const SecurityDetailsModal: React.FC<Props> = ({ security, toggleIsOpen }) => {
  const maturityDate = dayjs(security.maturityDate).format('DD-MMM-YYYY')
  const issueDate = dayjs(security.issueDate).format('DD-MMM-YYYY')
  const datedDate = dayjs(security.datedDate).format('DD-MMM-YYYY')
  const firstCouponDate = dayjs(security.firstCouponDate).format('DD-MMM-YYYY')
  const nextCouponDate = dayjs(security.nextCouponDate).format('DD-MMM-YYYY')
  const benchmarkMaturityData = dayjs(security.benchmarkMaturity).format(
    'DD-MMM-YYYY'
  )

  return (
    <Modal>
      <ComponentHeader
        title="Security Details"
        headerButtons={modalHeaderClose(toggleIsOpen)}
      />
      <div className={styles.securityDetailContent}>
        <span className={styles.benchmarkName}>{security.description}</span>
        <div className={styles.dividedContent}>
          <div className={styles.contentLabels}>
            <span>Issue Description:</span>
            <span>Board Label:</span>
            <span>Cusip:</span>
            <span>ISIN:</span>
            <span>Product:</span>
            <span>Industry Sector Label:</span>
            <span>Debt Type:</span>
            <span>Coupon:</span>
            <span>Maturity:</span>
            <span>Issue Date:</span>
            <span>Dated Date:</span>
            <span>First Coupon Date:</span>
            <span>Pay Frequency:</span>
            <span>Next Coupon Date:</span>
            <span>Day Count:</span>
            <span>Currency:</span>
            <span>Coupon Type:</span>
            <span>Term To Maturity:</span>
            <span>Minimum Piece:</span>
            <span>Minimum Increment:</span>
            <span>Original Amount Issued:</span>
            <span>Amount Outstanding:</span>
          </div>
          <div className={styles.contentValues}>
            <span>{security.description ?? '-'}</span>
            <span>{security.boardLabel ?? '-'}</span>
            <span>{security.cusip ? security.cusip : '-'}</span>
            <span>{security.isin ?? '-'}</span>
            <span>{security.product ?? '-'}</span>
            <span>{security.sector ?? '-'}</span>
            <span>
              {security.debtType.length !== 0 ? security.debtType : '-'}
            </span>
            <span>{security.coupon ?? '-'}</span>
            <span>{maturityDate ?? '-'}</span>
            <span>{issueDate ?? '-'}</span>
            <span>{datedDate ?? '-'}</span>
            <span>{firstCouponDate ?? '-'}</span>
            <span>{security.payFreq ?? '-'}</span>
            <span>{nextCouponDate ?? '-'}</span>
            <span>{security.dayCount ?? '-'}</span>
            <span>{security.currency ?? '-'}</span>
            <span>{security.couponType ?? '-'}</span>
            <span>{security.termToMat ?? '-'}</span>
            <span>{security.minimumPiece}</span>
            <span>{security.minimumSizeIncrement ?? '-'}</span>
            <span>
              {security.amountIssued
                ? security.amountIssued.toLocaleString()
                : '-'}
            </span>
            <span>
              {security.amountOutstanding
                ? security.amountOutstanding.toLocaleString()
                : '-'}
            </span>
          </div>
          <div className={styles.contentHighlight}>
            <div className={styles.contentContainer}>
              <span className={styles.contentHighlightSectionLabel}>
                Credit Ratings:
              </span>
              <div className={styles.contentHighlightContainer}>
                <span className={styles.contentHighlightLabel}>S & P:</span>
                <span>{security.sAndPRating}</span>
              </div>
            </div>
            <div className={styles.contentContainer}>
              <span className={styles.contentHighlightSectionLabel}>
                Benchmark (Hedge) Data:
              </span>
              <div className={styles.contentHighlightContainer}>
                <div className={styles.contentHighlightLabel}>
                  <span>{security.benchmarkCusip ? 'Cusip:' : 'ISIN:'}</span>
                  <span>Board Label:</span>
                  <span>Coupon:</span>
                  <span>Maturity:</span>
                </div>
                <div className={styles.contentHighlightItem}>
                  <span>
                    {security.benchmarkCusip
                      ? security.benchmarkCusip
                      : security.benchmarkISIN}
                  </span>
                  <span>{security.benchmarkSecurity}</span>
                  <span>{security.benchmarkCoupon}</span>
                  <span>{benchmarkMaturityData}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(SecurityDetailsModal)
