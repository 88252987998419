import React, { ReactNode } from 'react'

const ErrorReporter = <E extends { stack: ReactNode; message?: string }>(
  error: E
) => (
  <pre className="error-boundary">
    <h2>An error occurred:</h2>{' '}
    {error.stack || error.message || 'Authentication error'}
  </pre>
)

export default ErrorReporter
