import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserDashboard } from '../../../../store/admin/userDashboard/selectors'
import { UserDashboardEntry } from '../../../../store/admin/userDashboard/types'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import { columnDefinitions } from './columnDefs'

const getRowId: GetRowIdFunc<UserDashboardEntry> = ({ data }) => {
  return `${data.id}`
}

const UserDashboardGrid: FC = () => {
  const userDashboard = useSelector(getUserDashboard)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const columnState = localStorage.getItem('columnState')
        if (columnState) {
          const parsedState = JSON.parse(columnState)
          columnApi.applyColumnState(parsedState)
        }
      }
    },
    []
  )

  return (
    <AgGridReact
      rowData={userDashboard}
      columnDefs={columnDefinitions}
      overlayNoRowsTemplate="No users connected."
      overlayLoadingTemplate="Loading Active User Dashboard…"
      alwaysShowVerticalScroll={true}
      suppressDragLeaveHidesColumns={true}
      defaultColDef={defaultColumnDefinitions}
      onGridReady={onGridReady}
      rowSelection={'single'}
      groupIncludeTotalFooter={true}
      getRowId={getRowId}
    />
  )
}

export default UserDashboardGrid
