import { faExternalLinkAlt } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDOMPopout } from '../../../helpers/hooks/useDOMPopout'

export const PopoutDepthIcon = ({ securityId }: { securityId: number }) => {
  const handlePopout = useDOMPopout()
  return (
    <span
      data-testid={`popout-${securityId}`}
      onClick={() => handlePopout(securityId)}
    >
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        title="Popout Depth for this Security"
      />
    </span>
  )
}
