import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

export type Props = React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement>
/* eslint-disable react/prop-types */
const ButtonGroup = ({ className, ...props }: Props) => {
  return <div {...props} className={cx(styles.buttonGroup, className)} />
}

export default ButtonGroup
