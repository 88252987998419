import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveSystemParam } from '../../../store/admin/sysparam/actions'
import { getSystemParam } from '../../../store/admin/sysparam/selectors'
import ComponentFooter from '../../ComponentFooter/ComponentFooter'
import Modal from '../../GenericModal/Modal'
import styles from '../../GenericModal/modal.module.scss'

interface Props {
  closeFunc: () => void
  paramId: number
}
const SystemParameterModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const paramId = props.paramId
  const dispatch = useDispatch()
  const param = useSelector(getSystemParam)(paramId)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [value, setValue] = useState<string>('')

  const nameChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value)
  }

  const descriptionChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(evt.target.value)
  }

  const valueChanged = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value)
  }

  useEffect(() => {
    setName(param.name)
    setDescription(param.description)
    setValue(param.value)
  }, [])
  const handleValidateParam = () => {
    const paramToSave = param
    paramToSave.name = name
    paramToSave.description = description
    paramToSave.value = value

    dispatch(saveSystemParam(paramToSave))
    closeFunc()
  }
  return (
    <Modal>
      <div className={styles.formWrapper}>
        <label>Name:</label>
        <input type="text" value={name} onChange={nameChanged} />
        <label>Description:</label>
        <input type="text" value={description} onChange={descriptionChanged} />
        <label>Value:</label>
        <textarea value={value} onChange={valueChanged} rows={10} />
        <ComponentFooter
          onCancelClick={closeFunc}
          onApplyClick={handleValidateParam}
        />
      </div>
    </Modal>
  )
}
export default SystemParameterModal
