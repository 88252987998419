import {
  ExternalLiquidityStats,
  EXTERNALLIQUIDITYSTATS_ADD,
  EXTERNALLIQUIDITYSTATS_FETCH,
  EXTERNALLIQUIDITYSTATS_FETCH_FAIL,
  ExternalLiquidityStatsAction
} from './types'

export interface State {
  externalLiquidityStats?: ExternalLiquidityStats[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  externalLiquidityStats: undefined,
  pending: false,
  error: false
}

export default (
  state = initialState,
  action: ExternalLiquidityStatsAction
): State => {
  switch (action.type) {
    case EXTERNALLIQUIDITYSTATS_FETCH:
      return {
        ...state,
        pending: true,
        externalLiquidityStats: undefined,
        error: false
      }
    case EXTERNALLIQUIDITYSTATS_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case EXTERNALLIQUIDITYSTATS_ADD:
      let items: ExternalLiquidityStats[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.externalLiquidityStats) {
        return {
          ...state,
          pending: false,
          externalLiquidityStats: items
        }
      }

      return {
        ...state,
        pending: false,
        externalLiquidityStats: items
      }

    default:
      return state
  }
}
