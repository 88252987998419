import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import {
  fetchUserPreferencesFailure,
  fetchUserPreferencesSuccess,
  SetUserPreferencesAction,
  setUserPreferencesFailure,
  setUserPreferencesSuccess
} from './actions'
import { UserPreferences } from './type'

const fetchUserPreferencesEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('userPreferences.fetchUserPreferences'),
    mergeMap(() => {
      return getHub()
        .stream<UserPreferences>('GetUserPreferences')
        .pipe(
          map((userPreferences: UserPreferences) =>
            fetchUserPreferencesSuccess(userPreferences)
          ),
          // take(1),

          catchError((err) =>
            of(fetchUserPreferencesFailure(err), logError(err))
          )
        )
    })
  )

const saveUserPreferencesEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('userPreferences.setUserPreferences'),
    mergeMap((action: SetUserPreferencesAction) => {
      return getHub()
        .invoke<UserPreferences>('SetUserPreferences', action.payload)
        .pipe(
          map((transactionId: any) =>
            setUserPreferencesSuccess(action.payload, transactionId)
          ),
          catchError((err) => of(setUserPreferencesFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(fetchUserPreferencesEpic, saveUserPreferencesEpic)
