import { Customer } from './types'

export const addOrUpdateCustomer = (
  stateCustomers: Customer[],
  customersToAdd: Customer[]
) => {
  const unchangedCustomers = stateCustomers.filter(
    (t) => !customersToAdd.find(({ id }) => t.id === id)
  )
  return [...unchangedCustomers, ...customersToAdd]
}
