import React, { useMemo } from 'react'
import DropdownList, { Element } from '../DropdownList/DropdownList'
import styles from '../SearchBondsInput/SearchBondsResultsDropdown.module.scss'
import SecuritySearchResultIssuer from '../SearchBondsInput/SecuritySearchResultIssuer'
import SecuritySearchResultListItem from '../SearchBondsInput/SecuritySearchResultListItem'
import { IssuerSecurities } from '../SearchBondsInput/selectors'

interface GenericBondsSearchDropdownProps {
  watchListResults?: IssuerSecurities[]
  notInWatchListResults?: IssuerSecurities[]
  searchString?: string
  isShowLiveChecked: boolean
  onIssuerShowAllClicked?: (issuer: string) => void
  onIssuerAddAllClicked?: (issuer: string) => void
  onSecurityClicked?: (parentId: string, id: number) => void
  menuStyle?: string
}

const tickerRegex = new RegExp('^[a-zA-Z]+\\s*$', 'gm')
const tickerMatRegex = new RegExp('^[a-zA-Z]+ [2-9][2-9]\\s*$', 'gm')

const getActionLabel = (
  search: string,
  defaultLabel: string,
  showLiveChecked: boolean
) => {
  if (showLiveChecked) {
    const matched = tickerRegex.test(search) || tickerMatRegex.test(search)
    // reset for next use (https://medium.com/codesnips/js-careful-when-reusing-regex-636b92c6bf07)
    tickerRegex.lastIndex = 0
    tickerMatRegex.lastIndex = 0
    if (matched) {
      return 'Show all live'
    }
  }
  return defaultLabel
}

const notInWatchlistTitle = {
  label: (
    <span className={styles.notInWatchlistTitleWrapper}>
      <span className={styles.notInWatchlistTitle}>
        Bond(s) not found in current list or filter
      </span>
    </span>
  ),
  id: 'not-in-watchlist-label'
}

const NOT_IN_WATCHLIST_PREFIX = 'not-in-watchlist-'

const noop = () => {
  // hi ts lint
}

const GenericBondsSearchDropdown = ({
  watchListResults,
  notInWatchListResults,
  searchString,
  isShowLiveChecked,
  onIssuerShowAllClicked,
  onIssuerAddAllClicked,
  onSecurityClicked,
  menuStyle = styles.contentDropDownMenu
}: GenericBondsSearchDropdownProps) => {
  const inWatchListLabel = useMemo(() => {
    return getActionLabel(searchString ?? '', 'Show all', isShowLiveChecked)
  }, [searchString, isShowLiveChecked])
  const notInWatchListLabel = useMemo(() => {
    return getActionLabel(searchString ?? '', 'Add all', isShowLiveChecked)
  }, [searchString, isShowLiveChecked])
  const getIssuerSection = (
    result: IssuerSecurities,
    expanded: boolean,
    isInWatchlist: boolean,
    idPrefix = ''
  ) => ({
    id: `${idPrefix}${result.issuer}`,
    expanded,
    label: (
      <SecuritySearchResultIssuer
        issuerSymbol={result.issuer}
        numberOfSecurities={result.securities.length}
        search={searchString ?? ''}
        actionLabel={isInWatchlist ? inWatchListLabel : notInWatchListLabel}
        actionOnClick={
          (isInWatchlist ? onIssuerShowAllClicked : onIssuerAddAllClicked) ??
          noop
        }
      />
    ),
    children: result.securities.map((sec) => ({
      id: sec.id,
      label: <SecuritySearchResultListItem securitySearchResult={sec} />
    }))
  })

  const watchListElements = useMemo(() => {
    const expanded = watchListResults?.length === 1
    return (
      watchListResults?.map((result) =>
        getIssuerSection(result, expanded, true)
      ) ?? ([] as Element[])
    )
  }, [watchListResults, inWatchListLabel, onIssuerShowAllClicked])

  const notInWatchListElements = useMemo(() => {
    return (
      notInWatchListResults?.map((result) =>
        getIssuerSection(result, false, false, NOT_IN_WATCHLIST_PREFIX)
      ) ?? ([] as Element[])
    )
  }, [notInWatchListResults, notInWatchListLabel, onIssuerAddAllClicked])

  const allElements = useMemo(() => {
    if (!notInWatchListElements.length) return watchListElements
    return [
      ...watchListElements,
      notInWatchlistTitle,
      ...notInWatchListElements
    ]
  }, [watchListElements, notInWatchListElements])

  // ---------------------------------------------------------------------------------------------------------------- //

  return (
    <div className={menuStyle} data-testid="search-dropdown">
      <DropdownList
        elements={allElements}
        onChildSelected={onSecurityClicked ?? noop}
      />
    </div>
  )
}

export default GenericBondsSearchDropdown
