import React from 'react'
import { formatSize } from '../../../helpers/formatting'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.module.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const BestSizeCell =
  (orderType: OrderType) =>
  ({ data }: CellProps) => {
    const security = useSecurityOrderData(data)
    if (!security) return <> </>

    const order = orderType === 'buy' ? security.bestBid : security.bestOffer
    const size = order?.size
    let sizeStr = size?.toString()
    if (sizeStr) {
      sizeStr += order?.allOrNone ? 'a' : ''
    }
    const testId = data.id + (orderType === 'buy' ? '-bidsz' : '-ofrsz')
    const formattedSize = sizeStr
      ? formatSize(size, order?.totalSize, order.allOrNone, order?.myFirm)
      : '-'
    return (
      <span
        className={order?.myFirm ? styles.myOrder : ''}
        data-testid={testId}
        title={formattedSize}
      >
        {formattedSize}
      </span>
    )
  }

export default BestSizeCell
