import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  externalOrderStatsGetFail,
  externalOrderStatsGetSuccess,
  externalOrderStatsInitializeSuccess
} from './actions'
import {
  EXTERNALORDERSTATS_GET,
  EXTERNALORDERSTATS_SUCCESS,
  ExternalOrderStatsTable,
  GetExternalOrderStatsAction
} from './types'

const fetchExternalOrderStatsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(EXTERNALORDERSTATS_GET),
    delay(1000),
    switchMap((action: GetExternalOrderStatsAction) => {
      const getExternalOrderStats$ = getHub().stream<ExternalOrderStatsTable>(
        'GetExternalOrderStats',
        action.payload.queryUserId,
        action.payload.queryCustId,
        action.payload.startDate,
        action.payload.endDate
      )
      return getExternalOrderStats$.pipe(
        map((data) => {
          if (data.initialized) {
            return externalOrderStatsGetSuccess(data)
          } else {
            return externalOrderStatsInitializeSuccess(data)
          }
        }),
        takeUntil(action$.pipe(ofType(EXTERNALORDERSTATS_SUCCESS))),
        catchError((err) => of(externalOrderStatsGetFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchExternalOrderStatsEpic)
