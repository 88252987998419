import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import App from '../app/App'
import styles from '../assets/scss/global.module.scss'
import config from '../config'

const oktaAuth = new OktaAuth(config.okta)

const OktaWrapper = () => {
  const navigate = useNavigate()

  const restoreOriginalUri = useCallback(
    (_oktaAuth: any, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin))
    },
    []
  )

  return (
    <Security
      className={styles.wrapper}
      pkce={true}
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <App />
    </Security>
  )
}

export default OktaWrapper
