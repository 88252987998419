import { Action } from 'redux'

export interface BeginHeartbeatingAction extends Action {
  type: 'heartbeat.BeginHeartbeating'
}

export const beginHeartbeating = (): BeginHeartbeatingAction => ({
  type: 'heartbeat.BeginHeartbeating'
})

export interface HeartbeatReceivedAction extends Action {
  type: 'heartbeat.HeartbeatReceived'
}

export const receiveHeartbeat = (): HeartbeatReceivedAction => ({
  type: 'heartbeat.HeartbeatReceived'
})

export interface SuccessfulHeartbeatAction extends Action {
  type: 'heartbeat.SuccessfulHeartbeat'
}

export const successfulHeartbeat = (): SuccessfulHeartbeatAction => ({
  type: 'heartbeat.SuccessfulHeartbeat'
})

export type HeartbeatAction = HeartbeatReceivedAction | BeginHeartbeatingAction
