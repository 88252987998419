import { Reducer } from 'redux'
import { CounteredToppedAlert, TradingNowAlert } from '../alerts/types'
import { SettingsAction } from './actions'
import { injectWindowSettingsIntoState } from './helpers'
import {
  AggressAttemptWindowSetting,
  ExternalLiquidityWindowSetting,
  ExternalOrderWindowSetting,
  HistoricalDataWindowSetting,
  StickyColumnGrid,
  WatchlistWindowSetting
} from './types'

export interface State {
  actionTypesToTriggerSaving: string[]
  stateValuesToSave: string[]
  windowSettings: WatchlistWindowSetting[]
  aggressAttemptSettings: AggressAttemptWindowSetting | null
  externalOrderSettings: ExternalOrderWindowSetting | null
  externalLiquiditySettings: ExternalLiquidityWindowSetting | null
  historicalDataSettings: HistoricalDataWindowSetting | null
  listTradingSettings?: StickyColumnGrid
  tradingNowAlerts: TradingNowAlert[]
  counteredToppedAlerts: CounteredToppedAlert[]
  areSettingsLoaded: boolean
}

export const initialState: State = {
  actionTypesToTriggerSaving: [],
  stateValuesToSave: [],
  windowSettings: [],
  aggressAttemptSettings: null,
  externalOrderSettings: null,
  externalLiquiditySettings: null,
  historicalDataSettings: null,
  tradingNowAlerts: [],
  counteredToppedAlerts: [],
  areSettingsLoaded: false
}

export const settingsReducer: Reducer<State, SettingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'settings.handleSettings':
      return {
        ...state,
        actionTypesToTriggerSaving: action.payload.actionTypesToTriggerSaving,
        stateValuesToSave: action.payload.stateValuesToSave
      }
    case 'settings.aggressAttemptsColumnModelChanged':
      return {
        ...state,
        aggressAttemptSettings: {
          columnDefinitions: action.payload.aggressAttemptsColDefs
        }
      }
    case 'settings.externalOrderColumnModelChanged':
      return {
        ...state,
        externalOrderSettings: {
          columnDefinitions: action.payload.externalOrderColDefs
        }
      }
    case 'settings.historicalDataColumnModelChanged':
      return {
        ...state,
        historicalDataSettings: {
          columnDefinitions: action.payload.historicalDataColDefs
        }
      }
    case 'settings.externalLiquidityColumnModelChanged':
      return {
        ...state,
        externalLiquiditySettings: {
          columnDefinitions: action.payload.externalLiquidityColDefs
        }
      }
    case 'settings.updateColumnsOrder':
      return {
        ...state,
        [action.payload.gridName]: {
          columnDefinitions: action.payload.columnDefs
        }
      }
    default:
      return state
  }
}

// This reducer acts on the global state, not only for “settings” namespace.
export const globalSettingsReducer: Reducer<any, SettingsAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'settings.loadSettings':
      return state
    case 'settings.loadWindowSettings':
      const stickyColumnGrids = {
        aggressAttemptSettings:
          action.payload.settings.aggressAttemptWindowSettings,
        externalOrderSettings:
          action.payload.settings.externalOrderWindowSettings,
        externalLiquiditySettings:
          action.payload.settings.externalLiquidityWindowSettings,
        historicalDataSettings:
          action.payload.settings.historicalDataWindowSettings,
        listTradingSettings: action.payload.settings.listTradingWindowSettings
      }
      return injectWindowSettingsIntoState(
        action.payload.settings.watchlistWindowSettings,
        stickyColumnGrids,
        action.payload.settings.mutedTradingNowAlerts,
        action.payload.settings.mutedCounteredAlerts,
        action.payload.settings.mutedToppedAlerts,
        action.payload.settings.depthSecurityIds,
        action.payload.settings.maxDOMs,
        state
      )
    default:
      return state
  }
}
