import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import {
  CHECKBOX,
  columnDefinitions
} from '../../containers/BondList/columnDefs'

export function isEqual<T, K extends keyof T>(a: T, b: T): boolean {
  if (a === b) return true
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    return a === b
  }
  if (!a || !b) {
    return false
  }
  const keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => isEqual(a[k as K], b[k as K]))
}

const toColId = (col: ColDef) => col.colId
const getColumnSortOrders = (
  columnsOrder: string[],
  colDef: Array<ColDef | ColGroupDef>
) => {
  // styling looks wrong if we pin depth tools to the left, so hard-coding this (ish)
  const leftCols = [CHECKBOX, 'DepthTools'].filter(
    (id) => !columnsOrder.includes(id)
  )

  const mandatoryOtherCols = colDef.filter((col) => {
    if ('pinned' in col && col.pinned === 'left') return false
    if ('colId' in col) {
      return col.suppressColumnsToolPanel && !col.hide
    }
    return false // my orders group column, virtual columns, or not mandatory
  }) as ColDef[]
  const displayColumns = [
    ...leftCols,
    ...columnsOrder,
    ...mandatoryOtherCols.map(toColId)
  ].filter((id, i, arr) => id && arr.findIndex((arrId) => arrId === id) === i)
  const sortOrders: Record<string, number> = displayColumns.reduce(
    (map, colId, i) => ({
      ...map,
      [colId ?? '']: i
    }),
    {}
  )
  return {
    displayColumns,
    sortOrders
  }
}

export const applyColumnsOrder = (
  gridIndex: number,
  isAdmin: boolean,
  columnsOrder: string[] | undefined
) => {
  const colDef = columnDefinitions(gridIndex, isAdmin)
  if (!columnsOrder) return colDef
  const { displayColumns, sortOrders } = getColumnSortOrders(
    columnsOrder,
    colDef
  )
  const columns = colDef
    .map((col: ColDef | ColGroupDef) => {
      const hide =
        ('colId' in col && !displayColumns.includes(col.colId || '')) ||
        ('groupId' in col && !displayColumns.includes('myBidSize'))

      if ('children' in col && displayColumns.includes('myBidSize')) {
        const bidPrice = displayColumns.includes('bestBidPrice')
        const bidSpread = displayColumns.includes('bestBidSpread')
        const offerPrice = displayColumns.includes('bestOfferPrice')
        const offerSpread = displayColumns.includes('bestOfferSpread')
        const children = col.children.map((child: ColDef) => {
          const show =
            (child.colId === 'myBidPrice' && bidPrice) ||
            (child.colId === 'myBidSpread' && bidSpread) ||
            (child.colId === 'myOfferSpread' && offerSpread) ||
            (child.colId === 'myOfferPrice' && offerPrice) ||
            ![
              'myBidPrice',
              'myBidSpread',
              'myOfferSpread',
              'myOfferPrice',
              'Expand'
            ].includes(child.colId || '')
          return { ...child, hide: !show }
        })
        return { ...col, children, hide }
      }
      return { ...col, hide }
    })
    .sort((colA, colB) => {
      // my orders always at the very end if it's visible
      if ('groupId' in colA) {
        return 1
      }
      if ('groupId' in colB) {
        return -1
      }
      // 99 because it's not currently visible and needs to be at the end of the menu
      const sortA = sortOrders[(colA as ColDef).colId || ''] ?? 99
      const sortB = sortOrders[(colB as ColDef).colId || ''] ?? 99
      return sortA - sortB
    })

  return columns
}
