import { combineReducers } from 'redux'
import { namespace as activityNamespace } from './activity'
import activityReducer from './activity/reducer'
import { namespace as activityLogNamespace } from './activityLog'
import activityLogReducer from './activityLog/reducer'
import { namespace as activityTradeBlotterNamespace } from './activityTradeBlotter'
import activityTradeBlotterReducer from './activityTradeBlotter/reducer'
import { namespace as activityTickerNamespace } from './admin/activityTicker'
import activityTickerReducer from './admin/activityTicker/reducer'
import { namespace as tradeBlotterNamespace } from './admin/adminTradeBlotter'
import tradeBlotterReducer from './admin/adminTradeBlotter/reducer'
import { namespace as aggressAttemptNamespace } from './admin/aggressAttempt'
import aggressAttemptReducer from './admin/aggressAttempt/reducer'
import { namespace as bbmIsinNamespace } from './admin/bbmisin'
import bbmIsinReducer from './admin/bbmisin/reducer'
import { namespace as externalLiquidityStatsNamespace } from './admin/externalLiquidityStats'
import externalLiquidityStatsReducer from './admin/externalLiquidityStats/reducer'
import { namespace as externalOrderStatsDataNamespace } from './admin/externalOrderStats'
import externalOrderStatsReducer from './admin/externalOrderStats/reducer'
import { namespace as historicalUserDataNamespace } from './admin/historicalUserData'
import historicalUserDataReducer from './admin/historicalUserData/reducer'
import { namespace as managementReportsNamespace } from './admin/managementReports'
import managementReportsReducer from './admin/managementReports/reducer'
import { namespace as queryNamespace } from './admin/query'
import queryReducer from './admin/query/reducer'
import { namespace as queueStatsNamespace } from './admin/queueStats'
import queueStatsReducer from './admin/queueStats/reducer'
import { namespace as restrictedTradesNamespace } from './admin/restrictedTrades'
import restrictedTradesReducer from './admin/restrictedTrades/reducer'
import { namespace as systemParamNamespace } from './admin/sysparam'
import systemParamReducer from './admin/sysparam/reducer'
import { namespace as tiersNamespace } from './admin/tiers'
import tiersReducer from './admin/tiers/reducer'
import { namespace as userDashboardNamespace } from './admin/userDashboard'
import userDashboardReducer from './admin/userDashboard/reducer'
import { namespace as venueNamespace } from './admin/venues'
import venueReducer from './admin/venues/reducer'
import { namespace as alertFiltersNamespace } from './alertFilter'
import alertFiltersReducer from './alertFilter/reducer'
import { namespace as alertsNamespace } from './alerts'
import alertsReducer from './alerts/reducer'
import { namespace as authNamespace } from './auth'
import authReducer from './auth/reducer'
import { namespace as benchmarkDataNamespace } from './benchmarkData'
import benchmarkDataReducer from './benchmarkData/reducer'
import { namespace as booksNamespace } from './books'
import booksReducer from './books/reducer'
import { namespace as checkedOrdersNamespace } from './checkedOrders'
import checkedOrdersReducer from './checkedOrders/reducer'
import { namespace as customersNamespace } from './customers'
import customersReducer from './customers/reducer'
import { namespace as issuerNamespace } from './depthOfMarket'
import depthOfMarketReducer from './depthOfMarket/reducer'
import { namespace as gridNamespace } from './grid'
import gridReducer from './grid/reducer'
import { composeReducers } from './helpers'
import { namespace as listTradingNamespace } from './listTrading'
import { reducer as listTradingReducer } from './listTrading/reducer'
import { namespace as logNamespace } from './log'
import logReducer from './log/reducer'
import { namespace as marketNamespace } from './market'
import marketReducer from './market/reducer'
import { namespace as orderNamespace } from './order'
import orderReducer from './order/reducer'
import { namespace as passiveOrdersNamespace } from './passiveOrders'
import passiveOrdersReducer from './passiveOrders/reducer'
import { namespace as searchSecuritiesNamespace } from './searchSecurities'
import searchSecuritiesReducer from './searchSecurities/reducer'
import { namespace as securitiesNamespace } from './securities'
import securitiesReducer from './securities/reducer'
import { namespace as settingsNamespace } from './settings'
import { globalSettingsReducer, settingsReducer } from './settings/reducer'
import { namespace as stagedOrdersNamespace } from './stagedOrders'
import stagedOrdersReducer from './stagedOrders/reducer'
import { namespace as uploadNamespace } from './upload'
import uploadReducer from './upload/reducer'
import { namespace as userEditorDataNamespace } from './userEditorData'
import userEditorDataReducer from './userEditorData/reducer'
import { namespace as userPreferencesNamespace } from './userPreferences'
import { reducer as userPreferencesReducer } from './userPreferences/reducer'
import { namespace as usersNamespace } from './users'
import usersReducer from './users/reducer'
import { namespace as watchListNamespace } from './watchList'
import { reducer as watchListReducer } from './watchList/reducer'
import { namespace as webSettingsNamespace } from './webSettings'
import webSettingsReducer from './webSettings/reducer'
import { namespace as windowsNamespace } from './windows'
import windowsReducer from './windows/reducer'
import { namespace as connectionNamespace } from './ws'
import connectionReducer from './ws/reducer'

const reducer = composeReducers(
  combineReducers({
    [authNamespace]: authReducer,
    [activityNamespace]: activityReducer,
    [activityLogNamespace]: activityLogReducer,
    [activityTradeBlotterNamespace]: activityTradeBlotterReducer,
    [aggressAttemptNamespace]: aggressAttemptReducer,
    [benchmarkDataNamespace]: benchmarkDataReducer,
    [issuerNamespace]: depthOfMarketReducer,
    [connectionNamespace]: connectionReducer,
    [gridNamespace]: gridReducer,
    [securitiesNamespace]: securitiesReducer,
    [alertFiltersNamespace]: alertFiltersReducer,
    [windowsNamespace]: windowsReducer,
    [orderNamespace]: orderReducer,
    [watchListNamespace]: watchListReducer,
    [stagedOrdersNamespace]: stagedOrdersReducer,
    [checkedOrdersNamespace]: checkedOrdersReducer,
    [searchSecuritiesNamespace]: searchSecuritiesReducer,
    [userPreferencesNamespace]: userPreferencesReducer,
    [settingsNamespace]: settingsReducer,
    [booksNamespace]: booksReducer,
    [alertsNamespace]: alertsReducer,
    [uploadNamespace]: uploadReducer,
    [marketNamespace]: marketReducer,
    [logNamespace]: logReducer,
    [webSettingsNamespace]: webSettingsReducer,
    [usersNamespace]: usersReducer,
    [customersNamespace]: customersReducer,
    [tiersNamespace]: tiersReducer,
    [historicalUserDataNamespace]: historicalUserDataReducer,
    [externalOrderStatsDataNamespace]: externalOrderStatsReducer,
    [queueStatsNamespace]: queueStatsReducer,
    [systemParamNamespace]: systemParamReducer,
    [restrictedTradesNamespace]: restrictedTradesReducer,
    [bbmIsinNamespace]: bbmIsinReducer,
    [managementReportsNamespace]: managementReportsReducer,
    [userEditorDataNamespace]: userEditorDataReducer,
    [tradeBlotterNamespace]: tradeBlotterReducer,
    [queryNamespace]: queryReducer,
    [activityTickerNamespace]: activityTickerReducer,
    [userDashboardNamespace]: userDashboardReducer,
    [passiveOrdersNamespace]: passiveOrdersReducer,
    [venueNamespace]: venueReducer,
    [externalLiquidityStatsNamespace]: externalLiquidityStatsReducer,
    [listTradingNamespace]: listTradingReducer
  }),
  globalSettingsReducer
)
export default reducer
