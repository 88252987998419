import { LogAction } from './actions'
import { addRecord, addRecords, removeRecords } from './helpers'

export interface State {
  logId: number
  logItems: Record<number, string>
}

const initialState: State = {
  logId: 0,
  logItems: {}
}

export default (state = initialState, action: LogAction): State => {
  switch (action.type) {
    case 'log.ClearLogItems':
      return {
        ...state,
        logItems: removeRecords(action.payload.logIds, state.logItems)
      }
    case 'log.AddLogItem':
      const newId = state.logId
      return {
        logId: state.logId + 1,
        logItems: addRecord(newId, action.payload.log, state.logItems)
      }
    case 'log.AddLogItems':
      const newId2 = state.logId
      return {
        logId: state.logId + action.payload.log.length,
        logItems: addRecords(newId2, action.payload.log, state.logItems)
      }
    default:
      return state
  }
}
