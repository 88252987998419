import { Epic, ofType } from 'redux-observable'
import { delay, map } from 'rxjs/operators'
import { RemoveGridAction } from '../securities/actions'
import { resetColumnsForGrid } from './actions'

const resetColumnsForGridEpic: Epic = action$ =>
  action$.pipe(
    ofType<RemoveGridAction>('securities.removeGrid'),
    map(action => resetColumnsForGrid(action.payload.gridIndex)),
    delay(100)
  )

export default resetColumnsForGridEpic
