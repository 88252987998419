import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, forkJoin, from, merge, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators'
import {
  createOrderModel,
  getOppositeFromSecurity,
  validateOrderModel
} from '../../components/DepthOfMarket/helpers'
import { getHub } from '../../helpers/hub'
import { splitCallByChunks } from '../helpers'
import { addLogItem, addLogItems } from '../log/actions'
import { fetchSecuritiesByIds, RemoveGridAction } from '../securities/actions'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../securities/selectors'
import { StagedOrder } from '../stagedOrders/types'
import { toggleDropdownState } from '../upload/actions'
import { getUserPreferences } from '../userPreferences/selectors'
import { getIcebergThreshold } from '../webSettings/selectors'
import { addLastLookWindow } from '../windows/actions'
import { logError } from '../ws/actions'
import {
  addOrUpdateOperatorOrders,
  AddOrUpdateOperatorOrdersAction,
  addOrUpdateUserOrders,
  AddOrUpdateUserOrdersAction,
  CancelOrderAction,
  cancelOrderFailure,
  CancelOrdersAction,
  clearMyOrders,
  CreateOrderAction,
  CreateOrderErrorAction,
  CreateOrdersAction,
  createTempOrder,
  fetchUserOrders,
  handleBulkErr,
  handleErr,
  loadResubmitOrders,
  mapFakeTransactionId,
  setMyOrdersOpen,
  SubmitOrderAction,
  submitOrderFailure,
  unsubscribeFetchUserOrders,
  UnsubscribeFetchUserOrdersAction,
  updateOrdersValidations,
  UpdateOrdersValidationsAction,
  updateOrderValidation
} from './actions'
import {
  createOrderFromResponse,
  createOrdersFromResubmitOrders,
  getOrderTypeForResponse,
  orderModelToParams,
  showBest
} from './helpers'
import { getOperatorOrders, getUserOrders } from './selectors'
import { Order, OrderResponse, ValidationResult } from './types'

export let nextMessageId = 0

const submitOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.submit'),
    mergeMap((action: SubmitOrderAction) => {
      const {
        orderId,
        size,
        transactionId,
        custId,
        spotCrossSelection,
        listId = 0
      } = action.payload
      const messageId = nextMessageId++
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke<number>(
          'HitOrLiftOrder',
          selUser,
          orderId,
          size,
          custId,
          messageId,
          spotCrossSelection,
          listId
        )
        .pipe(
          map((orderTransactionId) =>
            mapFakeTransactionId(transactionId, orderTransactionId)
          ),
          catchError((err) =>
            of(
              submitOrderFailure(orderId, transactionId, listId, err),
              logError(err)
            )
          )
        )
    })
  )

const cancelOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CancelOrdersAction>('order.cancelOrders'),
    mergeMap((action) => {
      const orderIds = action.payload.orderIds
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke('CancelOrders', selUser, orderIds)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) => of(logError(err)))
        )
    }),
    mergeMapTo(EMPTY)
  )

const cancelOrderEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.cancel'),
    mergeMap((action: CancelOrderAction) => {
      const { orderId } = action.payload
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .invoke('CancelOrder', selUser, orderId)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) =>
            of(cancelOrderFailure(orderId, err), logError(err))
          )
        )
    })
  )

type ErrorFriendlyStagedOrder = StagedOrder & { isin?: string }
const createOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<CreateOrdersAction>('order.createOrders'),
    mergeMap((action) => {
      const getSecurity = getSecurityOrderDataById(state$.value)
      const getStatic = getSecurityStaticDataById(state$.value)
      const icebergThreshold = getIcebergThreshold(state$.value)
      const { minimumTradeSize: traderPrefMinimum, maximumTradeSize } =
        getUserPreferences(state$.value)
      const [ordersCreationParams, orderValidations] =
        action.payload.params.reduce(
          ([createParams, validation], params) => {
            const security = getSecurity(params.securityId)
            const staticSecurity = getStatic(params.securityId)
            const isin = staticSecurity?.isin

            const orderModel = createOrderModel(
              params,
              params.orderType,
              staticSecurity?.product === 'PrinUSGovtOutright'
            )

            let error = ''

            if (security) {
              /*
                  We don't do any UST conversions/validation here because for that
                  we would need a string. My Orders does the conversion before
                  storing the price in the grid, but upload does NOT,
                  In order to support UST bulk upload, it needs to be added to
                  the Upload form.
                  We'll probably get this for free when we transition to 
                  OrderEntryModel everywhere.
               */
              const oppositeAmount =
                getOppositeFromSecurity(
                  security,
                  orderModel.type,
                  orderModel.amountType
                ) || NaN
              const minSize =
                traderPrefMinimum || staticSecurity?.minimumSize || 0
              error =
                validateOrderModel(
                  orderModel,
                  oppositeAmount,
                  icebergThreshold,
                  minSize,
                  maximumTradeSize || undefined
                ) ?? ''
              let formattedStack = ''
              if (error) {
                if (error.startsWith('Crossing')) {
                  formattedStack = showBest(security)
                }
                return [
                  createParams,
                  [
                    ...validation,
                    {
                      securityId: params.securityId,
                      orderType: params.orderType,
                      error,
                      isin,
                      formattedError:
                        'Error creating order: ' + error + ' ' + formattedStack
                    }
                  ]
                ]
              }
            }
            return [
              // TODO: this is a bit of a hack until we can transition to
              // OrderEntryModel for storing and sending values
              [
                ...createParams,
                {
                  ...params,
                  isin,
                  size: parseInt(orderModel.totalSize, 10),
                  totalSize: parseInt(orderModel.displaySize, 10)
                }
              ],
              [
                ...validation,
                {
                  securityId: params.securityId,
                  orderType: params.orderType,
                  error: undefined,
                  isin
                }
              ]
            ]
          },
          [[] as ErrorFriendlyStagedOrder[], [] as ValidationResult[]]
        )

      const bulkDate = new Date()
      const actions: Action[] = [
        updateOrdersValidations(orderValidations),
        addLogItems(
          action.payload.params.map(
            (ord) =>
              'Creating bulk order: security: ' +
              ord.securityId +
              ' price: ' +
              (ord.isSpreadOrder ? ord.spread : ord.price) +
              ' isSpread: ' +
              ord.isSpreadOrder +
              ' size: ' +
              ord.size +
              ' side: ' +
              ord.orderType +
              ' aon: ' +
              ord.allOrNone
          )
        )
      ]
      if (orderValidations.filter((v) => !!v.error).length) {
        // TODO: add gridIndex to createOrders action
        actions.push(toggleDropdownState(0, 'invalidUpload'))
      }
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return merge(
        from(actions),
        splitCallByChunks(ordersCreationParams, 2000, (chunk) =>
          forkJoin(
            chunk.map((orderCreationParams) =>
              getHub()
                .invoke(
                  'CreateOrder',
                  selUser,
                  orderCreationParams.securityId,
                  getOrderTypeForResponse(orderCreationParams.orderType),
                  orderCreationParams.isSpreadOrder
                    ? orderCreationParams.spread ?? 0
                    : orderCreationParams.price,
                  orderCreationParams.isSpreadOrder,
                  orderCreationParams.size,
                  orderCreationParams.allOrNone,
                  orderCreationParams.individualMin || 0,
                  orderCreationParams.custId,
                  { tob: false, limitPrice: 0, floorPrice: 0 },
                  '',
                  nextMessageId++,
                  orderCreationParams.totalSize
                )
                .pipe(
                  catchError((err) =>
                    of(
                      handleErr(
                        err,
                        orderCreationParams.securityId,
                        orderCreationParams.orderType
                      )
                    )
                  )
                )
            )
          )
        ).pipe(
          concatMap((d: CreateOrderErrorAction[] /* probably*/) => {
            const arr2 = d.filter((elmt) => {
              return elmt.type === 'order.createOrderError'
            })
            const arr = arr2.map((e) => {
              const errOrder = ordersCreationParams.find(
                (o) => (o.securityId = e.payload?.securityId)
              )
              const errIsin = errOrder?.isin ?? e.isin
              const err = `${e.payload?.err?.message}`
              const newErr = { ...e, payload: { ...e.payload } }
              if (err?.includes('InvalidDataException')) {
                newErr.payload.err.message =
                  'Internal error, no order was created (InvalidDataException)'
              }

              return { error: { ...newErr, isin: errIsin }, date: bulkDate }
            })
            const bulkActions: Action[] = [handleBulkErr(arr)]
            if (arr.length) {
              bulkActions.push(toggleDropdownState(0, 'invalidUpload'))
            }
            return from(bulkActions)
          })
        )
      )
    })
  )

const updateOrdersValidationsEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.updateValidations'),
    mergeMap((action: UpdateOrdersValidationsAction) => {
      const validationResults = action.payload
      const errors = validationResults
        .filter((vr) => vr.error !== undefined)
        .map((vr) => vr.formattedError!)
      return of(addLogItems(errors))
    })
  )
const createOrUpdateOrderOnSecurityEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.createOrder'),
    mergeMap((action: CreateOrderAction) => {
      const { securityId, order } = action.payload

      const params = orderModelToParams(order)

      const orderType = order.type

      const security = getSecurityOrderDataById(state$.value)(securityId)
      const getStatic = getSecurityStaticDataById(state$.value)(securityId)
      const icebergThreshold = getIcebergThreshold(state$.value)
      const { minimumTradeSize: traderPrefMinimum, maximumTradeSize } =
        getUserPreferences(state$.value)

      let error = ''
      if (security) {
        const oppositeAmount =
          getOppositeFromSecurity(security, order.type, order.amountType) || NaN
        const minSize = traderPrefMinimum || getStatic?.minimumSize || 0
        error =
          validateOrderModel(
            order,
            oppositeAmount,
            icebergThreshold,
            minSize,
            maximumTradeSize || undefined
          ) ?? ''
      }
      let formattedStack = ''
      if (error && security) {
        if (error.startsWith('Crossing')) {
          formattedStack = showBest(security)
        }
        const isin = getStatic?.isin
        return of(
          addLogItem('Error creating order: ' + error + ' ' + formattedStack),
          updateOrderValidation({ securityId, orderType, error, isin })
        )
      }

      const messageId = nextMessageId++
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return merge(
        of(updateOrderValidation({ securityId, orderType, error: undefined })),
        getHub()
          .invoke(
            'CreateOrder',
            selUser,
            securityId,
            getOrderTypeForResponse(orderType),
            params.onePrice,
            params.isSpreadOrder,
            params.serverSize,
            order.aon,
            params.individualMin,
            order.selectedBook || 0,
            params.tob,
            order.goodTill,
            messageId,
            params.displaySize
          )
          .pipe(
            map((transactionId: number) =>
              createTempOrder(
                transactionId,
                securityId,
                orderType,
                params.onePrice ?? 0,
                params.isSpreadOrder,
                params.clientSize,
                order.aon,
                params.individualMin,
                params.tob,
                undefined,
                params.clientSize === params.totalSize
                  ? undefined
                  : params.totalSize
              )
            ),
            // catchError(err => of(logError(err)))
            catchError((err) => of(handleErr(err, securityId, orderType)))
          )
      )
    })
  )

const lastLookOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<AddOrUpdateUserOrdersAction>('order.addOrUpdateUserOrders'),
    withLatestFrom(state$),
    filter(([, state]) => getUserPreferences(state).lastLookInPopup),
    mergeMap(([action]) => {
      const orders = action.payload
      const orderIsLastLook = (order: Order) =>
        order.status === 'waitingForConfirmation' && order.aggressorOrder
      return from(
        orders
          .filter(orderIsLastLook)
          .map((order) => addLastLookWindow({ orderId: order.id }))
      )
    })
  )

const lastLookOperatorOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<AddOrUpdateOperatorOrdersAction>('order.addOrUpdateOperatorOrders'),
    withLatestFrom(state$),
    filter(([, state]) => getUserPreferences(state).lastLookInPopup),
    mergeMap(([action]) => {
      const orders = action.payload
      const orderIsLastLook = (order: Order) =>
        order.status === 'waitingForConfirmation' && order.aggressorOrder
      return from(
        orders
          .filter(orderIsLastLook)
          .map((order) => addLastLookWindow({ orderId: order.id }))
      )
    })
  )

const fetchSecuritiesForOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.addOrUpdateUserOrders'),
    mergeMap((action: AddOrUpdateUserOrdersAction) => {
      const getSecurity = getSecurityOrderDataById(state$.value)
      const ids = [
        ...new Set(action.payload.map((order) => order.securityId))
      ].filter((securityId) => getSecurity(securityId) === undefined)
      return ids.length > 0 ? of(fetchSecuritiesByIds(ids)) : EMPTY
    })
  )

const getUserOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchUserOrders'),
    mergeMap(() => {
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .stream('GetMyOrders', selUser)
        .pipe(
          map((responses: OrderResponse[]) => {
            const pendingOrders = getUserOrders(state$.value).filter(
              (f: Order) => f.id === ''
            )
            return responses.map((response) =>
              createOrderFromResponse(response, pendingOrders)
            )
          }),
          bufferTime(1000),
          map((ordersArrays) => {
            return ordersArrays.reduce((acc, orders) => [...acc, ...orders], [])
          }),
          filter((orders, index) => index === 0 || orders.length > 0),
          map(addOrUpdateUserOrders),
          takeUntil(
            action$.ofType<UnsubscribeFetchUserOrdersAction>(
              'order.unsubscribeFetchUserOrders'
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const getOperatorOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchOperatorOrders'),
    mergeMap(() =>
      getHub()
        .stream('GetOperatorOrders')
        .pipe(
          map((responses: OrderResponse[]) => {
            const pendingOrders = getOperatorOrders(state$.value).filter(
              (f: Order) => f.id === ''
            )
            return responses.map((response) =>
              createOrderFromResponse(response, pendingOrders)
            )
          }),
          bufferTime(1000),
          map((ordersArrays) => {
            return ordersArrays.reduce((acc, orders) => [...acc, ...orders], [])
          }),
          filter((orders, index) => index === 0 || orders.length > 0),
          map(addOrUpdateOperatorOrders),
          catchError((err) => of(logError(err)))
        )
    )
  )

const setSelectedUserEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('users.setSelectedUser'),
    map(() => {
      return unsubscribeFetchUserOrders()
    })
  )

const unsubscribeFetchUserOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.unsubscribeFetchUserOrders'),
    map(() => {
      return clearMyOrders()
    })
  )

const clearMyOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.clearMyOrders'),
    map(() => {
      return fetchUserOrders()
    })
  )

const getResubmitOrders: Epic = (action$, state$) =>
  action$.pipe(
    ofType('order.fetchResubmitOrders'),
    mergeMap(() =>
      getHub()
        .invoke('GetResubmitOrders')
        .pipe(
          map((orders) =>
            loadResubmitOrders(createOrdersFromResubmitOrders(orders))
          )
        )
    )
  )

const resetMyOrdersOpenForGridEpic: Epic = (action$) =>
  action$.pipe(
    ofType<RemoveGridAction>('securities.removeGrid'),
    map((action) => setMyOrdersOpen(action.payload.gridIndex, false))
  )

export default combineEpics(
  submitOrderEpic,
  cancelOrderEpic,
  cancelOrdersEpic,
  createOrdersEpic,
  createOrUpdateOrderOnSecurityEpic,
  getUserOrdersEpic,
  getOperatorOrdersEpic,
  lastLookOrdersEpic,
  lastLookOperatorOrdersEpic,
  fetchSecuritiesForOrdersEpic,
  setSelectedUserEpic,
  unsubscribeFetchUserOrdersEpic,
  clearMyOrdersEpic,
  resetMyOrdersOpenForGridEpic,
  getResubmitOrders,
  updateOrdersValidationsEpic
)
