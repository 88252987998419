import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  historicalDataGetFail,
  historicalDataGetSuccess,
  historicalDataInitializeSuccess
} from './actions'
import {
  GetHistoricalDataAction,
  HISTORICALDATA_GET,
  HISTORICALDATA_SUCCESS,
  HistoricalDataTable
} from './types'

const fetchHistoricalUserDataEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(HISTORICALDATA_GET),
    delay(1000),
    switchMap((action: GetHistoricalDataAction) => {
      const getHistoricalData$ = getHub().stream<HistoricalDataTable>(
        'GetHistoricalUserData',
        action.payload.queryUserId,
        action.payload.queryCustId,
        action.payload.startDate,
        action.payload.endDate
      )
      return getHistoricalData$.pipe(
        map((data) => {
          if (data.initialized) {
            return historicalDataGetSuccess(data)
          } else {
            return historicalDataInitializeSuccess(data)
          }
        }),
        takeUntil(action$.pipe(ofType(HISTORICALDATA_SUCCESS))),
        catchError((err) => of(historicalDataGetFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchHistoricalUserDataEpic)
