import React from 'react'
import styles from './MarketsClosed.module.scss'

type Props = {
  className: string
  isAdmin: boolean
}
const MarketsClosed = ({ className, isAdmin }: Props) => {
  return (
    <div className={styles.marketsClosed}>
      <h2 className={className}>ALL MARKETS CLOSED</h2>
      {!isAdmin && <div className={styles.serviceMsg}>Logging off now.</div>}
    </div>
  )
}

export default MarketsClosed
