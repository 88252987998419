import { ColDef } from '@ag-grid-community/core'
import { diffDateFormatter } from '../../../helpers/formatting'

export const EVENT_TIME = 'eventTime'
export const SECURITY = 'security'
export const DESCRIPTION = 'description'

export const customizableColumns: ColDef[] = [
  {
    colId: EVENT_TIME,
    field: EVENT_TIME,
    headerName: 'Order Time',
    valueFormatter: diffDateFormatter,
    sort: 'desc'
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security'
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    flex: 2
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
