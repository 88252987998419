import { Order } from '../order/types'
import { Security } from '../securities/reducer'

export const resetDepthForGridIndex = (
  stateRecords: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  >,
  gridIndex: number
) => {
  const newRecords = { ...stateRecords }
  delete newRecords[gridIndex]
  return newRecords
}

export const setGridDOMs = (
  payloadRecord: Record<number, Order[]>,
  stateRecord: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  >
) => {
  const newRecords: Record<
    number,
    {
      securityId: Security['id'] | undefined
      error: boolean
      orders: Order[]
      hoveredOrders: Order[]
    }
  > = {}
  for (const r in payloadRecord) {
    if (payloadRecord.hasOwnProperty(r)) {
      newRecords[r] = { ...stateRecord[r], orders: payloadRecord[r] }
    }
  }
  return newRecords
}
