import cx from 'classnames'
import React from 'react'
import styles from './uploadDropDownMenu.module.scss'

interface Props {
  watchlistName: string
  setWatchlistName: (name: string) => void
  className?: string
}

const NewWatchListInput: React.FC<Props> = ({
  watchlistName,
  setWatchlistName,
  className
}) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWatchlistName(event.target.value)
  }

  const classes = cx(styles.createNewInput, className)

  return (
    <div className={classes}>
      <span>Watchlist name: </span>
      <input
        type="text"
        onChange={update}
        data-testid="watchlist-name"
        value={watchlistName}
        maxLength={20}
      />
    </div>
  )
}

export default NewWatchListInput
