import React from 'react'
import { CellProps, useSecurity7ChordData } from './helpers'

import ChordCellsRenderer from './ChordCellsRenderer'

const ChordCells = (data: CellProps) => {
  const security = useSecurity7ChordData(data.data)
  if (!security) return <></>

  const field = data.column.colId
  const { type, name } = data.bidOfr || {}
  const value = type ? security[type][name] : security[field]
  return <ChordCellsRenderer field={field} value={value} />
}

export default ChordCells
