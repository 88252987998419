import {
  BENCHMARKDATA_FETCH,
  BENCHMARKDATA_FETCH_CANCEL,
  BENCHMARKDATA_FETCH_FAIL,
  BENCHMARKDATA_SUCCESS,
  BenchmarkDataFetchAction,
  BenchmarkDataFetchCancelAction,
  BenchmarkDataFetchFailAction,
  BenchmarkDataSuccessAction,
  BenchmarkInfo
} from './types'

export const benchmarkDataFetch = (): BenchmarkDataFetchAction => ({
  type: BENCHMARKDATA_FETCH
})

export const benchmarkDataFetchFail = (
  error: any
): BenchmarkDataFetchFailAction => ({
  type: BENCHMARKDATA_FETCH_FAIL,
  payload: error,
  error: true
})

export const getBenchmarkDataSuccess = (
  benchmarkInfo: BenchmarkInfo
): BenchmarkDataSuccessAction => ({
  type: BENCHMARKDATA_SUCCESS,
  payload: benchmarkInfo
})

export const unsubscribeFromBenchmark = (): BenchmarkDataFetchCancelAction => ({
  type: BENCHMARKDATA_FETCH_CANCEL
})
