import React from 'react'
import { useParams } from 'react-router'
import { useOpenFin } from '../../../app/openFinContext'
import UserEditorModal from '../../../components/UserSelector/UserEditorModal'

const OpenfinUserEditor = () => {
  const { id } = useParams()
  const { manageWindows } = useOpenFin()
  return (
    <UserEditorModal
      closeFunc={() => manageWindows('UserEditor', 'hide')}
      userId={Number(id)}
      wrapModal
    />
  )
}

export default OpenfinUserEditor
