import cx from 'classnames'
import React, { MouseEvent } from 'react'
import { orderTypeToAggressLabel } from '../helpers'
import { OrderType } from '../../../store/order/types'
import { QuoteReliability } from '../../../store/securities/types'
import qrStyles from '../../QuoteReliability/QuoteReliability.module.scss'

type Props = {
  onClick?: (e: MouseEvent) => void
  type: OrderType
  qr: QuoteReliability
}
const DepthAggressCell = ({ onClick, type, qr }: Props) => {
  const qrType = type === 'buy' ? 'hit' : 'lift'
  return (
    <span
      onClick={onClick}
      className={cx(qrStyles[QuoteReliability[qr]], qrStyles[qrType])}
    >
      {orderTypeToAggressLabel(type)}
    </span>
  )
}

export default DepthAggressCell
