import React from 'react'
import { formatSpread } from '../../../components/Activity/AlertDescription/helpers'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import { WorkingOrderFieldRendererProps } from './WorkingOrderFieldRenderer'

const SpreadRenderer = ({
  data,
  watchlistId
}: WorkingOrderFieldRendererProps) => {
  const { order } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin
  )
  if (!order || !data) return <></>

  if (!order.isSpread || !order.price) return <></>

  return <div>{formatSpread(order.price)}</div>
}

export default SpreadRenderer
