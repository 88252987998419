import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import { addVenues, venueFetchFail } from './actions'
import { Venue, VENUE_FETCH, VenueFetchAction } from './types'

const fetchVenues: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(VENUE_FETCH),
    delay(1000),
    switchMap((action: VenueFetchAction) => {
      const getVenues$: Observable<any[]> =
        getHub().invoke<Venue[]>('GetVenues')
      return getVenues$.pipe(
        map((venues) => {
          return addVenues(venues)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(venueFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchVenues)
