import { GridAction } from './actions'

export interface State {
  columnsOrder: { [gridIndex: number]: string[] | undefined }
  displayedColumns: { [gridIndex: number]: string[] | undefined }
}

export const initialState: State = {
  columnsOrder: {},
  displayedColumns: {}
}

export default (state = initialState, action: GridAction): State => {
  switch (action.type) {
    case 'grid.updateColumnsOrder':
      return {
        ...state,
        columnsOrder: {
          ...state.columnsOrder,
          [action.payload.gridIndex]: action.payload.columns
        }
      }
    case 'grid.updateDisplayedColumns':
      return {
        ...state,
        displayedColumns: {
          ...state.displayedColumns,
          [action.payload.gridIndex]: action.payload.columns
        }
      }
    case 'grid.resetColumnsForGrid':
      return {
        ...state,
        displayedColumns: {
          ...state.displayedColumns,
          [action.payload.gridIndex]: undefined
        },
        columnsOrder: {
          ...state.columnsOrder,
          [action.payload.gridIndex]: undefined
        }
      }
    default:
      return state
  }
}
