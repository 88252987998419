import {
  CustomCellEditorProps,
  CustomCellRendererProps
} from '@ag-grid-community/react'
import React, { JSXElementConstructor } from 'react'
import { WorkingOrderFields } from '../../../store/listTrading/types'
import { SecurityStaticData } from '../../../store/securities/reducer'

interface Props {
  fieldName: WorkingOrderFields
  watchlistId: number
}

export type WorkingOrderFieldRendererProps =
  CustomCellRendererProps<SecurityStaticData> & Props
export type WorkingOrderFieldEditorProps =
  CustomCellEditorProps<SecurityStaticData> & Props

const WorkingOrderFieldRenderer = <
  C =
    | CustomCellRendererProps<SecurityStaticData>
    | CustomCellEditorProps<SecurityStaticData>,
>(
  watchlistId: number,
  fieldName: WorkingOrderFields,
  Component: JSXElementConstructor<C & Props>
) => {
  return (props: C) => (
    <Component {...props} fieldName={fieldName} watchlistId={watchlistId} />
  )
}

export default WorkingOrderFieldRenderer
