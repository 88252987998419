import React, { FC, useMemo } from 'react'
import styles from '../bondListStyle.module.scss'
import dayjs from 'dayjs'

type FormatOptions = Partial<Intl.NumberFormatOptions>

export interface Props {
  field: string
  value: number
}
const ChordCellsRenderer: FC<Props> = ({ field, value }) => {
  const classes = useMemo(() => {
    if (field.includes('Signal')) {
      return value > 0 ? styles.positive : value < 0 ? styles.negative : ''
    }
    return ''
  }, [field, value])

  const getOptions = useMemo(() => {
    let options: FormatOptions = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }
    if (field === 'topExpertPrediction_7Chord') {
      options = { ...options, maximumFractionDigits: 3 } as const
    }
    if (field.includes('Signal')) {
      options = {
        ...options,
        style: 'percent',
        maximumFractionDigits: 3
      } as const
    }
    return options
  }, [field, value])

  const formatter = new Intl.NumberFormat('en-US', getOptions)

  return isNaN(value) ? (
    <>{dayjs(value).format('MM/DD/YY hh:mm a')}</>
  ) : (
    <div className={classes}>{formatter.format(value)}</div>
  )
}

export default ChordCellsRenderer
