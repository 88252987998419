import { faCalendar } from '@awesome.me/kit-5de77b2c02/icons/classic/light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { noop } from 'rxjs'

import { checkOrders } from '../../store/checkedOrders/actions'
import { addOrUpdateElement } from '../../store/helpers'
import { getXLastDayAndOrdersAssociate } from '../../store/order/selectors'
import { setIsMine } from '../../store/securities/actions'
import { getIsMine } from '../../store/securities/selectors'
import { StagedOrder } from '../../store/stagedOrders/types'
import { uploadOrdersFromPreviousDate } from '../../store/upload/actions'

import Checkbox from '../Checkbox'
import DropdownList from '../DropdownList/DropdownList'
import DropDownMenu from '../DropDownMenu/DropDownMenu'
import styles from './dropDownUploadOrdersByDatePicker.module.scss'
import { LastDay } from './helpers'
import ComponentFooter from '../ComponentFooter/ComponentFooter'

interface ListItemProps {
  id: number
  label: string
  nbOfOrder: number
  selected: boolean
  toggleSelected: (e: ChangeEvent<HTMLInputElement>) => void
}
const ListItem = ({
  id,
  selected,
  nbOfOrder,
  label,
  toggleSelected
}: ListItemProps) => {
  return (
    <div className={styles.columnListItemUpload}>
      <Checkbox
        locator={`column-${id}`}
        checked={selected}
        onChange={toggleSelected}
        disabled={nbOfOrder === 0}
      >
        {label}
        <span className={styles.count}>({nbOfOrder})</span>
      </Checkbox>
    </div>
  )
}

const button = () => (showDropdownMenu: any) => {
  return (
    <div onClick={showDropdownMenu} title="Resubmit Orders">
      <FontAwesomeIcon icon={faCalendar} />
    </div>
  )
}

export interface Props {
  gridIndex: number
}

const DropDownUploadOrdersByDatePicker = ({ gridIndex }: Props) => {
  const dispatch = useDispatch()
  const isMine = useSelector(getIsMine)(gridIndex)
  const getLastDayAndOrdersAssociate = useSelector(
    getXLastDayAndOrdersAssociate
  )
  const last5Days = getLastDayAndOrdersAssociate(5)
  const [fiveDaysState, setFiveDaysState] = useState(last5Days)
  const [closeDropdown, setCloseDropdown] = useState(false)

  const toggleSelected = useCallback(
    (colId: number) => () => {
      fiveDaysState[colId].isSelected = !fiveDaysState[colId].isSelected
      setFiveDaysState(
        addOrUpdateElement(fiveDaysState, fiveDaysState[colId], isRowMatching)
      )
    },
    [fiveDaysState]
  )

  const apply = useCallback(() => {
    if (last5Days) {
      let stagedOrdersSelectedByDatePicker: StagedOrder[] = []

      fiveDaysState.forEach((dayState, index) => {
        if (dayState.isSelected) {
          if (last5Days[index] && last5Days[index].stagedOrders) {
            stagedOrdersSelectedByDatePicker = [
              ...last5Days[index].stagedOrders,
              ...stagedOrdersSelectedByDatePicker
            ]
          }
        }
      })

      if (stagedOrdersSelectedByDatePicker) {
        dispatch(uploadOrdersFromPreviousDate(stagedOrdersSelectedByDatePicker))
        if (isMine) {
          setTimeout(() => {
            dispatch(setIsMine(gridIndex, true))

            if (stagedOrdersSelectedByDatePicker) {
              /* stagedOrdersSelectedByDatePicker.forEach((stagedOrder) => {
                  dispatch(
                    checkOrder(stagedOrder.securityId, stagedOrder.orderType)
                  )
                })*/
              dispatch(
                checkOrders(
                  stagedOrdersSelectedByDatePicker.map((so) => {
                    return {
                      securityId: so.securityId,
                      orderType: so.orderType
                    }
                  })
                )
              )
            }
          }, 100)
        } else {
          dispatch(setIsMine(gridIndex, true))
        }
      }
    }

    setCloseDropdown(true)
    setTimeout(() => {
      setCloseDropdown(false)
    }, 5)
  }, [fiveDaysState])

  const clear = useCallback(() => setFiveDaysState(last5Days), [fiveDaysState])

  const isRowMatching = (rowOne: LastDay, rowTwo: LastDay) =>
    rowOne.id === rowTwo.id

  const setElements = (lastXDays: any) => {
    const elements: Array<{ id: number; label: JSX.Element }> = []
    lastXDays.forEach((lastDay: LastDay) =>
      elements.push({
        id: lastDay.id,
        label: (
          <ListItem
            id={lastDay.id}
            label={lastDay.dateFormat}
            nbOfOrder={lastDay.stagedOrders.length}
            toggleSelected={toggleSelected(lastDay.id)}
            selected={fiveDaysState[lastDay.id].isSelected}
          />
        )
      })
    )
    return elements
  }

  return (
    <DropDownMenu
      buttonElement={button}
      actionAfterClose={clear}
      closeDropdown={closeDropdown}
    >
      <div className={styles.menu}>
        <DropdownList
          onElementSelected={noop} // So the elements look clickable, even if we handle the click in the onChange event of the checkbox.
          elements={setElements(last5Days)}
        />
        <ComponentFooter
          onCancelClick={clear}
          onApplyClick={apply}
          submitText="Apply"
        />
      </div>
    </DropDownMenu>
  )
}

export default DropDownUploadOrdersByDatePicker
