import { WsAction } from './actions'

export interface State {
  pending: boolean
  error: boolean
  messageError?: any
  connected: boolean
  serverTimeDelayMs?: number
  mustReload: boolean
}

const initialState: State = {
  pending: false,
  error: false,
  connected: false,
  mustReload: false
}

export default (state = initialState, action: WsAction): State => {
  switch (action.type) {
    case 'ws.connect':
      return { ...state, connected: false, pending: true }
    case 'ws.connect-success':
      return { ...state, connected: true, pending: false, error: false }
    case 'ws.connect-failure':
      return { ...state, error: true }
    case 'ws.adjust-server-time-success':
      return { ...state, serverTimeDelayMs: action.payload.delayMs }
    case 'ws.force-reload':
      return { ...state, mustReload: true }
    default:
      return state
  }
}
