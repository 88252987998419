import { SystemParam } from './types'

export const addOrUpdateSystemParam = (
  stateSystemParams: SystemParam[],
  systemParamsToAdd: SystemParam[]
) => {
  const unchangedSystemParams = stateSystemParams.filter(
    (t) => !systemParamsToAdd.find(({ id }) => t.id === id)
  )
  return [...unchangedSystemParams, ...systemParamsToAdd]
}
