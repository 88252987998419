import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { isParentOf } from '../../../../helpers/dom'
import { usePrevious } from '../../../../helpers/hooks'
import { querySearchSecurities } from '../../../../store/admin/query/actions'
import SearchInput from '../../../SearchInput/SearchInput'
import styles from './SearchBondsInput.module.scss'
import SearchBondsResultsDropdown from './SearchBondsResultsDropdown'

const ENTER = 'Enter'

interface Props {
  search: string
  setSearch: (st: string) => void
}

const QuerySearchBondsInput: FC<Props> = ({ search, setSearch }) => {
  const ref = useRef<HTMLDivElement>(null)
  // const [search, setSearch] = useState('')
  const [resultsDisplayed, setResultsDisplayed] = useState(false)
  const dispatch = useDispatch()

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value
    setSearch(newSearch)
    setResultsDisplayed(newSearch.length > 0)
  }

  const clear = () => {
    setSearch('')
  }

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === ENTER) {
        if (search.length === 0) {
          clear()
        } else {
          // dispatch(setQueryFilter(gridIndex, search))
        }
        hideResults()
      }
    },
    [search]
  )

  const onClear = useCallback(() => {
    clear()
    hideResults()
  }, [])

  const [debouncedSearch] = useDebounce(search, 300)
  const previousSearch = usePrevious(debouncedSearch)
  useEffect(() => {
    if (previousSearch !== debouncedSearch) {
      // One search for the watchlist, one for all securities
      dispatch(querySearchSecurities(debouncedSearch))
    }
  }, [debouncedSearch, previousSearch])

  const handleSearchFocus = useCallback(() => {
    setResultsDisplayed(search.length > 0)
  }, [search])

  const hideResults = useCallback(() => setResultsDisplayed(false), [])

  const hideResultsOnClick = useCallback(
    (e: MouseEvent) => {
      const isClickInside =
        e &&
        ref.current !== null &&
        isParentOf(ref.current, e.target as HTMLElement)
      if (!isClickInside) {
        hideResults()
      }
    },
    [ref.current]
  )

  useEffect(() => {
    if (resultsDisplayed) {
      document.addEventListener('click', hideResultsOnClick)
    }

    return () => {
      document.removeEventListener('click', hideResultsOnClick)
    }
  }, [resultsDisplayed])

  /* useEffect(() => {
    if (issuerFilter) {
      setSearch(`${issuerFilter} `)
    } else if (security) {
      if (!clearDomSearch) {
        setSearch(
          security
            ? `${security.issuerSymbol} ${
                security.coupon
              } ${formatMaturityDateAsText(security.maturityDate, false)}`
            : ''
        )
      }
      setClearDomSearch(false)
    } else {
      setSearch('')
    }
  }, [issuerFilter, security, watchlist])*/

  return (
    <div ref={ref} className={styles.dropdown}>
      <SearchInput
        value={search}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        onKeyUp={onKeyUp}
        onClear={onClear}
        className={styles.searchBondsInput}
        placeholder="Search Bonds"
      />
      {resultsDisplayed && (
        <SearchBondsResultsDropdown
          search={search}
          setSearch={setSearch}
          hideDropdown={hideResults}
        />
      )}
    </div>
  )
}

export default QuerySearchBondsInput
