import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { popoutSecurity } from '../../store/depthOfMarket/actions'

export const useDOMPopout = () => {
  const { fin, manageWindows } = useOpenFin()
  const dispatch = useDispatch()

  const handlePopout = useCallback((securityId: number) => {
    dispatch(popoutSecurity(securityId))
    fin && manageWindows(`Depth/${securityId}`, 'show')
  }, [])

  return handlePopout
}
