import { Action } from 'redux'

export interface AddLogItemAction extends Action {
  type: 'log.AddLogItem'
  payload: {
    log: string
  }
}

export const addLogItem = (log: string): AddLogItemAction => ({
  type: 'log.AddLogItem',
  payload: { log }
})

export interface AddLogItemsAction extends Action {
  type: 'log.AddLogItems'
  payload: {
    log: string[]
  }
}

export const addLogItems = (log: string[]): AddLogItemsAction => ({
  type: 'log.AddLogItems',
  payload: { log }
})

export interface ClearLogItemsAction extends Action {
  type: 'log.ClearLogItems'
  payload: {
    logIds: number[]
  }
}

export const clearLogItems = (logIds: number[]): ClearLogItemsAction => ({
  type: 'log.ClearLogItems',
  payload: { logIds }
})

export interface WriteLogItemsAction extends Action {
  type: 'log.WriteLogItems'
}

export const writeLogItems = (): WriteLogItemsAction => ({
  type: 'log.WriteLogItems'
})
/* export interface FetchBooksAction extends Action {
  type: 'books.fetchBooks'
}

export const fetchBooks = (): FetchBooksAction => ({
  type: 'books.fetchBooks'
})*/

export interface NoLogItemsAction extends Action {
  type: 'log.NoLogItems'
}

export const noLogItems = (): NoLogItemsAction => ({
  type: 'log.NoLogItems'
})

export type LogAction =
  | AddLogItemAction
  | AddLogItemsAction
  | ClearLogItemsAction
  | WriteLogItemsAction
  | NoLogItemsAction
