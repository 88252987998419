import { Action } from 'redux'

const NAMESPACE = `sysparam.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const SYSTEMPARAM_FETCH = `${NAMESPACE}fetch`
export interface SystemParamFetchAction extends Action {
  type: typeof SYSTEMPARAM_FETCH
}

/**
 * Fetch cancel
 */
export const SYSTEMPARAM_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface SystemParamFetchCancelAction extends Action {
  type: typeof SYSTEMPARAM_FETCH_CANCEL
}

export const SYSTEMPARAM_FETCH_FAIL = `${NAMESPACE}fetch`
export interface SystemParamFetchFailAction extends Action {
  type: typeof SYSTEMPARAM_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const SYSTEMPARAM_ADD = `${NAMESPACE}addSystemParams`
export interface SystemParamAddSystemParamAction extends Action {
  type: typeof SYSTEMPARAM_ADD
  payload: SystemParam[]
}

export const SYSTEMPARAM_SAVE = `${NAMESPACE}saveSystemParam`
export interface SystemParamSaveAction extends Action {
  type: typeof SYSTEMPARAM_SAVE
  payload: SystemParam
}

export const SYSTEMPARAM_SAVE_SUCCESS = `${NAMESPACE}saveSystemParamSuccess`
export interface SaveSystemParamSuccessAction extends Action {
  type: typeof SYSTEMPARAM_SAVE_SUCCESS
  payload: any
}

export const SYSTEMPARAM_SAVE_FAILURE = `${NAMESPACE}saveSystemParamFailure`
export interface SaveSystemParamFailureAction extends Action {
  type: typeof SYSTEMPARAM_SAVE_FAILURE
  payload: any
  error: boolean
}

export type SystemParamAction =
  | SystemParamFetchAction
  | SystemParamAddSystemParamAction
  | SystemParamFetchFailAction
  | SystemParamSaveAction
  | SaveSystemParamSuccessAction
  | SaveSystemParamFailureAction
  | SystemParamFetchCancelAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface SystemParam {
  id: number
  name: string
  description: string
  value: string
}

export const EmptyParam = {
  id: 0,
  name: '',
  description: '',
  value: ''
}
