import { Action } from 'redux'

const NAMESPACE = `queueStats.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const QUEUESTATS_FETCH = `${NAMESPACE}fetch`
export interface QueueStatsFetchAction extends Action {
  type: typeof QUEUESTATS_FETCH
}

export const QUEUESTATS_FETCH_FAIL = `${NAMESPACE}fetch`
export interface QueueStatsFetchFailAction extends Action {
  type: typeof QUEUESTATS_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const QUEUESTATS_ADD = `${NAMESPACE}addQueueStats`
export interface QueueStatsAddQueueStatsAction extends Action {
  type: typeof QUEUESTATS_ADD
  payload: QueueStats[]
}

export type QueueStatsAction =
  | QueueStatsFetchAction
  | QueueStatsAddQueueStatsAction
  | QueueStatsFetchFailAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface QueueStats {
  id: number
  queueSize: number
  componentName: string
  lastEditTime: Date
  isMDQueue: boolean
}
