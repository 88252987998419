import {
  AllRatingValues,
  AllSeries,
  CouponTypeValues,
  SecurityTypeValues
} from '../../../../store/filter/types'

const itemToElement = <V extends string>(
  map: object,
  value: V,
  index: number
) => ({
  ...map,
  [index]: value
})
export const RatingMap = AllRatingValues.reduce(itemToElement, {})
export const SecurityTypesMap = SecurityTypeValues.reduce(itemToElement, {})
export const CouponTypeMap = CouponTypeValues.reduce(itemToElement, {})
export const SeriesMap = AllSeries.reduce(itemToElement, {})
