import React, {
  ButtonHTMLAttributes,
  ClassAttributes,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react'
import Button from '../Button/Button'

type ButtonProps = ClassAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement>

interface Props extends ButtonProps {
  contentUpdate: string | JSX.Element
  timerToReturnToFirstState: number
}

const ButtonWithFeedback: FC<Props> = ({
  contentUpdate,
  timerToReturnToFirstState,
  onClick,
  children,
  title,
  className,
  disabled,
  ...rest
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleChangeContent = useCallback(() => {
    setIsClicked(true)
  }, [])

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(
        () => setIsClicked(false),
        timerToReturnToFirstState
      )
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isClicked])

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      handleChangeContent()
      if (onClick) {
        onClick(e)
      }
    },
    [onClick]
  )
  return (
    <Button
      className={className}
      onClick={handleClick}
      title={title}
      type="button"
      disabled={disabled}
      {...rest}
    >
      {isClicked ? contentUpdate : children}
    </Button>
  )
}

export default ButtonWithFeedback
