import { faTrashCan } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import {
  faBell,
  faBellSlash
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { AlertFilter } from '../../store/alertFilter/types'
import Button from '../Button/Button'
import styles from './AlertFilter.module.scss'

export interface Props {
  onSelect: () => void
  onDelete: () => void
  onMute: (b: boolean) => void
  filter: AlertFilter
  isSelected: boolean
}
const AlertFilterListItem: FC<Props> = ({
  filter,
  onSelect,
  onDelete,
  onMute,
  isSelected
}) => {
  const deleteAlertFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onDelete()
  }

  const muteAlertFilterToggle = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault()
    onMute(filter.muted)
  }
  const [confirmation, setConfirmation] = useState(false)
  const handleCallConfirmation = useCallback(() => {
    setConfirmation(true)
  }, [])

  const unconfirmedDelete = useCallback(() => {
    setConfirmation(false)
  }, [])

  return (
    <div
      className={cx(
        styles.AlertFilterListItem,
        confirmation && styles.handleConfirmation,
        isSelected && styles.selected
      )}
    >
      <span
        className={cx(
          styles.AlertFilterListItemName,
          confirmation && styles.none
        )}
        onClick={onSelect}
      >
        {filter.name}
      </span>
      <span className={confirmation ? styles.ConfirmDelete : styles.none}>
        Are you sure?
      </span>
      <div className={styles.ruleActionContainer}>
        <span className={cx(styles.actionIcon, confirmation && styles.none)}>
          <FontAwesomeIcon
            icon={filter.muted ? faBellSlash : faBell}
            className={styles.AlertFilterListItemIcon}
            onClick={muteAlertFilterToggle}
            title={filter.muted ? 'Unmute Rule' : 'Mute Rule'}
          />
        </span>
        <span
          className={cx(styles.actionIcon, confirmation ? styles.none : '')}
          title={'Delete'}
          onClick={handleCallConfirmation}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </span>
      </div>
      <span
        className={cx(
          styles.btnGrpDeleteWatchlist,
          !confirmation && styles.none
        )}
      >
        <Button
          data-testid="filter-deleteyes"
          className={styles.deleteWatchlistConfirmation}
          onClick={deleteAlertFilter}
        >
          Yes
        </Button>
        <Button
          data-testid="filter-deleteno"
          className={styles.deleteWatchlistConfirmation}
          onClick={() => unconfirmedDelete()}
        >
          No
        </Button>
      </span>
    </div>
  )
}

export default AlertFilterListItem
