import React, { ChangeEvent, useCallback } from 'react'
import styles from './RadioButtonGroup.module.scss'

type RadioOption<T> = {
  label: string
  value: T
}

type Props<T = string> = {
  className?: string
  legend?: string
  onSelect: (value?: T) => void
  options: Array<RadioOption<T>>
  selectedOption?: T
  testPrefix?: string
}

const RadioButtonGroup = <T,>({
  className = styles.default,
  legend = '',
  onSelect,
  options,
  selectedOption,
  testPrefix = ''
}: Props<T>) => {
  const onRadiobuttonChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const index = Number(e.target.value)
      if (!isNaN(index) && index >= 0) {
        const item = options[index]
        onSelect(item?.value)
      }
    },
    []
  )

  const dash = testPrefix ? '-' : ''
  const testid = `${testPrefix}${dash}${legend?.replace(' ', '-')}`
  const simpleLegend = legend?.replace(':', '') ?? ''

  return (
    <fieldset className={`${className}`} data-testid={testid}>
      {legend && <legend>{legend}</legend>}
      {options.map((option, i) => {
        const labelWithDashes = option.label.replace(' ', '-')
        const optionTestId = `${testPrefix}${dash}${labelWithDashes}`
        const isSelected = selectedOption === option.value
        const optionTitle =
          option.label.length > 1 ? `${simpleLegend} ${option.label}` : ''
        return (
          <label
            title={optionTitle}
            data-testid={optionTestId}
            key={optionTestId}
            data-is-selected={isSelected}
          >
            <input
              type="radio"
              value={i}
              onChange={onRadiobuttonChange}
              checked={isSelected}
              name={testid}
              id={labelWithDashes}
            />
            <span>{option.label}</span>
          </label>
        )
      })}
    </fieldset>
  )
}

export default RadioButtonGroup
