import { createStagedOrdersFromSecuritySearch } from '../searchSecurities/helpers'
import { OrderRowInfo, OrdersWithSecurityIds } from './types'

export const areLevelsEmpty = (orderInfo: Omit<OrderRowInfo, 'identifier'>) =>
  orderInfo.bidAmt === '' &&
  orderInfo.bidPrice === '' &&
  orderInfo.bidSprd === '' &&
  orderInfo.ofrAmt === '' &&
  orderInfo.ofrPrice === '' &&
  orderInfo.ofrSprd === ''

export const isRowEmpty = (orderInfo: OrderRowInfo) =>
  orderInfo.identifier === '' &&
  orderInfo.bidAmt === '' &&
  orderInfo.bidPrice === '' &&
  orderInfo.bidSprd === '' &&
  orderInfo.ofrAmt === '' &&
  orderInfo.ofrPrice === '' &&
  orderInfo.ofrSprd === ''

export const getFailures = (ordersWithSecurityIds: OrdersWithSecurityIds) =>
  ordersWithSecurityIds
    .filter(
      ({ securityIds, orderInfo }) =>
        (securityIds.length === 0 && !isRowEmpty(orderInfo)) ||
        (securityIds.length > 1 && !areLevelsEmpty(orderInfo))
    )
    .reduce(
      (acc, data, i) => [
        ...acc,
        {
          index: data.orderInfo.index,
          identifier: data.orderInfo.identifier,
          orderRowInfo: data.orderInfo,
          errorIndex: i
        }
      ],
      []
    )

export const getOrdersToStage = (
  ordersWithSecurityIds: OrdersWithSecurityIds,
  bookId: number
) =>
  ordersWithSecurityIds
    .reduce(
      (acc, { securityIds, orderInfo }) =>
        !areLevelsEmpty(orderInfo)
          ? [...acc, { securityIds, orderInfo }]
          : [...acc],
      []
    )
    .map(({ securityIds, orderInfo }) =>
      createStagedOrdersFromSecuritySearch(securityIds, orderInfo, bookId)
    )
    .reduce((acc, stagedOrders) => [...acc, ...stagedOrders], [])
