import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getUserEditorDataErrors = createSelector(
  [getState],
  (state) => state.errorMsg
)

export const getUserEditorDataById = createSelector(
  [getState],
  (state) => (userId: number) => state.userEditorData[userId]
)
