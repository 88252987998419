import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getTotalSecondsBeforeExpiration } from '../../../helpers/orders'
import { getAllUserOrders } from '../../../store/order/selectors'
import { Order, OrderStatus } from '../../../store/order/types'
import { getServerTimeDelayMs } from '../../../store/ws/selectors'
import TimerLeftComponent from '../../Timer/Timer'
import ModalActionsButtons from '../ModalActionsButtons/ModalActionsButtons'
import ModalFooter from '../ModalFooter/ModalFooter'

export interface Props {
  order: Order
  orderStatusComp: OrderStatus | undefined
  fontClassName: string
  type: string
  tradeAmt: number | undefined
  size: number | undefined
  currentSize: number | undefined
  actionCloseModal: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleCancelAttempt: (e: React.MouseEvent<HTMLButtonElement>) => void
  isCancelAttempt: boolean
  isAmountInputNOK: boolean
  liquidityRemoved?: boolean
  error: any
  maxTradeSize: number | null
  maxTradeSizeIsHardLimit: boolean
  benchmarkName: string | undefined
  spotCrossSelection: string
}

const AggressorModalFooter: FC<Props> = (props) => {
  const {
    order,
    orderStatusComp,
    fontClassName,
    type,
    tradeAmt,
    size,
    currentSize,
    actionCloseModal,
    handleCancelAttempt,
    isAmountInputNOK,
    liquidityRemoved,
    error,
    maxTradeSize,
    maxTradeSizeIsHardLimit,
    benchmarkName,
    spotCrossSelection
  } = props
  const allOrders = useSelector(getAllUserOrders)

  const getNotAvailableFooter = () => {
    const orderStatus = liquidityRemoved ? 'notAvailable' : orderStatusComp
    return (
      <ModalFooter
        title="NOT AVAILABLE"
        dataTestId="aggressor-status"
        content="Liquidity is no longer available"
        icon={<div />}
        buttonComponent={
          <ModalActionsButtons
            orderStatusComp={orderStatus}
            onModalClosed={actionCloseModal}
          />
        }
        customStyles={{
          content: 'bottomNotAvailable',
          icon: 'icnNotAvailable'
        }}
      />
    )
  }

  if (
    liquidityRemoved &&
    orderStatusComp !== 'rejected' &&
    orderStatusComp !== 'cancelled' &&
    orderStatusComp !== 'accepted' &&
    orderStatusComp !== 'error' &&
    order.transactionId &&
    order.transactionId < 1
    // the aggress order will have a 'fake' transactionId before it is submitted
    // only show Liquidity no longer available if the passive order is canceled before the aggress order is submitted
  ) {
    return getNotAvailableFooter()
  }

  const getContent = () => {
    const typeLabel = type === 'buy' ? 'bought' : 'sold'
    const tsyTypelabel = type === 'buy' ? 'sold' : 'bought'

    const getTsyStatus = () => {
      const matchingOrder = Object.values(allOrders).find(
        (value: any) => value.corpOrderId === order.id
      )
      switch (matchingOrder?.status) {
        case 'accepted':
          return (
            <div>
              Tsy hedge: {tsyTypelabel} {benchmarkName} {matchingOrder.size} @{' '}
              {matchingOrder.displayPrice}
            </div>
          )
        case 'waitingForConfirmation':
          return <div>Waiting on Tsy Hedge Execution...</div>
        case 'rejected':
          return <div>No Tsy Hedge Executed</div>
        default:
          return
      }
    }
    return (
      <div>
        <div>
          You {typeLabel} {tradeAmt} of {size}
        </div>
        {spotCrossSelection === 'H' && getTsyStatus()}
      </div>
    )
  }

  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)
  switch (orderStatusComp) {
    case 'accepted':
      return (
        <ModalFooter
          title={type === 'buy' ? 'BOUGHT' : 'SOLD'}
          dataTestId="aggressor-status"
          content={getContent()}
          icon={null}
          buttonComponent={
            <ModalActionsButtons
              orderStatusComp={orderStatusComp}
              onModalClosed={actionCloseModal}
            />
          }
          customStyles={{
            content: 'bottomContentSuccess',
            icon: 'icnAccepted'
          }}
        />
      )
    case 'pending': // FIXME for now, same behavior as 'waitingForConfirmation'
    case 'waitingForConfirmation':
      const { totalSeconds } = getTotalSecondsBeforeExpiration(
        order.expiration,
        order.submitTime,
        serverTimeDelayMs
      )
      return (
        <ModalFooter
          title="Pending"
          dataTestId="aggressor-status"
          content="Awaiting Counterparty"
          timer={
            <TimerLeftComponent
              timerInit={totalSeconds}
              valueColor="var(--primaryTextColor)"
              valueSize="default"
              totalSecondsBeforeExpiration={{
                expiration: order.expiration,
                submitTime: order.submitTime,
                serverTimeDelayMs
              }}
            />
          }
          buttonComponent={
            <ModalActionsButtons
              orderStatusComp={orderStatusComp}
              onButtonClicked={handleCancelAttempt}
              onModalClosed={actionCloseModal}
            />
          }
          customStyles={{
            content: 'bottomContentPending',
            icon: 'icnPending'
          }}
        />
      )
    case 'cancelled':
      return (
        <ModalFooter
          title="Cancelled"
          dataTestId="aggressor-status"
          content="Your attempt has been cancelled"
          icon={null}
          buttonComponent={
            <ModalActionsButtons
              orderStatusComp={orderStatusComp}
              onModalClosed={actionCloseModal}
              fontClassName={fontClassName}
              disabled={isAmountInputNOK}
            />
          }
          customStyles={{
            content: 'bottomContentRejected',
            icon: 'icnRejected'
          }}
        />
      )
    case 'error':
      return (
        <ModalFooter
          title="Error"
          dataTestId="aggressor-status"
          content={
            order.statusReason || 'An error occurred while submitting the order'
          }
          icon={null}
          buttonComponent={
            <ModalActionsButtons
              orderStatusComp={orderStatusComp}
              onModalClosed={actionCloseModal}
              fontClassName={fontClassName}
              disabled={isAmountInputNOK}
            />
          }
          customStyles={{
            content: 'bottomContentRejected',
            icon: 'icnRejected'
          }}
        />
      )
    case 'rejected':
      return (
        <ModalFooter
          title="Rejected"
          dataTestId="aggressor-status"
          content="Your order has been rejected"
          icon={<div />}
          buttonComponent={
            <ModalActionsButtons
              orderStatusComp={orderStatusComp}
              onModalClosed={actionCloseModal}
            />
          }
          customStyles={{
            content: 'bottomContentRejected',
            icon: 'icnRejected'
          }}
        />
      )
    default:
      if (error) {
        return (
          <ModalFooter
            title="Error"
            dataTestId="aggressor-status"
            content={error.message}
            icon={<div />}
            buttonComponent={
              <ModalActionsButtons
                orderStatusComp={orderStatusComp}
                onModalClosed={actionCloseModal}
                type="Resubmit"
              />
            }
            customStyles={{
              content: 'bottomContentRejected',
              icon: 'icnRejected'
            }}
          />
        )
      } else if (maxTradeSize && currentSize && maxTradeSize < currentSize) {
        if (maxTradeSizeIsHardLimit) {
          return (
            <ModalFooter
              dataTestId="aggressor-close"
              content={
                'The amount of ' +
                currentSize +
                ' exceeds your Maximum amount of ' +
                maxTradeSize
              }
              buttonComponent={
                <ModalActionsButtons
                  onModalClosed={actionCloseModal}
                  fontClassName={fontClassName}
                  disabled={true}
                />
              }
              customStyles={{
                content: 'bottomContentRejected',
                icon: 'icnRejected'
              }}
            />
          )
        } else {
          return (
            <ModalFooter
              dataTestId="aggressor-close"
              content={
                'The amount of ' +
                size +
                ' exceeds your Maximum amount of ' +
                maxTradeSize +
                '. Are you sure you want to submit a ' +
                (type === 'buy' ? 'lift' : 'hit') +
                ' for a size of ' +
                currentSize +
                '?'
              }
              buttonComponent={
                <ModalActionsButtons
                  onModalClosed={actionCloseModal}
                  type={type}
                  fontClassName={fontClassName}
                  disabled={false}
                />
              }
              customStyles={{
                content: 'bottomContentPending',
                icon: 'icnRejected'
              }}
            />
          )
        }
      } else {
        return (
          <ModalFooter
            dataTestId="aggressor-close"
            buttonComponent={
              <ModalActionsButtons
                onModalClosed={actionCloseModal}
                type={type}
                fontClassName={fontClassName}
                disabled={isAmountInputNOK}
              />
            }
          />
        )
      }
  }
}

export default AggressorModalFooter
