import {
  Customer,
  CUSTOMER_ADD,
  CUSTOMER_FETCH,
  CUSTOMER_FETCH_FAIL,
  CustomerAddCustomerAction,
  CustomerFetchAction,
  CustomerFetchFailAction
} from './types'

export const customerFetch = (): CustomerFetchAction => ({
  type: CUSTOMER_FETCH
})

export const customerAddCustomer = (
  customers: Customer[]
): CustomerAddCustomerAction => ({
  type: CUSTOMER_ADD,
  payload: customers
})

export const customerFetchFail = (error: any): CustomerFetchFailAction => ({
  type: CUSTOMER_FETCH_FAIL,
  payload: error,
  error: true
})
