import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../../helpers/hub'
import { BidAskDiffType, GreaterOrLessThan } from '../../alertFilter/types'
import { logError } from '../../ws/actions'
import {
  QuerySearchSecuritiesAction,
  querySearchSecuritiesFailure,
  querySearchSecuritiesSuccess,
  RunQueryAction,
  runQueryFailure,
  setClickhouseQueries,
  setLastQueryResult
} from './actions'

import { Series } from '../../filter/types'
import { createSecuritySearchResultFromResponse } from '../../searchSecurities/helpers'

const getQueriesEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('query.GetQueries'),
    mergeMap(() => {
      return getHub()
        .invoke('GetClickhouseQueries')
        .pipe(map(setClickhouseQueries))
    })
  )

const runQueryEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('query.RunQuery'),
    mergeMap((action: RunQueryAction) => {
      return getHub()
        .invoke('RunQuery', action.payload.queryName, action.payload.parameters)
        .pipe(
          map(setLastQueryResult),
          catchError((err) => of(runQueryFailure(err.errorMsg), logError(err)))
        )
    })
  )

const searchSecuritiesEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('query.searchSecurities'),
    mergeMap((action: QuerySearchSecuritiesAction) => {
      const { search } = action.payload
      const tickerRegex = /^\s*[a-zA-Z]+\s*$/gm
      const tickerMaturityRegex = /^[a-zA-Z]+ [2-9][2-9]\s*$/gm
      const filt = {
        sectors: [],
        maturities: [],
        securityTypes: [],
        couponTypes: [],
        currencies: [],
        ratings: [],
        securitiesAndTickers: [],
        price: undefined,
        priceGreaterLessThan: GreaterOrLessThan.GreaterThan,
        yield: undefined,
        yieldGreaterLessThan: GreaterOrLessThan.GreaterThan,
        bidAskDiff: undefined,
        bidAskDiffGreaterLessThan: GreaterOrLessThan.GreaterThan,
        bidAskDiffType: BidAskDiffType.Price,
        series: Series.All,
        countries: []
      }
      const filters = {
        InWatchlistId: undefined,
        NotInWatchlistId: undefined,
        MyOrders: false,
        sortToTop: [],
        filter: filt,
        showLive: false,
        isTicker: tickerRegex.test(search),
        tickerAndMaturity: tickerMaturityRegex.test(search)
      }
      return getHub()
        .invoke<any[]>('SearchSecurities', search, filters)
        .pipe(
          map((securityResponses) =>
            securityResponses.map(createSecuritySearchResultFromResponse)
          ),
          map((securities) => querySearchSecuritiesSuccess(securities)),
          catchError((err) =>
            of(querySearchSecuritiesFailure(err), logError(err))
          )
        )
    })
  )

export default combineEpics(getQueriesEpic, runQueryEpic, searchSecuritiesEpic)
