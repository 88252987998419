import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import {
  addExternalLiquidityStats,
  externalLiquidityStatsFetchFail
} from './actions'
import {
  ExternalLiquidityStats,
  EXTERNALLIQUIDITYSTATS_FETCH,
  ExternalLiquidityStatsFetchAction
} from './types'

const fetchQueueStatsEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(EXTERNALLIQUIDITYSTATS_FETCH),
    delay(1000),
    switchMap((action: ExternalLiquidityStatsFetchAction) => {
      const getExternalLiquidityStats$: Observable<ExternalLiquidityStats[]> =
        getHub().invoke<ExternalLiquidityStats[]>(
          'GetExternalLiquidityStats',
          action.payload.venues,
          action.payload.security
        )
      return getExternalLiquidityStats$.pipe(
        map((stats) => {
          return addExternalLiquidityStats(stats)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) =>
          of(externalLiquidityStatsFetchFail(err), logError(err))
        )
      )
    })
  )

export default combineEpics(fetchQueueStatsEpic)
