import { UserEditorData } from './types'

export const updateUserEditorData = (
  stateData: Record<number, UserEditorData>,
  newData: UserEditorData
) => {
  const newStateData = { ...stateData }
  newStateData[newData.id] = newData
  return newStateData
}
