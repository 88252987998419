import React from 'react'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.module.scss'
import { CellProps, useSecurityOrderData } from './helpers'

const BestSpreadCell =
  (orderType: OrderType) =>
  ({ data }: CellProps) => {
    const security = useSecurityOrderData(data)
    if (!security) return <> </>

    const order = orderType === 'buy' ? security.bestBid : security.bestOffer
    const spread = order?.spread
    const cellValue =
      spread !== undefined
        ? spread >= 0
          ? '+' + spread.toLocaleString()
          : spread.toLocaleString()
        : '-'
    const testId =
      data.id + (orderType === 'buy' ? '-bestbidspread' : '-bestofrspread')
    return (
      <span
        className={order?.myFirm ? styles.myOrder : ''}
        data-testid={testId}
        data-testid-value={data.id + '-' + spread}
      >
        {cellValue}
      </span>
    )
  }

export default BestSpreadCell
