import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsFilter } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import cx from 'classnames'
import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'

import { filtersEqual } from '../../store/filter/helpers'
import { defaultFilter } from '../../store/filter/types'
import {
  getAdvancedFilter,
  getMyBook,
  getMyFirm,
  getUseSize
} from '../../store/securities/selectors'
import headerStyles from '../../containers/BondList/HeaderButton.module.scss'

export interface Props {
  active: boolean
  setActive: (b: boolean) => void
  gridIndex: number
}
const AdvancedFilter: FC<Props> = ({ active, setActive, gridIndex }) => {
  const filter = useSelector(getAdvancedFilter)(gridIndex)
  const myFirmChecked = useSelector(getMyFirm)(gridIndex)
  const myBookChecked = useSelector(getMyBook)(gridIndex)
  const useSizeChecked = useSelector(getUseSize)(gridIndex)
  const isDefault =
    filtersEqual(filter, defaultFilter) &&
    !myFirmChecked &&
    !useSizeChecked &&
    !myBookChecked

  return (
    <div
      className={cx(
        headerStyles.headerIcon,
        active
          ? headerStyles.active
          : !isDefault
          ? headerStyles.filterActive
          : false
      )}
      onClick={() => setActive(!active)}
      title={active ? 'Hide Filters' : 'Apply Filters'}
    >
      <FontAwesomeIcon icon={faBarsFilter} />
    </div>
  )
}

export default memo(AdvancedFilter)
