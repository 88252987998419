import React, { useCallback, useMemo, useState } from 'react'

/**
 * Manages a datalist for an HTMLInput element
 */

type Props = {
  datalistId?: string // use undefined when there is nothing in the datalist
  datalistItems: string[]
}
const useDatalist = ({ datalistItems, datalistId }: Props) => {
  const [hideDatalist, setHideDatalist] = useState(false)

  const datalist = useMemo(() => {
    if (hideDatalist || !datalistId || !datalistItems.length) return <></>
    return (
      <datalist id={datalistId}>
        {datalistItems.map((item) => (
          <option value={item} key={item} />
        ))}
      </datalist>
    )
  }, [hideDatalist, datalistItems, datalistId])

  /* When the user selects an option, it's annoying to see the open datalist
     with just that option in it. When they make a selection, it will trigger
     a keyboard event with no key, so we can hide the list until they start
     typing again.
     https://stackoverflow.com/a/65073572
   */
  const onInputKeydown = useCallback((e: React.KeyboardEvent) => {
    setHideDatalist(!e.key || e.key === 'Unidentified')
  }, [])

  const listId =
    !hideDatalist && datalistId && datalistItems.length ? datalistId : undefined

  return { datalist, onInputKeydown, listId }
}

export default useDatalist
