import {
  RevertTierSuccessAction,
  SaveTierFailureAction,
  SaveTierSuccessAction,
  TIER_ADD,
  TIER_FETCH,
  TIER_FETCH_FAIL,
  TIER_REVERT,
  TIER_REVERT_SUCCESS,
  TIER_SAVE,
  TIER_SAVE_FAILURE,
  TIER_SAVE_SUCCESS,
  TierAddTierAction,
  TierFetchAction,
  TierFetchFailAction,
  TierList,
  TierRevertAction,
  TierSaveAction
} from './types'

export const tierFetch = (editable: boolean): TierFetchAction => ({
  type: TIER_FETCH,
  payload: editable
})

export const tierAddTier = (tiers: TierList[]): TierAddTierAction => ({
  type: TIER_ADD,
  payload: tiers
})

export const tierFetchFail = (error: any): TierFetchFailAction => ({
  type: TIER_FETCH_FAIL,
  payload: error,
  error: true
})

export const saveTier = (tl: TierList): TierSaveAction => ({
  type: TIER_SAVE,
  payload: tl
})

export const revertTier = (id: number): TierRevertAction => ({
  type: TIER_REVERT,
  payload: id
})

export const saveTierSuccess = (
  tierList: TierList,
  transactionId?: number
): SaveTierSuccessAction => ({
  type: TIER_SAVE_SUCCESS,
  payload: { tierList, transactionId }
})

export const revertTierSuccess = (
  id: number,
  transactionId?: number
): RevertTierSuccessAction => ({
  type: TIER_REVERT_SUCCESS,
  payload: { id, transactionId }
})

export const saveTierFailure = (error: any): SaveTierFailureAction => ({
  type: TIER_SAVE_FAILURE,
  payload: error,
  error: true
})
