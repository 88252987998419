import {
  faTimes,
  faUser,
  faWindowMinimize,
  faWindowRestore
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { faOctagon } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { hide as hideNotifCenter } from 'openfin-notifications'
import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import bondsLogoLightTheme from '../../../assets/images/bonds_logo_black.png'
import bondsLogoDarkTheme from '../../../assets/images/logo_white_blue.png'
import ComponentFooter from '../../../components/ComponentFooter/ComponentFooter'
import MarketsClosed from '../../../components/MarketsClosed/MarketsClosed'
import UserSelector from '../../../components/UserSelector/UserSelector'
import config from '../../../config'
import { getUserName } from '../../../store/auth/selectors'
import { getMarketClosed } from '../../../store/market/selectors'
import {
  getOpenfinParameters,
  getUseLightTheme
} from '../../../store/userPreferences/selectors'
import { cancelAllWatchListOrdersAction } from '../../../store/watchList/actions'
import { getIsAdmin } from '../../../store/webSettings/selectors'

import modalStyles from '../../../components/GenericModal/modal.module.scss'
import { getDOMLimitExceeded } from '../../../store/depthOfMarket/selectors'
import { useAppSelector } from '../../../store/types'
import styles from './titlebar.module.scss'

export interface Props {
  toggleMenu: (menu: string) => void
}

type Interval = ReturnType<typeof setInterval>

const Titlebar: FC<Props> = ({ toggleMenu }) => {
  const {
    fin,
    autoLaunch,
    renderWindowCheck,
    manageWindows,
    toggleFrame,
    closeHiddenWindows
  } = useOpenFin()
  const [showQuitConfirmation, setQuitConfirmation] = useState(false)
  const domLimitExceeded = useAppSelector(getDOMLimitExceeded)
  const isAdmin = useAppSelector(getIsAdmin)
  const dispatch = useDispatch()
  const userName = useAppSelector(getUserName)
  const marketClosed = useAppSelector(getMarketClosed)

  const mainWindow = fin?.desktop.Window.getCurrent()
  const finApp = fin?.Application.getCurrentSync()
  const startupParameters = useAppSelector(getOpenfinParameters)
  const [interval, setStateInterval] = useState<Interval | undefined>(undefined)
  const useLightTheme = useAppSelector(getUseLightTheme)

  const bondsLogoTheme = useLightTheme
    ? bondsLogoLightTheme
    : bondsLogoDarkTheme

  useEffect(() => {
    // remove default frame
    toggleFrame(false)

    // on start, launch windows from previous session
    const openWindows = localStorage.getItem(`openfinOpenWindows`)?.split(',')
    if (openWindows) {
      try {
        openWindows.forEach((name: string) => {
          if (
            ![
              'Alert',
              'UserEditor',
              'Depth',
              'Watchlist/',
              'LastLook',
              'Aggressor'
            ].some((partialName) => name.includes(partialName))
          ) {
            renderWindowCheck(name)
          }
        })
      } catch (error) {
        console.error('Error opening windows: ' + error)
      }
    }

    finApp.on('run-requested', () => {
      mainWindow.restore()
      manageWindows(null, 'show')
    })
  }, [])

  useEffect(() => {
    autoLaunch(startupParameters, interval, setStateInterval)
  }, [JSON.stringify(startupParameters)])

  const handleQuitConfirmation = () => {
    setQuitConfirmation(!showQuitConfirmation)
  }

  const onMinimizeClick = () => {
    mainWindow.minimize()
    manageWindows(null, 'minimize')
  }

  const onShowClick = () => {
    manageWindows(null, 'restore')
  }

  const onQuitClick = () => {
    cancelAllOrders()
    mainWindow.hide()
    closeHiddenWindows()
    manageWindows(null, 'hide')
    hideNotifCenter()
    setQuitConfirmation(false)
  }

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  return (
    <div className={styles.titlebarContainer}>
      <ReactModal
        className={modalStyles.wrapperModal}
        overlayClassName={modalStyles.overlay}
        isOpen={showQuitConfirmation}
      >
        <div className={modalStyles.confirmContent}>Close BondsPro?</div>
        <ComponentFooter
          onCancelClick={handleQuitConfirmation}
          onApplyClick={onQuitClick}
          submitText="YES"
        />
      </ReactModal>

      {!isAdmin && (
        <div className={styles.appTitle}>
          <img alt="bonds.com" src={bondsLogoTheme} />
        </div>
      )}

      {marketClosed && (
        <MarketsClosed className={styles.marketStatus} isAdmin={isAdmin} />
      )}

      {!marketClosed && isAdmin && (
        <div className={styles.selectorContainer}>
          <UserSelector />
        </div>
      )}
      <span className={styles.marketStatus}>{config.env}</span>

      <div className={styles.actions}>
        <span data-testid="email" className={styles.userName}>
          {userName}
        </span>
        <div
          className={cx(styles.icon, styles.cancelIcon)}
          onClick={cancelAllOrders}
          title="Cancel All Orders"
        >
          <FontAwesomeIcon icon={faOctagon} />
        </div>
        {isAdmin && (
          <div
            className={styles.icon}
            onClick={() => toggleMenu('Admin')}
            title="Admin Options"
          >
            <FontAwesomeIcon icon={faUser} />
          </div>
        )}
        <div
          className={cx(styles.icon, styles.menu)}
          onClick={() => toggleMenu('Client')}
          title="Menu"
        >
          Menu
        </div>

        <div
          className={styles.icon}
          onClick={onShowClick}
          title="Show All Windows"
        >
          <FontAwesomeIcon icon={faWindowRestore} />
        </div>

        <div
          className={styles.icon}
          onClick={onMinimizeClick}
          title="Minimize All Windows"
        >
          <FontAwesomeIcon icon={faWindowMinimize} />
        </div>

        <div
          className={styles.icon}
          onClick={handleQuitConfirmation}
          title="Quit"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  )
}

export default Titlebar
