import React, { FC } from 'react'
import { OrderStatus } from '../../../store/order/types'

import { useAppSelector } from '../../../store/types'
import { getHasTradingRights } from '../../../store/webSettings/selectors'
import styles from './modalActionsButtons.module.scss'

export interface Props {
  orderStatusComp?: OrderStatus | undefined
  onModalClosed?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onButtonClicked?: (e: React.MouseEvent<HTMLButtonElement>) => void
  fontClassName?: string
  type?: string
  disabled?: boolean
}

const ModalActionsButtons: FC<Props> = (props) => {
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const {
    orderStatusComp,
    onModalClosed,
    onButtonClicked,
    fontClassName,
    type,
    disabled
  } = props
  let buttonsComponents
  switch (orderStatusComp) {
    case 'accepted':
      buttonsComponents = (
        <>
          <button data-testid="aggressor-close" onClick={onModalClosed}>
            Close
          </button>
        </>
      )
      break
    case 'waitingForConfirmation':
      buttonsComponents = (
        <>
          <button data-testid="aggressor-cancel" onClick={onButtonClicked}>
            Cancel Attempt
          </button>
        </>
      )
      break
    case 'error':
    case 'cancelled':
      buttonsComponents = (
        <div className={styles.buttonsComponents}>
          <button
            data-testid="aggressor-close"
            onClick={onModalClosed}
            type="button"
          >
            Close
          </button>
          <button
            className={`confirm ${fontClassName}`}
            data-testid="aggressor-status"
            type="submit"
            disabled={disabled}
          >
            Resubmit
          </button>
        </div>
      )
      break
    case 'rejected':
      buttonsComponents = (
        <>
          <button data-testid="aggressor-close" onClick={onModalClosed}>
            Close
          </button>
        </>
      )
      break
    case 'notAvailable':
      buttonsComponents = (
        <>
          <button data-testid="aggressor-close" onClick={onModalClosed}>
            Close
          </button>
        </>
      )
      break
    default:
      buttonsComponents = (
        <>
          <button data-testid="aggressor-close" onClick={onModalClosed}>
            Close
          </button>
          {hasTradingRights && (
            <button
              data-testid="aggressor-btnsellbuy"
              type="submit"
              className={`confirm ${fontClassName}`}
              disabled={disabled}
            >
              {type}
            </button>
          )}
        </>
      )
      break
  }

  return buttonsComponents
}

export default ModalActionsButtons
