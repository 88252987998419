import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getActivityTradeBlotter = createSelector([getState], state => {
  return state.activityTradeBlotter
})

export const getActivityTradeBlotterIsLoading = createSelector(
  [getState],
  state => state.pending
)

export const getActivityTradeBlotterError = createSelector(
  [getState],
  state => state.error
)
