import { Action } from 'redux'
import { Filter } from '../filter/types'
import { WatchList } from '../watchList/types'
import { Security, State } from './reducer'
import { QuoteReliability } from './types'

export interface FetchSecuritiesAction extends Action {
  type: 'securities.fetchSecurities'
  payload: {
    gridIndex: number
    page: number
    sortToTop: number[]
    state?: State
  }
}

export const fetchSecurities = (
  gridIndex: number,
  page: number,
  sortToTop: number[] = [],
  state?: State
): FetchSecuritiesAction => ({
  type: 'securities.fetchSecurities',
  payload: { gridIndex, page, sortToTop, state }
})

export interface FetchSecuritiesByIdsAction extends Action {
  type: 'securities.fetchSecuritiesByIds'
  payload: Array<Security['id']>
}

export const fetchSecuritiesByIds = (
  ids: Array<Security['id']>
): FetchSecuritiesByIdsAction => ({
  type: 'securities.fetchSecuritiesByIds',
  payload: ids
})

export interface AddOrUpdateSecuritiesAction extends Action {
  type: 'securities.addOrUpdateSecurities'
  payload: {
    gridIndex: number
    securities: Security[]
    page: number
  }
}

export interface SortValidationsToTopAction extends Action {
  type: 'securities.sortValidationsToTopAction'
  payload: { gridIndex: number; sortToTop: number[] }
}

export const sortValidationsToTopAction = (
  gridIndex: number,
  sortToTop: number[]
): SortValidationsToTopAction => ({
  type: 'securities.sortValidationsToTopAction',
  payload: { gridIndex, sortToTop }
})

export const addOrUpdateSecurities = (
  gridIndex: number,
  securities: Security[],
  page: number
): AddOrUpdateSecuritiesAction => ({
  type: 'securities.addOrUpdateSecurities',
  payload: { gridIndex, securities, page }
})

export interface ResetSortToTopAction extends Action {
  type: 'securities.resetSortToTop'
  payload: { gridIndex: number }
}

export const resetSortToTop = (gridIndex: number): ResetSortToTopAction => ({
  type: 'securities.resetSortToTop',
  payload: { gridIndex }
})

export interface SetCurrentPageAction extends Action {
  type: 'securities.setCurrentPage'
  payload: { gridIndex: number; page: number }
}

export const setCurrentPage = (
  gridIndex: number,
  page: number
): SetCurrentPageAction => ({
  type: 'securities.setCurrentPage',
  payload: { gridIndex, page }
})

export interface AddOrUpdateSecuritiesCacheAction extends Action {
  type: 'securities.addOrUpdateSecuritiesCache'
  payload: {
    securities: Security[]
  }
}

export const addOrUpdateSecuritiesCache = (
  securities: Security[]
): AddOrUpdateSecuritiesCacheAction => ({
  type: 'securities.addOrUpdateSecuritiesCache',
  payload: { securities }
})

export interface SaveAdvancedFilterAction extends Action {
  type: 'securities.saveAdvancedFilter'
  payload: {
    gridIndex: number
    filter: Filter
  }
}

export const saveAdvancedFilter = (
  gridIndex: number,
  filter: Filter
): SaveAdvancedFilterAction => ({
  type: 'securities.saveAdvancedFilter',
  payload: { gridIndex, filter }
})

export interface FetchSecuritiesFailureAction extends Action {
  type: 'securities.fetchSecuritiesFailure'
  payload: { gridIndex: number; error: any }
  error: true
}

export const fetchSecuritiesFailure = (
  gridIndex: number,
  error: any
): FetchSecuritiesFailureAction => ({
  type: 'securities.fetchSecuritiesFailure',
  payload: { gridIndex, error },
  error: true
})

export interface SetWatchListId extends Action {
  type: 'securities.setWatchListId'
  payload: {
    gridIndex: number
    watchlistId: number | undefined
  }
}

export const setWatchListId = (
  gridIndex: number,
  watchlistId: number | undefined
): SetWatchListId => ({
  type: 'securities.setWatchListId',
  payload: { gridIndex, watchlistId }
})

export interface SetSecuritiesFilterAction extends Action {
  type: 'securities.setSecuritiesFilter'
  payload: { gridIndex: number; securityIds: Array<Security['id']> | undefined }
}

export const setSecuritiesFilter = (
  gridIndex: number,
  securityIds: Array<Security['id']> | undefined
): SetSecuritiesFilterAction => ({
  type: 'securities.setSecuritiesFilter',
  payload: { gridIndex, securityIds }
})

export interface SetIssuerFilterAction extends Action {
  type: 'securities.setIssuerFilter'
  payload: { gridIndex: number; issuerFilter: string | undefined }
}

export const setIssuerFilter = (
  gridIndex: number,
  issuerFilter: string | undefined
): SetIssuerFilterAction => ({
  type: 'securities.setIssuerFilter',
  payload: { gridIndex, issuerFilter }
})

export interface SetIsMine extends Action {
  type: 'securities.setIsMine'
  payload: { gridIndex: number; isMine: boolean }
}

export const setIsMine = (gridIndex: number, isMine: boolean) => ({
  type: 'securities.setIsMine',
  payload: { gridIndex, isMine }
})

export interface SetShowLive extends Action {
  type: 'securities.setShowLive'
  payload: { gridIndex: number; showLive: boolean }
}

export interface UnsubscribeGetSecurities extends Action {
  type: 'securities.unsubscribeGetSecurities'
  payload: null
}

export const unsubscribeGetSecurities = () => ({
  type: 'securities.unsubscribeGetSecurities'
})

export const setShowLive = (gridIndex: number, showLive: boolean) => ({
  type: 'securities.setShowLive',
  payload: { gridIndex, showLive }
})

export interface SetMyFirm extends Action {
  type: 'securities.setMyFirm'
  payload: { gridIndex: number; myFirm: boolean }
}

export const setMyFirm = (gridIndex: number, myFirm: boolean) => ({
  type: 'securities.setMyFirm',
  payload: { gridIndex, myFirm }
})

export interface SetMyBook extends Action {
  type: 'securities.setMyBook'
  payload: { gridIndex: number; myBook: boolean }
}

export const setMyBook = (gridIndex: number, myBook: boolean) => ({
  type: 'securities.setMyBook',
  payload: { gridIndex, myBook }
})

export interface SetQueryFilterAction extends Action {
  type: 'securities.setQueryFilter'
  payload: { gridIndex: number; query?: string }
}

export const setQueryFilter = (
  gridIndex: number,
  query?: string
): SetQueryFilterAction => ({
  type: 'securities.setQueryFilter',
  payload: { gridIndex, query }
})

export interface SetQuoteReliability extends Action {
  type: 'securities.setQuoteReliability'
  payload: { gridIndex: number; quoteReliability: QuoteReliability }
}

export const setQuoteReliability = (
  gridIndex: number,
  quoteReliability: QuoteReliability
) => ({
  type: 'securities.setQuoteReliability',
  payload: { gridIndex, quoteReliability }
})

export interface SetSizeFilter extends Action {
  type: 'securities.setSizeFilter'
  payload: { gridIndex: number; useSize: boolean; size: number | undefined }
}

export const setSizeFilter = (
  gridIndex: number,
  useSize: boolean,
  size: number | undefined
) => ({
  type: 'securities.setSizeFilter',
  payload: { gridIndex, useSize, size }
})

export interface SetUseAdvancedFilter extends Action {
  type: 'securities.setUseAdvancedFilter'
  payload: { gridIndex: number; useAdvancedFilter: boolean }
}

export const setUseAdvancedFilter = (
  gridIndex: number,
  useAdvancedFilter: boolean
) => ({
  type: 'securities.setUseAdvancedFilter',
  payload: { gridIndex, useAdvancedFilter }
})

export interface CreateGridAction extends Action {
  type: 'securities.createGrid'
  payload: {
    watchlistId: WatchList['id']
    afterIndex: number
    isMineGrid: boolean
  }
}

export const createGrid = (
  watchlistId: WatchList['id'],
  afterIndex: number,
  isMineGrid: boolean
): CreateGridAction => ({
  type: 'securities.createGrid',
  payload: { watchlistId, afterIndex, isMineGrid }
})

export interface RemoveGridAction extends Action {
  type: 'securities.removeGrid'
  payload: { gridIndex: number }
}

export const removeGrid = (gridIndex: number): RemoveGridAction => ({
  type: 'securities.removeGrid',
  payload: { gridIndex }
})

export const fetchListTradingSecurities = (watchlistId: number, page: number) =>
  ({
    type: `securities.fetchListTradingSecurities`,
    payload: { watchlistId, page }
  } as const)
export type FetchListTradingSecuritiesAction = ReturnType<
  typeof fetchListTradingSecurities
>
export const addOrUpdateListTradingSecurities = (
  securities: Security[],
  page: number
) =>
  ({
    type: `securities.addOrUpdateListTradingSecurities`,
    payload: { securities, page }
  } as const)
export type AddOrUpdateListTradingSecuritiesAction = ReturnType<
  typeof addOrUpdateListTradingSecurities
>
export const fetchListTradingSecuritiesFailure = () =>
  ({
    type: `securities.fetchListTradingSecuritiesFailure`
  } as const)
export type FetchListTradingSecuritiesFailureAction = ReturnType<
  typeof fetchListTradingSecuritiesFailure
>
export const unsubscribeListTrading = () =>
  ({
    type: 'securities.unsubscribeListTrading'
  } as const)
export type UnsubscribeListTradingAction = ReturnType<
  typeof unsubscribeListTrading
>

export type ListTradingActions =
  | FetchListTradingSecuritiesAction
  | AddOrUpdateListTradingSecuritiesAction
  | FetchListTradingSecuritiesFailureAction
  | UnsubscribeListTradingAction

export const showSecurityDetails = (
  gridIndex: number,
  securityId: Security['id'] | undefined
) =>
  ({
    type: 'securities.showSecurityDetails',
    payload: { gridIndex, securityId }
  } as const)

export type ShowSecurityDetailsAction = ReturnType<typeof showSecurityDetails>

export type GridSpecificAction =
  | SetCurrentPageAction
  | FetchSecuritiesAction
  | FetchSecuritiesFailureAction
  | AddOrUpdateSecuritiesAction
  | SetWatchListId
  | SetIssuerFilterAction
  | SetQueryFilterAction
  | SetSecuritiesFilterAction
  | SetIsMine
  | SetUseAdvancedFilter
  | SetShowLive
  | SetMyFirm
  | SetMyBook
  | SetQuoteReliability
  | SortValidationsToTopAction
  | ResetSortToTopAction
  | SaveAdvancedFilterAction
  | SetSizeFilter
  | UnsubscribeGetSecurities
  | ShowSecurityDetailsAction

export type SecuritiesAction =
  | GridSpecificAction
  | AddOrUpdateSecuritiesCacheAction
  | CreateGridAction
  | RemoveGridAction
  | UnsubscribeGetSecurities
  | ListTradingActions
