import { addOrUpdateQueueStats } from './helpers'
import {
  QueueStats,
  QUEUESTATS_ADD,
  QUEUESTATS_FETCH,
  QUEUESTATS_FETCH_FAIL,
  QueueStatsAction
} from './types'

export interface State {
  queueStats?: QueueStats[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  queueStats: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: QueueStatsAction): State => {
  switch (action.type) {
    case QUEUESTATS_FETCH:
      return {
        ...state,
        pending: true,
        queueStats: undefined,
        error: false
      }
    case QUEUESTATS_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case QUEUESTATS_ADD:
      let items: QueueStats[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.queueStats) {
        return {
          ...state,
          queueStats: items
        }
      }

      return {
        ...state,
        queueStats: addOrUpdateQueueStats(state.queueStats, items)
      }

    default:
      return state
  }
}
