import { faMedal } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDropDownMenu } from '../../helpers/hooks'
import { setQuoteReliability } from '../../store/securities/actions'
import { getQuoteReliability } from '../../store/securities/selectors'
import { QuoteReliability } from '../../store/securities/types'
import styles from '../../containers/BondList/HeaderButton.module.scss'
import qrStyles from './QuoteReliability.module.scss'
import QuoteReliabilityListItem from './QuoteReliabilityListItem'

export interface Props {
  gridIndex: number
}

const QuoteReliabilityDropDownMenu: FC<Props> = ({ gridIndex }) => {
  const quoteReliabilityStatus = useSelector(getQuoteReliability)(gridIndex)
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const { displayMenu, setDisplayMenu } = useDropDownMenu(ref)
  const onQuoteReliabilitySelection = useCallback(
    (quoteReliability: QuoteReliability) => {
      dispatch(setQuoteReliability(gridIndex, quoteReliability))
      setDisplayMenu(false)
    },
    []
  )

  const showDropdownMenu = useCallback(() => {
    setDisplayMenu(true)
  }, [displayMenu])

  return (
    <div
      className={cx(
        styles.headerIcon,
        qrStyles[QuoteReliability[quoteReliabilityStatus]]
      )}
      onClick={showDropdownMenu}
      title="Quote Reliability"
    >
      <FontAwesomeIcon icon={faMedal} />
      {displayMenu && (
        <div className={styles.menu}>
          <QuoteReliabilityListItem
            gridIndex={gridIndex}
            quoteReliability={QuoteReliability.All}
            selected={quoteReliabilityStatus === QuoteReliability.All}
            onSelectItem={onQuoteReliabilitySelection}
          />
          <QuoteReliabilityListItem
            gridIndex={gridIndex}
            quoteReliability={QuoteReliability.HighFillRate}
            selected={quoteReliabilityStatus === QuoteReliability.HighFillRate}
            onSelectItem={onQuoteReliabilitySelection}
          />
          <QuoteReliabilityListItem
            gridIndex={gridIndex}
            quoteReliability={QuoteReliability.Firm}
            selected={quoteReliabilityStatus === QuoteReliability.Firm}
            onSelectItem={onQuoteReliabilitySelection}
          />
        </div>
      )}
    </div>
  )
}

export default QuoteReliabilityDropDownMenu
