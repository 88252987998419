import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import { queueStatsAddQueueStats, queueStatsFetchFail } from './actions'
import { QueueStats, QUEUESTATS_FETCH, QueueStatsFetchAction } from './types'

const fetchQueueStatsEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(QUEUESTATS_FETCH),
    delay(1000),
    switchMap((action: QueueStatsFetchAction) => {
      const getQueueStatss$ = getHub().stream<QueueStats[]>('GetQueueStats')
      return getQueueStatss$.pipe(
        map((queueStats) => {
          return queueStatsAddQueueStats(queueStats)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(queueStatsFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchQueueStatsEpic)
