const formatSize = (size: number) => size.toLocaleString()

export const formatSpread = (spread: number) =>
  `${spread > 0 ? '+' : ''}${spread.toLocaleString()}`

const formatPrice = (price: number) =>
  price.toLocaleString(undefined, { maximumFractionDigits: 4 })

export const formatTradingNowDetails = (
  size: number,
  price: number,
  spread?: number
) =>
  `${formatSize(size)} @ ${
    spread ? `${formatSpread(spread)} | ` : ''
  }${formatPrice(price)}`

export const formatDetails = (size: number, price: number, spread?: number) =>
  `${spread ? `${formatSpread(spread)} | ` : ''}${formatPrice(
    price
  )} for ${formatSize(size)}k`
