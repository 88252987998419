import cx from 'classnames'
import React, { FC } from 'react'
import {
  QuoteReliability,
  QuoteReliabilityDisplayNames
} from '../../store/securities/types'
import styles from '../../containers/BondList/HeaderButton.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'

export interface Props {
  gridIndex: number
  quoteReliability: QuoteReliability
  selected: boolean
  onSelectItem: (quoteReliability: QuoteReliability) => void
}

const QuoteReliabilityListItem: FC<Props> = ({
  gridIndex,
  quoteReliability,
  selected,
  onSelectItem
}) => {
  return (
    <div className={cx(styles.listItem, styles.clickable)}>
      <span className={styles.icnSelected}>
        {selected && (
          <FontAwesomeIcon
            icon={faCheck}
            className={styles.icnSelected}
            aria-hidden="true"
          />
        )}{' '}
      </span>
      <label onClick={() => onSelectItem(quoteReliability)}>
        {QuoteReliabilityDisplayNames[QuoteReliability[quoteReliability]]}
      </label>
    </div>
  )
}

export default QuoteReliabilityListItem
