import { Action } from 'redux'

export interface UpdateColumnsOrderAction extends Action {
  type: 'grid.updateColumnsOrder'
  payload: { gridIndex: number; columns: string[] }
}

export const updateColumnsOrder = (
  gridIndex: number,
  columns: string[]
): UpdateColumnsOrderAction => ({
  type: 'grid.updateColumnsOrder',
  payload: { gridIndex, columns }
})

export interface UpdateDisplayedColumns extends Action {
  type: 'grid.updateDisplayedColumns'
  payload: { gridIndex: number; columns: string[] }
}

export const updateDisplayedColumns = (
  gridIndex: number,
  columns: string[]
): UpdateDisplayedColumns => ({
  type: 'grid.updateDisplayedColumns',
  payload: { gridIndex, columns }
})

export interface ResetColumnsForGridAction extends Action {
  type: 'grid.resetColumnsForGrid'
  payload: { gridIndex: number }
}

export const resetColumnsForGrid = (
  gridIndex: number
): ResetColumnsForGridAction => ({
  type: 'grid.resetColumnsForGrid',
  payload: { gridIndex }
})

export type GridAction =
  | UpdateColumnsOrderAction
  | UpdateDisplayedColumns
  | ResetColumnsForGridAction
