import { ColDef } from '@ag-grid-community/core'
import { diffDateFormatter } from '../../../../helpers/formatting'

export const QUEUESIZE = 'queueSize'
export const COMPONENTNAME = 'componentName'
export const LASTEDITTIME = 'lastEditTime'

export const customizableColumns: ColDef[] = [
  {
    colId: QUEUESIZE,
    field: QUEUESIZE,
    headerName: 'QueueSize'
  },
  {
    colId: COMPONENTNAME,
    field: COMPONENTNAME,
    headerName: 'ComponentName',
    flex: 2
  },
  {
    colId: LASTEDITTIME,
    field: LASTEDITTIME,
    headerName: 'LastEditTime',
    valueFormatter: diffDateFormatter
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
