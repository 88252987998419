import React from 'react'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity.tsx'
import { ListTradingSecurityRendererProps } from './ListTradingSecurityFieldRenderer.tsx'

const DefaultWorkingOrderFieldRenderer = ({
  data,
  fieldName
}: ListTradingSecurityRendererProps) => {
  const security = useGetListTradingSecurity(data?.id)

  if (!security || !fieldName || !security[fieldName]) return <div>-</div>

  return <div>{security[fieldName]}</div>
}

export default DefaultWorkingOrderFieldRenderer
