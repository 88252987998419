import cx from 'classnames'
import React, { ButtonHTMLAttributes, ClassAttributes, FC } from 'react'
import styles from './styles.module.scss'

type ButtonProps = ClassAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement>

const Button: FC<ButtonProps> = ({ className, ...buttonProps }) => {
  return <button {...buttonProps} className={cx(styles.button, className)} />
}

export default Button
