import dayjs from 'dayjs'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gridStyles from '../../Grid/grid.module.scss'
import {
  historicalDataClear,
  historicalDataGet
} from '../../../store/admin/historicalUserData/actions'
import { getHistoricalDataPending } from '../../../store/admin/historicalUserData/selectors'
import { Customer } from '../../../store/customers/types'
import { getUsers } from '../../../store/users/selectors'
import { User } from '../../../store/users/types'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import HistoricalDataGrid from './Grid/HistoricalDataGrid'
import styles from './HistoricalData.module.scss'
import { finHeaderButtons } from '../../ComponentHeader/helpers'

const HistoricalUserData: FC = () => {
  const dispatch = useDispatch()
  const today = new Date()
  const allUsers = useSelector(getUsers)
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs(today).format('YYYY-MM-DD'))
  const pending = useSelector(getHistoricalDataPending)

  const runQuery = () => {
    dispatch(historicalDataClear())
    dispatch(
      historicalDataGet(
        0,
        0,
        dayjs(startDate).toDate(),
        dayjs(endDate).toDate()
      )
    )
  }

  useEffect(() => {
    const newUsersByUserId: Record<number, User> = {}
    const newUsersByCustId: Record<number, User[]> = {}
    const custList: Customer[] = []
    if (allUsers) {
      for (const usr of allUsers) {
        newUsersByUserId[usr.id] = usr
        if (!newUsersByCustId[usr.custId]) {
          newUsersByCustId[usr.custId] = []
        }
        newUsersByCustId[usr.custId].push(usr)
        if (!custList[usr.custId]) {
          custList.push({ id: usr.custId, shortName: usr.custName })
        }
      }
    }
  }, [allUsers])

  useEffect(() => {
    runQuery()
  }, [])

  return (
    <React.Fragment>
      <div className={gridStyles.outerGridContainer}>
        <ComponentHeader
          title="GUI User Data"
          headerButtons={finHeaderButtons('GUIUserData')}
        />
        <div className={gridStyles.optionsContainer}>
          <span>
            <input
              className={styles.datePicker}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={pending}
            />
            <input
              className={styles.datePicker}
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={pending}
            />
            <button
              className={styles.btn}
              onClick={runQuery}
              disabled={pending}
            >
              Run query
            </button>
          </span>
        </div>
        <HistoricalDataGrid />
      </div>
    </React.Fragment>
  )
}

export default HistoricalUserData
