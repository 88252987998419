import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getUsers = createSelector([getState], (state) => state.users)

export const getUserFromUserId = createSelector(
  [getState],
  (state) => (userId: number) => state.users?.find((u) => u.id === userId)
)

export const getUserIsLoading = createSelector(
  [getState],
  (state) => state.pending
)

export const getUserError = createSelector([getState], (state) => state.error)

export const getSelectedUser = createSelector(
  [getState],
  (state) => state.selectedUser
)
