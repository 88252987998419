export const replaceColumn = (
  columnToReplace: string,
  columnsToAdd: string[]
) => (columns: string[]) => {
  const columnIndex = columns.indexOf(columnToReplace)

  return columnIndex !== -1
    ? [
        ...columns.slice(0, columnIndex),
        ...columnsToAdd,
        ...columns.slice(columnIndex + 1, columnToReplace.length)
      ]
    : columns
}
