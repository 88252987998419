import {
  ExternalLiquidityStats,
  EXTERNALLIQUIDITYSTATS_ADD,
  EXTERNALLIQUIDITYSTATS_CLEAR,
  EXTERNALLIQUIDITYSTATS_FETCH,
  EXTERNALLIQUIDITYSTATS_FETCH_FAIL,
  ExternalLiquidityStatsAddAction,
  ExternalLiquidityStatsClearAction,
  ExternalLiquidityStatsFetchAction,
  ExternalLiquidityStatsFetchFailAction
} from './types'

export const externalLiquidityStatsFetch = (
  venues: number[],
  security: string
): ExternalLiquidityStatsFetchAction => ({
  type: EXTERNALLIQUIDITYSTATS_FETCH,
  payload: { venues, security }
})

export const addExternalLiquidityStats = (
  stats: ExternalLiquidityStats[]
): ExternalLiquidityStatsAddAction => ({
  type: EXTERNALLIQUIDITYSTATS_ADD,
  payload: stats
})

export const clearExternalLiquidityStats =
  (): ExternalLiquidityStatsClearAction => ({
    type: EXTERNALLIQUIDITYSTATS_CLEAR
  })

export const externalLiquidityStatsFetchFail = (
  error: any
): ExternalLiquidityStatsFetchFailAction => ({
  type: EXTERNALLIQUIDITYSTATS_FETCH_FAIL,
  payload: error,
  error: true
})
