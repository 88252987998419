import { updateUserEditorData } from './helpers'
import {
  ADD_USEREDITORDATA,
  UserEditorData,
  USEREDITORDATA_SAVE_FAILURE,
  USEREDITORDATA_SAVE_SUCCESS,
  UserEditorDataAction
} from './types'

export interface State {
  userEditorData: Record<number, UserEditorData>
  errorMsg: string
  error: boolean
}

export const initialState: State = {
  userEditorData: {},
  errorMsg: '',
  error: false
}

export default (state = initialState, action: UserEditorDataAction): State => {
  switch (action.type) {
    case USEREDITORDATA_SAVE_FAILURE:
      return {
        ...state,
        errorMsg: action.payload.errors,
        error: false
      }
    case USEREDITORDATA_SAVE_SUCCESS:
      return {
        ...state,
        errorMsg: action.payload.message,
        error: true
      }
    case ADD_USEREDITORDATA:
      return {
        ...state,
        userEditorData: updateUserEditorData(
          state.userEditorData,
          action.payload
        )
      }

    default:
      return state
  }
}
