import {
  Activity,
  ACTIVITYLOG_ADD_ACTIVITY,
  ACTIVITYLOG_FETCH,
  ACTIVITYLOG_FETCH_CANCEL,
  ACTIVITYLOG_FETCH_FAIL,
  ActivityLogAddActivityAction,
  ActivityLogFetchAction,
  ActivityLogFetchCancelAction,
  ActivityLogFetchFailAction
} from './types'

export const activityLogFetch = (): ActivityLogFetchAction => ({
  type: ACTIVITYLOG_FETCH
})

export const activityLogFetchCancel = (): ActivityLogFetchCancelAction => ({
  type: ACTIVITYLOG_FETCH_CANCEL
})

export const activityLogFetchFail = (
  error: any
): ActivityLogFetchFailAction => ({
  type: ACTIVITYLOG_FETCH_FAIL,
  payload: error,
  error: true
})

export const activityLogAddActivity = (
  activityLog: Activity[]
): ActivityLogAddActivityAction => ({
  type: ACTIVITYLOG_ADD_ACTIVITY,
  payload: activityLog
})
