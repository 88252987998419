import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsSignedIn } from '../../store/auth/selectors'
import { setUserPreferences } from '../../store/userPreferences/actions'
import { getUserPreferences } from '../../store/userPreferences/selectors'
import styles from './CookieNotice.module.scss'

const CookieNotice: FC = () => {
  const dispatch = useDispatch()
  const isSignedIn = useSelector(getIsSignedIn)
  const acceptedCookies = document.cookie.includes('accepted-cookies')
  const userPreferences = useSelector(getUserPreferences)
  const [showCookieNotice, setShowCookieNotice] = useState(!acceptedCookies)

  const acceptCookie = () => {
    const date = new Date()
    const cookieConsentDate = date.toUTCString()
    const newPreferences = {
      ...userPreferences,
      cookieConsentDate
    }
    dispatch(setUserPreferences(newPreferences))
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    const expiration = date.toUTCString()
    document.cookie = `accepted-cookies=${1}; expires=${expiration};`
    setShowCookieNotice(false)
  }
  return (
    <>
      {isSignedIn && showCookieNotice && (
        <div className={styles.cookieContainer}>
          By using our website you agree to the use of cookies
          <button className="confirm" onClick={acceptCookie}>
            Accept
          </button>
        </div>
      )}
    </>
  )
}

export default CookieNotice
