import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import { addManagementReport, managementReportFetchFail } from './actions'
import {
  ManagementReport,
  MANAGEMENTREPORT_FETCH,
  ManagementReportFetchAction
} from './types'

const fetchManagementReportEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(MANAGEMENTREPORT_FETCH),
    delay(1000),
    switchMap((action: ManagementReportFetchAction) => {
      const getManagementReports$: Observable<any> = getHub().invoke<ManagementReport[]>(
        'GetManagementReports',
        action.payload
      )
      return getManagementReports$.pipe(
        map((managementReport: ManagementReport) => {
          return addManagementReport(managementReport)
        }),
        // takeUntil(action$.pipe(ofType(ACTIVITYLOG_FETCH_CANCEL))),
        catchError((err) => of(managementReportFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchManagementReportEpic)
