import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notification from '../../../assets/sounds/notification.wav'
import { confirmOrder, rejectOrder } from '../../../store/lastLook/actions'
import { SpotCrossSelection } from '../../../store/lastLook/types'
import { addLogItem } from '../../../store/log/actions'
import { getUserOrOperatorOrderById } from '../../../store/order/selectors'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder
} from '../../../store/stagedOrders/actions'
import { getStagedOrderBySecurityForOrderType } from '../../../store/stagedOrders/selectors'
import { getUserPreferences } from '../../../store/userPreferences/selectors'
import { showTsySetting } from '../../../store/webSettings/selectors'
import { removeLastLookFromWindows } from '../../../store/windows/actions'
import { LastLookWindowInfo } from '../../../store/windows/types'
import { useSecurityModalInfo } from '../AggressorModal/helpers'
import LastLookModalFooter from '../LastLookModalFooter/LastLookModalFooter'
import HeaderModal from '../ModalHeader/ModalHeader'
import ModalSecurityInformationWrapper from '../ModalSecurityInformationWrapper'
import styles from '../modal.module.scss'
import LastLookModalContent from './LastLookModalContent'

interface Props {
  windowInfo: LastLookWindowInfo
  index: number
  isActiveWindow: boolean
  handleMinimize: (index: number) => void
}

const LastLookModal: FC<Props> = ({
  windowInfo,
  index = 0,
  isActiveWindow = true,
  handleMinimize
}) => {
  const [logWritten, setLogWritten] = useState<boolean>(false)
  const showTsy = useSelector(showTsySetting)
  const [size, setSize] = useState(0)
  const securityModalInfo = useSecurityModalInfo(windowInfo.orderId, size)
  const getSecurity = useSelector(getSecurityStaticDataById)
  const getOrder = useSelector(getUserOrOperatorOrderById)
  const getStagedOrder = useSelector(getStagedOrderBySecurityForOrderType)
  const userPreferences = useSelector(getUserPreferences)
  const dispatch = useDispatch()
  const [spotCrossSelection, setSpotCrossSelection] =
    useState<SpotCrossSelection>('S')

  const security =
    securityModalInfo && getSecurity(securityModalInfo.securityId)

  const order = securityModalInfo && getOrder(windowInfo.orderId)

  const aggressorOrder = order?.aggressorOrder
  const orderStatus = aggressorOrder?.status
  const tradeAmt = aggressorOrder?.size
  const minSizeForTsyHedge = securityModalInfo?.minSizeForTsyHedge
    ? securityModalInfo.minSizeForTsyHedge
    : '500'

  const disableButton =
    !securityModalInfo?.benchmarkPrice ||
    orderStatus === 'accepted' ||
    orderStatus === 'rejected' ||
    orderStatus === 'cancelled'

  const disableCrossButton =
    disableButton || Number(tradeAmt) < Number(minSizeForTsyHedge)

  useEffect(() => {
    // check user record to determine which autospot/cross button to select on load
    if (securityModalInfo && tradeAmt && minSizeForTsyHedge) {
      if (
        securityModalInfo.spotHedgePreference.startsWith('H') &&
        securityModalInfo.benchmarkPrice
      ) {
        setSpotCrossSelection('H')
      }
      if (tradeAmt < Number(minSizeForTsyHedge)) {
        setSpotCrossSelection('S')
      }
    }
  }, [securityModalInfo, aggressorOrder])

  useEffect(() => {
    if (!userPreferences.muteNotifications) {
      const notificationAudio = new Audio(notification)
      // tslint:disable-next-line: no-floating-promises
      notificationAudio.play()
    }
  }, [])

  useEffect(() => {
    if (!logWritten && securityModalInfo) {
      const st = `Last look popup for ${security?.boardLabel} - ${
        order?.type
      } ${String(tradeAmt)} @ ${securityModalInfo?.price}`
      dispatch(addLogItem(st))
      setLogWritten(true)
    }
  }, [securityModalInfo])

  useEffect(() => {
    setSize(order?.aggressorOrder?.size || 0)
  }, [order?.aggressorOrder?.size])

  if (!security || !securityModalInfo || !order) {
    return null
  }

  const handleCloseModal = () => {
    dispatch(removeLastLookFromWindows(windowInfo))
  }

  const handleConfirmButtonClick = (tradeAmount?: number) => () => {
    if (order?.id) {
      dispatch(confirmOrder(order.id, spotCrossSelection))

      const stagedOrder = getStagedOrder(order.securityId, order.type)
      if (
        stagedOrder &&
        tradeAmount &&
        tradeAmount !== Math.max(stagedOrder.size, stagedOrder.totalSize ?? 0)
      ) {
        const { size: orderSize, totalSize = 0, ...rest } = stagedOrder
        const isIb = totalSize > orderSize
        const newSize = (totalSize ?? orderSize) - tradeAmount
        const overrides = isIb
          ? { totalSize: newSize, size: orderSize }
          : { size: newSize }
        dispatch(
          addOrUpdateStagedOrders([
            {
              ...rest,
              ...overrides
            }
          ])
        )
      } else {
        dispatch(
          removeStagedOrder({
            securityId: order.securityId,
            orderType: order.type
          })
        )
      }
    } else {
      console.error('orderId not found')
    }
  }

  const handleRejectButtonClick = () => {
    if (order?.id) {
      dispatch(rejectOrder(order.id))
    } else {
      console.error('orderId not found')
    }
  }

  const fontClassName =
    order.type === 'buy' ? styles.buyColor : styles.sellColor

  const expandedOrCollapsed = isActiveWindow
    ? styles.expandableModal
    : styles.collapsedModal

  return (
    <div id="modalWrapper" className={styles.modalWrapper}>
      <section className={styles.modalMain}>
        <HeaderModal
          type={order.type}
          security={security}
          securityModalInfo={securityModalInfo}
          styles={styles}
          isLastLook={false}
          index={index}
          isActiveWindow={isActiveWindow}
          handleMinimize={handleMinimize}
          orderStatusComp={aggressorOrder?.status}
        />

        <div className={`${styles.contentExpandable} ${expandedOrCollapsed}`}>
          <LastLookModalContent
            order={order}
            securityModalInfo={securityModalInfo}
            isLastLook={true}
            price={securityModalInfo.price}
            styles={styles}
            tradeAmt={String(tradeAmt)}
            type={securityModalInfo.type}
            handleCloseModal={handleCloseModal}
            handleRejectButtonClick={handleRejectButtonClick}
            handleConfirmButtonClick={handleConfirmButtonClick}
            orderStatusComp={orderStatus}
            currency={security.currency}
            isTreasury={security.product === 'PrinUSGovtOutright'}
          />

          {showTsy && (
            <div className={styles.lastLookPriceHedgeContainer}>
              <div className={styles.leftSide}>
                <div className={styles.title}>Price/hedge options:</div>
                <div>choose your preference</div>
              </div>
              <div className={styles.rightSide}>
                <button
                  type="button"
                  className={cx(
                    styles.priceHedgeOption,
                    spotCrossSelection === 'S' && styles.selectedButton
                  )}
                  onClick={() => setSpotCrossSelection('S')}
                  disabled={disableButton}
                  title={disableButton ? 'Not Available' : ''}
                >
                  Auto-SPOT (Tsy)
                </button>

                <button
                  type="button"
                  className={cx(
                    styles.priceHedgeOption,
                    spotCrossSelection === 'H' && styles.selectedButton
                  )}
                  onClick={() => setSpotCrossSelection('H')}
                  disabled={disableCrossButton}
                  title={disableCrossButton ? 'Not Available' : ''}
                >
                  Auto-HEDGE (Tsy)
                </button>
              </div>
            </div>
          )}

          <ModalSecurityInformationWrapper
            securityModalInfo={securityModalInfo}
            security={security}
          />

          <LastLookModalFooter
            orderId={order.id}
            fontClassName={fontClassName}
            orderStatusComp={orderStatus}
            size={order.size}
            type={order.type}
            tradeAmt={Number(tradeAmt)}
            actionCloseModal={handleCloseModal}
            handleConfirmButtonClick={handleConfirmButtonClick}
            handleRejectButtonClick={handleRejectButtonClick}
            benchmarkName={security.benchmarkName}
            spotCrossSelection={spotCrossSelection}
          />
        </div>
      </section>
    </div>
  )
}

export default LastLookModal
