import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { clearLogItems, noLogItems } from './actions'
import { getLog } from './selectors'

const logEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('log.WriteLogItems'),
    mergeMap(() => {
      const log = getLog(state$.value)
      if (Object.keys(log).length === 0) {
        return of(noLogItems())
      }
      const idsToRemove: number[] = []
      const logItems = []
      for (const key in log) {
        if (log.hasOwnProperty(key)) {
          const keyNum = Number(key)
          idsToRemove.push(keyNum)
          logItems.push(log[key])
        }
      }
      return getHub()
        .invoke<boolean>('LogFromClient', logItems)
        .pipe(map((val) => clearLogItems(idsToRemove)))
    })
  )

export default combineEpics(logEpic)
