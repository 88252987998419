import React from 'react'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { ListTradingSecurityRendererProps } from './ListTradingSecurityFieldRenderer'

const BestPriceOrSpreadRenderer = ({
  data
}: ListTradingSecurityRendererProps) => {
  const security = useGetListTradingSecurity(data?.id)
  if (!data || !security) return <></>
  const bestTrade = security.bestOrder

  if (!bestTrade) return <></>

  const cellStyle = security.isBid ? 'offer' : 'bid'

  return <span className={cellStyle}>{bestTrade.size || '-'}</span>
}

export default BestPriceOrSpreadRenderer
