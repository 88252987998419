import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: any): State => state[namespace]

export const isPending = createSelector([getState], (state) => state.pending)

export const hasError = createSelector([getState], (state) => state.error)

export const getUserPreferences = createSelector(
  [getState],
  (state) => state.userPreferences
)

export const getMaxTradeSize = createSelector(
  [getState],
  (state) => state.userPreferences.maximumTradeSize
)

export const getMinTradeSize = createSelector(
  [getState],
  (state) => state.userPreferences.minimumTradeSize
)

export const getDefaultBidOfferValue = createSelector(
  [getState],
  (state) => state.userPreferences.defaultBidOfferValue
)

export const getMaxTradeSizeIsHardLimit = createSelector(
  [getState],
  (state) => state.userPreferences.maximumTradeSizeIsHardLimit
)

export const getOpenfinParameters = createSelector([getState], (state) => ({
  openfinAutoStart: state.userPreferences.openfinAutoStart,
  openfinStartTime: state.userPreferences.openfinStartTime
}))

export const getUseLightTheme = createSelector(
  [getState],
  (state) => state.userPreferences.useLightTheme
)

export const getCurrentTheme = createSelector(
  [getUseLightTheme],
  (isLightTheme) => `ag-theme-quartz${isLightTheme ? '' : '-dark'}`
)
