import { PassiveOrdersAction } from './actions'
import { getNewPassiveOrders } from './helpers'
import { PassiveOrder } from './types'

export interface State {
  orders: PassiveOrder[]
  viewAllOrders: boolean
  pending: boolean
}

export const initialState: State = {
  orders: [],
  viewAllOrders: false,
  pending: false
}

export default (state = initialState, action: PassiveOrdersAction): State => {
  switch (action.type) {
    case 'passiveOrders.addOrUpdatePassiveOrders':
      return {
        ...state,
        pending: false,
        orders: getNewPassiveOrders(state.orders, action.payload)
      }
    case 'passiveOrders.clearPassiveOrders':
      return { ...state, orders: [] }
    case 'passiveOrders.fetchPassiveOrders':
      return { ...state, pending: true }
    case 'passiveOrders.viewAllOrders':
      return {
        ...state,
        viewAllOrders: action.payload
      }
    default:
      return state
  }
}
