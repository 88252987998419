import {
  ACTIVITYTICKER_FETCH,
  ACTIVITYTICKER_FETCH_CANCEL,
  ACTIVITYTICKER_FETCH_FAIL,
  ACTIVITYTICKER_SUCCESS,
  ActivityTickerFetchAction,
  ActivityTickerFetchCancelAction,
  ActivityTickerFetchFailAction,
  ActivityTickerSuccessAction,
  TickerInfo
} from './types'

export const activityTickerFetch = (): ActivityTickerFetchAction => ({
  type: ACTIVITYTICKER_FETCH
})

export const activityTickerFetchFail = (
  error: any
): ActivityTickerFetchFailAction => ({
  type: ACTIVITYTICKER_FETCH_FAIL,
  payload: error,
  error: true
})

export const getActivityTickerSuccess = (
  tickerInfo: TickerInfo[]
): ActivityTickerSuccessAction => ({
  type: ACTIVITYTICKER_SUCCESS,
  payload: tickerInfo
})

export const unsubscribeFromTicker = (): ActivityTickerFetchCancelAction => ({
  type: ACTIVITYTICKER_FETCH_CANCEL
})
