import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  filter,
  map,
  mergeMap,
  takeUntil
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { fetchSecuritiesByIds } from '../securities/actions'
import { getSecurityStaticDataById } from '../securities/selectors'
import { logError } from '../ws/actions'
import {
  addCancelOrderAlerts,
  AddCancelOrderAlerts,
  addCounteredToppedAlerts,
  addNewOrderAlerts,
  AddNewOrderAlerts,
  addTradingNowAlerts,
  AddTradingNowAlerts,
  SubscribeToAlertsAction,
  UnsubscribeFromAlertsAction
} from './actions'
import {
  cancelOrderAlertFromResponse,
  counteredToppedAlertFromResponse,
  newOrderAlertFromResponse,
  tradingNowAlertFromResponse
} from './helpers'
import {
  getMutedCounteredAlertsSecurityIds,
  getMutedNewOrderAlertsSecurityIds,
  getMutedToppedAlertsSecurityIds,
  getMutedTradingNowAlertsSecurityIds
} from './selectors'

const BUFFER_DELAY = 300

const notEmpty = (array: any[]) => array.length > 0

const subscribeToTradingNowAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetTradingNowAlerts')
        .pipe(
          map(tradingNowAlertFromResponse),
          filter((alert) => {
            const securityIds = getMutedTradingNowAlertsSecurityIds(
              state$.value
            )
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addTradingNowAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToNewOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetNewOrderAlerts')
        .pipe(
          map(newOrderAlertFromResponse),
          filter((alert) => {
            const securityIds = getMutedNewOrderAlertsSecurityIds(state$.value)
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addNewOrderAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToOrderCancelAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetOrderCancelAlerts')
        .pipe(
          map(cancelOrderAlertFromResponse),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addCancelOrderAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const subscribeToCounteredToppedAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<SubscribeToAlertsAction>('alerts.subscribe'),
    mergeMap(() =>
      getHub()
        .stream('GetCounteredToppedAlerts')
        .pipe(
          map(counteredToppedAlertFromResponse),
          filter((alert) => {
            const securityIds =
              alert.alertType === 'countered'
                ? getMutedCounteredAlertsSecurityIds(state$.value)
                : getMutedToppedAlertsSecurityIds(state$.value)
            return !securityIds.includes(alert.securityId)
          }),
          bufferTime(BUFFER_DELAY),
          filter(notEmpty),
          map(addCounteredToppedAlerts),
          takeUntil(
            action$.ofType<UnsubscribeFromAlertsAction>('alerts.unsubscribe')
          ),
          catchError((err) => of(logError(err)))
        )
    )
  )

const fetchSecurityInfoForAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddTradingNowAlerts>('alerts.addTradingNowAlerts'),
    mergeMap((action) => {
      const { tradingNowAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = tradingNowAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )

const fetchSecurityInfoForNewOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddNewOrderAlerts>('alerts.addNewOrderAlerts'),
    mergeMap((action) => {
      const { newOrderAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = newOrderAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )

const fetchSecurityInfoForCancelOrderAlertsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<AddCancelOrderAlerts>('alerts.addCancelOrderAlerts'),
    mergeMap((action) => {
      const { cancelOrderAlerts } = action.payload
      const getSecurity = getSecurityStaticDataById(state$.value)
      const securityIds = cancelOrderAlerts
        .map((alert) => alert.securityId)
        .filter((securityId) => getSecurity(securityId) === undefined)
      return securityIds.length > 0
        ? of(fetchSecuritiesByIds(securityIds))
        : EMPTY
    })
  )

export default combineEpics(
  subscribeToTradingNowAlertsEpic,
  subscribeToCounteredToppedAlertsEpic,
  fetchSecurityInfoForAlertsEpic,
  fetchSecurityInfoForNewOrderAlertsEpic,
  fetchSecurityInfoForCancelOrderAlertsEpic,
  subscribeToNewOrderAlertsEpic,
  subscribeToOrderCancelAlertsEpic
)
