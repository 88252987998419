import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import React, { MouseEvent } from 'react'
import styles from './DepthOfMarket.module.scss'

type Props = {
  onClick?: (e: MouseEvent) => void
  title?: string
  testId?: string
}
const CancelButton = ({ onClick, testId, title }: Props) => {
  return (
    <div
      onClick={onClick}
      className={styles.cancelButton}
      data-testid={testId ?? 'cancel-depth-order'}
      title={title}
    >
      <FontAwesomeIcon icon={faSquareXmark} />
    </div>
  )
}

export default CancelButton
