import { addOrUpdateRestrictedTrades } from './helpers'
import {
  RESTRICTED_TRADES_ADD,
  RESTRICTED_TRADES_FETCH,
  RESTRICTED_TRADES_FETCH_FAIL,
  RestrictedTrades,
  RestrictedTradesAction
} from './types'

export interface State {
  restrictedTrades?: RestrictedTrades[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  restrictedTrades: undefined,
  pending: true,
  error: false
}

export default (
  state = initialState,
  action: RestrictedTradesAction
): State => {
  switch (action.type) {
    case RESTRICTED_TRADES_FETCH:
      return {
        ...state,
        pending: true,
        restrictedTrades: undefined,
        error: false
      }
    case RESTRICTED_TRADES_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case RESTRICTED_TRADES_ADD:
      let items: RestrictedTrades[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.restrictedTrades) {
        return {
          ...state,
          restrictedTrades: items
        }
      }

      return {
        ...state,
        restrictedTrades: addOrUpdateRestrictedTrades(
          state.restrictedTrades,
          items
        )
      }
    default:
      return state
  }
}
