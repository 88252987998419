import { Filter, Series } from './types'
export const filtersEqual = (filter1: Filter, filter2: Filter) => {
  const eq =
    filter1.maturities.length === filter2.maturities.length &&
    filter1.currencies.length === filter2.currencies.length &&
    filter1.ratings.length === filter2.ratings.length &&
    filter1.sectors.length === filter2.sectors.length &&
    filter1.securityTypes.length === filter2.securityTypes.length &&
    filter1.countries.length === filter2.countries.length &&
    filter1.couponTypes.length === filter2.couponTypes.length
  if (!eq) return false
  filter1.maturities.forEach((m) => {
    if (!filter2.maturities.includes(m)) {
      return false
    }
  })
  filter1.currencies.forEach((c) => {
    if (!filter2.currencies.includes(c)) {
      return false
    }
  })
  filter1.ratings.forEach((r) => {
    if (!filter2.ratings.includes(r)) {
      return false
    }
  })
  filter1.sectors.forEach((s) => {
    if (!filter2.sectors.includes(s)) {
      return false
    }
  })
  filter1.securityTypes.forEach((st) => {
    if (!filter2.securityTypes.includes(st)) {
      return false
    }
  })

  if (filter1.series !== filter2.series) {
    return false
  }

  if (
    // tslint:disable-next-line
    filter1.price !== filter2.price ||
    filter1.priceGreaterLessThan !== filter2.priceGreaterLessThan
  ) {
    return false
  }
  if (
    // tslint:disable-next-line
    filter1.yield !== filter2.yield ||
    filter1.yieldGreaterLessThan !== filter2.yieldGreaterLessThan
  ) {
    return false
  }
  if (
    // tslint:disable-next-line
    filter1.bidAskDiff !== filter2.bidAskDiff ||
    filter1.bidAskDiffGreaterLessThan !== filter2.bidAskDiffGreaterLessThan
  ) {
    return false
  }
  return true
}

export const SeriesTypeFromString = (st: string) => {
  switch (st) {
    case '144a':
      return Series._144a
    case 'REGS':
      return Series.REGS
    case 'All Series':
    default:
      return Series.All
  }
}

export const StringFromSeriesType = (s: Series) => {
  switch (s) {
    case Series._144a:
      return '144a'
    case Series.REGS:
      return 'REGS'
    case Series.All:
      return 'All Series'
  }
}
