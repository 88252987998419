import React, { FC } from 'react'
import styles from './Message.module.scss'

export interface Props {
  type?: 'error' | 'information'
}

const Message: FC<Props> = ({ children, type = 'information' }) => {
  const className = type === 'error' ? styles.errorMessage : styles.infoMessage
  return (
    <p className={className} data-testid={`message-${type}`}>
      {children}
    </p>
  )
}

export default Message
