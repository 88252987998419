import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../Grid/grid.module.scss'
import {
  restrictedTradesFetch,
  unsubscribeFromRestrictedTrades
} from '../../../store/admin/restrictedTrades/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import RestrictedTradesGrid from './Grid/RestrictedTradesGrid'
import { finHeaderButtons } from '../../ComponentHeader/helpers'

export interface Props {
  selectedTab: string
}

const RestrictedTrades: FC<Props> = (props) => {
  const isPermanent = props.selectedTab === 'Permanent'
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(restrictedTradesFetch())
    return () => {
      dispatch(unsubscribeFromRestrictedTrades())
    }
  }, [])

  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader
        title={`Restricted Trading ${isPermanent ? 'Permanent' : ''}`}
        headerButtons={finHeaderButtons(
          `RestrictedTrading${isPermanent ? 'Permanent' : ''}`
        )}
      />
      <RestrictedTradesGrid isPermanent={isPermanent} />
    </div>
  )
}

export default RestrictedTrades
