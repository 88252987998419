import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getAlertFilters = createSelector(
  [getState],
  state => state.filters
)

export const getAlertFilter = createSelector(
  [getState],
  state => (filterId: number) =>
    state.filters.find(filter => filter.id === filterId)
)
