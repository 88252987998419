import { faPlus } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteAlertFilter,
  muteAlertFilter
} from '../../store/alertFilter/actions'
import { AlertFilter } from '../../store/alertFilter/types'
import styles from './AlertFilter.module.scss'
import AlertFilterListItem from './AlertFilterListItem'

export interface Props {
  filters: AlertFilter[]
  selectAlertFilterId: (num: number) => void
  selectedAlertFilterId: number
}
const AlertFilterList: FC<Props> = ({
  filters,
  selectAlertFilterId,
  selectedAlertFilterId
}) => {
  const dispatch = useDispatch()
  return (
    <div className={styles.alertFilterWrapper}>
      <div className={styles.menuSection}>
        <label>Rules</label>
        <div
          className={styles.actionButton}
          onClick={() => selectAlertFilterId(0)}
        >
          New <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.RuleListItems}>
          {filters.map((f) => (
            <AlertFilterListItem
              key={`${f.id}`}
              onSelect={() => selectAlertFilterId(f.id)}
              filter={f}
              onDelete={() => dispatch(deleteAlertFilter(f.id))}
              onMute={() => dispatch(muteAlertFilter(f.id, !f.muted))}
              isSelected={f.id === selectedAlertFilterId}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AlertFilterList
