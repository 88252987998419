import { addOrUpdateElement } from '../helpers'
import { OrderAction } from './actions'
import {
  addOrUpdateNewOperatorOrders,
  addOrUpdateOrderNew,
  addOrUpdateOrderNewBatch,
  replaceOrderWithFakeTransactionIdInStateNew,
  updateFakeTransactionIdMap,
  updateHitLiftErrors,
  updateUserOrdersByTransactionIdTempAggressorOrder,
  updateUserOrdersByTransactionIdTempOrder
} from './helpers'
import { Order, OrderType, ValidationResult } from './types'

export interface State {
  myOrdersIsOpen: { [gridIndex: number]: boolean }
  userOrders: Record<string, Order>
  operatorOrders: Record<string, Order>
  userOrdersByTransactionId: Record<number, Order>
  userOrdersBySecurityId: Record<number, Order[]>
  acceptedOrders: Record<string, Order>
  rejectedOrders: Record<string, Order>
  cancelledOrders: Record<string, Order>
  waitingForConfirmationOrders: Record<string, Order>
  resubmitOrders: Record<string, Record<number, Record<OrderType, Order>>>
  fakeTransactionIdMap: Record<number, number>
  hitLiftErrors: Record<number, string>
  listTradeErrors: Record<string, Partial<Error>>
  validations: Array<ValidationResult & { date: Date }>
  ordersFetched: boolean
  selectedValidation: { securityId: number; orderType: OrderType } | undefined
  validationOpen: boolean
}

export const initialState: State = {
  myOrdersIsOpen: {},
  userOrders: {},
  operatorOrders: {},
  userOrdersByTransactionId: {},
  userOrdersBySecurityId: {},
  acceptedOrders: {},
  rejectedOrders: {},
  cancelledOrders: {},
  waitingForConfirmationOrders: {},
  resubmitOrders: {},
  fakeTransactionIdMap: {},
  hitLiftErrors: {},
  listTradeErrors: {},
  validations: [],
  ordersFetched: false,
  selectedValidation: undefined,
  validationOpen: false
}

const updateValidation = (
  validations: State['validations'],
  validation: ValidationResult
) =>
  addOrUpdateElement(
    validations,
    { ...validation, date: new Date() },
    (firstValidation, secondValidation) =>
      firstValidation.securityId === secondValidation.securityId &&
      firstValidation.orderType === secondValidation.orderType
  ).filter((v) => v.error !== undefined)

const checkValidation = (
  errorsCrossedFind: Order | undefined,
  validations: State['validations']
) => {
  if (errorsCrossedFind) {
    const validationResult: ValidationResult = {
      securityId: errorsCrossedFind.securityId,
      orderType: errorsCrossedFind.type,
      error: errorsCrossedFind.statusReason,
      isin: errorsCrossedFind.isin
    }
    return updateValidation(validations, validationResult)
  } else {
    return validations
  }
}

export default (state = initialState, action: OrderAction): State => {
  switch (action.type) {
    case 'order.setMyOrdersOpen':
      return {
        ...state,
        myOrdersIsOpen: {
          ...state.myOrdersIsOpen,
          [action.payload.gridIndex]: action.payload.open
        }
      }
    case 'order.updateValidation':
      return {
        ...state,
        validations: updateValidation(state.validations, action.payload)
      }
    case 'order.updateValidations':
      return {
        ...state,
        validations: action.payload.reduce(updateValidation, state.validations)
      }
    case 'order.setValidation':
      return {
        ...state,
        selectedValidation: {
          securityId: action.payload.securityId,
          orderType: action.payload.orderType
        }
      }
    case 'order.clearMyOrders':
      return {
        ...state,
        userOrders: {},
        userOrdersByTransactionId: {},
        userOrdersBySecurityId: {}
      }

    case 'order.resetValidations':
      return { ...state, validations: [] }
    case 'order.createTempAggressorOrder':
      return {
        ...state,
        userOrdersByTransactionId:
          updateUserOrdersByTransactionIdTempAggressorOrder(
            state.userOrdersByTransactionId,
            action.payload
          )
      }
    case 'order.createTempOrder':
      return {
        ...state,
        userOrdersByTransactionId: updateUserOrdersByTransactionIdTempOrder(
          state.userOrdersByTransactionId,
          action.payload
        )
      }
    case 'order.submit':
      if (
        !action.payload.listId ||
        !state.listTradeErrors[action.payload.orderId]
      ) {
        return state
      }
      const { [action.payload.orderId]: deleted, ...ltErrors } =
        state.listTradeErrors
      return { ...state, listTradeErrors: ltErrors }

    case 'order.submitFailure':
      const order = state.userOrders.hasOwnProperty(action.payload.orderId)
        ? state.userOrders[action.payload.orderId]
        : undefined
      if (order) {
        const submitFailureRetval = addOrUpdateOrderNew(
          state.userOrders,
          state.userOrdersByTransactionId,
          state.userOrdersBySecurityId,
          state.acceptedOrders,
          state.rejectedOrders,
          state.cancelledOrders,
          state.waitingForConfirmationOrders,
          state.resubmitOrders,
          { ...order, status: 'error' }
        )
        return {
          ...state,
          userOrders: submitFailureRetval.userOrders,
          userOrdersBySecurityId: submitFailureRetval.userOrdersBySecurityId,
          acceptedOrders: submitFailureRetval.acceptedOrders,
          rejectedOrders: submitFailureRetval.rejectedOrders,
          cancelledOrders: submitFailureRetval.cancelledOrders,
          waitingForConfirmationOrders:
            submitFailureRetval.waitingForConfirmationOrders,
          resubmitOrders: submitFailureRetval.resubmitOrders,
          userOrdersByTransactionId:
            submitFailureRetval.userOrdersByTransactionId
        }
      } else {
        if (action.payload.listId) {
          return {
            ...state,
            listTradeErrors: {
              ...state.listTradeErrors,
              [action.payload.orderId]: action.payload.error
            }
          }
        }
        return {
          ...state,
          hitLiftErrors: updateHitLiftErrors(
            state.hitLiftErrors,
            action.payload.transactionId,
            action.payload.error
          )
        }
      }
    case 'order.setOrderError':
      const orderToUpdate = state.userOrders.hasOwnProperty(action.payload)
        ? state.userOrders[action.payload]
        : undefined
      if (orderToUpdate) {
        const setOrderErrorRetval = addOrUpdateOrderNew(
          state.userOrders,
          state.userOrdersByTransactionId,
          state.userOrdersBySecurityId,
          state.acceptedOrders,
          state.rejectedOrders,
          state.cancelledOrders,
          state.waitingForConfirmationOrders,
          state.resubmitOrders,
          { ...orderToUpdate, status: 'error' }
        )
        return {
          ...state,
          userOrders: setOrderErrorRetval.userOrders,
          userOrdersBySecurityId: setOrderErrorRetval.userOrdersBySecurityId,
          acceptedOrders: setOrderErrorRetval.acceptedOrders,
          rejectedOrders: setOrderErrorRetval.rejectedOrders,
          cancelledOrders: setOrderErrorRetval.cancelledOrders,
          waitingForConfirmationOrders:
            setOrderErrorRetval.waitingForConfirmationOrders,
          resubmitOrders: setOrderErrorRetval.resubmitOrders,
          userOrdersByTransactionId:
            setOrderErrorRetval.userOrdersByTransactionId
        }
      } else {
        return state
      }
    case 'order.cancel':
      const orderToCancel = state.userOrders[action.payload.orderId]
      const cancelRetval = addOrUpdateOrderNew(
        state.userOrders,
        state.userOrdersByTransactionId,
        state.userOrdersBySecurityId,
        state.acceptedOrders,
        state.rejectedOrders,
        state.cancelledOrders,
        state.waitingForConfirmationOrders,
        state.resubmitOrders,
        { ...orderToCancel }
      )
      return {
        ...state,
        userOrders: cancelRetval.userOrders,
        userOrdersBySecurityId: cancelRetval.userOrdersBySecurityId,
        acceptedOrders: cancelRetval.acceptedOrders,
        rejectedOrders: cancelRetval.rejectedOrders,
        cancelledOrders: cancelRetval.cancelledOrders,
        waitingForConfirmationOrders: cancelRetval.waitingForConfirmationOrders,
        resubmitOrders: cancelRetval.resubmitOrders,
        userOrdersByTransactionId: cancelRetval.userOrdersByTransactionId
      }
    case 'order.cancelFailure':
      const orderFailure = state.userOrders[action.payload.orderId]

      const cancelFailureRetval = addOrUpdateOrderNew(
        state.userOrders,
        state.userOrdersByTransactionId,
        state.userOrdersBySecurityId,
        state.acceptedOrders,
        state.rejectedOrders,
        state.cancelledOrders,
        state.waitingForConfirmationOrders,
        state.resubmitOrders,
        { ...orderFailure, status: 'error' }
      )
      return {
        ...state,
        userOrders: cancelFailureRetval.userOrders,
        userOrdersBySecurityId: cancelFailureRetval.userOrdersBySecurityId,
        acceptedOrders: cancelFailureRetval.acceptedOrders,
        rejectedOrders: cancelFailureRetval.rejectedOrders,
        cancelledOrders: cancelFailureRetval.cancelledOrders,
        waitingForConfirmationOrders:
          cancelFailureRetval.waitingForConfirmationOrders,
        resubmitOrders: cancelFailureRetval.resubmitOrders,
        userOrdersByTransactionId: cancelFailureRetval.userOrdersByTransactionId
      }
    case 'order.addOrUpdateUserOrders':
      let newUserOrders = state.userOrders
      let newUserOrdersBySecurityId = state.userOrdersBySecurityId
      let newUserOrdersByTransactionId = state.userOrdersByTransactionId

      const addOrUpdateUserOrdersRetval = addOrUpdateOrderNewBatch(
        newUserOrders,
        newUserOrdersByTransactionId,
        newUserOrdersBySecurityId,
        state.acceptedOrders,
        state.rejectedOrders,
        state.cancelledOrders,
        state.waitingForConfirmationOrders,
        state.resubmitOrders,
        action.payload
      )
      newUserOrders = addOrUpdateUserOrdersRetval.userOrders
      newUserOrdersBySecurityId =
        addOrUpdateUserOrdersRetval.userOrdersBySecurityId
      newUserOrdersByTransactionId =
        addOrUpdateUserOrdersRetval.userOrdersByTransactionId

      const errorsCrossed = action.payload.find(
        ({ status }) => status === 'error'
      )
      return {
        ...state,
        userOrders: newUserOrders,
        userOrdersBySecurityId: newUserOrdersBySecurityId,
        userOrdersByTransactionId: newUserOrdersByTransactionId,
        acceptedOrders: addOrUpdateUserOrdersRetval.acceptedOrders,
        rejectedOrders: addOrUpdateUserOrdersRetval.rejectedOrders,
        cancelledOrders: addOrUpdateUserOrdersRetval.cancelledOrders,
        waitingForConfirmationOrders:
          addOrUpdateUserOrdersRetval.waitingForConfirmationOrders,
        resubmitOrders: addOrUpdateUserOrdersRetval.resubmitOrders,
        ordersFetched: true,
        validations: checkValidation(errorsCrossed, state.validations)
      }
    case 'order.addOrUpdateOperatorOrders':
      return {
        ...state,
        operatorOrders: addOrUpdateNewOperatorOrders(
          state.operatorOrders,
          action.payload
        )
      }

    case 'order.mapFakeTransactionId':
      const mapFakeTransactionIdRetval =
        replaceOrderWithFakeTransactionIdInStateNew(
          state.userOrdersByTransactionId,
          state.userOrders,
          action.payload.fakeTransactionId,
          action.payload.transactionId
        )
      return {
        ...state,
        userOrdersByTransactionId:
          mapFakeTransactionIdRetval.userOrdersByTransactionId,
        userOrders: mapFakeTransactionIdRetval.userOrders,
        fakeTransactionIdMap: updateFakeTransactionIdMap(
          state.fakeTransactionIdMap,
          action.payload.fakeTransactionId,
          action.payload.transactionId
        )
      }
    case 'order.createOrderError': // error from the backend
      return {
        ...state,
        validations: [
          ...state.validations,
          {
            securityId: action.payload.securityId,
            orderType: action.payload.orderType,
            error: action.payload.err.message,
            isin: action.payload.isin,
            date: new Date()
          }
        ]
      }
    case 'order.createOrderBulkError': // error from the backend
      return {
        ...state,
        validations: state.validations.concat(
          action.payload.error.map((a: any) => {
            return {
              securityId: a.error.payload.securityId,
              orderType: a.error.payload.orderType,
              error: a.error.payload.err.message,
              isin: a.error.payload.isin,
              date: a.date
            }
          })
        )
      }
    case 'order.setValidationOpen':
      return {
        ...state,
        validationOpen: action.payload.validationOpen
      }
    case 'order.loadResubmitOrders':
      return {
        ...state,
        resubmitOrders: action.payload
      }

    default:
      return state
  }
}
