import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useOpenFin } from '../../app/openFinContext'
import BondList from '../../containers/BondList/BondList'
import { copyAppStyles } from './helpers'

interface Props {
  gridIndex: number
}

const popupElementId = 'watchlistPopupRoot'

export const WatchlistPopoutHost = (props: any) => {
  return <div style={{ height: '100%', display: 'flex' }} id={popupElementId} />
}

const WatchlistPopout: FC<Props> = ({ gridIndex }) => {
  const { fin, createOpenfinWindow } = useOpenFin()
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
      }
    }
  }, [])

  useEffect(() => {
    fin.desktop.System.getMousePosition((mousePosition: any) => {
      renderWindow(mousePosition.left, mousePosition.top)
    })
  }, [gridIndex])

  const renderWindow = (left: number, top: number) => {
    if (externalWindow) {
      externalWindow.setAsForeground()
    } else {
      createOpenfinWindow(`Watchlist/${gridIndex}`, left, top, false, true)
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )

            copyAppStyles(webWindow)

            extWindow.on('close-requested', (e: any) => {
              extWindow.hide()
            })
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.hide()
            }
          }
        })
        /* eslint-disable no-console */
        .catch((err) => console.log(err))
    }
  }

  return (
    containerElement &&
    externalWindow &&
    ReactDOM.createPortal(<BondList gridIndex={gridIndex} />, containerElement)
  )
}

export default WatchlistPopout
