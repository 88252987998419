import {
  User,
  USER_ADD,
  USER_FETCH,
  USER_FETCH_FAIL,
  USER_SET_SELECTED_USER,
  UserAddUserAction,
  UserFetchAction,
  UserFetchFailAction,
  UserSetSelectedUserAction
} from './types'

export const userFetch = (): UserFetchAction => ({
  type: USER_FETCH
})

export const userAddUser = (users: User[]): UserAddUserAction => ({
  type: USER_ADD,
  payload: users
})

export const userFetchFail = (error: any): UserFetchFailAction => ({
  type: USER_FETCH_FAIL,
  payload: error,
  error: true
})

export const setSelectedUser = (userId: number): UserSetSelectedUserAction => ({
  type: USER_SET_SELECTED_USER,
  payload: userId
})
