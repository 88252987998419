import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { JSXElementConstructor } from 'react'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'

interface Props {
  // undefined means multiple fields are used to figure out what to show
  fieldName: keyof ListTradingSecurity | undefined
}

export type ListTradingSecurityRendererProps =
  CustomCellRendererProps<SecurityStaticData> & Props

const FieldRenderer = ({
  data,
  fieldName
}: ListTradingSecurityRendererProps) => {
  const security = useGetListTradingSecurity(data?.id)
  if (!data || !security || !fieldName) return <></>
  return <span>{security[fieldName]}</span>
}

const ListTradingSecurityFieldRenderer = (
  fieldName: keyof ListTradingSecurity | undefined,
  Component: JSXElementConstructor<ListTradingSecurityRendererProps> = FieldRenderer
) => {
  return (props: CustomCellRendererProps<SecurityStaticData>) => (
    <Component {...props} fieldName={fieldName} />
  )
}

export default ListTradingSecurityFieldRenderer
