import React from 'react'
import styles from './HeaderButton.module.scss'

import cx from 'classnames'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SpanProps = React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>

export interface Props extends SpanProps {
  active: boolean
  toggle: () => void
  faIcon: IconProp
  dataFor?: string
  className?: string
}

const HeaderButton = ({ active, toggle, faIcon, dataFor, ...props }: Props) => {
  return (
    <span
      {...props}
      className={cx(
        props.className,
        styles.headerIcon,
        active && styles.active
      )}
      onClick={toggle}
      data-for={dataFor}
    >
      <FontAwesomeIcon icon={faIcon} />
    </span>
  )
}

export default HeaderButton
