import { BookAction } from './actions'
import { Book } from './types'

export interface State {
  books: Book[] | undefined
  pending: boolean
  error: boolean
}

const initialState: State = {
  books: undefined,
  pending: false,
  error: false
}

export default (state = initialState, action: BookAction): State => {
  switch (action.type) {
    case 'books.fetchBooks':
      return {
        ...state,
        pending: true,
        error: false
      }

    case 'books.fetchBooksFailure':
      return {
        ...state,
        pending: false,
        error: true
      }

    case 'books.addOrUpdateBooks':
      return {
        ...state,
        pending: false,
        error: false,
        books: [...action.payload]
      }

    default:
      return state
  }
}
