import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import { catchError, mergeMap, mergeMapTo } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { setOrderError } from '../order/actions'
import { OrderResponse } from '../order/types'
import { logError } from '../ws/actions'
import { ConfirmOrderAction, RejectOrderAction } from './actions'

const cancelOrderEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('lastLook.rejectOrder'),
    mergeMap((action: RejectOrderAction) => {
      return getHub()
        .invoke<OrderResponse>('RejectOrder', action.payload)
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) => of(setOrderError(action.payload), logError(err)))
        )
    })
  )

const confirmOrderEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('lastLook.confirmOrder'),
    mergeMap((action: ConfirmOrderAction) => {
      return getHub()
        .invoke<OrderResponse>(
          'ConfirmOrder',
          action.payload.id,
          action.payload.spotCrossSelection
        )
        .pipe(
          mergeMapTo(EMPTY),
          catchError((err) =>
            of(setOrderError(action.payload.id), logError(err))
          )
        )
    })
  )

export default combineEpics(cancelOrderEpic, confirmOrderEpic)
