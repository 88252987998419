import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from '../../../helpers/hooks'
import {
  getDepthOfMarketOrder,
  getDepthOfMarketOrderByInfo
} from '../../../store/depthOfMarket/selectors'
import {
  getOrderById,
  getOrderByTransactionId
} from '../../../store/order/selectors'
import { Order } from '../../../store/order/types'
import {
  fetchDataForSecurityModal,
  unsubscribeFetchDataForSecurityModal
} from '../../../store/windows/actions'
import {
  getAggressorWindowOrder,
  getSecurityModalInfo
} from '../../../store/windows/selectors'
import { AggressorWindowInfo } from '../../../store/windows/types'

export const useAggressorOrder = (initialOrderId: Order['id']) => {
  const getAggressorOrder = useSelector(getAggressorWindowOrder)
  const getOrder = useSelector(getOrderById)

  const [aggressorOrderId, setAggressorOrderId] = useState<
    Order['id'] | undefined
  >()

  const initialAggressorOrder = getAggressorOrder(initialOrderId)

  useEffect(() => {
    if (
      initialAggressorOrder &&
      initialAggressorOrder?.id !== '' &&
      (!aggressorOrderId || initialAggressorOrder.status !== 'creationPending')
    ) {
      setAggressorOrderId(initialAggressorOrder?.id)
    }
  }, [aggressorOrderId, initialAggressorOrder])

  return aggressorOrderId ? getOrder(aggressorOrderId) : initialAggressorOrder
}

export const useAggressorModalOrders = (windowInfo: AggressorWindowInfo) => {
  const getDOMOrderById = useSelector(getDepthOfMarketOrder)
  const getDOMOrderByInfo = useSelector(getDepthOfMarketOrderByInfo)

  const domOrder = getDOMOrderById(
    windowInfo.initialOrderId,
    windowInfo.securityId
  )
  const firstAggressorOrder = useAggressorOrder(windowInfo.initialOrderId)

  const [firstInitialOrder, setFirstInitialOrder] = useState(domOrder)
  useEffect(() => {
    if (
      domOrder !== undefined ||
      firstAggressorOrder?.status === 'creationPending'
    ) {
      setFirstInitialOrder(domOrder)
    }
  }, [domOrder, firstAggressorOrder])

  const initialOrderType = useMemo(() => firstInitialOrder?.type, [])!
  const initialOrderPrice = useMemo(() => firstInitialOrder?.price, [])!
  const initialOrderSize = useMemo(() => firstInitialOrder?.size, [])!

  const initialOrderIfCancelled = getDOMOrderByInfo(
    windowInfo.securityId,
    initialOrderType,
    initialOrderPrice,
    initialOrderSize
  )

  const initialOrder =
    firstAggressorOrder?.status === 'cancelled'
      ? initialOrderIfCancelled
      : firstInitialOrder

  return {
    initialOrder
  }
}

export const getAggressorOrderForAggressorWindow = (transactionId: number) => {
  const initialAggressorOrder = useSelector(getOrderByTransactionId)(
    transactionId
  )
  const [currentAggressorOrder, setCurrentAggressorOrder] = useState(
    initialAggressorOrder
  )
  useEffect(() => {
    if (initialAggressorOrder !== undefined) {
      setCurrentAggressorOrder(initialAggressorOrder)
    }
  }, [initialAggressorOrder])
  return currentAggressorOrder
}

export const useSecurityModalInfo = (orderId: Order['id'], size: number) => {
  const securityModalInfo = useSelector(getSecurityModalInfo)(orderId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDataForSecurityModal(orderId, size))

    return () => {
      dispatch(unsubscribeFetchDataForSecurityModal(orderId))
    }
  }, [orderId, size])

  return securityModalInfo
}

export const useValueChangeAlert = <T>(
  checkVal: string | undefined,
  exceptedStrings: string[],
  value: T,
  buySellAmount: number
) => {
  const previousValue = usePrevious(value)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (
      checkVal === undefined ||
      exceptedStrings.indexOf(checkVal) < 0 ||
      (checkVal === 'creationPending' && !buySellAmount)
    ) {
      if (value !== previousValue && previousValue !== undefined) {
        setShowAlert(true)
      }
    }
  }, [value])

  return showAlert
}
