import React, { FC, useCallback, useEffect, useState } from 'react'

import {
  faExclamationTriangle,
  faSpinner,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  tradeBlotterFetch,
  tradeBlotterFetchCancel
} from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import Modal from '../../GenericModal/Modal'
import styles from './TradeConfirm.module.scss'

interface Props {
  tradeConfirmId: number
  handleTradeConfirmClick: (id: string | null) => void
}

const TradeConfirm: FC<Props> = ({
  tradeConfirmId,
  handleTradeConfirmClick
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [tradeConfirmText, setTradeConfirmText] = useState(null)

  const dispatch = useAppDispatch()
  const tradeData = useAppSelector(getActivityTradeBlotter)
  const tradeDataError = useAppSelector(getActivityTradeBlotterError)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  useEffect(() => {
    if (tradeDataError) {
      setError(true)
      dispatch(tradeBlotterFetchCancel())
      setLoading(false)
    }

    if (tradeData && tradeConfirmId) {
      const tradeConfirmData = tradeData.find(
        (trade) => trade.orderId === Number(tradeConfirmId)
      )
      if (tradeConfirmData) {
        setTradeConfirmText(tradeConfirmData.confirmText)
      }
      setLoading(false)
    }
  }, [tradeData, tradeDataError])

  const onTradeConfirmClick = useCallback(() => {
    dispatch(tradeBlotterFetchCancel())
    handleTradeConfirmClick(null)
  }, [])

  return (
    <Modal>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faTimes} onClick={onTradeConfirmClick} />
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </div>
      )}

      {((!loading && error) || (!loading && !tradeConfirmText)) && (
        <div className={styles.loadingContainer}>
          <FontAwesomeIcon icon={faExclamationTriangle} /> An unexpected error
          occurred.
        </div>
      )}

      {tradeConfirmText && (
        <pre className={styles.confirmText}>{tradeConfirmText}</pre>
      )}
    </Modal>
  )
}

export default TradeConfirm
