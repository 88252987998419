export const StringToBBMISINList = (bbmIsinList: string) => {
  const arr = []
  const lines = bbmIsinList.split('\n')
  const errors = []
  let lineNum = 0
  for (const line of lines) {
    const lineItems = line.split('\t')
    lineNum++
    if (lineItems.length === 1) {
      arr.push({ cusipOrIsin: lineItems[0], bbmisin: '' })
    } else if (lineItems.length === 2) {
      arr.push({ cusipOrIsin: lineItems[0], bbmisin: lineItems[1] })
    } else if (lineItems.length > 2) {
      errors.push('Unable to process line #' + lineNum + ' ' + lineItems[0])
    }
  }
  return { bbmIsinList: arr, errors }
}
