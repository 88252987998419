import { createSelector } from 'reselect'
import { namespace } from '.'
import { getAllOrNoneStatus } from '../helpers'
import { getUserOrders } from '../order/selectors'
import { Order, OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { getDisplayedSecurityIds } from '../securities/selectors'
import {
  getStagedOrderBySecurityForOrderType,
  getStagedOrdersByTypeToArray,
  getStagedOrdersToArray
} from '../stagedOrders/selectors'
import { StagedOrder } from '../stagedOrders/types'
import { includesCheckedOrder } from './helpers'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getCheckedOrders = createSelector(
  [getState],
  state => state.checkedOrders
)

export const isOrderChecked = createSelector(
  getCheckedOrders,
  checkedOrders => (securityId: Security['id'], orderType: OrderType) =>
    includesCheckedOrder(checkedOrders, { securityId, orderType })
)

export const getOrdersForDisplayedSecurities = createSelector(
  [getUserOrders, getDisplayedSecurityIds],
  (userOrders, displayedSecurityIds) => (
    gridIndex: number,
    orderType: OrderType
  ) =>
    userOrders.filter(
      order =>
        displayedSecurityIds(gridIndex).includes(order.securityId) &&
        order.status === 'pending' &&
        order.type === orderType
    )
)

export const checkedOrdersStatus = createSelector(
  [
    getOrdersForDisplayedSecurities,
    getStagedOrdersByTypeToArray,
    isOrderChecked
  ],
  (getUserOrdersForType, getStagedOrdersByType, isChecked) => (
    gridIndex: number,
    orderType: OrderType
  ) => {
    const orders = [
      ...getUserOrdersForType(gridIndex, orderType).map(order => ({
        securityId: order.securityId,
        orderType: order.type
      })),
      ...getStagedOrdersByType(orderType)
    ]
    return getAllOrNoneStatus(orders, order =>
      isChecked(order.securityId, order.orderType)
    )
  }
)

export const getNumberOfCheckedOrders = createSelector(
  [getCheckedOrders],
  checkedOrders => checkedOrders.length
)

export const getNumberOfCancellableOrders = createSelector(
  [getUserOrders, isOrderChecked],
  (orders, isChecked) =>
    orders.filter(
      o => o.status === 'pending' && isChecked(o.securityId, o.type)
    ).length
)

export const getNumberOfSubmitableOrders = createSelector(
  [getNumberOfCheckedOrders, getNumberOfCancellableOrders],
  (numberOfCheckedOrders, numberOfCancellableOrders) => {
    return numberOfCheckedOrders - numberOfCancellableOrders
  }
)

const getcheckedLiveOrder = createSelector(
  [getUserOrders, isOrderChecked],
  (userOrders, isChecked) =>
    userOrders
      .filter(
        order =>
          order.status === 'pending' && isChecked(order.securityId, order.type)
      )
      .map(
        order =>
          ({
            id: order.id,
            securityId: order.securityId,
            orderType: order.type,
            price: order.price,
            spread: order.spread,
            size: order.size
          } as StagedOrder & { id: Order['id'] })
      )
)

export const getCheckedStagedOrderIsNotLive = createSelector(
  [getCheckedOrders, getcheckedLiveOrder, getStagedOrderBySecurityForOrderType],
  (checkedOrders, liveOrders, getStagedOrder) =>
    checkedOrders
      .map(checkedOrder =>
        getStagedOrder(checkedOrder.securityId, checkedOrder.orderType)
      )
      .filter(Boolean)
      .filter(
        stagedOrder =>
          !liveOrders.some(
            liveOrder =>
              liveOrder.securityId === stagedOrder?.securityId &&
              liveOrder.orderType === stagedOrder.orderType
          )
      ) as StagedOrder[]
)

export const getStagedOrderWithoutCheckedOrder = createSelector(
  [getCheckedOrders, getcheckedLiveOrder, getStagedOrdersToArray],
  (checkedOrders, liveOrders, stagedOrders) =>
    stagedOrders
      .filter(
        stagedOrder =>
          !liveOrders.some(
            liveOrder =>
              liveOrder.securityId === stagedOrder?.securityId &&
              liveOrder.orderType === stagedOrder.orderType
          )
      )
      .filter(
        stagedOrder =>
          !checkedOrders.some(
            checkedOrder =>
              checkedOrder.securityId === stagedOrder?.securityId &&
              checkedOrder.orderType === stagedOrder.orderType
          )
      )
)

export const getNumberOfCheckedStagedOrderIsNotLive = createSelector(
  [getCheckedStagedOrderIsNotLive],
  checkedStagedOrderIsNotLive => checkedStagedOrderIsNotLive.length
)
