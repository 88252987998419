import { CustomCellRendererProps } from '@ag-grid-community/react'
import React from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'

const SizeOrInterestFieldRenderer = ({
  data,
  context: { watchlistId }
}: CustomCellRendererProps<SecurityStaticData, any, TradingListContext>) => {
  const { order } = useWorkingListTradingOrder(
    watchlistId,
    data?.id,
    data?.isin
  )
  const security = useGetListTradingSecurity(data?.id)

  return <div>{security?.remainingInterest || order.size || ''}</div>
}

export default SizeOrInterestFieldRenderer
