import { UploadOrdersActions } from './actions'
import { DropdownState, ErrorInfo } from './types'

export interface State {
  invalidIdentfiers: ErrorInfo[] | undefined
  newWatchlistTransactionId: { [gridIndex: number]: number | undefined }
  pending: boolean
  error: boolean
  dropdownState: { [gridIndex: number]: DropdownState }
  serverErrorMessage: string
  serverError: boolean
}

const initialState: State = {
  invalidIdentfiers: undefined,
  newWatchlistTransactionId: {},
  pending: false,
  error: false,
  dropdownState: {},
  serverErrorMessage: '',
  serverError: false
}

export default (state = initialState, action: UploadOrdersActions): State => {
  switch (action.type) {
    case 'upload.createWatchlistWithEnhancedUploadRows':
    case 'upload.uploadEnhancedOrderRows':
      return {
        ...state,
        pending: true,
        error: false,
        invalidIdentfiers: undefined
      }

    case 'upload.resetInvalidIdentifiers':
      return {
        ...state,
        invalidIdentfiers: undefined,
        pending: false,
        error: false
      }
    case 'upload.resetUploadOrdersServerError':
      return {
        ...state,
        serverErrorMessage: '',
        serverError: false
      }

    case 'upload.uploadOrdersFailure':
      return {
        ...state,
        invalidIdentfiers: action.payload,
        pending: false,
        error: !!action.payload
      }
    case 'upload.uploadOrdersServerError':
      return {
        ...state,
        pending: false,
        serverError: true,
        serverErrorMessage: action.payload
      }

    case 'upload.setNewWatchlistTransactionId':
      return {
        ...state,
        pending: false,
        newWatchlistTransactionId: {
          ...state.newWatchlistTransactionId,
          [action.payload.gridIndex]: action.payload.transactionId
        }
      }

    case 'upload.resetNewWatchlistTransactionId':
      return {
        ...state,
        newWatchlistTransactionId: {
          ...state.newWatchlistTransactionId,
          [action.payload.gridIndex]: undefined
        }
      }

    case 'upload.toggleDropdownState':
      const pending = action.payload.state === 'closed' ? false : state.pending
      return {
        ...state,
        pending,
        dropdownState: {
          ...state.dropdownState,
          [action.payload.gridIndex]: action.payload.state
        }
      }

    default:
      return state
  }
}
