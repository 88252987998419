import { Action } from 'redux'

const NAMESPACE = `userEditorData.`

export const USEREDITORDATA_SAVE = `${NAMESPACE}saveUserEditorData`
export interface UserEditorDataSaveAction extends Action {
  type: typeof USEREDITORDATA_SAVE
  payload: UserEditorData
}

export const USEREDITORDATA_SAVE_SUCCESS = `${NAMESPACE}saveUserEditorDataSuccess`
export interface UserEditorDataSaveSuccessAction extends Action {
  type: typeof USEREDITORDATA_SAVE_SUCCESS
  payload: any
}

export const USEREDITORDATA_SAVE_FAILURE = `${NAMESPACE}saveUserEditorDataFailure`
export interface UserEditorDataSaveFailureAction extends Action {
  type: typeof USEREDITORDATA_SAVE_FAILURE
  payload: any
  error: boolean
}

export const USEREDITORDATA_FETCH = `${NAMESPACE}fetchUserEditorData`
export interface UserEditorDataFetchAction extends Action {
  type: typeof USEREDITORDATA_FETCH
  payload: number
}

export const ADD_USEREDITORDATA = `${NAMESPACE}addUserEditorData`
export interface AddUserEditorDataAction extends Action {
  type: typeof ADD_USEREDITORDATA
  payload: UserEditorData
}

export const USEREDITORDATA_FETCH_FAILURE = `${NAMESPACE}fetchUserEditorDataFailure`
export interface UserEditorDataFetchFailureAction extends Action {
  type: typeof USEREDITORDATA_FETCH_FAILURE
  payload: any
  error: boolean
}

export type UserEditorDataAction =
  | UserEditorDataSaveAction
  | UserEditorDataSaveSuccessAction
  | UserEditorDataSaveFailureAction
  | UserEditorDataFetchAction
  | AddUserEditorDataAction
  | UserEditorDataFetchFailureAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface UserEditorData {
  id: number
  userName: string
  possibleUserClassifications: UserClassificationInfo[]
  possibleCustomerRecords: CustomerInfo[]
  possibleBrokers: UserInfo[]
  cust: CustomerInfo
  userClassification: UserClassificationInfo
  emailAddress: string
  isAdmin: boolean
  isBackOffice: boolean
  isBroker: boolean
  doNotAddToAD: boolean
  ableToSeeTradersTheyCover: boolean
  isWebUser: boolean
  ableToSeeAllTradersAtFirm: boolean
  logonName: string
  loginPassword: string
  automaticallyEnabledNewAccounts: boolean
  subAccounts: SubAccountInfo[]

  broker1A: UserInfo
  broker1APct: string
  broker1B: UserInfo
  broker1BPct: string
  broker1C: UserInfo
  broker1CPct: string

  broker2A: UserInfo
  broker2APct: string
  broker2B: UserInfo
  broker2BPct: string
  broker2C: UserInfo
  broker2CPct: string

  broker3A: UserInfo
  broker3APct: string
  broker3B: UserInfo
  broker3BPct: string
  broker3C: UserInfo
  broker3CPct: string

  salesPersonWhoCovers1: UserInfo
  salesPersonWhoCovers2: UserInfo
  salesPersonWhoCovers3: UserInfo

  currencies: string[]
  validMacs: string
  bloombergUUID: string
  qr: string
  canRunReports: boolean
  highlightWhenNotLogged: boolean
  markAsDeleted: boolean
  lastClickThroughVersion: string
  defaultFIXAccountAlias: string
  maxSingleOrderLimit: string
}

export interface UserClassificationInfo {
  id: number
  userClassName: string
}

export interface CustomerInfo {
  id: number
  customerName: string
}

export interface UserInfo {
  id: number
  userName: string
}

export const NoBrokerSet: UserInfo = { id: 0, userName: '' }

export interface SubAccountInfo {
  custId: number
  custName: string
  description: string
  enabled: boolean
  isDefault: boolean
}
