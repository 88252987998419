import { addOrUpdateTrade } from './helpers'
import {
  Trade,
  TRADEBLOTTER_ADD_TRADES,
  TRADEBLOTTER_FETCH,
  TRADEBLOTTER_FETCH_CANCEL,
  TRADEBLOTTER_FETCH_FAIL,
  TradeBlotterActions
} from './types'

export interface State {
  activityTradeBlotter?: Trade[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  activityTradeBlotter: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: TradeBlotterActions): State => {
  switch (action.type) {
    case TRADEBLOTTER_FETCH:
    case TRADEBLOTTER_FETCH_CANCEL:
      return {
        ...state,
        pending: true,
        error: false,
        activityTradeBlotter: undefined
      }

    case TRADEBLOTTER_FETCH_FAIL:
      return {
        activityTradeBlotter: undefined,
        ...state,
        pending: false,
        error: true
      }

    case TRADEBLOTTER_ADD_TRADES:
      let items: Trade[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.activityTradeBlotter) {
        return {
          ...state,
          activityTradeBlotter: items
        }
      }

      return {
        ...state,
        // activityTradeBlotter: [...state.activityTradeBlotter, ...items]
        activityTradeBlotter: addOrUpdateTrade(
          state.activityTradeBlotter,
          items
        )
      }

    default:
      return state
  }
}
