import { createSelector } from 'reselect'
import { namespace } from '.'
import { COUPON, MATURITY } from '../../containers/BondList/columnDefs'
import { replaceColumn } from './helpers'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

const SECURITY = 'SECURITY'
const replaceSecurityColumn = replaceColumn(SECURITY, [COUPON, MATURITY])

export const getColumnsOrder = createSelector(
  [getState],
  state => (gridIndex: number) => {
    const {
      columnsOrder: { [gridIndex]: columnsOrder },
      displayedColumns: { [gridIndex]: displayedColumns }
    } = state

    if (!columnsOrder && !displayedColumns) {
      return undefined
    } else if (!columnsOrder && displayedColumns) {
      return replaceSecurityColumn(displayedColumns)
    } else if (columnsOrder && !displayedColumns) {
      return replaceSecurityColumn(columnsOrder)
    } else {
      const cols = [
        ...columnsOrder!.filter(col => displayedColumns!.includes(col)),
        ...displayedColumns!.filter(col => !columnsOrder!.includes(col))
      ]
      return replaceSecurityColumn(cols)
    }
  }
)
