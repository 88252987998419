import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearSettings } from '../../store/settings/actions'

// temp hook to allow resetting user settings with a keyboard shortcut
export const useResetStoredSettings = (isOn: boolean = true) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      // ctrl + alt + r
      if (e.key === 'r' && e.altKey && (e.ctrlKey || e.metaKey)) {
        dispatch(clearSettings())
      }
    }
    if (isOn) {
      window.addEventListener('keyup', listener)
    }
    return () => window.removeEventListener('keyup', listener)
  }, [isOn])
}
