import {
  ApiCallState,
  callFailed,
  callInitiated,
  callSucceeded
} from '../../types'
import { addOrUpdateElement } from '../helpers'
import { WatchListsAction } from './actions'
import { WatchList, WatchlistDetails } from './types'

export interface State {
  watchLists: WatchList[] | undefined
  watchlistsDetails: Record<number, WatchlistDetails>
  pending: boolean
  error: boolean
  detailsCallState: ApiCallState
  lastCheckedSecurity: Record<number, number>
  checkedSecurities: Record<number, Set<number>>
  adminWatchlists: WatchList[] | undefined
  failedAdminCusipsAndIsins: Record<number, string[]>
}

export const initialState: State = {
  watchLists: undefined,
  watchlistsDetails: {},
  detailsCallState: callSucceeded,
  pending: false,
  error: false,
  lastCheckedSecurity: {},
  checkedSecurities: {},
  adminWatchlists: undefined,
  failedAdminCusipsAndIsins: {}
}

export const reducer = (
  state = initialState,
  action: WatchListsAction
): State => {
  const watchListMatch = (watchListOne: WatchList, watchListTwo: WatchList) =>
    watchListOne.id === watchListTwo.id
  switch (action.type) {
    case 'watchlist.setLastChecked':
      const updatedLastCheckedSecurity = state.lastCheckedSecurity
      updatedLastCheckedSecurity[action.payload.watchlistId] =
        action.payload.securityId
      return { ...state, lastCheckedSecurity: updatedLastCheckedSecurity }
    case 'watchList.fetchWatchLists':
      return { ...state, pending: true, error: false }
    case 'watchList.fetchWatchListsFailure':
      return { ...state, pending: false, error: true }
    case 'watchList.fetchWatchListsSuccess':
      return {
        ...state,
        watchLists: action.payload.watchlists.reduce(
          (acc, currVal) => addOrUpdateElement(acc, currVal, watchListMatch),
          state.watchLists || []
        ),
        pending: false,
        error: false
      }
    case 'watchList.fetchWatchlistDetails':
      return {
        ...state,
        detailsCallState: callInitiated
      }
    case 'watchList.fetchWatchlistDetailsFailure':
      return {
        ...state,
        detailsCallState: { ...callFailed, status: action.payload }
      }
    case 'watchList.fetchWatchlistDetailsSuccess':
      return {
        ...state,
        watchlistsDetails: {
          ...state.watchlistsDetails,
          [action.payload.watchlistId]: action.payload.watchlistDetails
        },
        detailsCallState: callSucceeded
      }
    case 'watchlist.checkOrUncheckSecurities':
      const updatedSecurities = state.checkedSecurities
      if (action.payload.checked) {
        if (updatedSecurities.hasOwnProperty(action.payload.watchlistId)) {
          action.payload.securityIds.forEach((s) =>
            updatedSecurities[action.payload.watchlistId].add(s)
          )
        } else {
          updatedSecurities[action.payload.watchlistId] = new Set(
            action.payload.securityIds
          )
        }
      } else {
        if (updatedSecurities.hasOwnProperty(action.payload.watchlistId)) {
          action.payload.securityIds.forEach((s) =>
            updatedSecurities[action.payload.watchlistId].delete(s)
          )
        }
      }

      return {
        ...state,
        checkedSecurities: updatedSecurities
      }
    case 'watchlist.resetCheckedSecurities':
      const updatedCheckedSecurities = state.checkedSecurities
      if (updatedCheckedSecurities.hasOwnProperty(action.payload.watchlistId)) {
        delete updatedCheckedSecurities[action.payload.watchlistId]
      }
      const updateLastCheckedSecurity = state.lastCheckedSecurity
      if (
        updateLastCheckedSecurity.hasOwnProperty(action.payload.watchlistId)
      ) {
        delete updateLastCheckedSecurity[action.payload.watchlistId]
      }
      return {
        ...state,
        checkedSecurities: updatedCheckedSecurities,
        lastCheckedSecurity: updateLastCheckedSecurity
      }
    case 'watchlist.deleteWatchlist':
    case 'watchlist.hideWatchlist':
      return {
        ...state,
        watchLists: state.watchLists?.filter((wl) => wl.id !== action.payload),
        // todo: get deleteWatchlistSuccess action to fire from epic
        adminWatchlists: state.adminWatchlists?.filter(
          (watchlist) => watchlist.id !== action.payload
        )
      }

    case 'watchList.fetchAdminWatchLists':
    case 'watchList.fetchAdminWatchlistsByIdentifier':
    case 'watchList.fetchAdminWatchlistsByUser':
      return {
        ...state,
        adminWatchlists: undefined
      }
    case 'watchList.fetchAdminWatchListsSuccess':
    case 'watchList.fetchAdminWatchlistsByIdentifierSuccess':
    case 'watchList.fetchAdminWatchlistsByUserSuccess':
      return {
        ...state,
        adminWatchlists: action.payload.watchlists.reduce(
          (acc, currVal) => addOrUpdateElement(acc, currVal, watchListMatch),
          state.adminWatchlists || []
        )
      }
    case 'watchList.definitionUpdated':
      return {
        ...state,
        adminWatchlists: addOrUpdateElement(
          state.adminWatchlists || [],
          action.payload,
          watchListMatch
        )
      }
    case 'watchList.failedSymbolsAndCusipsFromWatchlistDefinitionUpdate': {
      const failedAdminCusipsAndIsins = state.failedAdminCusipsAndIsins
      return {
        ...state,
        failedAdminCusipsAndIsins: {
          ...failedAdminCusipsAndIsins,
          [action.payload.watchlistId]: action.payload.symbolsAndCusips
        }
      }
    }
    case 'watchList.clearFailedSymbolsAndCusipsFromWatchlistDefinitionUpdate': {
      const failedAdminCusipsAndIsins = state.failedAdminCusipsAndIsins
      return {
        ...state,
        failedAdminCusipsAndIsins: {
          ...failedAdminCusipsAndIsins,
          [action.payload]: []
        }
      }
    }
    default:
      return state
  }
}
