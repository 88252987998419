import { ConvertUSTPrice } from './formatting'

export const flatten = (thing: Record<string, any>, prefix = ''): any =>
  Object.entries(thing).reduce((obj: any, [key, value]: any) => {
    if (value && typeof value === 'object' && !(value instanceof Date)) {
      return { ...obj, [key]: value, ...flatten(value, prefix + key) }
    }
    const firstLetter = key[0].toUpperCase()
    const newKey = prefix ? firstLetter + key.slice(1) : key
    obj[prefix + newKey] = value
    return obj
  }, {})

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

export const objectsAreEqual = <T extends Record<string, unknown>>(
  object1: T | undefined,
  object2: T | undefined
) => {
  if (!object1 || !object2) return !object1 && !object2
  const entries1 = Object.entries(object1)
  const entries2 = Object.entries(object2)
  if (entries1.length !== entries2.length) return false
  for (const [key, value] of entries1) {
    if (typeof value === 'object' && typeof object2[key] === 'object') {
      if (
        !objectsAreEqual(
          value as Record<string, unknown>,
          object2[key] as Record<string, unknown>
        )
      ) {
        return false
      }
    } else {
      if (object2[key] !== value) return false
    }
  }
  return true
}
export const calculateOrderPriceFromInput = (
  input: string,
  isTreasury: boolean
) => {
  const numberPrice = Number(input)
  if (!isNaN(numberPrice) || !isTreasury) return numberPrice || 0 // NaN is falsey, so this will also cast NaN to 0
  return ConvertUSTPrice(input)
}
