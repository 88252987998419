import cx from 'classnames'
import React, { ReactNode } from 'react'
import styles from './StyledFieldset.module.scss'

type Props = {
  legend?: string
  children?: ReactNode
  className?: string
}

const StyledFieldset = ({ legend, children, className }: Props) => {
  const classes = cx(className, styles.fieldset)
  return (
    <fieldset className={classes}>
      {legend && <legend>{legend}</legend>}
      {children}
    </fieldset>
  )
}

export default StyledFieldset
