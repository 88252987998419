import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import Loading from '../../components/Loading/Loading'
import {
  getIsAdmin,
  getWebSettingsLoaded
} from '../../store/webSettings/selectors'

import styles from './adminRouteParent.module.scss'

const AdminRouteParent = () => {
  const isAdmin = useSelector(getIsAdmin)
  const isAdminFullyLoaded = useSelector(getWebSettingsLoaded)

  if (isAdmin) {
    return (
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    )
  }

  if (!isAdminFullyLoaded) {
    return <Loading />
  }

  return (
    <div className={styles.denied}>
      You do not have permission to view this page.
    </div>
  )
}

export default AdminRouteParent
