import cx from 'classnames'
import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../app/openFinContext'
import BondList from '../containers/BondList/BondList'
import DepthOpener from '../containers/Openfin/DepthOpener'
import WatchlistPopout from '../containers/Openfin/Watchlist'
import { getActivityPanelVisible } from '../store/activity/selectors'
import { unsubscribeGetSecurities } from '../store/securities/actions'
import { getGridIndices } from '../store/securities/selectors'
import { areSettingsLoaded } from '../store/settings/selectors'
import { getDropdownState } from '../store/upload/selectors'
import ActivityNotifications from './Activity/ActivityNotifications'
import ActivityWrapper from './Activity/ActivityWrapper'
import { AggressorAndLastLook } from './AggressorAndLastLook/AggressorAndLastLook'
import styles from './SecuritiesTable.module.scss'
import EnhancedUploadDropdownMenu from './Upload/EnhancedUpload/EnhancedUploadDropdownMenu'

const SecuritiesTable = () => {
  const { fin } = useOpenFin()
  const gridIndices = useSelector(getGridIndices)
  const activityPanelVisible = useSelector(getActivityPanelVisible)
  const settingsLoaded = useSelector(areSettingsLoaded)
  const dropdownState = useSelector(getDropdownState)(0)
  const dispatch = useDispatch()

  const showBondList = useCallback(() => {
    return gridIndices.map((gridIndex) => (
      <div
        key={gridIndex}
        className={cx(styles.bondListWrapper, fin && styles.finWrapper)}
      >
        {(!fin || (fin && gridIndex === 0)) && (
          <BondList gridIndex={gridIndex} />
        )}
        {fin && gridIndex > 0 && <WatchlistPopout gridIndex={gridIndex} />}
      </div>
    ))
  }, [gridIndices])

  useEffect(() => {
    return () => {
      dispatch(unsubscribeGetSecurities())
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div
        className={
          fin
            ? styles.finSecuritiesWrapper
            : cx(
                styles.securitiesWrapper,
                activityPanelVisible || styles.activitiesHidden
              )
        }
      >
        <div className={styles.panelWrapper}>
          {showBondList()}
          <DepthOpener />
          {dropdownState !== 'closed' && (
            <EnhancedUploadDropdownMenu
              gridIndex={0}
              currentState={dropdownState}
            />
          )}
        </div>
      </div>
      <AggressorAndLastLook />
      {fin
        ? settingsLoaded && <ActivityNotifications />
        : settingsLoaded && <ActivityWrapper />}
    </div>
  )
}

export default memo(SecuritiesTable)
