import { Action } from 'redux'

const NAMESPACE = `externalLiquidityStats.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const EXTERNALLIQUIDITYSTATS_FETCH = `${NAMESPACE}fetch`
export interface ExternalLiquidityStatsFetchAction extends Action {
  type: typeof EXTERNALLIQUIDITYSTATS_FETCH
  payload: { venues: number[]; security: string }
}

export const EXTERNALLIQUIDITYSTATS_FETCH_FAIL = `${NAMESPACE}fetchFail`
export interface ExternalLiquidityStatsFetchFailAction extends Action {
  type: typeof EXTERNALLIQUIDITYSTATS_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const EXTERNALLIQUIDITYSTATS_ADD = `${NAMESPACE}addVenue`
export interface ExternalLiquidityStatsAddAction extends Action {
  type: typeof EXTERNALLIQUIDITYSTATS_ADD
  payload: ExternalLiquidityStats[]
}

export const EXTERNALLIQUIDITYSTATS_CLEAR = `${NAMESPACE}clearVenue`
export interface ExternalLiquidityStatsClearAction extends Action {
  type: typeof EXTERNALLIQUIDITYSTATS_CLEAR
}

export type ExternalLiquidityStatsAction =
  | ExternalLiquidityStatsFetchAction
  | ExternalLiquidityStatsAddAction
  | ExternalLiquidityStatsFetchFailAction
  | ExternalLiquidityStatsClearAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface ExternalLiquidityStats {
  id: number
  securityId: number
  boardLabel: string
  sector: string
  productType: string
  ISIN: string
  cusip: string
  submitTime: Date
  venueName: string
  venueSuppressed: boolean
  price: number
  orderAmount: number
  minimumAmount: number
  bbgPricingNumber: string
  bbgOrderBook: string
  bidOffer: string
  currency: string
  showAsUser: string
  showAsCustomer: string
  restricted: boolean
  isLive: boolean
  parameters: string
}
