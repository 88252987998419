import cx from 'classnames'
import React, { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'
import styles from './modal.module.scss'

interface Props {
  body?: string
  children?: ReactNode | ReactNode[]
  shouldCloseOnEsc?: boolean
  shouldCloseOnOutsideClick?: boolean
  customWrapperStyles?: any
}

// // Needed in order to prevent reactmodal from looking for a nonexistant app element
const isTestEnv = import.meta.env.MODE === 'test'

if (!isTestEnv) {
  // http://reactcommunity.org/react-modal/accessibility/#app-element
  ReactModal.setAppElement('#app')
}

const Modal: FC<Props> = (props: Props) => {
  const {
    shouldCloseOnEsc = false,
    shouldCloseOnOutsideClick = false,
    customWrapperStyles,
    children
  } = props

  return (
    <ReactModal
      className={cx(styles.wrapperModal, customWrapperStyles)}
      overlayClassName={styles.overlay}
      isOpen={true}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOutsideClick}
      ariaHideApp={!isTestEnv}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
