export const errorMessages = {
  INVALID_BID_PRICE_OR_SIZE: 'Invalid bid price or size.',
  INVALID_OFFER_PRICE_OR_SIZE: 'Invalid offer price or size.',
  BID_PRICE_GREATER_THAN_OFFER_PRICE:
    'Crossing or locking markets is not allowed. Bid price must be lower than offer price.',
  BID_SPREAD_LOWER_THAN_OFFER_SPREAD:
    'Crossing or locking markets is not allowed. Bid price must be lower than offer price.',
  OFFER_SPREAD_GREATER_THAN_BID_SPREAD:
    'Crossing or locking markets is not allowed. Offer price must be greater than bid price.',
  OFFER_PRICE_LOWER_THAN_BID_PRICE:
    'Crossing or locking markets is not allowed. Offer price must be greater than bid price.',
  BID_SIZE_EXCEEDS_MAX: (size: number, max: number) =>
    `Bid size of ${size} exceeds the system-wide maximum of ${max}`,
  OFFER_SIZE_EXCEEDS_MAX: (size: number, max: number) =>
    `Offer size of ${size} exceeds the system-wide maximum of ${max}`,
  GENERAL_ORDER_SIZE_LESS_THAN_SPECIFIED_SIZE: (
    displayType: string,
    minimum: number
  ) => `${displayType} size must be greater than ${minimum}.`,
  BID_SIZE_LOWER_THAN_TRADER_PREFERENCE:
    'Bid size must be greater than min size.',
  OFFER_SIZE_LOWER_THAN_TRADER_PREFERENCE:
    'Offer size must be greater than min size.',
  SIZE_LOWER_THAN_INDIVIDUAL_MIN: 'Size must be greater than min size.',
  TOB_ORDER_WITHOUT_LIMIT_PRICE: 'TOB order without limit price/spread.',
  TOB_ORDER_WITHOUT_FLOOR_PRICE: 'TOB order without floor price/spread.',
  TOB_ORDER_LIMIT_PRICE_LOWER_THAN_FLOOR_PRICE:
    'TOB limit price must be greater than floor price.',
  TOB_ORDER_LIMIT_PRICE_GREATER_THAN_FLOOR_PRICE:
    'TOB limit price must be lower than floor price.',
  TOB_ORDER_LIMIT_SPREAD_LOWER_THAN_FLOOR_SPREAD:
    'TOB limit spread must be greater than floor spread.',
  TOB_ORDER_LIMIT_SPREAD_GREATER_THAN_FLOOR_SPREAD:
    'TOB limit spread must be less than floor spread.',
  SHOW_SIZE_GREATER_THAN_TOTAL_SIZE: 'Show size must be less than total size.',
  MIN_SIZE_GREATER_THAN_SHOW_SIZE:
    'Minimum size must be less than display size.',
  NEGATIVE_MIN_SIZE: 'Minimum size cannot be negative.',
  TIME_OUT_OF_RANGE: 'Please choose a time between now and 5pm ET.',
  ICEBERG_THRESHOLD: (iceberg: number) =>
    `Total Size must be greater than ${iceberg}`
}
