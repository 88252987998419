import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getUserDashboard = createSelector(
  [getState],
  (state) => state.userDashboard
)
