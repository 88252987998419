import React from 'react'

interface Props {
  message: string
  onOk: () => void
  className?: string
}

const ErrorDialog = ({ className, message, onOk }: Props) => {
  return (
    <div className={className}>
      <p title={message}>{message}</p>
      <button type="button" onClick={onOk}>
        OK
      </button>
    </div>
  )
}

export default ErrorDialog
