import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

type T = typeof namespace

type HasNsKey = {
  [key in T]: State
}
const getState = (state: HasNsKey): State => state[namespace]
export const getPassiveOrders = createSelector(
  [getState],
  (state) => state.orders
)

export const getViewAllPassiveOrders = createSelector(
  [getState],
  (state) => state.viewAllOrders
)

export const getPassiveordersPending = createSelector(
  [getState],
  (state) => state.pending
)
