import { CustomCellRendererProps } from '@ag-grid-community/react'
import {
  faAnglesDown,
  faAnglesRight
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { useCallback, useState } from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer.ts'
import styles from '../../BondList/bondListStyle.module.scss'
import { useSecurityOrderData } from '../../BondList/cells/helpers.tsx'

const DepthOfMarketControls = ({
  node,
  data
}: CustomCellRendererProps<SecurityStaticData>) => {
  const [expanded, setExpanded] = useState(node?.expanded)
  const toggleExpanded = useCallback(() => {
    node?.setExpanded(!expanded)
    setExpanded(!expanded)
  }, [expanded])
  const security = useSecurityOrderData(data)
  const classes = cx(styles.cellContainer, styles.emphasized)
  if (!security) return <></>
  return (
    <span className={classes}>
      <FontAwesomeIcon
        icon={!node.expanded ? faAnglesRight : faAnglesDown}
        onClick={toggleExpanded}
        title={
          node.expanded
            ? 'Close Depth of Market'
            : 'Open Depth of Market in Context'
        }
      />
    </span>
  )
}

export default DepthOfMarketControls
