import { Action } from 'redux'
import { OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import { CheckedOrder } from './types'

export interface CheckOrderAction extends Action {
  type: 'checkedOrders.checkOrder'
  payload: CheckedOrder
}

export const checkOrder = (
  securityId: Security['id'],
  orderType: OrderType
): CheckOrderAction => ({
  type: 'checkedOrders.checkOrder',
  payload: { securityId, orderType }
})

export interface CheckOrdersAction extends Action {
  type: 'checkedOrders.checkOrders'
  payload: CheckedOrder[]
}

export const checkOrders = (
  checkedOrders: CheckedOrder[]
): CheckOrdersAction => ({
  type: 'checkedOrders.checkOrders',
  payload: checkedOrders
})

export interface UncheckOrderAction extends Action {
  type: 'checkedOrders.uncheckOrder'
  payload: CheckedOrder
}

export const uncheckOrder = (
  securityId: Security['id'],
  orderType: OrderType
): UncheckOrderAction => ({
  type: 'checkedOrders.uncheckOrder',
  payload: { securityId, orderType }
})

export interface UncheckOrdersAction extends Action {
  type: 'checkedOrders.uncheckOrders'
  payload: CheckedOrder[]
}

export const uncheckOrders = (
  checkedOrders: CheckedOrder[]
): UncheckOrdersAction => ({
  type: 'checkedOrders.uncheckOrders',
  payload: checkedOrders
})

export interface ToggleOrderAction extends Action {
  type: 'checkedOrders.toggleOrder'
  payload: CheckedOrder
}

export const toggleOrder = (
  securityId: Security['id'],
  orderType: OrderType
): ToggleOrderAction => ({
  type: 'checkedOrders.toggleOrder',
  payload: { securityId, orderType }
})

export interface ResetCheckedOrdersAction extends Action {
  type: 'checkedOrders.reset'
}

export const resetCheckedOrders = (): ResetCheckedOrdersAction => ({
  type: 'checkedOrders.reset'
})

export interface CheckOrUncheckAllAction extends Action {
  type: 'checkedOrders.checkOrUncheckAllAction'
  payload: {
    gridIndex: number
    orderType: OrderType
  }
}

export const checkOrUncheckAll = (
  gridIndex: number,
  orderType: OrderType
): CheckOrUncheckAllAction => ({
  type: 'checkedOrders.checkOrUncheckAllAction',
  payload: { gridIndex, orderType }
})

export interface UpdateCheckedOrdersAction extends Action {
  type: 'checkedOrders.updateCheckedOrders'
  payload: {
    orderType: OrderType
    securityIds: Array<Security['id']>
  }
}

export const updateCheckedOrders = (
  orderType: OrderType,
  securityIds: Array<Security['id']>
): UpdateCheckedOrdersAction => ({
  type: 'checkedOrders.updateCheckedOrders',
  payload: { orderType, securityIds }
})

export interface AdjustCheckedOrdersAction extends Action {
  type: 'checkedOrders.adjustCheckedOrders'
  payload: {
    increment: number
  }
}

export const adjustCheckedOrders = (
  increment: number
): AdjustCheckedOrdersAction => ({
  type: 'checkedOrders.adjustCheckedOrders',
  payload: { increment }
})

export interface CancelCheckedOrdersAction extends Action {
  type: 'checkedOrders.cancelCheckedOrders'
  payload: {
    updateStagedOrder?: boolean
  }
}

export const cancelCheckedOrders = (
  updateStagedOrder: boolean = true
): CancelCheckedOrdersAction => ({
  type: 'checkedOrders.cancelCheckedOrders',
  payload: {
    updateStagedOrder
  }
})

export interface SubmitCheckedOrdersAction extends Action {
  type: 'checkedOrders.submitCheckedOrders'
}

export const submitCheckedOrders = (): SubmitCheckedOrdersAction => ({
  type: 'checkedOrders.submitCheckedOrders'
})

export interface CreateTempCheckedOrders extends Action {
  type: 'checkedOrders.createTempCheckedOrders'
  payload: {
    gridIndex: number
    checkedOrders: CheckedOrder[]
  }
}

export const createTempCheckedOrders = (
  gridIndex: number,
  checkedOrders: CheckedOrder[]
): CreateTempCheckedOrders => ({
  type: 'checkedOrders.createTempCheckedOrders',
  payload: {
    gridIndex,
    checkedOrders
  }
})

export interface ApplyTempCheckedOrders extends Action {
  type: 'checkedOrders.applyTempCheckedOrders'
}

export const applyTempCheckedOrders = (): ApplyTempCheckedOrders => ({
  type: 'checkedOrders.applyTempCheckedOrders'
})

export type CheckedOrderAction =
  | CheckOrderAction
  | CheckOrdersAction
  | ToggleOrderAction
  | ResetCheckedOrdersAction
  | CheckOrUncheckAllAction
  | UpdateCheckedOrdersAction
  | CancelCheckedOrdersAction
  | SubmitCheckedOrdersAction
  | UncheckOrderAction
  | UncheckOrdersAction
  | CreateTempCheckedOrders
  | ApplyTempCheckedOrders
