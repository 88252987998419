import React, { FC } from 'react'
import { getTotalSecondsBeforeExpiration } from '../../helpers/orders'
import CountdownCircle from '../CountdownCircle/CountdownCircle'
import { setKeyframe } from '../CountdownCircle/helper'

export interface Props {
  timerInit: number
  valueColor: string
  isPlaying?: boolean
  valueSize: 'small' | 'default' | 'big'
  totalSecondsBeforeExpiration: {
    expiration?: Date
    submitTime?: Date
    serverTimeDelayMs?: number
  }
}

const TimerLeftComponent: FC<Props> = props => {
  const {
    timerInit,
    valueColor,
    isPlaying = true,
    valueSize,
    totalSecondsBeforeExpiration
  } = props

  let valueCircle

  if (valueSize === 'default') {
    valueCircle = {
      r: '22',
      cx: '25',
      cy: '25'
    }
  } else if (valueSize === 'big') {
    valueCircle = {
      r: '44',
      cx: '46',
      cy: '46'
    }
  } else {
    valueCircle = {
      r: '15',
      cx: '17',
      cy: '17'
    }
  }

  const secondsLeft = Math.floor(
    getTotalSecondsBeforeExpiration(
      totalSecondsBeforeExpiration.expiration,
      totalSecondsBeforeExpiration.submitTime,
      totalSecondsBeforeExpiration.serverTimeDelayMs
    ).secondsLeft
  )
  setKeyframe(Number(valueCircle.r), secondsLeft, timerInit, valueSize)

  const strokeWidth = valueSize === 'default' ? 3 : 2

  return (
    <CountdownCircle
      valueColor={valueColor}
      valueSize={valueSize}
      isPlaying={isPlaying}
      timerInit={timerInit}
      valueCircle={valueCircle}
      strokeWidth={strokeWidth}
      totalSecondsBeforeExpiration={totalSecondsBeforeExpiration}
    ></CountdownCircle>
  )
}
export default TimerLeftComponent
