import {
  QueueStats,
  QUEUESTATS_ADD,
  QUEUESTATS_FETCH,
  QUEUESTATS_FETCH_FAIL,
  QueueStatsAddQueueStatsAction,
  QueueStatsFetchAction,
  QueueStatsFetchFailAction
} from './types'

export const queueStatsFetch = (): QueueStatsFetchAction => ({
  type: QUEUESTATS_FETCH
})

export const queueStatsAddQueueStats = (
  queueStats: QueueStats[]
): QueueStatsAddQueueStatsAction => ({
  type: QUEUESTATS_ADD,
  payload: queueStats
})

export const queueStatsFetchFail = (error: any): QueueStatsFetchFailAction => ({
  type: QUEUESTATS_FETCH_FAIL,
  payload: error,
  error: true
})
