import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { logError } from '../ws/actions'
import { fetchUserPermissionsSuccess, setWebSettings } from './actions'
import { UserPermissions } from './types'

const getWebSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    switchMap(() => {
      return getHub().invoke('GetWebSettings').pipe(map(setWebSettings))
    })
  )

const fetchUserPermissionsEpic: Epic = (action$) =>
  action$.pipe(
    ofType('webSettings.fetchUserPermissions'),
    mergeMap(() => {
      return getHub()
        .invoke<UserPermissions>('GetUserPermissions')
        .pipe(
          map((userPermissions: UserPermissions) =>
            fetchUserPermissionsSuccess(userPermissions)
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

export default combineEpics(getWebSettingsEpic, fetchUserPermissionsEpic)
