import { OrderEntryModel } from '../depthOfMarket/types'
import { UpdateWorkingOrderFieldAction } from './actions'

const isAon = (value: string) => {
  const aonExp = /\d+[a]/i
  return aonExp.test(value)
}

const formatSize = (size: string) => {
  const isIceberg = /\d+a? ?\(\d+\)/i.test(size)
  if (!isIceberg) return { totalSize: size, displaySize: '' }
  const words = size.replace(' ', '').replace(')', '').split('(')
  const displaySize = words[0]
  const totalSize = words[1]
  const totalSizeNum = parseInt(totalSize || '', 10)
  // Todo: this is hard-coded bc we don't know the iceberg min size inside the staged order reducer
  if (totalSizeNum >= 500) {
    return { displaySize, totalSize }
  }
  return { totalSize: displaySize }
}

export const workingOrderMiniReducer = (
  model: OrderEntryModel,
  action: UpdateWorkingOrderFieldAction
) => {
  const { field, value } = action.payload
  const totalSize = model.totalSize || `${action.payload.defaultSize}`
  switch (field) {
    case 'size':
      const aon = isAon(value)
      const sizes = formatSize(value)
      return { ...model, ...sizes, aon }
    case 'price':
      return {
        ...model,
        amountType: 'price',
        amount: value,
        totalSize
      } as const
    case 'spread':
      return {
        ...model,
        amountType: 'spread',
        amount: value,
        totalSize
      } as const
    default:
      return model
  }
}
