import { Action } from 'redux'
import { PassiveOrder } from './types'

export const fetchPassiveOrders = (): FetchPassiveOrdersAction => ({
  type: 'passiveOrders.fetchPassiveOrders'
})

export interface FetchPassiveOrdersAction extends Action {
  type: 'passiveOrders.fetchPassiveOrders'
}

export const unsubscribePassiveOrders = (): UnsubscribePassiveOrdersAction => ({
  type: 'passiveOrders.unsubscribePassiveOrders'
})

export interface UnsubscribePassiveOrdersAction extends Action {
  type: 'passiveOrders.unsubscribePassiveOrders'
}

export const clearPassiveOrders = (): ClearPassiveOrdersAction => ({
  type: 'passiveOrders.clearPassiveOrders'
})

export interface ClearPassiveOrdersAction extends Action {
  type: 'passiveOrders.clearPassiveOrders'
}

export const fetchPassiveOrdersFail = (
  error: any
): FetchPassiveOrdersFailAction => ({
  type: 'passiveOrders.fetchPassiveOrdersFail',
  payload: error,
  error: true
})

export interface FetchPassiveOrdersFailAction extends Action {
  type: 'passiveOrders.fetchPassiveOrdersFail'
  payload: any
  error: true
}

export const addOrUpdatePassiveOrders = (
  orders: PassiveOrder[]
): AddOrUpdatePassiveOrdersAction => {
  const retval = {
    type: 'passiveOrders.addOrUpdatePassiveOrders',
    payload: orders
  }
  return retval as AddOrUpdatePassiveOrdersAction
}

export interface AddOrUpdatePassiveOrdersAction extends Action {
  type: 'passiveOrders.addOrUpdatePassiveOrders'
  payload: PassiveOrder[]
}

export const setViewAllPassiveOrdersAction = (
  viewAll: boolean
): SetViewAllPassiveOrdersAction => ({
  type: 'passiveOrders.viewAllOrders',
  payload: viewAll
})

export interface SetViewAllPassiveOrdersAction extends Action {
  type: 'passiveOrders.viewAllOrders'
  payload: boolean
}

export const cancelPassiveOrder = (
  orderId: string
): CancelPassiveOrderAction => ({
  type: 'passiveOrders.cancelPassiveOrder',
  payload: orderId
})

export interface CancelPassiveOrderAction extends Action {
  type: 'passiveOrders.cancelPassiveOrder'
  payload: string
}

export const cancelPassiveOrderFailure = (
  orderId: string,
  failure: string
): CancelPassiveOrderFailureAction => ({
  type: 'passiveOrders.cancelPassiveOrderFailure',
  payload: { orderId, failure }
})

export interface CancelPassiveOrderFailureAction extends Action {
  type: 'passiveOrders.cancelPassiveOrderFailure'
  payload: { orderId: string; failure: string }
}

export const createOrderFromPassiveOrder = (
  orderId: number
): CreateOrderFromPassiveOrderAction => ({
  type: 'passiveOrders.createOrderFromPassiveOrder',
  payload: {
    orderId
  }
})

export interface CreateOrderFromPassiveOrderAction extends Action {
  type: 'passiveOrders.createOrderFromPassiveOrder'
  payload: {
    orderId: number
  }
}

export const createOrderFromPassiveOrderSuccess = (): CreateOrderFromPassiveOrderSuccess => ({
  type: 'passiveOrders.createOrderFromPassiveOrderSuccess'
})

export interface CreateOrderFromPassiveOrderSuccess extends Action {
  type: 'passiveOrders.createOrderFromPassiveOrderSuccess'
}

export const handleCreateOrderFromPassiveOrderError = (
  err: any,
  orderId: number
): CreateOrderFromPassiveOrderErrorAction => {
  return {
    type: 'passiveOrders.createOrderFromPassiveOrderError',
    payload: { err, orderId }
  }
}

export interface CreateOrderFromPassiveOrderErrorAction {
  type: 'passiveOrders.createOrderFromPassiveOrderError'
  payload: { err: any; orderId: number }
}

export type PassiveOrdersAction =
  | FetchPassiveOrdersAction
  | AddOrUpdatePassiveOrdersAction
  | SetViewAllPassiveOrdersAction
  | UnsubscribePassiveOrdersAction
  | ClearPassiveOrdersAction
  | CancelPassiveOrderAction
  | CreateOrderFromPassiveOrderErrorAction
  | CreateOrderFromPassiveOrderSuccess
