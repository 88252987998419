import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'

import { saveBBMISINsFailure, saveBBMISINsSuccess } from './actions'
import { BBMISIN, BBMISIN_SAVE, BBMISINSaveAction } from './types'

const saveBBMISINEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType(BBMISIN_SAVE),
    mergeMap((action: BBMISINSaveAction) => {
      return getHub()
        .invoke<BBMISIN[]>('SaveBBMISINData', action.payload)
        .pipe(
          map((errors: any) => saveBBMISINsSuccess(errors)),
          catchError((err) => of(saveBBMISINsFailure(err), logError(err)))
        )
    })
  )

export default combineEpics(saveBBMISINEpic)
