import React, { ChangeEvent, useCallback, useEffect, useReducer } from 'react'
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup'

export type LTFilter = {
  completed: 'completed' | 'partial' | 'unfilled' | 'all'
  pending: boolean
  targetMet: 'amount' | 'amountAndSize' | 'all'
}

export const emptyFilter: LTFilter = {
  completed: 'all',
  pending: false,
  targetMet: 'all'
}

type LTFilterState = LTFilter & { isChanged: boolean }

interface Props {
  onFilterChange: (filter: LTFilter) => void
  className?: string
}

type UpdateFilterActions = UpdateActions<LTFilter>
type ClientNotified = {
  type: 'client_notified'
}

type InitFilter = {
  type: 'init_filter'
}

const filterReducer = (
  filter: LTFilterState,
  action: UpdateFilterActions | ClientNotified | InitFilter
) => {
  switch (action.type) {
    case 'client_notified':
      return { ...filter, isChanged: false }
    case 'init_filter':
      return { ...emptyFilter, isChanged: false }
    case 'update_completed':
      return { ...filter, completed: action.payload, isChanged: true }
    case 'update_pending':
      return { ...filter, pending: action.payload, isChanged: true }
    case 'update_targetMet':
      return { ...filter, targetMet: action.payload, isChanged: true }
    default:
      return filter
  }
}

const completedOptions = [
  { label: 'All', value: 'all' },
  { label: 'Completed', value: 'completed' },
  { label: 'Partial', value: 'partial' },
  { label: 'Unfilled', value: 'unfilled' }
]
const targetMetOptions = [
  { label: 'Price/Spread Met', value: 'amount' },
  /* { label: 'Price/Spread and Size Met', value: 'amountAndSize' },*/
  { label: 'All', value: 'all' }
]

const ListTradingFilter = ({ className, onFilterChange }: Props) => {
  const [filter, dispatch] = useReducer(filterReducer, {
    ...emptyFilter,
    isChanged: false
  })

  const apply = useCallback(() => {
    const { isChanged, ...ltFilter } = filter
    onFilterChange(ltFilter)
    dispatch({
      type: 'client_notified'
    })
  }, [filter])
  const reset = useCallback(() => {
    onFilterChange(emptyFilter)
    dispatch({ type: 'init_filter' })
  }, [])
  const updateCompleted = useCallback((value: LTFilter['completed']) => {
    dispatch({
      type: 'update_completed',
      payload: value
    })
  }, [])
  const updateTargetMet = useCallback((value: LTFilter['targetMet']) => {
    dispatch({
      type: 'update_targetMet',
      payload: value
    })
  }, [])
  const updatePending = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.checked
    dispatch({
      type: 'update_pending',
      payload: value
    })
  }, [])

  useEffect(() => {
    filter.isChanged && apply()
  }, [filter.isChanged])

  return (
    <div className={className}>
      <RadioButtonGroup
        legend="Status:"
        onSelect={updateCompleted}
        options={completedOptions}
        selectedOption={filter.completed}
        testPrefix="lt-filter-completed-grp"
      />
      <RadioButtonGroup
        legend="Target met:"
        onSelect={updateTargetMet}
        options={targetMetOptions}
        selectedOption={filter.targetMet}
        testPrefix="lt-filter-targetMet-grp"
      />
      <label>
        <input
          type="checkbox"
          checked={filter.pending}
          onChange={updatePending}
        />
        Pending
      </label>
      <div>
        <button onClick={reset}>Reset</button>
      </div>
    </div>
  )
}

export default ListTradingFilter
