import { addOrUpdateCustomer } from './helpers'
import {
  Customer,
  CUSTOMER_ADD,
  CUSTOMER_FETCH,
  CUSTOMER_FETCH_FAIL,
  CustomersAction
} from './types'

export interface State {
  customers?: Customer[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  customers: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: CustomersAction): State => {
  switch (action.type) {
    case CUSTOMER_FETCH:
      return {
        ...state,
        pending: true,
        customers: undefined,
        error: false
      }
    case CUSTOMER_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case CUSTOMER_ADD:
      let items: Customer[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.customers) {
        return {
          ...state,
          customers: items
        }
      }

      return {
        ...state,
        customers: addOrUpdateCustomer(state.customers, items)
      }

    default:
      return state
  }
}
