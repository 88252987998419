import { createSelector } from 'reselect'
import { namespace } from '.'
import { getUserOrders } from '../order/selectors'
import { Order } from '../order/types'
import { State } from './reducer'

const byDateDesc = (o1: Order, o2: Order) =>
  o2.submitTime.valueOf() - o1.submitTime.valueOf()

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getAggressorWindows = createSelector(
  [getState],
  (state) => state.aggressorWindows
)

export const getActiveAggressorWindow = createSelector(
  [getState],
  (state) => state.aggressorForegroundIndex
)

export const getActiveLastLookWindow = createSelector(
  [getState],
  (state) => state.lastLookForegroundIndex
)

export const getLastLookWindows = createSelector(
  [getState],
  (state) => state.lastLookWindows
)

export const getLastLookOrderIds = createSelector(
  [getLastLookWindows],
  (lastLookWindows) => lastLookWindows.map((window) => window.orderId)
)

const getSecuritiesModalInfo = createSelector(
  [getState],
  (state) => state.securitiesModalInfo
)

export const getSecurityModalInfo = createSelector(
  [getSecuritiesModalInfo],
  (securitiesModalInfo) => (orderId: Order['id']) =>
    securitiesModalInfo[orderId]
)

export const getAggressorWindowOrder = createSelector(
  [getUserOrders],
  (orders) => (initialOrderId: Order['id']) =>
    [...orders]
      .sort(byDateDesc)
      .find(
        (order) =>
          order.initialOrder?.id === initialOrderId &&
          order.status !== 'cancelled'
      )
)

export const getWindowFront = createSelector(
  [getState],
  (state) => state.windowFront
)

export const getSpotHedgePreferences = createSelector(
  [getState],
  (state) => (orderId: Order['id']) => state.securitiesModalInfo[orderId]
)
