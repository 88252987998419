import { MarketAction } from './actions'

export interface State {
  closed: boolean
  closeTimer?: number
}

const initialState: State = {
  closed: false
}

export const closeTimerCount = 30

export default (state = initialState, action: MarketAction): State => {
  switch (action.type) {
    case 'market.marketClosed': {
      return {
        closed: action.payload.closed
      }
    }
    case 'market.closeTimerTick': {
      const closeTimer = state.closeTimer ?? closeTimerCount
      return {
        ...state,
        closeTimer: closeTimer - 1
      }
    }
    default:
      return state
  }
}
