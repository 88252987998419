import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSearchResultsByIssuer,
  getSearchResultsIgnoringWatchlistByIssuer
} from '../../components/SearchBondsInput/selectors'
import { searchSecurities } from '../../store/searchSecurities/actions'
import { getShowLive } from '../../store/securities/selectors'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'

export const useFetchBondsSearchResults = (
  gridIndex: number,
  search: string,
  overrideEditorRequirement?: boolean
) => {
  const watchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const dispatch = useDispatch()
  const watchListResults = useSelector(getSearchResultsByIssuer)(gridIndex)
  const notInWatchListResults = useSelector(
    getSearchResultsIgnoringWatchlistByIssuer
  )(gridIndex)
  // Conceptually, this doesn't belong here, but putting it here means we don't need to
  // add redux functionality to several components
  const showLiveChecked = useSelector(getShowLive)(gridIndex)

  useEffect(() => {
    if (!search) return
    // One search for the watchlist, one for all securities
    dispatch(searchSecurities(gridIndex, search, false))
    if (
      (watchlist && watchlist.canEdit) ||
      (overrideEditorRequirement && !watchlist?.canEdit)
    ) {
      dispatch(searchSecurities(gridIndex, search, true))
    }
  }, [gridIndex, search, watchlist?.canEdit, overrideEditorRequirement])
  return {
    showLiveChecked,
    watchlist,
    watchListResults: search ? watchListResults : [],
    notInWatchListResults: search ? notInWatchListResults : []
  }
}
