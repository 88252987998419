import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getMarketClosed = createSelector(
  [getState],
  (state) => state.closed
)

export const getMarketClosedTimer = createSelector(
  [getState],
  (state) => state.closeTimer
)
