import React, { ReactNode, useMemo } from 'react'
import { formatCurrency, formatSize } from '../../../helpers/formatting'
import { Order, OrderType } from '../../../store/order/types'
import {
  capitalize,
  depthColumnsByOrderType,
  formatSpread,
  OrderDisplay
} from '../helpers'
import CancelButton from './CancelButton'
import DepthAggressCell from './DepthAggressCell'

interface Props {
  aggressCallback?: (order: Order) => void
  className?: string
  currency?: string
  rows: Order[]
  securityId: number
  type: OrderType
  myOrders: string[]
  extraColumns?: OrderDisplay[]
}

const noop = (_args: any) => {
  /* intentionally empty */
}

const BuyOrSellDepth = ({
  rows,
  type,
  securityId,
  currency,
  aggressCallback,
  className,
  myOrders,
  extraColumns = []
}: Props) => {
  const columns = useMemo(() => {
    return depthColumnsByOrderType(type, extraColumns)
  }, [type, extraColumns])
  const rootid = `${type}-market-${securityId}`
  const callback = aggressCallback || noop

  return (
    <div className={className}>
      <table data-testid={rootid}>
        <tbody>
          {rows.map((row) => (
            <tr
              key={row.id}
              data-testid={`${rootid}-row-${row.id}`}
              data-is-my-firm={row.myFirm ? 'true' : 'false'}
            >
              {columns.map((col, i) => {
                if (typeof col !== 'string') {
                  const Component = col
                  return (
                    <Component
                      order={row}
                      key={`${rootid}-row-${row.id}-${i}`}
                    />
                  )
                }
                const key = `${rootid}-row-${row.id}-${col}`
                let content: ReactNode = ' '
                let title: string = col
                switch (col) {
                  case 'aggress':
                    if (row.canAggress) {
                      content = (
                        <DepthAggressCell
                          type={type}
                          qr={row.qr}
                          onClick={() => callback(row)}
                        />
                      )
                    } else if (myOrders.includes(row.id)) {
                      title = 'cancel'
                      content = <CancelButton onClick={() => callback(row)} />
                    }
                    break
                  case 'price':
                    content = formatCurrency(
                      row.displayPrice ?? row.price,
                      currency
                    )
                    break
                  case 'size':
                    content = formatSize(
                      row.size,
                      row.totalSize,
                      row.allOrNone,
                      myOrders.includes(row.id)
                    )
                    break
                  case 'spread':
                    content = formatSpread(row.spread)
                    break
                }

                return (
                  <td
                    key={key}
                    data-testid={key}
                    data-column={col}
                    title={capitalize(title)}
                  >
                    {content}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default BuyOrSellDepth
