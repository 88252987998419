import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetRowIdFunc,
  GridApi
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  getExternalOrderStats,
  getExternalOrderStatsError,
  getExternalOrderStatsPending
} from '../../../../store/admin/externalOrderStats/selectors'
import { ExternalOrderStatsTableRow } from '../../../../store/admin/externalOrderStats/types'
import { externalOrderColumnModelChanged } from '../../../../store/settings/actions'
import { getExternalOrderColumnOrder } from '../../../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import { applyColumnsOrder } from '../../helpers'
import { columnDefinitions } from './columnDefs'

const getRowId: GetRowIdFunc<
  ExternalOrderStatsTableRow & { id: string | number }
> = ({ data }) => {
  return `${data.id}`
}
const ExternalOrderStatsGrid: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const pending = useAppSelector(getExternalOrderStatsPending)
  const error = useAppSelector(getExternalOrderStatsError)
  const externalOrderStats = useAppSelector(getExternalOrderStats)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)
  const [externalOrderStatsRowData, setexternalOrderStatsRowData] = useState<
    ExternalOrderStatsTableRow[]
  >([])

  const columnsOrder = useAppSelector(getExternalOrderColumnOrder)
  const [columnDefs, setColumnDefs] = useState<
    ColDef[] | ColGroupDef[] | undefined
  >(undefined)

  //
  // Set Initial Columns Def
  useEffect(() => {
    setColumnDefs(applyColumnsOrder(columnsOrder, columnDefinitions))
  }, [columnsOrder?.join(',')])

  useEffect(() => {
    if (externalOrderStats) {
      setexternalOrderStatsRowData(externalOrderStats.rows)
    } else {
      setexternalOrderStatsRowData([])
    }
  }, [externalOrderStats])

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
      }
    },
    []
  )

  useEffect(() => {
    if (gridApi) {
      if (pending) {
        gridApi.api.showLoadingOverlay()
      } else if (error) {
        gridApi.api.showNoRowsOverlay()
      } else {
        if (externalOrderStatsRowData.length === 0) {
          gridApi.api.showNoRowsOverlay()
        } else {
          gridApi.api.hideOverlay()
        }
      }
    }
  }, [pending, error, externalOrderStatsRowData, gridApi])

  const handleColumnChange = useCallback(() => {
    if (dataInitialized) {
      const displayed = gridApi?.columnApi
        .getAllDisplayedColumns()
        .map((col) => {
          return col.getColId()
        })
      if (displayed) {
        dispatch(externalOrderColumnModelChanged(displayed))
      }
    }
  }, [dataInitialized])

  return (
    <div className={cx(gridStyles.gridDimensions, theme)}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={externalOrderStatsRowData}
        defaultColDef={defaultColumnDefinitions}
        overlayNoRowsTemplate="No user data."
        overlayLoadingTemplate="Loading API user data. This may take up to several minutes depending on server load."
        suppressDragLeaveHidesColumns={true}
        onColumnMoved={handleColumnChange}
        onDisplayedColumnsChanged={handleColumnChange}
        onFirstDataRendered={onDataInitialized}
        alwaysShowVerticalScroll={true}
        onGridReady={onGridReady}
        enableBrowserTooltips={true}
        rowSelection={'single'}
        groupIncludeTotalFooter={true}
        getRowId={getRowId}
      />
    </div>
  )
}

export default ExternalOrderStatsGrid
