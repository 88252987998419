import { ColDef } from '@ag-grid-community/core'
import { dateFormatter } from '../../../../helpers/formatting'

export const ORDER_TIME = 'orderTime'
export const SECURITY = 'security'
export const DESCRIPTION = 'description'
export const TRADER = 'trader'
export const OPERATOR = 'operator'
export const EXCEPTION = 'exception'

export const customizableColumns: ColDef[] = [
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Time',
    valueFormatter: dateFormatter
  },
  {
    colId: DESCRIPTION,
    field: DESCRIPTION,
    headerName: 'Description',
    tooltipField: DESCRIPTION,
    flex: 3
  },
  {
    colId: SECURITY,
    field: SECURITY,
    headerName: 'Security'
  }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
