export const getLineHeight = (rayon: number) => {
  const heightWidth = Math.floor(rayon * 2)
  return { lineHeight: `${heightWidth + 5}px` }
}

export const setStrokeColor = (valueColor: string) => {
  return { stroke: valueColor }
}

export const setStrokeDasharray = (rayon: number, strokeWidth: number) => {
  const diameter = Math.floor(2 * Math.PI * rayon)
  return { strokeDasharray: `${diameter}px`, strokeWidth: `${strokeWidth}px` }
}

export const setWidthHeightStyle = (rayon: number) => {
  const heightWidth = Math.floor(rayon * 2)
  return {
    width: `${heightWidth + 5}px`,
    height: `${heightWidth + 5}px`
  }
}

export const setKeyframe = (
  rayon: number,
  secondsLeft: number,
  timerInit: number,
  valueSize: 'small' | 'default' | 'big'
) => {
  const diameter = Math.floor(2 * Math.PI * rayon)
  const setCircleTimerCountDownStart =
    diameter - (secondsLeft / timerInit) * diameter

  if (valueSize === 'small') {
    document.documentElement.style.setProperty(
      '--countdown-timer-start-small',
      `${setCircleTimerCountDownStart}px`
    )
    document.documentElement.style.setProperty(
      '--countdown-timer-end-small',
      `${diameter}px`
    )
  } else if (valueSize === 'default') {
    document.documentElement.style.setProperty(
      '--countdown-timer-start',
      `${setCircleTimerCountDownStart}px`
    )
    document.documentElement.style.setProperty(
      '--countdown-timer-end',
      `${diameter}px`
    )
  } else if (valueSize === 'big') {
    document.documentElement.style.setProperty(
      '--countdown-timer-start-big',
      `${setCircleTimerCountDownStart}px`
    )
    document.documentElement.style.setProperty(
      '--countdown-timer-end-big',
      `${diameter}px`
    )
  }
}
