import cx from 'classnames'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useStagedAndLiveOrders } from '../../containers/BondList/cells/helpers'
import { getBooks } from '../../store/books/selectors'
import { OrderEntryModel } from '../../store/depthOfMarket/types'
import { cancelOrder, updateOrderValidation } from '../../store/order/actions'
import {
  getErrorForOrder,
  getPendingUserOrderForSecurity
} from '../../store/order/selectors'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getSecurityOrderDataById } from '../../store/securities/selectors'
import { useAppSelector } from '../../store/types'
import {
  getMaxTradeSize,
  getMinTradeSize
} from '../../store/userPreferences/selectors'
import {
  getIcebergThreshold,
  showTOBSetting
} from '../../store/webSettings/selectors'
import DepthOfMarket from './Depth/DepthOfMarket'
import styles from './Depth/DepthOfMarket.module.scss'
import InlineDepthHeader from './Depth/InlineDepthHeader'
import {
  checkPrice,
  createActionFromOrderModel,
  createOrderModel,
  getOppositeFromSecurity,
  isTobParam,
  OrderDisplay,
  validateOrderModel
} from './helpers'
import OrderEntry from './OrderEntry/OrderEntry'

type SecurityDOMProps = {
  security: SecurityStaticData
  gridIndex: number
  isPopout: boolean
  extraColumns?: OrderDisplay[]
  showOrderEntry: boolean
}
export const PassiveDepthOfMarket = ({
  security,
  gridIndex,
  isPopout = false,
  extraColumns,
  showOrderEntry
}: SecurityDOMProps) => {
  const dispatch = useDispatch()

  const securityOrderData = useAppSelector(getSecurityOrderDataById)(
    security.id
  )

  const { stagedOrder: buyStagedOrder, liveOrder: buyLiveOrder } =
    useStagedAndLiveOrders(security?.id, 'buy')
  const { stagedOrder: sellStagedOrder, liveOrder: sellLiveOrder } =
    useStagedAndLiveOrders(security?.id, 'sell')
  const getOrderForSecurity = useAppSelector(getPendingUserOrderForSecurity)
  const getServerError = useAppSelector(getErrorForOrder)

  const showTOB = useAppSelector(showTOBSetting)

  const userMaxTrade = useAppSelector(getMaxTradeSize)
  const userMinTrade = useAppSelector(getMinTradeSize)

  const icebergThreshold = useAppSelector(getIcebergThreshold)

  const buyServerError = getServerError(security?.id || 0, 'buy')
  const sellServerError = getServerError(security?.id || 0, 'sell')

  const isTreasury = security.product === 'PrinUSGovtOutright'

  const buyOrder = useMemo(() => {
    return createOrderModel(buyLiveOrder || buyStagedOrder, 'buy', isTreasury)
  }, [buyLiveOrder, buyStagedOrder])
  const sellOrder = useMemo(() => {
    return createOrderModel(
      sellLiveOrder || sellStagedOrder,
      'sell',
      isTreasury
    )
  }, [sellLiveOrder, sellStagedOrder])

  const onCancel = useCallback((orderId: string | undefined) => {
    if (orderId) {
      dispatch(cancelOrder(orderId))
    }
  }, [])

  const onSubmit = useCallback(
    (submittedOrder: OrderEntryModel) => {
      if (!security) return

      const price =
        submittedOrder.amountType === 'price' &&
        !isTobParam(submittedOrder.amount)
          ? checkPrice(
              submittedOrder.amount,
              security.product === 'PrinUSGovtOutright'
            )
          : undefined
      const systemOrder = getOrderForSecurity(security.id, submittedOrder.type)
      const id = systemOrder?.id || submittedOrder.id
      const amount = price || submittedOrder.amount

      const order = { ...submittedOrder, id, amount, book: selectedBookId }
      const oppositeAmount = securityOrderData
        ? getOppositeFromSecurity(
            securityOrderData,
            order.type,
            order.amountType
          ) || NaN
        : NaN

      const minSize =
        userMinTrade && security.minimumSize
          ? Math.max(userMinTrade, security.minimumSize)
          : userMinTrade || security.minimumSize || 0

      const error = validateOrderModel(
        order,
        oppositeAmount,
        icebergThreshold,
        minSize,
        userMaxTrade || undefined
      )
      if (error) {
        return error // back to the order entry component for display
      }

      const action = createActionFromOrderModel(order, security.id)
      if (action) {
        dispatch(action)
      }
    },
    [getOrderForSecurity, securityOrderData]
  )

  // ------------------ selecting a book ------------------ //
  const books = useAppSelector(getBooks)
  const [selectedBookId, setSelectedBookId] = useState(0)

  const handleBookSelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const bookId = Number(e.currentTarget.value)
    setSelectedBookId(bookId)
  }, [])

  const footer = useMemo(() => {
    if (!books?.length || !showOrderEntry) return <></>
    return (
      <div className={styles.footer}>
        <label htmlFor={`${security.id}-book-select`}>Book:</label>
        <select
          id={`${security.id}-book-select`}
          value={selectedBookId}
          onChange={handleBookSelect}
        >
          {books?.map((book) => (
            <option key={book.id} value={book.id}>
              {book.name}
            </option>
          ))}
        </select>
      </div>
    )
  }, [books, selectedBookId, showOrderEntry])

  // ------------------ cleanup ------------------ //
  useEffect(() => {
    return () => {
      if (buyServerError) {
        dispatch(
          updateOrderValidation({
            securityId: security.id,
            orderType: 'buy',
            error: undefined
          })
        )
      }
      if (sellServerError) {
        dispatch(
          updateOrderValidation({
            securityId: security.id,
            orderType: 'sell',
            error: undefined
          })
        )
      }
    }
  }, [buyServerError, sellServerError])

  return (
    <div className={styles.securitySection}>
      {showOrderEntry && (
        <OrderEntry
          order={buyOrder}
          rowId={security.id}
          onSubmit={onSubmit}
          onCancel={onCancel}
          showPegged={showTOB}
          serverError={buyServerError}
          className={cx(styles.buy)}
          icebergThreshold={icebergThreshold}
        />
      )}

      <DepthOfMarket
        gridIndex={gridIndex}
        security={security}
        className={styles.depthOfMarket}
        isPopout={isPopout}
        footer={isPopout && footer}
        extraColumns={extraColumns}
      >
        {!isPopout && <InlineDepthHeader security={security} footer={footer} />}
      </DepthOfMarket>
      {showOrderEntry && (
        <OrderEntry
          order={sellOrder}
          rowId={security.id}
          onSubmit={onSubmit}
          onCancel={onCancel}
          showPegged={showTOB}
          serverError={sellServerError}
          className={cx(styles.sell)}
          icebergThreshold={icebergThreshold}
        />
      )}
    </div>
  )
}
