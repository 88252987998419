import { Action } from 'redux'
import { UserDashboardEntry } from './types'

export interface SubscribeToUserDashboard extends Action {
  type: 'userDashboard.subscribe'
}

export const subscribeToUserDashboard = (): SubscribeToUserDashboard => ({
  type: 'userDashboard.subscribe'
})
export interface UnsubscribeFromUserDashboard extends Action {
  type: 'userDashboard.unsubscribe'
}

export const unsubscribeFromUserDashboard =
  (): UnsubscribeFromUserDashboard => ({
    type: 'userDashboard.unsubscribe'
  })

export interface UpdateUserDashboard extends Action {
  type: 'userDashboard.updateUserDashboard'
  payload: { userDashboard: UserDashboardEntry[] }
}

export const updateUserDashboard = (
  userDashboard: UserDashboardEntry[]
): UpdateUserDashboard => ({
  type: 'userDashboard.updateUserDashboard',
  payload: { userDashboard }
})

export type UserDashboardAction =
  | SubscribeToUserDashboard
  | UpdateUserDashboard
  | UnsubscribeFromUserDashboard
