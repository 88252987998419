import {
  BENCHMARKDATA_FETCH,
  BENCHMARKDATA_FETCH_FAIL,
  BENCHMARKDATA_SUCCESS,
  BenchmarkDataAction,
  BenchmarkInfo
} from './types'

export interface State {
  benchmarkInfo?: BenchmarkInfo
  pending: boolean
  error: boolean
}

export const initialState: State = {
  benchmarkInfo: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: BenchmarkDataAction): State => {
  switch (action.type) {
    case BENCHMARKDATA_SUCCESS:
      return {
        ...state,
        benchmarkInfo: action.payload,
        error: false
      }

    case BENCHMARKDATA_FETCH:
      return {
        ...state,
        pending: true,
        benchmarkInfo: undefined,
        error: false
      }

    case BENCHMARKDATA_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    default:
      return state
  }
}
