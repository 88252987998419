import { IRowNode } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultColumnDefinitions } from '../../../helpers/formatting'
import {
  getPassiveOrders,
  getPassiveordersPending
} from '../../../store/passiveOrders/selectors'
import { PassiveOrder } from '../../../store/passiveOrders/types'
import { getCurrentTheme } from '../../../store/userPreferences/selectors'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import Checkbox from '../../Checkbox'
import gridStyles from '../../Grid/grid.module.scss'
import styles from '../PassiveOrders.module.scss'
import { adminColumnDefinitions, columnDefinitions } from './columnDefs'

const toggleBoolean = (val: boolean) => !val

type GridType = PassiveOrder

const PassiveOrdersGrid: FC = () => {
  const theme = useSelector(getCurrentTheme)

  const loading = useSelector(getPassiveordersPending)
  const admin = useSelector(getIsAdmin)
  const gridRef = useRef<AgGridReact<GridType>>(null)

  const [igState, setIG] = useState<boolean>(false)
  const [hyState, setHY] = useState<boolean>(false)
  const [liveState, setLive] = useState<boolean>(false)

  const passiveOrders = useSelector(getPassiveOrders) ?? []

  const externalFilterChanged = useCallback(() => {
    gridRef.current?.api.onFilterChanged()
  }, [])

  const isExternalFilterPresent = useCallback(() => {
    return igState || hyState || liveState
  }, [igState, hyState, liveState])

  const igChanged = useCallback(() => {
    setHY(false)
    setIG(toggleBoolean)
    externalFilterChanged()
  }, [])

  const hyChanged = useCallback(() => {
    setIG(false)
    setHY(toggleBoolean)
    externalFilterChanged()
  }, [])

  const liveChanged = useCallback(() => {
    setLive(toggleBoolean)
    externalFilterChanged()
  }, [])

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<GridType>) => {
      if (!node.data) {
        return true
      }
      if (igState && !node.data.isIG) {
        return false
      }
      if (hyState && node.data.isIG) {
        return false
      }
      if (liveState && !node.data.isLive) {
        return false
      }
      return true
    },
    [igState, hyState, liveState]
  )

  useEffect(() => {
    if (gridRef.current?.api) {
      if (loading) {
        gridRef.current.api.showLoadingOverlay()
      } else {
        if (!passiveOrders || passiveOrders.length === 0) {
          gridRef.current.api.showNoRowsOverlay()
        } else {
          gridRef.current.api.hideOverlay()
        }
      }
    }
  }, [loading, gridRef.current])

  const rowStyle = (params: any) => {
    if (!params.data.isLive) {
      return { backgroundColor: 'var(--secondaryBackgroundColor)' }
    }
  }

  return (
    <>
      <div className={styles.topMenu}>
        <Checkbox
          checkboxClass={styles.cb}
          locator="igCheckbox"
          checked={igState}
          onChange={igChanged}
        >
          IG
        </Checkbox>
        <Checkbox
          checkboxClass={styles.cb}
          locator="hyCheckbox"
          checked={hyState}
          onChange={hyChanged}
        >
          HY
        </Checkbox>
        <Checkbox
          checkboxClass={styles.cb}
          locator="liveCheckbox"
          checked={liveState}
          onChange={liveChanged}
        >
          Live
        </Checkbox>
      </div>
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <AgGridReact
          ref={gridRef}
          rowData={passiveOrders}
          columnDefs={admin ? adminColumnDefinitions : columnDefinitions}
          defaultColDef={defaultColumnDefinitions}
          overlayNoRowsTemplate="No orders found."
          overlayLoadingTemplate="Loading orders..."
          alwaysShowVerticalScroll={true}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          suppressDragLeaveHidesColumns={true}
          rowSelection={'single'}
          getRowStyle={rowStyle}
          enableCellTextSelection={true}
        />
      </div>
    </>
  )
}

export default PassiveOrdersGrid
