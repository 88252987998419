import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmOrder } from '../../store/lastLook/actions'
import { getSpotHedgePreferences } from '../../store/windows/selectors'
import styles from './ActivityCard.module.scss'
import { SpotCrossSelection } from '../../store/lastLook/types'

interface Props {
  id: string
}

const AcceptButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const securities = useSelector(getSpotHedgePreferences)
  const securityModalInfo = securities(id)
  const [spotCrossSelection, setSpotCrossSelection] =
    useState<SpotCrossSelection>('D')

  useEffect(() => {
    if (securityModalInfo) {
      securityModalInfo.spotHedgePreference === 'H'
        ? setSpotCrossSelection('H')
        : setSpotCrossSelection('S')
    } else {
      setSpotCrossSelection('D') // Flag for Default -- backend will use trader preference
    }
  }, [securityModalInfo])

  const handleAcceptClick = useCallback(() => {
    dispatch(confirmOrder(id, spotCrossSelection))
  }, [spotCrossSelection])

  return (
    <button
      data-testid="pending-confirm"
      className={cx(styles.actionButton, 'confirm')}
      onClick={handleAcceptClick}
    >
      Accept
    </button>
  )
}

export default AcceptButton
