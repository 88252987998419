import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faPlay,
  faSquareXmark
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useManageOneOrderType } from '../../../helpers/hooks/useManageMyOrders'
import {
  cancelOrder,
  setValidation,
  setValidationOpen
} from '../../../store/order/actions'
import { OrderType } from '../../../store/order/types'
import styles from '../bondListStyle.module.scss'
import {
  CellProps,
  useSecurityStaticData,
  useStagedAndLiveOrdersValidation
} from './helpers'

const SubmitOrCancelCell =
  (orderType: OrderType) =>
  ({ data }: CellProps) => {
    const security = useSecurityStaticData(data)
    const { stagedOrder, liveOrder, orderValidation } =
      useStagedAndLiveOrdersValidation(security?.id, orderType)
    const { submitStagedOrder } = useManageOneOrderType(orderType)
    const dispatch = useDispatch()

    const setSelectedValidation = useCallback(() => {
      if (security) {
        dispatch(setValidation(security?.id, orderType))
        dispatch(setValidationOpen(true))
      }
    }, [orderValidation])

    const cancelLiveOrder = useCallback(() => {
      dispatch(cancelOrder(liveOrder!.id))
    }, [liveOrder])

    const createLiveOrder = useCallback(() => {
      submitStagedOrder(security)
    }, [security, stagedOrder])

    if (!security) {
      return <> </>
    }

    if (liveOrder) {
      return (
        <div
          className={cx(styles.action, styles.cancel)}
          onClick={cancelLiveOrder}
          data-testid={`order-cancel-${data.id}`}
        >
          <FontAwesomeIcon icon={faSquareXmark} />
        </div>
      )
    }

    if (stagedOrder) {
      if (orderValidation) {
        return (
          <div
            className={cx(styles.action, styles.warning)}
            onClick={setSelectedValidation}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )
      } else {
        return (
          <div
            className={cx(styles.action, styles.submit)}
            onClick={createLiveOrder}
            data-testid={`order-submit-${data.id}`}
          >
            <FontAwesomeIcon icon={faPlay} />
          </div>
        )
      }
    }

    return <> </>
  }

SubmitOrCancelCell.displayName = 'SubmitOrCancelCell'

export default SubmitOrCancelCell
