import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getClickhouseQueries = createSelector(
  [getState],
  (state) => state.queries
)

export const getQueryResult = createSelector(
  [getState],
  (state) => state.lastQueryResult
)

export const getWaitingForQuery = createSelector(
  [getState],
  (state) => state.waitingForQuery
)

export const getQueryError = createSelector(
  [getState],
  (state) => state.errorMsg
)

export const searchIsPending = createSelector(
  [getState],
  (state) => () => state.search.pending
)

export const searchHasError = createSelector(
  [getState],
  (state) => () => state.search.error
)

export const getSearchResults = createSelector(
  [getState],
  (state) => state.search.results
)
