import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getSearchResults } from '../../../../store/admin/query/selectors'
import { Security } from '../../../../store/securities/reducer'
import DropdownList from '../../../DropdownList/DropdownList'
import styles from './SearchBondsResultsDropdown.module.scss'
import SecuritySearchResultIssuer from './SecuritySearchResultIssuer'
import SecuritySearchResultListItem from './SecuritySearchResultListItem'
import { IssuerSecurities, getSearchResultsByIssuer } from './selectors'

export interface Props {
  search: string
  setSearch: (st: string) => void
  hideDropdown: () => void
}

const SearchBondsResultsDropdown: FC<Props> = ({
  search,
  setSearch,
  hideDropdown
}) => {
  const searchResults = useSelector(getSearchResultsByIssuer)
  const allResults = useSelector(getSearchResults)

  const onSecurityClicked = useCallback(
    (issuerId: string, securityId: Security['id']) => {
      const selectedSec = allResults.filter((sec) => sec.id === securityId)
      const cusipIsin = selectedSec[0].cusip.length
        ? selectedSec[0].cusip
        : selectedSec[0].isin
      setSearch(cusipIsin)
      hideDropdown()
    },
    [searchResults]
  )

  const getLabel = (result: IssuerSecurities, isInWatchlist: boolean) => {
    return (
      <SecuritySearchResultIssuer
        issuerSymbol={result.issuer}
        numberOfSecurities={result.securities.length}
        search={search}
      />
    )
  }

  const getIssuerSection = (
    result: IssuerSecurities,
    expanded: boolean,
    isInWatchlist: boolean,
    idPrefix = ''
  ) => ({
    id: idPrefix + result.issuer,
    label: getLabel(result, isInWatchlist),
    expanded,
    children: result.securities.map((sec) => ({
      id: sec.id,
      label: <SecuritySearchResultListItem securitySearchResult={sec} />
    }))
  })

  const getDropdownElements = () => {
    const watchlistElements = searchResults.map((result) =>
      getIssuerSection(result, searchResults.length === 1, true)
    )

    /* const displayNotInWatchlistElements =
      watchlist &&
      watchlist.canEdit &&
      searchResultsIgnoringWatchlist.length > 0

    if (!displayNotInWatchlistElements) {
      return watchlistElements
    }*/

    return [...watchlistElements]
  }

  return (
    <div className={styles.contentDropDownMenu}>
      <DropdownList
        elements={getDropdownElements()}
        onChildSelected={onSecurityClicked}
      />
    </div>
  )
}

export default SearchBondsResultsDropdown
