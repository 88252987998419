import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { getDepthOfMarketOrder } from '../depthOfMarket/selectors'
import { createTempAggressorOrder } from '../order/actions'
import { getAggressorOrderByInitialOrder } from '../order/selectors'
import { logError } from '../ws/actions'
import {
  addAggressorWindow,
  FetchDataForSecurityModalAction,
  foregroundAggressorWindow,
  OpenAggressorWindowAction,
  removeAggressorWindow,
  UnsubscribeFetchDataForSecurityModalAction,
  updateDataForSecurityModal
} from './actions'
import { createSecurityModalInfoFromResponse } from './helpers'
import { getAggressorWindows } from './selectors'
import { SecurityModalInfo } from './types'

const fetchDataForSecurityModalEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<FetchDataForSecurityModalAction>(
      'windows.fetchDataForSecurityModal'
    ),
    mergeMap(({ payload: { orderId, size } }) => {
      const selUser = state$.value.users ? state$.value.users.selectedUser : 0
      return getHub()
        .stream<SecurityModalInfo>('GetAggressorView', selUser, orderId, size)
        .pipe(
          map(createSecurityModalInfoFromResponse),
          map((securityModalInfo) =>
            updateDataForSecurityModal(orderId, securityModalInfo)
          ),
          takeUntil(
            action$.pipe(
              ofType<UnsubscribeFetchDataForSecurityModalAction>(
                'windows.unsubscribeFetchDataForSecurityModal'
              ),
              filter(
                (unsubscribeAction) =>
                  unsubscribeAction.payload.orderId === orderId
              )
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const openAggressorWindowEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<OpenAggressorWindowAction>('windows.openAggressorWindow'),
    mergeMap((action) => {
      const { initialOrderId, securityId, listId } = action.payload
      const windows = getAggressorWindows(state$.value)
      const initialOrder = getDepthOfMarketOrder(state$.value)(
        initialOrderId,
        securityId
      )
      const getAggressorOrder = getAggressorOrderByInitialOrder(state$.value)
      if (!initialOrder) {
        throw new Error(`Can’t find initial order with ID ${initialOrderId}`)
      }

      const existingWindow = windows.find(
        (window) => window.initialOrderId === initialOrderId
      )
      if (existingWindow) {
        const aggressorOrder = getAggressorOrder(initialOrderId)
        if (!aggressorOrder || aggressorOrder?.status === 'creationPending') {
          return of(foregroundAggressorWindow(initialOrderId))
        }
      }

      const transactionId = Math.random()
      return from([
        createTempAggressorOrder(initialOrder, transactionId),
        ...(existingWindow ? [removeAggressorWindow(existingWindow)] : []),
        addAggressorWindow({
          initialOrderId: action.payload.initialOrderId,
          securityId,
          transactionId,
          listId
        })
      ])
    })
  )

export default combineEpics(
  openAggressorWindowEpic,
  fetchDataForSecurityModalEpic
)
