export enum QuoteReliability {
  All = 0,
  HighFillRate,
  Firm
}

export const QuoteReliabilityDisplayNames: Record<string, string> = {
  All: 'All',
  HighFillRate: '90+% Fill Rate',
  Firm: '~Firm Only'
}
