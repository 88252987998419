import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getActivityLog = createSelector(
  [getState],
  state => state.activityLog
)

export const getActivityLogIsLoading = createSelector(
  [getState],
  state => state.pending
)

export const getActivityLogError = createSelector(
  [getState],
  state => state.error
)
