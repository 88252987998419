import { addOrUpdateUser } from './helpers'
import {
  User,
  USER_ADD,
  USER_FETCH,
  USER_FETCH_FAIL,
  USER_SET_SELECTED_USER,
  UsersAction
} from './types'

export interface State {
  users?: User[]
  pending: boolean
  error: boolean
  selectedUser: number
}

export const initialState: State = {
  users: undefined,
  pending: true,
  error: false,
  selectedUser: 0
}

export default (state = initialState, action: UsersAction): State => {
  switch (action.type) {
    case USER_FETCH:
      return {
        ...state,
        pending: true,
        users: undefined,
        error: false
      }
    case USER_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case USER_ADD:
      let items: User[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.users) {
        return {
          ...state,
          users: items
        }
      }

      return {
        ...state,
        users: addOrUpdateUser(state.users, items)
      }
    case USER_SET_SELECTED_USER:
      let userId = 0
      if ('payload' in action) {
        userId = action.payload ?? 0
      }
      return {
        ...state,
        selectedUser: userId
      }
    default:
      return state
  }
}
