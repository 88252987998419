import { CheckedOrderAction } from './actions'
import {
  addCheckedOrder,
  removeCheckedOrder,
  removeCheckedOrders,
  toggleCheckedOrder
} from './helpers'
import { CheckedOrder } from './types'

export interface State {
  checkedOrders: CheckedOrder[]
  tempCheckedOrders: CheckedOrder[]
}

const initialState: State = {
  checkedOrders: [],
  tempCheckedOrders: []
}

export default (state = initialState, action: CheckedOrderAction) => {
  switch (action.type) {
    case 'checkedOrders.checkOrder':
      return {
        ...state,
        checkedOrders: addCheckedOrder(state.checkedOrders, action.payload)
      }

    case 'checkedOrders.checkOrders':
      return {
        ...state,
        checkedOrders: action.payload.reduce(
          addCheckedOrder,
          state.checkedOrders
        )
      }

    case 'checkedOrders.toggleOrder':
      return {
        ...state,
        checkedOrders: toggleCheckedOrder(state.checkedOrders, action.payload)
      }
    case 'checkedOrders.uncheckOrder':
      return {
        ...state,
        checkedOrders: removeCheckedOrder(state.checkedOrders, action.payload)
      }
    case 'checkedOrders.uncheckOrders':
      return {
        ...state,
        checkedOrders: removeCheckedOrders(state.checkedOrders, action.payload)
      }
    case 'checkedOrders.reset':
      return {
        ...state,
        checkedOrders: []
      }
    case 'checkedOrders.createTempCheckedOrders':
      const newState = {
        ...state,
        tempCheckedOrders: action.payload.checkedOrders
      }
      return newState

    case 'checkedOrders.applyTempCheckedOrders':
      return {
        ...state,
        tempCheckedOrders: [],
        checkedOrders:
          state.tempCheckedOrders.length > 0
            ? state.tempCheckedOrders
            : state.checkedOrders
      }

    case 'checkedOrders.updateCheckedOrders':
      return {
        ...state,
        checkedOrders: [
          ...state.checkedOrders.filter(
            co => co.orderType !== action.payload.orderType
          ),
          ...action.payload.securityIds.map(securityId => ({
            securityId,
            orderType: action.payload.orderType
          }))
        ]
      }
    default:
      return state
  }
}
