import {
  ADMINTRADEBLOTTER_FETCH,
  ADMINTRADEBLOTTER_FETCH_CANCEL,
  ADMINTRADEBLOTTER_FETCH_FAIL,
  ADMINTRADEBLOTTER_SUCCESS,
  AdminTradeBlotterFetchAction,
  AdminTradeBlotterFetchCancelAction,
  AdminTradeBlotterFetchFailAction,
  AdminTradeBlotterSuccessAction,
  TradeInfo
} from './types'

export const adminTradeBlotterFetch = (): AdminTradeBlotterFetchAction => ({
  type: ADMINTRADEBLOTTER_FETCH
})

export const adminTradeBlotterFetchFail = (
  error: any
): AdminTradeBlotterFetchFailAction => ({
  type: ADMINTRADEBLOTTER_FETCH_FAIL,
  payload: error,
  error: true
})

export const getAdminTradeBlotterSuccess = (
  tradeInfo: TradeInfo[]
): AdminTradeBlotterSuccessAction => ({
  type: ADMINTRADEBLOTTER_SUCCESS,
  payload: tradeInfo
})

export const unsubscribeFromBlotter =
  (): AdminTradeBlotterFetchCancelAction => ({
    type: ADMINTRADEBLOTTER_FETCH_CANCEL
  })
