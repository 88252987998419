import { CustomCellRendererProps } from '@ag-grid-community/react'
import React from 'react'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { getListTradeOrders } from '../../../store/order/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppSelector } from '../../../store/types'
import { TradingListContext } from '../Grid'
import { getDisplayStatus } from '../helpers'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'

// TODO: ListTradingSecurityFieldRenderer should handle this
const InnerStatus = ({
  security,
  watchlistId
}: {
  security: ListTradingSecurity
  watchlistId: number
}) => {
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const aggressOrders = getAggressOrders(
    security.id,
    security.isBid ? 'buy' : 'sell',
    watchlistId
  )

  const status = security.error || getDisplayStatus(aggressOrders[0]?.status)

  return <div>{status}</div>
}

const StatusRenderer = ({
  data,
  context
}: CustomCellRendererProps<SecurityStaticData, any, TradingListContext>) => {
  const security = useGetListTradingSecurity(data?.id)

  if (!security || !context.watchlistId) return <></>

  return <InnerStatus security={security} watchlistId={context.watchlistId} />
}

export default StatusRenderer
