import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const isUploadPending = createSelector(
  [getState],
  (state) => state.pending
)

export const hasUploadError = createSelector([getState], (state) => state.error)

export const getInvalidIdentifiers = createSelector(
  [getState],
  (state) => state.invalidIdentfiers
)

export const getNewWatchlistTransactionId = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.newWatchlistTransactionId[gridIndex]
)

export const getDropdownState = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.dropdownState[gridIndex] || 'closed'
)
