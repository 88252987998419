import cx from 'classnames'
import React from 'react'
import LastLookOpener from '../../containers/Openfin/LastLookOpener'
import AggressorModalStack from '../Modal/AggressorModalStack/AggressorModalStack'
import styles from '../SecuritiesTable.module.scss'

export const AggressorAndLastLook = () => {
  return (
    <div className={cx(styles.aggressorAndLastLookwrapper)}>
      <AggressorModalStack />
      <LastLookOpener />
    </div>
  )
}
