import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router'
import Loading from '../components/Loading/Loading'
import { setAuth, useToken } from '../helpers/auth'
import { needsWSConnection } from '../helpers/hoc'

// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
const ProtectedRouteParent = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }

    setAuth(authState)
  }, [oktaAuth, !!authState, authState?.isAuthenticated])

  useToken(oktaAuth)

  if (!authState || !authState?.isAuthenticated) {
    return <Loading />
  }

  return <Outlet />
}

export default needsWSConnection(ProtectedRouteParent)
