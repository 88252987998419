import { addOrUpdateAggressAttempt } from './helpers'
import {
  AggressAttempt,
  AGGRESSATTEMPT_ADD,
  AGGRESSATTEMPT_FETCH,
  AGGRESSATTEMPT_FETCH_FAIL,
  AggressAttemptAction
} from './types'

export interface State {
  aggressAttempts?: AggressAttempt[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  aggressAttempts: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: AggressAttemptAction): State => {
  switch (action.type) {
    case AGGRESSATTEMPT_FETCH:
      return {
        ...state,
        pending: true,
        aggressAttempts: undefined,
        error: false
      }

    case AGGRESSATTEMPT_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case AGGRESSATTEMPT_ADD:
      let items: AggressAttempt[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.aggressAttempts) {
        return {
          ...state,
          aggressAttempts: items
        }
      }

      return {
        ...state,
        aggressAttempts: addOrUpdateAggressAttempt(state.aggressAttempts, items)
      }

    default:
      return state
  }
}
