import { defaultSearch, Search } from '../../searchSecurities/reducer'
import { QueryAction } from './actions'
import { PlotType, Query, QueryResult } from './types'

export interface State {
  lastQueryResult: QueryResult
  waitingForQuery: boolean
  queries: Query[]
  error: boolean
  errorMsg: string
  search: Search
}

const initialState: State = {
  lastQueryResult: {
    queryName: '',
    data: [],
    rawData: '',
    chartType: PlotType.Line,
    breaks: [],
    queryTitle: ''
  },
  waitingForQuery: false,
  queries: [],
  error: false,
  errorMsg: '',
  search: defaultSearch
}

export default (state = initialState, action: QueryAction): State => {
  switch (action.type) {
    case 'query.SetClickhouseQueriesAction':
      return {
        ...state,
        queries: action.payload.queries,
        error: false,
        errorMsg: ''
      }
    case 'query.RunQueryFailure':
      return {
        ...state,
        error: true,
        errorMsg: action.payload.errorMsg,
        waitingForQuery: false
      }
    case 'query.SetLastQueryResult':
      return {
        ...state,
        lastQueryResult: action.payload.result,
        error: false,
        errorMsg: '',
        waitingForQuery: false
      }
    case 'query.ClearLastQueryResult':
      return {
        ...state,
        lastQueryResult: {
          queryName: '',
          data: [],
          rawData: '',
          chartType: PlotType.Line,
          breaks: [],
          queryTitle: ''
        },
        error: false,
        errorMsg: '',
        waitingForQuery: true
      }
    case 'query.searchSecurities':
      return {
        ...state,
        search: {
          ...state.search,
          pending: true,
          error: false,
          results: (action.payload.search !== '' && state.search.results) || [],
          resultsIgnoringWatchlist: []
        }
      }
    case 'query.searchSecuritiesSuccess':
      return {
        ...state,
        search: {
          ...state.search,

          pending: false,
          results: action.payload.securities,
          resultsIgnoringWatchlist: []
        }
      }
    case 'query.searchSecuritiesFailure':
      return {
        ...state,
        search: {
          ...state.search,

          pending: false,
          error: true,
          results: [],
          resultsIgnoringWatchlist: []
        }
      }
    default:
      return state
  }
}
