import {
  Venue,
  VENUE_ADD,
  VENUE_FETCH,
  VENUE_FETCH_FAIL,
  VenueAction
} from './types'

export interface State {
  venues?: Venue[]
  pending: boolean
  error: boolean
}

export const initialState: State = {
  venues: undefined,
  pending: true,
  error: false
}

export default (state = initialState, action: VenueAction): State => {
  switch (action.type) {
    case VENUE_FETCH:
      return {
        ...state,
        pending: true,
        venues: undefined,
        error: false
      }
    case VENUE_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case VENUE_ADD:
      let items: Venue[] = []

      if ('payload' in action) {
        items = action.payload ?? []
      }

      if (!state.venues) {
        return {
          ...state,
          venues: items
        }
      }

      return {
        ...state,
        venues: items
      }

    default:
      return state
  }
}
